import { apiActions } from 'src/redux/Api';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { connect } from 'react-redux';
import CirrusInstallInfoPage, {
    Actions,
    Props,
} from './components/CirrusInstallInfoPage';
import { cirrusInstallInfoSelectors } from 'src/redux/CirrusInstallInfo';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        cirrusInstallCreatedDate:
            cirrusInstallInfoSelectors.getCreatedDate(state),
        cirrusInstallInfoLoaded: cirrusInstallInfoSelectors.getIsLoaded(state),
        cirrusInstallVersion: cirrusInstallInfoSelectors.getVersion(state),
    };
};

const mapDispatchToProps: (dispatch: any) => Actions = (dispatch) => {
    return {
        getCirrusInstallVersionInformation: () => {
            dispatch(apiActions.getCirrusInstallVersionInformation());
            dispatch(apiActions.getCirrusInstallVersionInformation());
        },
        getCirrusInstallDownloadLink: (callback) => {
            dispatch(apiActions.getCirrusInstallDownloadLink(callback));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CirrusInstallInfoPage);
