const en: Record<string, string> = require('./en.json');
const errorReasons: Record<string, string> = require('./enErrorReasons.json');
const errorSolutions: Record<
    string,
    string
> = require('./enErrorSolutions.json');

const getError = (errorMessage: string) => {
    if (errorMessage?.length > 0) {
        let title = en[errorMessage];
        let reason = errorReasons[errorMessage];
        let solution = errorSolutions[errorMessage];
        return {
            title,
            reason,
            solution,
        };
    }
};

export default getError;
