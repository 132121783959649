import { AddressService } from '../services';
import { apiConstants, apiActions } from '../redux/Api';
import { Middleware } from 'redux';
import { ApplicationState } from '../reduxSetup/interfaces';
import { NOT_FOUND } from '../redux/Projects/interfaces';
import { AddressDTO } from '../services/Dtos/AddressDTO';
import { AddressInfo } from '../redux/Addresses/interfaces';
import { addressActions } from '../redux/Addresses';
import { Guid } from 'guid-typescript';

const projectApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;

const addressMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const knownAction = action as apiActions.KnownAction;
    const addressService = new AddressService(projectApiUrl);

    switch (knownAction.type) {
        case apiConstants.GET_ADDRESSES:
            await addressService
                .GetAddresses(knownAction.subscriptionId)
                .then((addresses) => {
                    if (
                        addresses == null ||
                        addresses == undefined ||
                        addresses == NOT_FOUND
                    ) {
                        alert('No addresses found');
                    } else if (addresses as AddressDTO[]) {
                        let addressInfos = [];
                        for (let a of addresses) {
                            let address = a as AddressDTO;
                            let addressInfo: AddressInfo = {
                                ...address,
                                addressId: Guid.parse(address.addressId),
                            };
                            addressInfos.push(addressInfo);
                        }

                        dispatch(addressActions.SetAddresses(addressInfos));
                    }
                });
            break;
    }
};

export default addressMiddleware;
