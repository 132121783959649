import { Guid } from 'guid-typescript';
import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';
import api from './api';
import { CreateSubscriptionCommand } from './Dtos/CreateSubscriptionCommand';
import { syncServiceApi } from './syncServiceApi';
import { SubscriptionsDTO } from './Dtos/SubscriptionDTO';

export default class SubscriptionService {
    getSubscriptions() {
        const requestConfig = customApiScopes.syncServiceApiRequestConfig;

        return api({
            url: backends.syncServiceApi + '/api/subscriptions',
            method: 'GET',
            requestConfig,
        }).catch((error) => {
            if (error.status == 403) {
                throw error;
            }
            alert('Failed to get subscription');
            throw error;
        });
    }
    getEula(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return api({
            url: `${backends.projectApi}/api/me/action/eula`,
            method: 'GET',
            requestConfig,
        }).catch((error) => {
            if (error.status == 403) {
                throw error;
            }
            alert('Failed to get eula');
            throw error;
        });
    }
    setEula(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            return api({
                url: `${backends.projectApi}/api/me/action/eula?hasAccepted=true`,
                method: 'POST',
                options,
            });
        });
    }

    getSubscriptionRoleAssignments(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            return api({
                url: `${backends.projectApi}/api/subscriptions/${subscriptionId}/roleAssignments?include=children`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to get subscription');
                throw error;
            });
        });
    }
    createSubscription(createSubscriptionCommand: CreateSubscriptionCommand) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(createSubscriptionCommand),
            };
            return api({
                url: `${backends.projectApi}/api/subscriptions`,
                method: 'POST',
                options,
            });
        });
    }
}

const subscriptionApi = syncServiceApi.injectEndpoints({
    endpoints: (builder) => ({
        getSubscriptions: builder.query<SubscriptionsDTO, unknown>({
            query: () => `subscriptions`,
        }),
    }),
});

export const { useGetSubscriptionsQuery } = subscriptionApi;
