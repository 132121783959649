import { withStyles } from '@material-ui/core';
import {
    useSnackbar,
    VariantType,
    WithSnackbarProps,
    SnackbarProvider as nsSnackbarProvider,
} from 'notistack';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import useTranslation from 'src/hooks/useTranslation';

let useSnackbarRef: WithSnackbarProps;
let getTranslationRef: (
    key: string,
    values?: Record<string, string | number | boolean | undefined>
) => string;
export const SnackbarUtilsConfigurator: React.FC = () => {
    getTranslationRef = useTranslation();
    useSnackbarRef = useSnackbar();
    return null;
};

export default {
    success(msg: string) {
        this.toast(msg, 'success');
    },
    warning(msg: string) {
        this.toast(msg, 'warning');
    },
    info(msg: string) {
        this.toast(msg, 'info');
    },
    error(msg: string) {
        this.toast(msg, 'error');
    },
    toast(msg: string, variant: VariantType = 'default') {
        useSnackbarRef.enqueueSnackbar(getTranslationRef(msg), { variant });
    },
};

const iconStyles = {
    fontSize: 20,
    marginInlineEnd: 8,
};
type Icon = (props: SvgIconProps) => JSX.Element;
const ErrorIcon: Icon = (props) => (
    <SvgIcon {...props}>
        <path d="M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3ZM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5H14.9L19 9.1V14.9V14.9Z" />
        <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" />
        <path d="M11 7H13V14H11V7Z" />
    </SvgIcon>
);

export const iconVariants = {
    success: <CheckCircleOutlineIcon style={iconStyles} />,
    error: <ErrorIcon style={iconStyles} />,
    warning: <ReportProblemOutlinedIcon style={iconStyles} />,
    info: <InfoOutlinedIcon style={iconStyles} />,
};

export const SnackbarProvider = withStyles((theme) => ({
    variantSuccess: {
        backgroundColor: theme.palette.pastel.green,
        color: '#000',
    },
    variantError: { backgroundColor: theme.palette.pastel.pink, color: '#000' },
    variantWarning: {
        backgroundColor: theme.palette.pastel.yellow,
        color: '#000',
    },
    variantInfo: { backgroundColor: theme.palette.pastel.blue, color: '#000' },
}))(nsSnackbarProvider);

export * from 'notistack';
