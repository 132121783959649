import { useState, useEffect } from 'react';
import { useTranslation } from 'src/hooks';
import { InputLabel } from 'src/components/Input';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import GlobalAccessSelector from './GlobalAccessSelector';
import ProjectAccessSelector from './ProjectAccessSelector';
import { UsersService } from 'src/services';
import { Button } from '@ndc/react-component-library';
import { RoleAssignment, User } from 'src/services/interfaces';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { Role, RoleIds } from 'src/constants/enums';
import { ProjectInfo } from 'src/redux/Projects/interfaces';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-start;
    margin-top: 40px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
`;

export type Props = {
    subscriptionId: string;
    resource: ProjectInfo | SubscriptionInfo;
    scopeType: 'Global' | 'Project';
    subscriptionOwnerEmail?: string;
    user: User;
    editUserRolesCallback: (
        scopeType: string,
        userName: string,
        userChanged: boolean,
        error?: string
    ) => void;
};

export type Actions = {};

const EditUserRoles = ({
    subscriptionId,
    resource,
    scopeType,
    subscriptionOwnerEmail,
    user,
    editUserRolesCallback,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const isGlobalScope = scopeType == 'Global';
    const scope = isGlobalScope
        ? '/subscriptions/' + subscriptionId
        : '/subscriptions/' +
          subscriptionId +
          '/projects/' +
          (resource as ProjectInfo).id;

    const getActiveRoles = () => {
        return user.roles.filter((r) => r.scope == scope);
    };

    const getSelectedRolesFromUser = () => {
        const rolesWithThisScope = getActiveRoles();

        const selectedRoles = rolesWithThisScope.map((role) =>
            role.roleId.toLocaleLowerCase()
        );

        const uniqueRoles = [...new Set(selectedRoles)];
        return uniqueRoles;
    };

    const [selectedRoles, setSelectedRoles] = useState(
        getSelectedRolesFromUser()
    );
    const [showMissingRolesError, setShowMissingRolesError] = useState(false);
    const [userPermissionsAreBeingUpdated, setUserPermissionsAreBeingUpdated] =
        useState(false);

    useEffect(() => {
        setShowMissingRolesError(false);
    }, [selectedRoles]);

    const addUserRoles = (roleIds: string[]) => {
        const usersService = new UsersService();

        const principalId = user.userOrGroupId;

        return Promise.all(
            roleIds.map((roleId) =>
                usersService.addRoleAssignment(scope, roleId, principalId)
            )
        );
    };

    const removeUserRoles = (roles: RoleAssignment[]) => {
        const usersService = new UsersService();
        return Promise.all(
            roles.map((role) =>
                usersService.removeRoleAssignment(scope, role.roleAssignmentId)
            )
        );
    };

    const validateAndSaveUser = () => {
        if (userPermissionsAreBeingUpdated) return;
        let validationPassed = true;

        if (selectedRoles.length == 0) {
            setShowMissingRolesError(true);
            validationPassed = false;
        }

        if (validationPassed) {
            const previousRoles = getActiveRoles();
            const rolesToRemove: RoleAssignment[] = [];
            const previousRoleIds = previousRoles.map((role) => {
                const roleId = role.roleId;
                if (
                    roleId &&
                    !selectedRoles.includes(roleId.toLocaleLowerCase())
                ) {
                    rolesToRemove.push(role);
                }
                return roleId.toLocaleLowerCase();
            });

            const rolesToAdd = selectedRoles.filter(
                (roleId) => !previousRoleIds.includes(roleId)
            );

            const userAddActionPromises: Promise<any>[] = [];
            const userRemoveActionPromises: Promise<any>[] = [];
            if (rolesToAdd.length > 0) {
                userAddActionPromises.push(addUserRoles(rolesToAdd));
            } else {
                userAddActionPromises.push(Promise.resolve());
            }

            if (rolesToRemove.length > 0) {
                userRemoveActionPromises.push(removeUserRoles(rolesToRemove));
            } else {
                userRemoveActionPromises.push(Promise.resolve());
            }

            if (rolesToAdd.length > 0 || rolesToRemove.length > 0) {
                setUserPermissionsAreBeingUpdated(true);
                Promise.all(userAddActionPromises)
                    .then(() => {
                        Promise.all(userRemoveActionPromises)
                            .then(() => {
                                setUserPermissionsAreBeingUpdated(false);
                                editUserRolesCallback(
                                    scopeType,
                                    user.name,
                                    true
                                );
                            })
                            .catch((error) => {
                                setUserPermissionsAreBeingUpdated(false);
                                editUserRolesCallback(
                                    scopeType,
                                    user.name,
                                    false,
                                    error
                                );
                            });
                    })
                    .catch((error) => {
                        setUserPermissionsAreBeingUpdated(false);
                        editUserRolesCallback(
                            scopeType,
                            user.name,
                            false,
                            error
                        );
                    });
            } else {
                editUserRolesCallback(scopeType, user.name, false);
            }
        }
    };

    const renderResourceName = () => (
        <Typography data-testid="resource-name">
            {isGlobalScope
                ? (resource as SubscriptionInfo).displayName
                : (resource as ProjectInfo).commissioningProject}
        </Typography>
    );

    const renderAccessModule = () => {
        const props = {
            setSelectedRoles,
            defaultSelectedRoles: selectedRoles,
            showMissingRolesError,
        };
        return isGlobalScope ? (
            <GlobalAccessSelector
                {...props}
                disableRoleChange={
                    subscriptionOwnerEmail == user.email &&
                    getSelectedRolesFromUser().includes(
                        RoleIds[Role.Owner].toString().toLowerCase()
                    )
                }
            />
        ) : (
            <ProjectAccessSelector {...props} />
        );
    };

    return (
        <Container>
            <Container>
                {renderResourceName()}
                {renderAccessModule()}
            </Container>
            <ButtonContainer>
                <Button
                    data-testid="edit-user-button"
                    key={'edit-user-button'}
                    onClick={validateAndSaveUser}
                    disabled={userPermissionsAreBeingUpdated}
                >
                    {getTranslation(translationKeys.General_Save)}
                </Button>
                {userPermissionsAreBeingUpdated && (
                    <CircularProgress size="35px" color="secondary" />
                )}
            </ButtonContainer>
        </Container>
    );
};

export default EditUserRoles;
