//Mainly used for smoother communication between React and PIXI,
//but also for some communication within PIXI

export enum EVENTS {
    ON_DESELECT_ALL_OBJECTS = 'ondeselectallobjects',
    ON_MAP_CLICKED = 'onmapclicked',
    ON_SELECT_OBJECTS = 'onselectobjects',
}

type ListenerRemover = () => boolean;
type ListenerAction = (...args: Array<any>) => void;
type DefinedEvents = EVENTS;

class CustomListener {
    private listeners: Array<ListenerAction> = [];

    public getSize = (): number => {
        return this.listeners.length;
    };

    public add = (action: ListenerAction): ListenerRemover => {
        this.listeners.push(action);
        let removed = false;
        return (): boolean => {
            if (removed) {
                return false;
            }
            removed = true;
            return this.remove(action);
        };
    };

    public remove = (action: ListenerAction): boolean => {
        for (let i = 0; i < this.listeners.length; ++i) {
            if (action === this.listeners[i]) {
                this.listeners.splice(i, 1);
                return true;
            }
        }
        return false;
    };

    public trigger = (...args: Array<any>): void => {
        for (let i = 0; i < this.listeners.length; ++i) {
            const listener = this.listeners[i];
            listener.apply(listener, args);
        }
    };

    public clean = (): void => {
        this.listeners = [];
    };
}

export default class EventHandler {
    private static instance: EventHandler;
    private count: number = 0;
    private listeners: Record<string, CustomListener> = {};

    public static getInstance(): EventHandler {
        if (!EventHandler.instance) {
            EventHandler.instance = new EventHandler();
        }

        return EventHandler.instance;
    }

    public getSize = (event?: DefinedEvents | null): number => {
        if (event) {
            const entry = this.listeners[event];
            if (entry) {
                return entry.getSize();
            }
            return 0;
        }
        return this.count;
    };

    public add = (
        event: DefinedEvents,
        action: ListenerAction
    ): ListenerRemover => {
        this.count += 1;
        const entry =
            this.listeners[event] ??
            (this.listeners[event] = new CustomListener());
        const remove = entry.add(action);
        return (): boolean => {
            if (remove()) {
                this.count -= 1;
                if (!entry.getSize()) {
                    delete this.listeners[event];
                }
                return true;
            }
            return false;
        };
    };

    public remove = (event: DefinedEvents, action: ListenerAction): boolean => {
        const entry = this.listeners[event];
        if (entry == null) {
            return false;
        }
        if (action) {
            if (entry.remove(action)) {
                this.count -= 1;
                if (!entry.getSize()) {
                    delete this.listeners[event];
                }
                return true;
            }
            return false;
        } else {
            this.count -= entry.getSize();
            delete this.listeners[event];
            return true;
        }
    };

    public trigger = (event: DefinedEvents, eventData?: unknown): void => {
        const entry = this.listeners[event];
        if (entry) {
            entry.trigger(eventData);
        }
    };

    public clean = (): void => {
        if (this.count > 0) {
            this.count = 0;
            this.listeners = {};
        }
    };
}
