import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { history } from 'src/routing';
import Layout from '../../Layout/components/Layout';
import { Projects, Companies, Users, ProjectViewer, Settings } from 'src/pages';
// import './custom.css';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { CreateEditCompany } from 'src/pages/CreateEditCompany';
import { CreateEditProject } from 'src/pages/CreateEditProject';
import {
    InviteExpired,
    NotAuthorized,
    NotAuthorizedResource,
} from 'src/pages/Error';
import { B2cErrorCode } from 'src/constants/enums';
import { Login } from 'src/pages/Login';
import { CirrusInstallInfoPage } from 'src/pages/CirrusInstallInfoPage';
import { isValidJSON, addLeadingSlash } from 'src/helpers/utils';
import { AboutCirrusPage } from 'src/pages/AboutCirrusPage';
import { LayoutViewer } from 'src/pages/LayoutViewerPage';
import { ModalsContainer } from 'src/components/Modals';
import { KMAdmin } from 'src/pages/KMAdminPage';

interface StringMap {
    [key: string]: string;
}
const App = () => {
    let hash = window.location.hash;
    if (hash && hash.length > 0) {
        hash = hash.slice(1);
        let params: StringMap = {};
        hash.split('&').forEach((hk) => {
            let temp = hk.split('=');
            params[temp[0]] = temp[1];
        });
        if (
            params.hasOwnProperty('error') &&
            hash.indexOf(B2cErrorCode.InviteExpired) > -1
        ) {
            history.push('/error/invite-expired');
        }
        if (params.hasOwnProperty('state')) {
            const redirectStates = unescape(params.state)
                .split(',')
                .filter((n) => n);
            const redirectState =
                redirectStates.length > 0 ? redirectStates[0] : '';
            if (isValidJSON(redirectState)) {
                const stateObject = JSON.parse(redirectState);
                if (stateObject.hasOwnProperty('subPage')) {
                    const subPageRedirect = addLeadingSlash(
                        stateObject.subPage
                    );
                    history.push(subPageRedirect);
                }
            }
        }
    }

    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <Switch>
                    <Route exact path="/error/not-authorized">
                        <NotAuthorized />
                    </Route>
                    <Route exact path="/error/not-authorized-resource">
                        <NotAuthorizedResource />
                    </Route>
                    <Route>
                        <Layout>
                            <Route
                                path="/subscriptions/:subscriptionGuid/project/:projectGuid/visualize"
                                component={LayoutViewer}
                                exact
                            />
                            <Route
                                path="/project/:projectGuid/visualize"
                                component={LayoutViewer}
                                exact
                            />
                            <Route
                                path="/subscriptions/:subscriptionGuid/projects"
                                component={Projects}
                                exact
                            />
                            <Route exact path="/" component={Projects} />
                            <Route path="/projects" component={Projects} />
                            <Route
                                path="/subscriptions/:subscriptionGuid/users"
                                component={Users}
                                exact
                            />
                            <Route path="/users" component={Users} />
                            <Route
                                path="/subscriptions/:subscriptionGuid/companies"
                                component={Companies}
                                exact
                            />
                            <Route path="/companies" component={Companies} />
                            <Route
                                path="/subscriptions/:subscriptionGuid/project/:projectGuid"
                                component={ProjectViewer}
                                exact
                            />
                            <Route
                                path="/project/:projectGuid"
                                component={ProjectViewer}
                                exact
                            />
                            <Route
                                path="/subscriptions/:subscriptionGuid/create-company"
                                component={CreateEditCompany}
                                exact
                            />
                            <Route
                                path="/create-company"
                                component={CreateEditCompany}
                            />
                            <Route
                                path="/subscriptions/:subscriptionGuid/company/:companyGuid"
                                component={CreateEditCompany}
                                exact
                            />
                            <Route
                                path="/company/:companyGuid"
                                component={CreateEditCompany}
                            />
                            <Route
                                path="/subscriptions/:subscriptionGuid/create-project"
                                component={CreateEditProject}
                                exact
                            />
                            <Route
                                path="/create-project"
                                component={CreateEditProject}
                            />
                            <Route
                                path="/subscriptions/:subscriptionGuid/edit-project/:projectGuid"
                                component={CreateEditProject}
                                exact
                            />
                            <Route
                                path="/edit-project/:projectGuid"
                                component={CreateEditProject}
                            />
                            <Route
                                path="/cirrus-install"
                                component={CirrusInstallInfoPage}
                            />
                            <Route
                                path="/subscriptions/:subscriptionGuid/settings"
                                component={Settings}
                                exact
                            />
                            <Route path="/settings" component={Settings} />
                            <Route
                                path="/about-cirrus"
                                component={AboutCirrusPage}
                            />
                            <Route path="/km-admin" component={KMAdmin} />
                        </Layout>
                    </Route>
                </Switch>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Switch>
                    <Route path="/error/invite-expired">
                        <InviteExpired />
                    </Route>
                    <Route>
                        <Login />
                    </Route>
                </Switch>
            </UnauthenticatedTemplate>
            <ModalsContainer />
        </React.Fragment>
    );
};

export default App;
