const InfoIcon = () => {
    let width = 24;
    let height = 25;
    let viewBox = '0 0 24 25';

    return (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5Z"
                fill="#E0E0E0"
            />
            <path
                d="M11 7.5H13V9.5H11V7.5ZM11 11.5H13V17.5H11V11.5Z"
                fill="black"
            />
        </svg>
    );
};

export default InfoIcon;
