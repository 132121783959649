import translations from './en.json';

const createTranslationConstants = () => {
    const translationsConstants: Record<string, string> = {};
    Object.entries(translations).forEach(([key, value]) => {
        const newKey = key.replace(/\./g, '_');
        translationsConstants[newKey] = key;
    });
    return translationsConstants;
};

const translationConstants = createTranslationConstants();

export default translationConstants;
