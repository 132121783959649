import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PixiApp } from 'src/components/PixiObjects/PixiApp';
import { forEach } from 'lodash';
import useWindowSize, { WindowSize } from 'src/hooks/useWindowSize';
import { LayoutLayers } from 'src/constants/enums';
import {
    LayoutBackgroundDrawingDTO,
    LayoutVisualizationDataDTO,
} from 'src/services/Dtos';
import EventHandler, { EVENTS } from 'src/utils/eventHandler';
import {
    IClickedMapObjects,
    ISelectedObjects,
} from 'src/components/PixiObjects/interfaces';
import {
    PointDTO,
    SegmentDTO,
    UpdatesPointDTO,
    UpdatesSegmentDTO,
} from 'src/services/Dtos/LayoutVisualizationDataDTO';
import { OverlappingObjectsMenu } from 'src/components/OverlappingObjectsMenu';
import { getPointsMinMaxValues } from 'src/helpers/utils';
import { SegmentDetails } from 'src/components/PixiObjects/layoutObjects/Segment';
import { SidePanel } from 'src/components/SidePanel';
import SelectedPointModule from './SelectedPointModule';
import SelectedSegmentModule from './SelectedSegmentModule';

const PixiContainer = styled.div`
    height: 100%;
    width: 100%;
`;

interface CombinedSegmentDTO extends SegmentDTO, UpdatesSegmentDTO {}
interface CombinedPointDTO extends PointDTO, UpdatesPointDTO {}

export type Props = {
    layoutVisualizationData: LayoutVisualizationDataDTO;
    layoutBackgroundDrawingData: LayoutBackgroundDrawingDTO | null;
};
const eventHandler = EventHandler.getInstance();

const LayoutVisualization = ({
    layoutVisualizationData,
    layoutBackgroundDrawingData,
}: Props) => {
    const pixiApp = useRef() as MutableRefObject<PixiApp>;
    const size: WindowSize = useWindowSize();
    const pixiContainerRef = useRef() as MutableRefObject<HTMLInputElement>;
    const [layoutResourcesLoaded, setLayoutRecourcesLoaded] = useState(false);
    const [overlappingObjects, setOverlappingObjects] = useState<
        IClickedMapObjects | undefined
    >(undefined);
    const [overlappingObjectsMenuOpen, setOverlappingObjectsMenuOpen] =
        useState(false);
    const [overlappingObjectsMenuPosition, setOverlappingObjectsMenuPosition] =
        useState({ top: 0, left: 0 });
    const [sidePanelContent, setSidePanelContent] =
        useState<React.ReactNode>(undefined);

    useEffect(() => {
        pixiApp.current = PixiApp.getInstance();

        if (pixiApp.current.view === null) {
            return;
        }
        pixiApp.current.loader.onComplete.add(() => {
            setLayoutRecourcesLoaded(true);
        });
        if (pixiContainerRef.current.children.length > 0)
            pixiContainerRef.current.removeChild(
                pixiContainerRef.current.children[0]
            );
        pixiContainerRef.current.appendChild(pixiApp.current.view);

        eventHandler.add(EVENTS.ON_MAP_CLICKED, mapClickHandler);
        eventHandler.add(EVENTS.ON_SELECT_OBJECTS, selectedObjectsHandler);

        return () => {
            pixiApp.current.destroy();
            eventHandler.remove(EVENTS.ON_MAP_CLICKED, mapClickHandler);
            eventHandler.remove(
                EVENTS.ON_SELECT_OBJECTS,
                selectedObjectsHandler
            );
        };
    }, []);

    useEffect(() => {
        pixiApp.current.resize(
            pixiContainerRef.current.clientWidth,
            pixiContainerRef.current.clientHeight
        );
    }, [size]);
    useEffect(() => {
        if (!layoutResourcesLoaded) return;
        if (layoutVisualizationData) {
            drawLayoutData(layoutVisualizationData);
            pixiApp.current.resize(
                pixiContainerRef.current.clientWidth,
                pixiContainerRef.current.clientHeight
            );
            pixiApp.current.createCoordinatesHelpLines(
                pixiContainerRef.current.clientHeight
            );
            pixiApp.current.render();
        }
    }, [layoutResourcesLoaded, layoutVisualizationData]);
    useEffect(() => {
        if (!layoutResourcesLoaded) return;
        if (
            layoutBackgroundDrawingData &&
            layoutBackgroundDrawingData.svgPath
        ) {
            pixiApp.current.addBackgroundDrawing(
                layoutBackgroundDrawingData.svgPath
            );
        }
    }, [layoutResourcesLoaded, layoutBackgroundDrawingData]);

    const drawLayoutData = (
        layoutVisualizationData: LayoutVisualizationDataDTO
    ) => {
        pixiApp.current.addLayer(LayoutLayers.SegmentLayer);
        pixiApp.current.addLayer(LayoutLayers.PointLayer);
        pixiApp.current.addLayer(LayoutLayers.UpdatedSegmentLayer);
        pixiApp.current.addLayer(LayoutLayers.UpdatedPointLayer);
        forEach(layoutVisualizationData.base.points, (point) => {
            addPixiPoint(point as CombinedPointDTO, LayoutLayers.PointLayer);
        });
        forEach(layoutVisualizationData.base.segments, (segment) => {
            addPixiSegment(
                segment as CombinedSegmentDTO,
                LayoutLayers.SegmentLayer
            );
        });
        forEach(layoutVisualizationData.updates.points, (point) => {
            addPixiPoint(point, LayoutLayers.UpdatedPointLayer, true);
        });
        forEach(layoutVisualizationData.updates.segments, (segment) => {
            addPixiSegment(
                segment as CombinedSegmentDTO,
                LayoutLayers.UpdatedSegmentLayer,
                true
            );
        });
        const pointsBoundary = getPointsMinMaxValues(
            layoutVisualizationData.base.points
        );
        pixiApp.current.setVieportSizeAndPosition(
            pointsBoundary,
            pixiContainerRef.current.clientWidth,
            pixiContainerRef.current.clientHeight
        );
    };
    const addPixiPoint = (
        point: CombinedPointDTO,
        layerId: LayoutLayers,
        isUpdate = false
    ) => {
        pixiApp.current.addPoint(
            {
                id: point.id,
                x: point.x,
                y: -point.y,
                angle: point.angle,
                layerId,
                layoutUpdateId: point.layoutUpdateId,
            },
            isUpdate
        );
    };
    const addPixiSegment = (
        segment: CombinedSegmentDTO,
        layerId: LayoutLayers,
        isUpdate = false
    ) => {
        const startPoint = isUpdate
            ? pixiApp.current.updatedPoints[segment.startPointId]?.object ??
              pixiApp.current.points[segment.startPointId]?.object
            : pixiApp.current.points[segment.startPointId]?.object;
        const endPoint = isUpdate
            ? pixiApp.current.updatedPoints[segment.endPointId]?.object ??
              pixiApp.current.points[segment.endPointId]?.object
            : pixiApp.current.points[segment.endPointId]?.object;
        if (startPoint && endPoint) {
            pixiApp.current.addSegment(
                {
                    id: segment.id,
                    x: startPoint.x,
                    y: startPoint.y,
                    layerId,
                    startPoint: {
                        id: startPoint.id,
                        x: startPoint.x,
                        y: startPoint.y,
                        angle: startPoint.angle,
                    },
                    endPoint: {
                        id: endPoint.id,
                        x: endPoint.x,
                        y: endPoint.y,
                        angle: endPoint.angle,
                    },
                    controlPoints: segment.controlPoints ?? null,
                    curveParts: segment.curveParts ?? null,
                    smallPoints: segment.smallPoints ?? null,
                    rotationPoint: segment.rotationPoint,
                    segmentInfo: getSegmentInfo(segment),
                    layoutUpdateId: segment.layoutUpdateId,
                },
                isUpdate
            );
        }
    };
    const getSegmentInfo = (
        segment: CombinedSegmentDTO
    ): SegmentDetails | null => {
        if (!segment.templateId) return null;
        return {
            current: {
                templateId: segment.templateId,
                templateName: segment.templateName,
                travelTime: segment.travelTime,
            },
            project: { ...segment.project },
        };
    };
    const clickRouter = (selectedObjects: ISelectedObjects) => {
        setOverlappingObjectsMenuOpen(false);
        eventHandler.trigger(EVENTS.ON_SELECT_OBJECTS, selectedObjects);
    };
    const mapClickHandler = (data: {
        position: { x: number; y: number };
        clickedObjects: IClickedMapObjects;
    }) => {
        const { position, clickedObjects } = data;
        const clickedObjectsCount = Object.values(clickedObjects).reduce(
            (a, e) => a + e.length,
            0
        );
        if (clickedObjectsCount === 0) {
            setSidePanelContent(undefined);
            eventHandler.trigger(EVENTS.ON_DESELECT_ALL_OBJECTS);
            return;
        } else if (clickedObjectsCount > 1) {
            setOverlappingObjects(clickedObjects);
            setOverlappingObjectsMenuPosition({
                top: position.y + 40,
                left: position.x + 20,
            });
            setOverlappingObjectsMenuOpen(true);
            return;
        }

        eventHandler.trigger(EVENTS.ON_SELECT_OBJECTS, {
            points: [
                ...new Set([
                    ...clickedObjects.points,
                    ...clickedObjects.updatedPoints,
                ]),
            ],
            segments: [
                ...new Set([
                    ...clickedObjects.segments,
                    ...clickedObjects.updatedSegments,
                ]),
            ],
        } as ISelectedObjects);
    };
    const sidePanelCloseHandler = () => {
        setSidePanelContent(undefined);
        eventHandler.trigger(EVENTS.ON_DESELECT_ALL_OBJECTS);
    };
    const selectedObjectsHandler = (selectedObjects: ISelectedObjects) => {
        const selectedObjectsCount = Object.values(selectedObjects).reduce(
            (a, e) => a + e.length,
            0
        );
        if (
            selectedObjectsCount > 1 ||
            selectedObjects.points.length > 1 ||
            selectedObjects.segments.length > 1
        )
            return;
        if (selectedObjects.points.length === 1) {
            const selectedPoint = {
                base: pixiApp.current.points[selectedObjects.points[0]].object,
                updated:
                    pixiApp.current.updatedPoints[selectedObjects.points[0]]
                        ?.object ?? null,
            };
            setSidePanelContent(
                <SelectedPointModule
                    selectedPoint={selectedPoint}
                    onCloseBehaviour={sidePanelCloseHandler}
                />
            );
        }
        if (selectedObjects.segments.length === 1) {
            const isUpdated =
                !!pixiApp.current.updatedSegments[selectedObjects.segments[0]];
            const selectedSegment = {
                base: {
                    id: pixiApp.current.segments[selectedObjects.segments[0]]
                        .object.id,
                },
                updated: !isUpdated
                    ? null
                    : {
                          id: pixiApp.current.updatedSegments[
                              selectedObjects.segments[0]
                          ].object.id,
                          current: {
                              ...pixiApp.current.updatedSegments[
                                  selectedObjects.segments[0]
                              ].object.segment.segmentInfo?.current,
                          },
                          project: {
                              ...pixiApp.current.updatedSegments[
                                  selectedObjects.segments[0]
                              ].object.segment.segmentInfo?.project,
                          },
                          layoutUpdateId:
                              pixiApp.current.updatedSegments[
                                  selectedObjects.segments[0]
                              ].object.segment.layoutUpdateId,
                      },
            };
            setSidePanelContent(
                <SelectedSegmentModule
                    selectedSegment={selectedSegment}
                    onCloseBehaviour={sidePanelCloseHandler}
                />
            );
        }
    };

    return (
        <>
            <OverlappingObjectsMenu
                clickAwayHandler={() => {
                    if (overlappingObjectsMenuOpen)
                        setOverlappingObjectsMenuOpen(false);
                }}
                clickRouter={clickRouter}
                open={overlappingObjectsMenuOpen}
                clickedObjects={overlappingObjects}
                position={overlappingObjectsMenuPosition}
            />
            <PixiContainer
                tabIndex={0}
                id="pixi-canvas-container"
                ref={pixiContainerRef}
            />
            <SidePanel>{sidePanelContent}</SidePanel>
        </>
    );
};

export default LayoutVisualization;
