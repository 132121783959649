import { useState } from 'react';
import { useTranslation } from 'src/hooks';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { Guid } from 'guid-typescript';
import { UsersService } from 'src/services';
import { Button, ButtonSize, Variant } from '@ndc/react-component-library';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 40px;
    margin-right: -70px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
`;

export type Props = {
    operationDetails: any;
    closeModal: () => void;
    deleteOperationSettingCallback: (operationSettingIds: Guid[]) => void;
};

export type Actions = {};

const RemoveOperation = ({
    operationDetails,
    closeModal,
    deleteOperationSettingCallback,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    const renderRemoveConfirmation = () => {
        if (operationDetails.length > 1) {
            return (
                <Typography>
                    {getTranslation('Settings.RemoveOperationModal.Dialogue')}
                </Typography>
            );
        } else {
            return (
                <Typography>
                    {getTranslation(
                        'Settings.RemoveOperationModalSingluar.Dialogue',
                        {
                            n: operationDetails.name,
                            c: operationDetails.code,
                        }
                    )}
                </Typography>
            );
        }
    };

    const removeUser = () => {
        if (operationDetails.length) {
            deleteOperationSettingCallback(operationDetails);
        } else {
            const operationSettingId = operationDetails.id as unknown as Guid;
            const operationSettingIds: Guid[] = [];
            operationSettingIds.push(operationSettingId);
            deleteOperationSettingCallback(operationSettingIds);
        }
        closeModal();
    };

    return (
        <Container>
            {renderRemoveConfirmation()}
            <ButtonContainer>
                <Button
                    variant={Variant.secondary}
                    size={ButtonSize.small}
                    key={'cancel-remove-operation'}
                    data-testid="cancel-remove-operation"
                    onClick={() => closeModal()}
                >
                    {getTranslation(translationKeys.General_Cancel)}
                </Button>
                <Button
                    size={ButtonSize.small}
                    key={'remove-operation'}
                    data-testid="remove-operation"
                    onClick={removeUser}
                >
                    {getTranslation(translationKeys.General_Remove)}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default RemoveOperation;
