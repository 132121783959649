import { Guid } from 'guid-typescript';
import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';
// TODO: Update relevant Not Found
import { NOT_FOUND } from '../redux/Projects/interfaces';
import api from './api';
import { OperationSettingTemplate } from './Dtos/OperationSettingTemplate';
import {
    OperationSettingCommand,
    OperationSettingTemplateCommand,
} from './Dtos/OperationSettingCommand';

export default class OperationSettingsService {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    // Returns 204 if no template is available.
    GetOperationSettingsTemplates(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<OperationSettingTemplate[]>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to retrieve operation settings');
                throw error;
            });
        });
    }

    // Can be used when we know the template Id.
    // Returns 204 if no setting is available.
    GetOperationSettingTemplate(subscriptionId: Guid, templateId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<OperationSettingTemplate>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates/${templateId.toString()}`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to retrieve operation settings');
                throw error;
            });
        });
    }

    // Used for creating the first OperationSetting in a Project/Subscription.
    CreateOperationSettingTemplate(
        subscriptionId: Guid,
        operationSettingTemplateCommand: OperationSettingTemplateCommand
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(operationSettingTemplateCommand),
            };
            return api<OperationSettingTemplateCommand>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates`,
                method: 'POST',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to create operation setting');
                throw error;
            });
        });
    }

    CreateOperationSetting(
        subscriptionId: Guid,
        templateId: Guid,
        operationSettingCommand: OperationSettingCommand
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(operationSettingCommand),
            };
            return api<OperationSettingCommand>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates/${templateId.toString()}/operationsetting`,
                method: 'POST',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to create operation setting');
                throw error;
            });
        });
    }

    UpdateOperationSetting(
        subscriptionId: Guid,
        templateId: Guid,
        operationSettingId: Guid,
        operationSettingCommand: OperationSettingCommand
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(operationSettingCommand),
            };

            return api<string>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates/${templateId.toString()}/operationsetting/${operationSettingId.toString()}`,
                method: 'PATCH',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to update operation setting');
                throw error;
            });
        });
    }

    UpdateOperationCodeDisplayFormat(
        subscriptionId: Guid,
        templateId: Guid,
        operationCodeFormat: string
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<string>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates/
                ${templateId.toString()}/action/updateopcodeformat?operationCodeFormat=${operationCodeFormat}`,
                method: 'PUT',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to update operation code format');
                throw error;
            });
        });
    }

    DeleteOperationSetting(
        subscriptionId: Guid,
        templateId: Guid,
        operationSettingIds: Guid[]
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(operationSettingIds),
            };

            return api<string>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates/${templateId.toString()}
                /operationsetting`,
                method: 'DELETE',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                if (error.status == 404) {
                    return NOT_FOUND;
                }
                throw error;
            });
        });
    }

    // Deletes all the OperationSettings in a template (including the template).
    DeleteOperationSettingTemplate(subscriptionId: Guid, templateId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<string>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/operationsettingstemplates/${templateId.toString()}`,
                method: 'DELETE',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                if (error.status == 404) {
                    return NOT_FOUND;
                }
                throw error;
            });
        });
    }
}
