import React, { useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Typography,
    Checkbox,
    InputAdornment,
    Divider,
    Paper,
} from '@material-ui/core';
import styled from 'styled-components';
import { Button, ButtonVariant } from '@ndc/react-component-library';
import { InteractiveModule } from 'src/components/InteractiveModule';
import { InputTextField } from 'src/components/Input';
import useTranslation from 'src/hooks/useTranslation';
import translationKeys from 'src/translations';
import theme from 'src/theme';
import { OperationCodeFormat } from 'src/constants/enums';
import { hexToDecimal, isHex } from 'src/helpers/utils';
import { OperationSetting } from 'src/redux/OperationSettings/interfaces';

interface OperationSettingCommand {
    code: string | number;
    name: string;
    parameter1Name: string;
    parameter1Unit: string;
    parameter1Enabled: boolean;
    parameter2Name: string;
    parameter2Unit: string;
    parameter2Enabled: boolean;
    prerequisite: string;
    expectedBehavior: string;
    expectedFeedback?: string;
}

const StyledContainer = styled(Paper)`
    box-shadow: none;
    padding: ${({ theme }) =>
        `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(
            1
        )} ${theme.spacing(6)}`};
`;

const StyledDivider = styled(Divider)`
    background-color: ${({ theme }) => theme.palette.grey[300]};
    margin-top: 5px;
`;

const UsedParametersContainer = styled.div`
    display: flex;
`;

const StyledInputTextField = styled(InputTextField)`
    width: 370px;
    & input {
        padding: 6px 5px 7px;
    }
    & p {
        margin-left: 0;
    }
`;

const AdditionalInfoInputTextField = styled(InputTextField)`
    width: 370px;
    & div:first-child {
        padding: 6px 5px 7px;
        height: 100px;
    }
`;

const UsedParameterUnit = styled(InputTextField)`
    width: 120px;
    & input:first-child {
        padding: 6px 5px 7px;
    }
`;

const CustomCheckbox = withStyles({
    root: {
        '&$checked': {
            color: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
            color: '#bdbdbd',
        },
    },
    checked: {},
})(Checkbox);

type Props = {
    operation: string;
    sidePanelCloseHandler?: () => void;
    onSaveOperationClick: (
        operation: string,
        updatedOperationValues: OperationSettingCommand,
        operationSettingId: any
    ) => void;
    operationDetails?: any;
    operationSettings?: any;
    operationCodeFormat: string;
};

const SidePanelContent = ({
    operation,
    sidePanelCloseHandler,
    onSaveOperationClick,
    operationDetails,
    operationSettings,
    operationCodeFormat,
}: Props) => {
    const existingDetailsExceptSelected = operationSettings?.filter(
        (obj: OperationSetting) => obj?.id !== operationDetails?.id
    );
    const getTranslation = useTranslation();

    const [operationCodeError, setOperationCodeError] = useState(false);
    const [operationCodeIsReq, setOperationCodeIsReq] = useState(false);
    const [operationCodeInvalid, setOperationCodeInvalid] = useState(false);
    const [operationCodeNameInvalid, setOperationCodeNameInvalid] =
        useState(false);
    const [parameterNameInvalid, setParameterNameInvalid] = useState(false);
    const [parameterUnitInvalid, setParameterUnitInvalid] = useState(false);
    const [parameter2UnitInvalid, setParameter2UnitInvalid] = useState(false);
    const [plc2Checked, setPlc2Checked] = useState(false);
    const [formData, setFormData] = useState({
        code: '',
        name: '',
        parameter1Name: '',
        parameter1Unit: '',
        parameter1Enabled: true,
        parameter2Name: '',
        parameter2Unit: '',
        parameter2Enabled: false,
        prerequisite: '',
        expectedBehavior: '',
        expectedFeedback: '',
    });

    useEffect(() => {
        (operationDetails?.parameter2Name ||
            operationDetails?.parameter2Unit) &&
            setPlc2Checked(true);
    }, [operationDetails]);

    useEffect(() => {
        if (validateOperationCode(formData.code)) {
            setOperationCodeIsReq(false);
            setOperationCodeInvalid(false);
        }
    }, [formData]);

    useEffect(() => {
        if (validateOperationCodeName(formData.name)) {
            setOperationCodeNameInvalid(false);
        }
        if (validateParameter1Name(formData.parameter1Name)) {
            setParameterNameInvalid(false);
        }
        if (formData.parameter1Unit.length > 5) {
            setParameterUnitInvalid(true);
        } else setParameterUnitInvalid(false);
        if (formData.parameter2Unit.length > 5) {
            setParameter2UnitInvalid(true);
        } else setParameter2UnitInvalid(false);
    }, [formData]);

    useEffect(() => {
        // If operationDetails is provided, update the form data with its values
        if (operationDetails) {
            setFormData({
                code: operationDetails.code ?? '',
                name: operationDetails.name ?? '',
                parameter1Name: operationDetails.parameter1Name ?? '',
                parameter1Unit: operationDetails.parameter1Unit ?? '',
                parameter1Enabled: operationDetails.parameter1Enabled ?? true,
                parameter2Name: operationDetails.parameter2Name ?? '',
                parameter2Unit: operationDetails.parameter2Unit ?? '',
                parameter2Enabled: operationDetails.parameter2Enabled ?? false,
                prerequisite: operationDetails.prerequisite ?? '',
                expectedBehavior: operationDetails.expectedBehavior ?? '',
                expectedFeedback: operationDetails.expectedFeedback ?? '',
            });
        }
    }, [operationDetails]);

    const validateOperationCode = (
        operationCode: number | string | boolean
    ) => {
        if (
            operationCode == null ||
            operationCode === undefined ||
            operationCode === 0
        ) {
            setOperationCodeError(false);
            return false;
        }

        const formattedOperationCode = String(
            operationCodeFormat === OperationCodeFormat.Hexadecimal
                ? hexToDecimal(operationCode)
                : operationCode
        );

        const isExisting = (
            operation === 'edit'
                ? existingDetailsExceptSelected
                : operationSettings ?? []
        ).some(
            (setting: any) => String(setting.code) === formattedOperationCode
        );

        setOperationCodeError(isExisting);

        return true;
    };

    const validateOperationCodeName = (operationCodeName: string) => {
        return (
            operationCodeName != null &&
            operationCodeName != undefined &&
            operationCodeName != ''
        );
    };

    const validateParameter1Name = (parameter1Name: string) => {
        return (
            parameter1Name != null &&
            parameter1Name != undefined &&
            parameter1Name != ''
        );
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        if (name === 'parameter2Enabled' && !inputValue) {
            setFormData((prevData) => ({
                ...prevData,
                parameter2Enabled: false,
                parameter2Name: '',
                parameter2Unit: '',
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: inputValue,
            }));
        }

        if (name === 'code') {
            validateOperationCode(inputValue); // Call the validation function when code changes
        }
    };

    const onSaveClick = (operation: any, operationDetails: any) => {
        const errors = {
            code: false,
            name: false,
            parameter1Name: false,
            parameterUnit: false,
        };

        if (!formData.code) {
            setOperationCodeIsReq(true);
            errors.code = true;
        } else if (!isHex(formData.code)) {
            setOperationCodeInvalid(true);
            errors.code = true;
        } else if (
            operationCodeFormat === OperationCodeFormat.Decimal &&
            /[a-zA-Z]/.test(formData.code)
        ) {
            setOperationCodeInvalid(true);
            errors.code = true;
        } else if (operationCodeError) {
            errors.code = true;
        }

        if (!formData.name) {
            setOperationCodeNameInvalid(true);
            errors.name = true;
        }

        if (!formData.parameter1Name) {
            setParameterNameInvalid(true);
            errors.parameter1Name = true;
        }

        if (parameterUnitInvalid || parameter2UnitInvalid) {
            errors.parameterUnit = true;
        }

        if (
            errors.code ||
            errors.name ||
            errors.parameter1Name ||
            errors.parameterUnit
        ) {
            return;
        }

        const updatedCode =
            operationCodeFormat === OperationCodeFormat.Hexadecimal
                ? hexToDecimal(formData.code)
                : formData.code;

        sidePanelCloseHandler && sidePanelCloseHandler();

        const updatedOperationValues = {
            ...formData,
            code: updatedCode,
        };

        onSaveOperationClick(
            operation,
            updatedOperationValues,
            operationDetails?.id ?? ''
        );
    };

    const operationCodeErrorText =
        (operationCodeIsReq &&
            getTranslation(
                translationKeys.SidePanelContent_OperationCodeReq_ErrorText
            )) ||
        (operationCodeError &&
            getTranslation(
                translationKeys.SidePanelContent_OperationCodeExist_ErrorText
            )) ||
        (operationCodeInvalid &&
            getTranslation(
                translationKeys.SidePanelContent_OperationCodeInvalid_ErrorText
            )) ||
        (operationCodeFormat === OperationCodeFormat.Hexadecimal &&
            getTranslation(
                translationKeys.SidePanelContent_OperationCodeinHex_HelperText
            )) ||
        getTranslation(
            translationKeys.SidePanelContent_OperationCodeinDec_HelperText
        );

    return (
        <InteractiveModule
            headerText={getTranslation(translationKeys.General_Operation)}
            onCloseBehaviour={sidePanelCloseHandler}
        >
            <StyledContainer>
                <Typography variant="subtitle1">
                    {getTranslation(
                        translationKeys.Settings_OperationCode_Label
                    )}
                </Typography>
                <StyledInputTextField
                    id="operation-code"
                    name="code"
                    inputProps={{
                        maxLength:
                            operationCodeFormat ===
                            OperationCodeFormat.Hexadecimal
                                ? 4
                                : 5,
                    }}
                    value={formData.code}
                    InputProps={{
                        startAdornment: operationCodeFormat ===
                            OperationCodeFormat.Hexadecimal && (
                            <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                    onChange={handleInputChange}
                    required
                    error={
                        operationCodeIsReq ||
                        operationCodeError ||
                        operationCodeInvalid
                    }
                    helperText={operationCodeErrorText}
                />
            </StyledContainer>
            <StyledContainer>
                <Typography variant="subtitle1">
                    {getTranslation(
                        translationKeys.Settings_OperationName_Label
                    )}
                </Typography>
                <StyledInputTextField
                    id="operation-code-name"
                    name="name"
                    inputProps={{ maxLength: 100 }}
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    error={operationCodeNameInvalid}
                    helperText={
                        operationCodeNameInvalid
                            ? getTranslation(
                                  translationKeys.SidePanelContent_OperationNameReq_ErrorText
                              )
                            : ''
                    }
                />
            </StyledContainer>
            <StyledContainer>
                <Typography variant="h6">
                    {getTranslation(
                        translationKeys.Settings_UsedParameters_Label
                    )}
                </Typography>
                <FormControlLabel
                    style={{ marginBottom: '0' }}
                    className={'plc1-name'}
                    control={
                        <CustomCheckbox
                            checked={formData.parameter1Enabled}
                            name={'parameter1Enabled'}
                        />
                    }
                    label={
                        <Typography variant="subtitle1">
                            {getTranslation(
                                translationKeys.Settings_Plc1_Label
                            )}
                        </Typography>
                    }
                    labelPlacement="end"
                />
                {formData.parameter1Enabled && (
                    <UsedParametersContainer>
                        <div>
                            <Typography>{'Name'}</Typography>
                            <StyledInputTextField
                                style={{ width: '236px' }}
                                id="plc-1-name"
                                name="parameter1Name"
                                inputProps={{ maxLength: 100 }}
                                value={formData.parameter1Name}
                                onChange={handleInputChange}
                                required
                                error={parameterNameInvalid}
                                helperText={
                                    parameterNameInvalid
                                        ? getTranslation(
                                              translationKeys.SidePanelContent_Parameter1Req_ErrorText
                                          )
                                        : ''
                                }
                            />
                        </div>
                        <div>
                            <Typography>{'Unit'}</Typography>
                            <UsedParameterUnit
                                id="plc-1-unit"
                                name="parameter1Unit"
                                value={formData.parameter1Unit}
                                onChange={handleInputChange}
                                error={parameterUnitInvalid}
                                helperText={
                                    parameterUnitInvalid
                                        ? getTranslation(
                                              translationKeys.SidePanelContent_PlcUnit_ErrorText
                                          )
                                        : ''
                                }
                            />
                        </div>
                    </UsedParametersContainer>
                )}
                <FormControlLabel
                    style={{ marginBottom: '0' }}
                    className={'plc2-name'}
                    onChange={() => setPlc2Checked(!plc2Checked)}
                    control={
                        <CustomCheckbox
                            checked={formData.parameter2Enabled}
                            name={'parameter2Enabled'}
                            onChange={handleInputChange}
                        />
                    }
                    label={
                        <Typography variant="subtitle1">
                            {getTranslation(
                                translationKeys.Settings_Plc2_Label
                            )}
                        </Typography>
                    }
                    labelPlacement="end"
                />
                {formData.parameter2Enabled && (
                    <UsedParametersContainer>
                        <div>
                            <Typography>{'Name'}</Typography>
                            <StyledInputTextField
                                style={{ width: '236px' }}
                                id="plc-2-name"
                                name="parameter2Name"
                                inputProps={{ maxLength: 100 }}
                                value={formData.parameter2Name}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <Typography>{'Unit'}</Typography>
                            <UsedParameterUnit
                                id="plc-2-unit"
                                name="parameter2Unit"
                                value={formData.parameter2Unit}
                                onChange={handleInputChange}
                                error={parameter2UnitInvalid}
                                helperText={
                                    parameter2UnitInvalid
                                        ? getTranslation(
                                              translationKeys.SidePanelContent_PlcUnit_ErrorText
                                          )
                                        : ''
                                }
                            />
                        </div>
                    </UsedParametersContainer>
                )}
            </StyledContainer>
            <StyledContainer>
                <Typography variant="h6">
                    {getTranslation(
                        translationKeys.Settings_AdditionalInfoOptional_Label
                    )}
                </Typography>

                <Typography variant="subtitle1">
                    {getTranslation(
                        translationKeys.Settings_BeforeSendingOrder_Label
                    )}
                </Typography>
                <AdditionalInfoInputTextField
                    multiline
                    minRows={4}
                    maxRows={4}
                    inputProps={{ maxLength: 1000 }}
                    id="prerequisite"
                    name="prerequisite"
                    value={formData.prerequisite}
                    onChange={handleInputChange}
                />
                <Typography variant="subtitle1">
                    {getTranslation(
                        translationKeys.Settings_ExpectedBehavior_Label
                    )}
                </Typography>
                <AdditionalInfoInputTextField
                    multiline
                    minRows={4}
                    maxRows={4}
                    inputProps={{ maxLength: 1000 }}
                    id="expectedBehavior"
                    name="expectedBehavior"
                    value={formData.expectedBehavior}
                    onChange={handleInputChange}
                />
                <Typography variant="subtitle1">
                    {getTranslation(
                        translationKeys.Settings_ExpectedFeedback_Label
                    )}
                </Typography>
                <AdditionalInfoInputTextField
                    multiline
                    minRows={4}
                    maxRows={4}
                    inputProps={{ maxLength: 1000 }}
                    id="expectedFeedback"
                    name="expectedFeedback"
                    value={formData.expectedFeedback}
                    onChange={handleInputChange}
                />
            </StyledContainer>
            <div style={{ position: 'sticky', bottom: '0' }}>
                <StyledDivider />
                <StyledContainer>
                    <Button
                        onClick={() => onSaveClick(operation, operationDetails)}
                        variant={ButtonVariant.primary}
                    >
                        {operation === 'clone'
                            ? getTranslation(
                                  translationKeys.SidePanelContent_CloneOperation_ButtonLabel
                              )
                            : getTranslation(
                                  translationKeys.SidePanelContent_SaveOperation_ButtonLabel
                              )}
                    </Button>
                </StyledContainer>
            </div>
        </InteractiveModule>
    );
};

export default SidePanelContent;
