import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    ClickAwayListener,
    List,
    Popover,
    Typography,
} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';
import useTranslation from 'src/hooks/useTranslation';
import { LayoutObjectType } from 'src/constants/enums';
import {
    IClickedMapObjects,
    ISelectedObjects,
} from 'src/components/PixiObjects/interfaces';
import OverlappingObjectsMenuItem from './OverlappingObjectsMenuItem';

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px',
        maxWidth: '250px',
    },
    header: {
        padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
        color: theme.palette.common.white,
    },
    list: {
        backgroundColor: theme.palette.common.white,
    },
}));
export type Props = {
    clickAwayHandler: () => void;
    clickRouter: (selectedObjects: ISelectedObjects) => void;
    open: boolean;
    clickedObjects: IClickedMapObjects | undefined;
    position: { top: number; left: number };
};
const OverlappingObjectsMenu = ({
    clickAwayHandler,
    clickRouter,
    open,
    clickedObjects,
    position,
}: Props) => {
    const getTranslation = useTranslation();
    const [delayedOpen, setDelayedOpen] = useState(false);
    const classes = useStyles();
    const onClickHandler = (objectType: LayoutObjectType, id: number) => {
        const selectedObjects: ISelectedObjects = {
            points: [],
            segments: [],
        };
        if (
            objectType === LayoutObjectType.POINT ||
            objectType === LayoutObjectType.UPDATEDPOINT
        )
            selectedObjects.points.push(id);
        else if (
            objectType === LayoutObjectType.SEGMENT ||
            objectType === LayoutObjectType.UPDATEDSEGMENT
        )
            selectedObjects.segments.push(id);
        clickRouter(selectedObjects);
    };
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (open) {
            timeout = setTimeout(() => {
                setDelayedOpen(true);
            }, 100);
        } else {
            setDelayedOpen(false);
        }
        return () => clearTimeout(timeout);
    }, [open]);

    const getOverlappingObjects = () => {
        const overlappingObjects: {
            objectType: LayoutObjectType;
            id: number;
            text: string;
        }[] = [];
        if (!!clickedObjects) {
            Object.entries(clickedObjects).forEach(([key, value]) => {
                let objectType = LayoutObjectType.POINT;
                let text = '';
                if (key === 'points') {
                    objectType = LayoutObjectType.POINT;
                    text = getTranslation('General.Point');
                } else if (key === 'segments') {
                    objectType = LayoutObjectType.SEGMENT;
                    text = getTranslation('General.Segment');
                } else if (key === 'updatedPoints') {
                    objectType = LayoutObjectType.UPDATEDPOINT;
                    text = getTranslation('General.Point');
                } else if (key === 'updatedSegments') {
                    objectType = LayoutObjectType.UPDATEDSEGMENT;
                    text = getTranslation('General.Segment');
                }
                value.forEach((id: number) => {
                    overlappingObjects.push({
                        objectType,
                        id,
                        text: `${text} ${id}`,
                    });
                });
            });
        }

        return overlappingObjects;
    };

    return (
        <Popover
            classes={{ paper: classes.paper }}
            elevation={2}
            anchorReference="anchorPosition"
            anchorPosition={position}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={delayedOpen}
        >
            <ClickAwayListener
                onClickAway={() => {
                    if (delayedOpen) clickAwayHandler();
                }}
                disableReactTree={true}
            >
                <div>
                    <Typography className={classes.header} variant="body2">
                        {getTranslation('OverlappingObjectsMenu.Title')}
                    </Typography>
                    <Scrollbars autoHeight autoHeightMax={300}>
                        <List className={classes.list} dense>
                            {getOverlappingObjects().map((item) => {
                                return (
                                    <OverlappingObjectsMenuItem
                                        text={item.text}
                                        objectType={item.objectType}
                                        onClickHandler={onClickHandler}
                                        id={item.id}
                                        key={`${item.objectType}-${item.id}`}
                                        testId={`${item.objectType}-${item.id}`}
                                    />
                                );
                            })}
                        </List>
                    </Scrollbars>
                </div>
            </ClickAwayListener>
        </Popover>
    );
};

export default OverlappingObjectsMenu;
