import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LayoutVisualization } from 'src/components/LayoutVisualization';
import { Topbar } from 'src/components/Topbar';
import useTranslation from 'src/hooks/useTranslation';
import { NOT_FOUND, ProjectInfo } from 'src/redux/Projects/interfaces';
import { useParams } from 'react-router-dom';
import { Guid } from 'guid-typescript';
import { CircularProgress } from '@material-ui/core';
import {
    LayoutBackgroundDrawingDTO,
    LayoutVisualizationDataDTO,
} from 'src/services/Dtos';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';

const Container = styled.main`
    ${({ theme }) => `
    display: flex;
    flexDirection: column
    width: 100%;
    height: calc(100vh - ${theme.measurements.topbarHeight});
    overflow: hidden;
    margin-top: ${theme.measurements.topbarHeight};
    `}
`;

export type Props = {
    selectedProject: ProjectInfo | undefined | typeof NOT_FOUND;
    layoutVisualizationData: LayoutVisualizationDataDTO | null;
    layoutVisualizationIsLoading: boolean;
    layoutVisualizationIsLoaded: boolean;
    layoutVisualizationError: string;
    layoutBackgroundDrawingData: LayoutBackgroundDrawingDTO | null;
    subscriptions: SubscriptionInfo[];
};

export type Actions = {
    GetProject: (subscriptionId: Guid, guid: string) => void;
    GetLayoutVisualizationData: (
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) => void;
    GetLayoutBackgroundDrawingData: (
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) => void;
};

const ProjectViewer = ({
    selectedProject,
    layoutVisualizationData,
    layoutVisualizationIsLoading,
    layoutVisualizationIsLoaded,
    layoutBackgroundDrawingData,
    subscriptions,
    GetProject,
    GetLayoutVisualizationData,
    GetLayoutBackgroundDrawingData,
}: Props & Actions) => {
    const projectGuid = useParams<{ projectGuid: string }>().projectGuid;
    const getTranslation = useTranslation();
    const calledOnce = React.useRef(false);
    const url = window.location.pathname;
    const regex = /\/subscriptions\/([^/]+)/;
    const matchSubscriptionIdInUrl = url.match(regex);
    const [subscriptionId, setSubscriptionId] = useState<Guid>(
        Guid.createEmpty()
    );
    const [idInSubList, setIdInSubList] = useState<
        SubscriptionInfo | null | Boolean
    >();

    useEffect(() => {
        subscriptions.length > 0 &&
            matchSubscriptionIdInUrl &&
            subscriptionId &&
            setIdInSubList(
                subscriptions.find(
                    (obj) =>
                        obj.subscriptionId.toString() ===
                        subscriptionId.toString()
                )
            );
    }, [subscriptions, url, matchSubscriptionIdInUrl]);

    useEffect(() => {
        matchSubscriptionIdInUrl &&
            setSubscriptionId(Guid.parse(matchSubscriptionIdInUrl[1]));
    }, [idInSubList]);

    useEffect(() => {
        if (matchSubscriptionIdInUrl && !selectedProject && idInSubList) {
            GetProject(subscriptionId, projectGuid);
        }
    }, [idInSubList, subscriptions]);

    useEffect(() => {
        if (!idInSubList || !selectedProject || selectedProject == NOT_FOUND)
            return;
        if (calledOnce.current) return;
        if (matchSubscriptionIdInUrl && selectedProject) {
            GetLayoutVisualizationData(
                subscriptionId,
                Guid.parse(selectedProject?.id),
                Guid.parse(selectedProject.layoutId!)
            );
            GetLayoutBackgroundDrawingData(
                subscriptionId,
                Guid.parse(selectedProject.id),
                Guid.parse(selectedProject.layoutId!)
            );
            calledOnce.current = true;
        }
    }, [matchSubscriptionIdInUrl, selectedProject]);

    const Loading = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    );

    if (!selectedProject || selectedProject == NOT_FOUND) return <Loading />;

    return (
        <React.Fragment>
            <Topbar
                title={getTranslation('LayoutViewer.MenuBar.Title')}
                subtitle={selectedProject.commissioningProject}
            />
            <Container>
                {layoutVisualizationIsLoading && <Loading />}
                {layoutVisualizationData && layoutVisualizationIsLoaded && (
                    <LayoutVisualization
                        layoutVisualizationData={layoutVisualizationData}
                        layoutBackgroundDrawingData={
                            layoutBackgroundDrawingData
                        }
                    />
                )}
            </Container>
        </React.Fragment>
    );
};

export default ProjectViewer;
