import styled from 'styled-components';
import { TextField } from '@material-ui/core';

const StyledTextfield = styled(TextField)`
    margin: 4px;
`;

const InputTextField = (props: any) => {
    let className = '';
    let thisProps = { ...props };
    return (
        <StyledTextfield
            className={className}
            variant="outlined"
            {...thisProps}
        />
    );
};

export default InputTextField;
