const PointIcon = ({ iconColor = '#1A6899' }) => {
    let width = 16;
    let height = 16;
    let viewBox = '4 0 8 16';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={width}
            height={height}
            viewBox={viewBox}
            role="icon"
            aria-label="Segment"
            data-testid="PointIcon"
        >
            <path
                d="M0 8C0 5.86312 0.832147 3.85415 2.34314 2.34314C3.85415 0.832147 5.86312 0 8 0C10.1369 0 12.1459 0.832147 13.6569 2.34314C15.1679 3.85415 16 5.86312 16 8C16 10.1369 15.1679 12.1459 13.6569 13.6569C12.1459 15.1679 10.1369 16 8 16C5.86312 16 3.85415 15.1679 2.34314 13.6569C0.832147 12.1459 0 10.1369 0 8Z"
                fill={iconColor}
            />
            <path d="M8 0V8" stroke="white" strokeWidth="2" />
        </svg>
    );
};

export default PointIcon;
