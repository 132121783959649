import React, { useState, useEffect, useRef } from 'react';
import useTranslation from 'src/hooks/useTranslation';
import { NDCTable, Column } from 'src/components/Table';
import { Chip, Menu, MenuItem, IconButton } from '@material-ui/core';
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { User } from 'src/services/interfaces';
import withStyles from '@material-ui/core/styles/withStyles';
import wrapEmail from 'src/helpers/wrapEmail';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { RoleIdToTranslationKeyMap, Scope } from 'src/constants/enums';

const translationKeys = require('src/translations').default;

const StyledChip = withStyles({
    root: {
        borderRadius: '3px',
        fontWeight: 600,
    },
})(Chip);

type UserMenuSettings = {
    anchorEl?: any;
    menuItems: React.ReactNode[];
};

export type Props = {
    activeUserId: string;
    filteredProjects: ProjectInfo[];
    preventUserMenu: boolean;
    subscriptionId: string;
    subscriptionOwnerEmail?: string;
    subscriptionUsers: User[];
};

export type Actions = {
    addGlobalAccessToUser: (user: User) => void;
    addProjectAccessToUser: (user: User, sites: ProjectInfo[]) => void;
    editGlobalRolesOfUser: (user: User) => void;
    removeGlobalRolesOfUser: (user: User) => void;
    removeUser: (user: User) => void;
};

const UsersTable = ({
    activeUserId,
    addGlobalAccessToUser,
    addProjectAccessToUser,
    editGlobalRolesOfUser,
    filteredProjects,
    removeGlobalRolesOfUser,
    removeUser,
    preventUserMenu,
    subscriptionOwnerEmail,
    subscriptionId,
    subscriptionUsers,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    const userCanAddUsers = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'createUsers',
    });
    const userCanDeleteUsers = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'deleteUsers',
    });

    const [userMenuSettings, setUserMenuSettings] = useState<UserMenuSettings>({
        anchorEl: undefined,
        menuItems: [],
    });
    const userMenuSettingsRef = useRef(userMenuSettings);

    const hideUserSideMenu = () =>
        setUserMenuSettings({
            menuItems: userMenuSettingsRef.current.menuItems,
            anchorEl: undefined,
        });

    useEffect(() => {
        userMenuSettingsRef.current = userMenuSettings;
    }, [userMenuSettings]);

    const getAccessLevel = (user: User) => {
        const accessLevels: string[] = [];

        const subscriptionsScope = '/subscriptions/' + subscriptionId;
        const projectScope = '/subscriptions/' + subscriptionId + '/projects/';

        const userHasGlobalScopeAccess = user.roles.some(
            (r) => r.scope == subscriptionsScope
        );
        const userHasProjectScopeAccess = user.roles.some((r) =>
            r.scope.includes(projectScope)
        );

        if (userHasGlobalScopeAccess) {
            accessLevels.push(
                getTranslation(
                    translationKeys.UserInfo_AccessLevel_Global_Label
                )
            );
        }

        if (userHasProjectScopeAccess) {
            accessLevels.push(
                getTranslation(
                    translationKeys.UserInfo_AccessLevel_Project_Label
                )
            );
        }
        return accessLevels.join(', ');
    };

    const getUserSideMenu = (user: User) => {
        const menuOptions: React.ReactNode[] = [];

        const subscriptionScope = '/subscriptions/' + subscriptionId;
        const projectScope = '/subscriptions/' + subscriptionId + '/projects/';
        const userHasGlobalScopeAccess = user.roles.some(
            (r) => r.scope == subscriptionScope
        );
        const userHasProjectScopeAccess = user.roles.some((r) =>
            r.scope.startsWith(projectScope)
        );
        const userIsSubscriptionOwner = subscriptionOwnerEmail === user.email;

        if (userHasGlobalScopeAccess) {
            menuOptions.push(
                <MenuItem
                    data-testid="edit-global-access"
                    key={'edit-global-access'}
                    onClick={() => {
                        hideUserSideMenu();
                        editGlobalRolesOfUser(user);
                    }}
                >
                    {getTranslation(
                        translationKeys.Users_EditGlobalRoles_Label
                    )}
                </MenuItem>
            );
            if (userHasProjectScopeAccess && userCanDeleteUsers) {
                if (!userIsSubscriptionOwner)
                    menuOptions.push(
                        <MenuItem
                            data-testid="remove-global-roles"
                            key={'remove-global-roles'}
                            onClick={() => {
                                hideUserSideMenu();
                                removeGlobalRolesOfUser(user);
                            }}
                        >
                            {getTranslation(
                                translationKeys.Users_RemoveGlobalRoles_Label
                            )}
                        </MenuItem>
                    );
            }
        } else
            menuOptions.push(
                <MenuItem
                    data-testid="add-global-access"
                    key={'add-global-access'}
                    onClick={() => {
                        hideUserSideMenu();
                        addGlobalAccessToUser(user);
                    }}
                >
                    {getTranslation(
                        translationKeys.Users_AddGlobalPermissions_Label
                    )}
                </MenuItem>
            );

        const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (
            event
        ) => {
            if (!preventUserMenu) {
                setUserMenuSettings({
                    anchorEl: event.target,
                    menuItems: menuOptions,
                });
            }
        };

        const nonAssignedProjects = filteredProjects.filter(
            (project) =>
                !user.roles
                    .map((r) => r.scope)
                    .includes(
                        `/subscriptions/${subscriptionId}/projects/${project.id}`
                    )
        );

        if (nonAssignedProjects.length > 0) {
            menuOptions.push(
                <MenuItem
                    data-testid="add-site-access"
                    key={'add-site-access'}
                    onClick={() => {
                        hideUserSideMenu();
                        addProjectAccessToUser(user, nonAssignedProjects);
                    }}
                >
                    {getTranslation(
                        translationKeys.Users_AddProjectPermissions_Label
                    )}
                </MenuItem>
            );
        }
        if (userCanDeleteUsers && !userIsSubscriptionOwner) {
            menuOptions.push(
                <MenuItem
                    data-testid="remove-user"
                    key={'remove-user'}
                    onClick={() => {
                        hideUserSideMenu();
                        removeUser(user);
                    }}
                >
                    {getTranslation(translationKeys.Users_RemoveUser_Label)}
                </MenuItem>
            );
        }

        return (
            <IconButton
                onClick={handleOpenMenu}
                data-testid={'user-side-menu-button-' + user.userOrGroupId}
                style={{ marginTop: '-2px', marginBottom: '-2px' }}
            >
                <MoreIcon />
            </IconButton>
        );
    };

    const getUserEmail = (user: User) => {
        const emailRender = wrapEmail(user.email);
        const subscriptionOwnerChip =
            subscriptionOwnerEmail == user.email ? (
                <StyledChip
                    label={getTranslation(
                        translationKeys.UserInfo_SubscriptionOwner_Tag
                    )}
                    size="small"
                />
            ) : (
                ''
            );

        const loggedInUserChip =
            user.userOrGroupId == activeUserId ? (
                <StyledChip
                    color="primary"
                    label={getTranslation(
                        translationKeys.UserInfo_LoggedInUser_Tag
                    )}
                    size="small"
                />
            ) : (
                ''
            );

        return (
            <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
                {emailRender}
                {loggedInUserChip}
                {subscriptionOwnerChip}
            </div>
        );
    };

    const getGlobalRoles = (user: User) => {
        const subscriptionScope = '/subscriptions/' + subscriptionId;
        const assignedGlobalRoleNames = [
            ...new Set(
                user.roles
                    .filter(
                        (r) =>
                            r.scope == subscriptionScope &&
                            RoleIdToTranslationKeyMap[r.roleId.toUpperCase()]
                    )
                    .map(
                        (r) => RoleIdToTranslationKeyMap[r.roleId.toUpperCase()]
                    )
            ),
        ];

        if (assignedGlobalRoleNames.length == 0) return '-';

        assignedGlobalRoleNames.sort();

        return assignedGlobalRoleNames
            .map((n) => getTranslation('Roles.' + n + '.Title'))
            .join(', ');
    };

    const getTableColumns = () => {
        const tableColumns: Column[] = [
            {
                headerLabel: getTranslation(
                    translationKeys.UserInfo_Email_Label
                ),
                getContent: getUserEmail,
                sortKey: (user: User) => user.email.toLowerCase(),
            },
            {
                headerLabel: getTranslation(
                    translationKeys.UserInfo_AccessLevel_Label
                ),
                getContent: getAccessLevel,
                thStyle: {
                    width: '20%',
                },
                sortKey: (user: User) => getAccessLevel(user) ?? '',
            },
            {
                headerLabel: getTranslation(
                    translationKeys.UserInfo_AccessLevel_GlobalRoles_Label
                ),
                getContent: getGlobalRoles,
                thStyle: {
                    width: '20%',
                },
                sortKey: (user: User) =>
                    getGlobalRoles(user) === '-'
                        ? '~'
                        : getGlobalRoles(user) ?? '',
            },
        ];

        if (userCanAddUsers) {
            const userActionsButton = {
                headerLabel: '', // More button
                getContent: getUserSideMenu,
                thStyle: {
                    width: '2%',
                },
            };

            tableColumns.push(userActionsButton);
        } else {
            const spacer = {
                headerLabel: '',
                getContent: () => <div style={{ height: '44px' }} />,
                thStyle: {
                    width: '2%',
                },
            };
            tableColumns.push(spacer);
        }

        return tableColumns;
    };

    return (
        <>
            <NDCTable columns={getTableColumns()} items={subscriptionUsers} />
            <Menu
                anchorEl={userMenuSettings.anchorEl}
                keepMounted
                open={userMenuSettings.anchorEl != undefined}
                onClose={hideUserSideMenu}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {userMenuSettings.menuItems}
            </Menu>
        </>
    );
};

export default UsersTable;
