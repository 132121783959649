import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from 'src/assets/icons/InfoIcon';
import { RichPopper } from 'src/components/Popper';
import { OperationUpdateDTO, PointUpdateDTO } from 'src/services/Dtos';
import {
    SegmentUpdateDTO,
    DrawingLineUpdateDTO,
} from 'src/services/Dtos/LayoutUpdate';
import LayoutUpdatesTable from './LayoutUpdatesTable';
import { JSXElementConstructor, ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface TableContainerProps {
    title: string;
    noDataMessage: string;
    tableData:
        | PointUpdateDTO[]
        | OperationUpdateDTO[]
        | SegmentUpdateDTO[]
        | DrawingLineUpdateDTO[];
    tableConfig: {
        headers: (string | JSX.Element)[];
        columnContents: (
            | string
            | JSX.Element
            | ((obj: any) => JSX.Element)
            | ((obj: any) => string)
        )[];
        footerContent?: string;
    };
    marginBottom: number;
    popperContent?: ReactElement<any, string | JSXElementConstructor<any>>;
    displayPopper?: boolean;
}

const useStyles = makeStyles((theme) => ({
    pagination: {
        '& .MuiTablePagination-toolbar': {
            minHeight: theme.spacing(8),
            '& .MuiButtonBase-root': {
                padding: theme.spacing(2),
            },
        },
    },
}));

const LayoutUpdatesTableContainer = ({
    title,
    noDataMessage,
    tableData,
    tableConfig,
    marginBottom,
    popperContent,
    displayPopper = false,
}: TableContainerProps) => {
    const classes = useStyles();
    const [popperOpen, setPopperOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rowCount = tableData.length;
    const showPagination = rowCount > 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Box mb={marginBottom}>
            <Box
                fontWeight={600}
                mb={4}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Typography style={{ paddingRight: '10px' }} variant="h6">
                    {title}
                </Typography>
                {displayPopper && popperContent && (
                    <RichPopper
                        content={popperContent}
                        open={popperOpen}
                        placement="bottom"
                        onClose={() => setPopperOpen(false)}
                    >
                        <IconButton
                            onClick={() => setPopperOpen(!popperOpen)}
                            style={{ padding: '0px' }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </RichPopper>
                )}
            </Box>
            {rowCount > 0 ? (
                <>
                    <LayoutUpdatesTable
                        objects={tableData.slice(
                            rowsPerPage * page,
                            rowsPerPage * page + rowsPerPage
                        )}
                        headers={tableConfig.headers}
                        columnContents={tableConfig.columnContents}
                        footerContent={tableConfig.footerContent}
                    />
                    {showPagination && (
                        <TablePagination
                            className={classes.pagination}
                            component="div"
                            count={rowCount}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            size="small"
                        />
                    )}
                </>
            ) : (
                <Typography>{noDataMessage}</Typography>
            )}
        </Box>
    );
};

export default LayoutUpdatesTableContainer;
