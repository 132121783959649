import {
    InteractionRequiredAuthError,
    BrowserAuthError,
    PublicClientApplication,
} from '@azure/msal-browser';
import { MsalMinimalSilentRequestConfig } from './apiScopes';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);
export function getTokenPopup(requestConfig: MsalMinimalSilentRequestConfig) {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
        return msalInstance.acquireTokenRedirect({ ...requestConfig });
    }
    const request = {
        ...requestConfig,
        account: activeAccount || accounts[0],
    };

    return msalInstance
        .acquireTokenSilent(request)
        .then((response) => {
            // In case the response from B2C server has an empty accessToken field
            // throw an error to initiate token acquisition
            if (!response.accessToken || response.accessToken === '') {
                throw new InteractionRequiredAuthError();
            }
            return response.accessToken;
        })
        .catch((error) => {
            if (
                error instanceof InteractionRequiredAuthError ||
                error instanceof BrowserAuthError
            ) {
                // fallback to interaction when silent call fails
                return msalInstance
                    .acquireTokenPopup(request)
                    .then((response) => {
                        return response.accessToken;
                    })
                    .catch((popupError) => {
                        // fallback to redirect when popup call fails
                        if (popupError instanceof BrowserAuthError)
                            return msalInstance
                                .acquireTokenRedirect(request)
                                .then((response) => {
                                    return response;
                                })
                                .catch((redirectError) => {
                                    console.log(redirectError);
                                });
                        else console.log(popupError);
                    });
            } else {
                console.log(error);
            }
        });
}
