import { Guid } from 'guid-typescript';
import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';
import api from './api';
import { AddressDTO } from './Dtos/AddressDTO';

export default class AddressService {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    GetAddresses(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            return api<AddressDTO[]>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/addresses`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to load address');
                throw error;
            });
        });
    }
}
