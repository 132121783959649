import { CompanyService } from '../services';
import { apiConstants, apiActions } from '../redux/Api';
import { Middleware } from 'redux';
import { ApplicationState } from '../reduxSetup/interfaces';
import { CompanyDTO } from '../services/Dtos';
import { NOT_FOUND } from '../redux/Projects/interfaces';
import { companyActions } from '../redux/Companies';
import { CompanyInfo } from '../redux/Companies/interfaces';
import { Guid } from 'guid-typescript';

const projectApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;

const companyMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const knownAction = action as apiActions.KnownAction;
    const companyService = new CompanyService(projectApiUrl);

    switch (knownAction.type) {
        case apiConstants.GET_COMPANIES:
            await companyService
                .GetCompanies(knownAction.subscriptionId)
                .then((companies) => {
                    if (
                        companies == null ||
                        companies == undefined ||
                        companies == NOT_FOUND
                    ) {
                        alert('No company with that id found');
                    } else if (companies as CompanyDTO[]) {
                        let companyInfos = [];
                        for (let c of companies) {
                            let company = c as CompanyDTO;
                            let companyInfo: CompanyInfo = {
                                id: Guid.parse(company.companyId as string),
                                name: company.companyName,
                                address: company.address,
                            };
                            companyInfos.push(companyInfo);
                        }

                        dispatch(companyActions.SetCompanies(companyInfos));
                    }
                })
                .catch((error) => {
                    console.error('GET_COMPANIES error', error);
                });
            break;
        case apiConstants.CREATE_COMPANY:
            await companyService
                .CreateCompany(
                    knownAction.subscriptionId,
                    knownAction.companyInfo
                )
                .then((response) => {
                    if (response != undefined) {
                        const newCompany = response as CompanyDTO;
                        if (newCompany) {
                            knownAction.createdCompanyCallback(
                                newCompany.companyId as string
                            );
                        }
                        return;
                    }
                    knownAction.createdCompanyCallback();
                })
                .catch(() => {
                    knownAction.createdCompanyCallback();
                });
            break;
        case apiConstants.GET_COMPANY:
            await companyService
                .GetCompany(knownAction.subscriptionId, knownAction.companyId)
                .then((response) => {
                    if (response != undefined) {
                        const companyDto = response as CompanyDTO;
                        if (
                            companyDto.companyId == undefined ||
                            companyDto.subscriptionId == undefined
                        ) {
                            alert('Failed to get company');
                            return;
                        }
                        const company: CompanyInfo = {
                            id: Guid.parse(companyDto.companyId),
                            name: companyDto.companyName,
                            address: companyDto.address,
                        };
                        dispatch(companyActions.setSelectedCompany(company));
                    }
                });
            break;
        case apiConstants.UPDATE_COMPANY:
            await companyService
                .UpdateCompany(
                    knownAction.subscriptionId,
                    knownAction.companyInfo
                )
                .then((response) => {
                    if (response != undefined) {
                        const companyDto = response as CompanyDTO;
                        if (
                            companyDto.companyId == undefined ||
                            companyDto.subscriptionId == undefined
                        ) {
                            knownAction.updatedCompanyCallback();
                            alert('Failed to get company');
                            return;
                        }
                        knownAction.updatedCompanyCallback(
                            companyDto.companyId
                        );
                    }
                })
                .catch(() => {
                    knownAction.updatedCompanyCallback();
                });
            break;
    }
};

export default companyMiddleware;
