import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Table } from 'src/components/Table';

const tableStyle = `
th:first-child, td:first-child {
    width: 60px;
}
`;
type LayoutUpdatesTableProps = {
    objects: any[] | undefined;
    headers: (string | React.ReactElement)[];
    columnContents: (string | React.ReactElement | Function)[];
    footerContent?: string;
};
const LayoutUpdatesTable = ({
    objects,
    headers,
    columnContents,
    footerContent,
}: LayoutUpdatesTableProps) => (
    <div>
        <Box maxHeight="300px" overflow="auto">
            <style>{tableStyle}</style>
            <Table
                innerProps={{
                    style: {
                        width: '100%',
                        minWidth: '720px',
                        gridTemplateColumns: `repeat(${headers.length}, 1fr) 0.2fr`,
                        tableLayout: 'fixed',
                        marginBottom: 0,
                    },
                }}
                objects={objects}
                headers={headers}
                columnContents={columnContents}
            />
        </Box>
        {typeof footerContent !== 'undefined' && (
            <Box mt={2}>
                <Typography color="textSecondary">{footerContent}</Typography>
            </Box>
        )}
    </div>
);

export default LayoutUpdatesTable;
