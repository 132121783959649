import { Guid } from 'guid-typescript';
import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';
import { CompanyInfo } from '../redux/Companies/interfaces';
import api from './api';
import { CompanyDTO } from './Dtos';
import { AddressDTO } from './Dtos/AddressDTO';

export default class CompanyService {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    GetCompanies(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<CompanyDTO[]>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/companies`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to load companies');
                throw error;
            });
        });
    }

    CreateCompany(subscriptionId: Guid, newCompany: CompanyInfo) {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        let newCompanyDTO: CompanyDTO = {
            companyName: newCompany.name,
            address: newCompany.address,
        };
        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                body: JSON.stringify(newCompany.address),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api({
                url: `${
                    backends.projectApi
                }/api/subscriptions/${subscriptionId.toString()}/addresses`,
                method: 'POST',
                options,
            })
                .then((result) => {
                    const address = result as AddressDTO;
                    return getTokenPopup(requestConfig).then((accessToken) => {
                        const options = {
                            body: JSON.stringify({
                                companyName: newCompany.name,
                                addressId: address.addressId,
                            }),
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                'x-api-version': '1.0',
                                Authorization: `Bearer ${accessToken}`,
                            },
                        };
                        return api<CompanyDTO>({
                            url: `${
                                backends.projectApi
                            }/api/subscriptions/${subscriptionId.toString()}/companies`,
                            method: 'POST',
                            options,
                        });
                    });
                })
                .catch((error) => {
                    alert('Failed to create companies');
                    throw error;
                });
        });
    }

    GetCompany(subscriptionId: Guid, companyId: string) {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<CompanyDTO>({
                url: `${
                    backends.projectApi
                }/api/subscriptions/${subscriptionId.toString()}/companies/${companyId.toString()}`,
                method: 'GET',
                options,
            }).catch((error) => {
                alert('Failed to load company');
                throw error;
            });
        });
    }

    UpdateCompany(subscriptionId: Guid, company: CompanyInfo) {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                body: JSON.stringify({
                    companyName: company.name,
                    addressId: company.address.addressId,
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            return api<CompanyDTO>({
                url: `${
                    backends.projectApi
                }/api/subscriptions/${subscriptionId.toString()}/companies/${company.id.toString()}`,
                method: 'PUT',
                options,
            })
                .then(async (result) => {
                    let companyUpdated = result as CompanyDTO;
                    if (companyUpdated) {
                        let addressUpdated = (await getTokenPopup(
                            requestConfig
                        ).then((accessToken) => {
                            const options = {
                                body: JSON.stringify(company.address),
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    'x-api-version': '1.0',
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            };
                            return api<AddressDTO>({
                                url: `${
                                    backends.projectApi
                                }/api/subscriptions/${subscriptionId.toString()}/addresses`,
                                method: 'PUT',
                                options,
                            });
                        })) as AddressDTO;
                        if (addressUpdated) {
                            companyUpdated.address = addressUpdated;
                            return companyUpdated;
                        }
                        alert('Failed to update company address');
                        return companyUpdated;
                    }
                })
                .catch((error) => {
                    alert('Failed to load company');
                    throw error;
                });
        });
    }
}
