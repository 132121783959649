import { Typography } from '@material-ui/core';
import { get, isFunction } from 'lodash';
import React from 'react';
import theme from 'src/theme';

interface ITableItem {
    objects: any[] | undefined;
    headers: (string | React.ReactElement)[];
    columnContents: (string | React.ReactElement | Function)[];
    innerProps?: any | undefined;
}
const Table = ({
    objects,
    headers,
    columnContents,
    innerProps,
}: ITableItem) => {
    const generateRows = (objects: any[]) => {
        return objects.map((obj: any, ind: number) => {
            return <tr key={ind}>{generateColumns(obj, columnContents)}</tr>;
        });
    };

    const generateColumns = (object: any, columns: any) => {
        return columns.map((content: any, ind: number) => {
            let innerContent;
            if (typeof content == 'string') {
                innerContent = get(object, content);
            } else if (React.isValidElement(content)) {
                innerContent = content;
            } else if (isFunction(content)) {
                innerContent = content(object);
            }
            return (
                <td
                    style={{
                        verticalAlign: 'middle',
                        fontSize: theme.typography.body1.fontSize,
                        wordBreak: 'break-word',
                    }}
                    key={ind}
                >
                    {innerContent}
                </td>
            );
        });
    };

    const generateHeaders = (headers: any) => {
        return (
            <tr>
                {headers.map((content: any, ind: number) => {
                    if (
                        typeof content == 'string' ||
                        React.isValidElement(content)
                    )
                        return <th key={ind}>{content}</th>;
                })}
            </tr>
        );
    };

    return (
        <table
            className="table table-striped"
            aria-labelledby="tabelLabel"
            style={{
                marginTop: '30px',
                tableLayout: 'fixed',
            }}
            {...innerProps}
        >
            <thead>{generateHeaders(headers)}</thead>
            <tbody>{objects && generateRows(objects)}</tbody>
        </table>
    );
};

export default Table;
