import * as PIXI from 'pixi.js';
import { DisplayObject, Point } from 'pixi.js';

export interface ILayoutObjectProps {
    id: number;
    x: number;
    y: number;
    layerId: string;
    origin?: ObjectOrigin;
    selected?: boolean;
    faded?: boolean;
}

export enum ObjectOrigin {
    Base = 0,
    Update = 1,
}

export default class LayoutObject {
    id: number;
    x: number;
    y: number;
    selected: boolean;
    location: PIXI.Point;
    container: PIXI.Container;
    layerId: string;
    origin: ObjectOrigin;
    faded: boolean;

    constructor(props: ILayoutObjectProps) {
        this.id = props.id;
        this.x = props.x;
        this.y = props.y;
        this.container = new PIXI.Container();
        this.selected = props.selected ?? false;
        this.faded = props.faded ?? false;
        this.location = new PIXI.Point(props.x, props.y);
        this.layerId = props.layerId;
        this.origin = props.origin ?? ObjectOrigin.Base;
        this.container.x = this.location.x;
        this.container.y = -this.location.y;
    }

    hide() {
        this.container.visible = false;
    }

    show() {
        this.container.visible = true;
    }

    select() {
        this.selected = true;
        this.onSelected();
    }

    protected onSelected() {}

    deselect() {
        this.selected = false;
        this.onDeselected();
    }

    protected onDeselected() {}

    fade() {
        this.faded = true;
        this.onFaded();
    }
    protected onFaded() {}

    unfade() {
        this.faded = false;
        this.onUnfaded();
    }
    protected onUnfaded() {}

    setId(id: number) {
        this.id = id;
    }

    setLocation(location: Point) {
        this.location = location.clone();
        this.container.x = this.location.x;
        this.container.y = this.location.y;
    }

    move(x: number, y: number) {
        this.setLocation(
            new PIXI.Point(this.location.x + x, this.location.y + y)
        );
    }

    destroy() {
        this.container.parent.removeChild(this.container);
        this.container.destroy({ children: true });
    }
}
