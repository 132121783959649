import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const getCompany = (state: ApplicationState) => state.companies;

export const getCompanies = createSelector(
    [getCompany],
    (companyState) => companyState.companies
);

export const getSelectedCompany = createSelector(
    [getCompany],
    (companyState) => companyState.selectedCompany
);
