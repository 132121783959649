import * as PIXI from 'pixi.js';
import { Graphics } from 'pixi.js';
import Curve from '../components/CurveComponent';
import { controlPoint, curvePart } from '../layoutObjects/Segment';

export const drawSplineSegment = (
    graphic: Graphics,
    controlPoints: controlPoint[]
) => {
    for (let i = 2; i < controlPoints.length - 2; i++) {
        const xc = (controlPoints[i].x + controlPoints[i + 1].x) / 2;
        const yc = (controlPoints[i].y + controlPoints[i + 1].y) / 2;
        graphic.quadraticCurveTo(
            controlPoints[i].x,
            -controlPoints[i].y,
            xc,
            -yc
        );
    }
};

export const drawAutoCurveSegment = (
    graphic: Graphics,
    curveParts: curvePart[]
) => {
    curveParts.forEach((curvePart) => {
        const curvePoints = new Curve(
            {
                x: curvePart.startPoint.x,
                y: curvePart.startPoint.y,
                th: curvePart.startPoint.angle,
            },
            {
                x: curvePart.endPoint.x,
                y: curvePart.endPoint.y,
                th: curvePart.endPoint.angle,
            },
            curvePart.factor / 10,
            (curvePart.dth424 * Math.PI) / 18000
        ).getPoints();
        curvePoints.forEach((p) => {
            graphic.lineTo(p.x, -p.y);
        });
    });
};

export const drawCoordinatesHelpLines = (
    graphic: Graphics,
    screenHeight: number
) => {
    //Arrows
    graphic.moveTo(15, screenHeight - 110);
    graphic.lineTo(15, screenHeight - 10);
    graphic.moveTo(10, screenHeight - 15);
    graphic.lineTo(110, screenHeight - 15);
    graphic.lineTo(105, screenHeight - 20);
    graphic.moveTo(110, screenHeight - 15);
    graphic.lineTo(105, screenHeight - 10);
    graphic.moveTo(15, screenHeight - 110);
    graphic.lineTo(10, screenHeight - 105);
    graphic.moveTo(15, screenHeight - 110);
    graphic.lineTo(20, screenHeight - 105);

    //X & Y Symbols
    graphic.moveTo(115, screenHeight - 10);
    graphic.lineTo(120, screenHeight - 20);
    graphic.moveTo(120, screenHeight - 10);
    graphic.lineTo(115, screenHeight - 20);

    graphic.moveTo(15, screenHeight - 115);
    graphic.lineTo(15, screenHeight - 120);
    graphic.lineTo(18, screenHeight - 125);
    graphic.moveTo(15, screenHeight - 120);
    graphic.lineTo(12, screenHeight - 125);
};
