export const ADD_USER_TO_PROJECT = 'ADD_USER_TO_PROJECT';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_LAYOUT_WITH_NEW_SITE_AND_SYSTEM =
    'CREATE_LAYOUT_WITH_NEW_SITE_AND_SYSTEM';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const DELETE_USER_FROM_PROJECT = 'DELETE_USER_FROM_PROJECT';
export const DOWNLOAD_LAYOUT_UPDATES = 'DOWNLOAD_LAYOUT_UPDATES';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_LAYOUT_FILE_DOWNLOAD_LINK = 'GET_LAYOUT_FILE_DOWNLOAD_LINK';
export const GET_CIRRUS_INSTALL_DOWNLOAD_LINK =
    'GET_CIRRUS_INSTALL_DOWNLOAD_LINK';
export const GET_CIRRUS_INSTALL_INFO = 'GET_CIRRUS_INSTALL_INFO';
export const GET_LAYOUT_BACKGROUNDDRAWING = 'GET_LAYOUT_BACKGROUNDDRAWING';
export const GET_LAYOUT_UPDATES = 'GET_LAYOUT_UPDATES';
export const GET_LAYOUT_VISUALIZATION = 'GET_LAYOUT_VISUALIZATION';
export const GET_OPERATION_SETTINGS = 'GET_OPERATION_SETTINGS';
export const CREATE_OPERATION_SETTINGS = 'CREATE_OPERATION_SETTINGS';
export const UPDATE_OPERATION_SETTINGS = 'UPDATE_OPERATION_SETTINGS';
export const UPDATE_OPERATION_CODE_DISPLAY_FORMAT =
    'UPDATE_OPERATION_CODE_DISPLAY_FORMAT';
export const DELETE_OPERATION_SETTINGS = 'DELETE_OPERATION_SETTINGS';
export const DELETE_OPERATION_SETTINGS_TEMPLATE =
    'DELETE_OPERATION_SETTINGS_TEMPLATE';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_ROLE_ASSIGNMENTS = 'GET_PROJECT_ROLE_ASSIGNMENTS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT_OPERATION_SETTINGS = 'GET_PROJECT_OPERATION_SETTINGS';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_ROLE_ASSIGNMENTS =
    'GET_SUBSCRIPTION_ROLE_ASSIGNMENTS';
export const MARK_OPERATIONS_AS_DOWNLOADED = 'MARK_OPERATIONS_AS_DOWNLOADED';
export const MARK_OPERATIONS_AS_ARCHIVED = 'MARK_OPERATIONS_AS_ARCHIVED';
export const MARK_POINTS_AS_DOWNLOADED = 'MARK_POINTS_AS_DOWNLOADED';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPLOAD_DWG = 'UPLOAD_DWG';
export const UPLOAD_LAYOUT_DATABASE = 'UPLOAD_LAYOUT_DATABASE';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const GET_EULA = 'GET_EULA';
