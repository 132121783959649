import React, { useEffect, useState } from 'react';
import {
    Table as NDCTable,
    TableContainer,
    TableBody,
    TableHeaderCell,
    TableCell,
    TableHead,
    TableRow,
} from '@ndc/react-component-library';

export type Column = {
    headerLabel: any;
    getContent: (item: any) => React.ReactNode;
    thStyle?: React.CSSProperties;
    sortKey?: (item: any) => string;
    hidden?: boolean;
};

type SortSettings = {
    column: Column | undefined;
    sortDescending: boolean;
};

export type Props = {
    columns: Column[];
    items: any[];
    tdStyle?: React.CSSProperties;
};

const defaultThStyle = {
    paddingLeft: '16px',
    paddingRight: '16px',
    fontWeight: 600,
};
const defaultTdStyle = { padding: '2px 16px', minHeight: '45px' };

export type Actions = {};

const Table = ({ columns, items, tdStyle }: Props & Actions) => {
    const getFirstSortableColumn = () => {
        for (const column of columns) {
            if (column.sortKey) {
                return column;
            }
        }
        return undefined;
    };

    const sortItems = (_items: any[], _sortSettings: SortSettings) => {
        const { column, sortDescending } = _sortSettings;

        if (column && column.sortKey) {
            const sortKey = column.sortKey;
            const compareFn = (i1: any, i2: any) => {
                const key1 = sortKey(i1);
                const key2 = sortKey(i2);

                if (key1 == key2) return 0;
                if (key1 > key2) return 1;
                else return -1;
            };
            const itemsCopy = [...items];

            itemsCopy.sort((i1: any, i2: any) => compareFn(i1, i2));
            if (sortDescending) itemsCopy.reverse();
            return itemsCopy;
        } else {
            return items;
        }
    };

    const [sortSettings, setSortSettings] = useState<SortSettings>({
        column: getFirstSortableColumn(),
        sortDescending: false,
    });

    const [sortedItems, setSortedItems] = useState(
        sortItems(items, sortSettings)
    );

    useEffect(() => {
        setSortedItems(sortItems(items, sortSettings));
    }, [items, sortSettings]);

    return (
        <TableContainer translate={undefined} css={{ width: '100%' }}>
            <NDCTable style={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        {columns.map(
                            (column, key) =>
                                !column.hidden && (
                                    <TableHeaderCell
                                        key={key}
                                        onClick={
                                            column.sortKey
                                                ? () => {
                                                      if (column.sortKey) {
                                                          if (
                                                              column ==
                                                              sortSettings.column
                                                          ) {
                                                              setSortSettings({
                                                                  column,
                                                                  sortDescending:
                                                                      !sortSettings.sortDescending,
                                                              });
                                                          } else {
                                                              setSortSettings({
                                                                  column,
                                                                  sortDescending:
                                                                      false,
                                                              });
                                                          }
                                                      }
                                                  }
                                                : undefined
                                        }
                                        arrowOptions={
                                            sortSettings.column &&
                                            sortSettings.column.headerLabel ==
                                                column.headerLabel
                                                ? {
                                                      ascending:
                                                          !sortSettings.sortDescending,
                                                      sortOnThis: true,
                                                  }
                                                : undefined
                                        }
                                        style={{
                                            ...defaultThStyle,
                                            ...column.thStyle,
                                        }}
                                    >
                                        {column.headerLabel}
                                    </TableHeaderCell>
                                )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedItems.map((item, index) => (
                        <TableRow key={index}>
                            {columns.map(
                                (c, index) =>
                                    !c.hidden && (
                                        <TableCell
                                            key={index}
                                            style={{
                                                ...defaultTdStyle,
                                                ...tdStyle,
                                            }}
                                        >
                                            {c.getContent(item)}
                                        </TableCell>
                                    )
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </NDCTable>
        </TableContainer>
    );
};

export default Table;
