import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'src/hooks';
import InfoIcon from 'src/assets/icons/InfoIcon';
import { RichPopper } from 'src/components/Popper';

const StyledBox = styled(Box)`
    padding: 8px 12px 8px 12px;
    display: grid;
    width: 320px;
`;

const HeaderTypography = styled(Typography)`
    font-weight: 600;
`;

interface Props {
    operationDetails: any;
}

const AdditionalInfoPopper = ({ operationDetails }: Props) => {
    const getTranslation = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const renderContent = (headerText: any, contentText: any) => (
        <StyledBox>
            <HeaderTypography variant="body2">{headerText}</HeaderTypography>
            <Typography variant="body2">{contentText}</Typography>
        </StyledBox>
    );

    const popperContent = (
        <div>
            {(operationDetails.prerequisite ?? '') !== '' &&
                renderContent(
                    getTranslation('AdditionalInfoPopper.Prerequisite.Header'),
                    operationDetails.prerequisite
                )}
            {(operationDetails.expectedBehavior ?? '') !== '' &&
                renderContent(
                    getTranslation(
                        'AdditionalInfoPopper.ExpectedBehavior.Header'
                    ),
                    operationDetails.expectedBehavior
                )}
            {(operationDetails.expectedFeedback ?? '') !== '' &&
                renderContent(
                    getTranslation(
                        'AdditionalInfoPopper.ExpectedFeedback.Header'
                    ),
                    operationDetails.expectedFeedback
                )}
        </div>
    );

    return (
        <RichPopper
            content={popperContent}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            placement="bottom"
        >
            <IconButton
                onClick={() => setIsOpen(!isOpen)}
                style={{ padding: '0px' }}
                data-testid="additionalinfo-icon-button"
            >
                <InfoIcon />
            </IconButton>
        </RichPopper>
    );
};

AdditionalInfoPopper.propTypes = {
    additionalInfo: PropTypes.any,
};

export default AdditionalInfoPopper;
