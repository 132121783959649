import { Layer } from '@pixi/layers';
import * as PIXI from 'pixi.js';

type SvgDimensionData = {
    width: number;
    height: number;
    originX: number;
    originY: number;
};

interface BackGroundDrawing extends SvgDimensionData {
    svgText: string;
}

export const createBackgroundDrawing = (svgPath: string, layer: Layer) => {
    return new Promise<void>(async (resolve, reject) => {
        if (!svgPath) {
            reject('Svg/Dwg file path was empty.');
        }
        try {
            const bg = await getBackgroundDrawing(svgPath);
            const maxTextureSize = 5000;
            const scale = maxTextureSize / Math.max(bg.width, bg.height);

            const bgTexture = PIXI.Texture.from(bg.svgText, {
                resourceOptions: { scale: scale },
            });
            const bgSprite = new PIXI.Sprite(bgTexture);
            bgSprite.tint = 0x000000;
            bgSprite.alpha = 0.5;

            bgSprite.width = bg.width * 1000;
            bgSprite.height = bg.height * 1000;
            bgSprite.position.set(bg.originX, -bg.originY);
            bgSprite.anchor.set(0.5, 0.5);
            layer.addChild(bgSprite);
            await loadBackgroundDrawing(bg.svgText);
            resolve();
        } catch (error) {
            console.log('error', error);
            reject(error);
        }
    });
};

const getBackgroundDrawing = (svgPath: string) => {
    return new Promise<BackGroundDrawing>((resolve, reject) => {
        fetch(svgPath)
            .then((response) => response.text())
            .then(async (svgText) => {
                const svgDimensions = getSvgDimensions(svgText);
                if (
                    isNaN(svgDimensions.width) ||
                    isNaN(svgDimensions.height) ||
                    isNaN(svgDimensions.originX) ||
                    isNaN(svgDimensions.originY)
                )
                    reject('Missing svg dimensions');
                resolve({ svgText, ...svgDimensions });
            })
            .catch((reason) => {
                reject(reason);
            });
    });
};

const getSvgDimensions = (svgText: string): SvgDimensionData => {
    const svgElement = svgText.match(/<svg.*>/);
    if (svgElement) {
        const width = extractAttributeValue(svgElement[0], 'width');
        const height = extractAttributeValue(svgElement[0], 'height');
        const originX = extractAttributeValue(svgElement[0], 'originX');
        const originY = extractAttributeValue(svgElement[0], 'originY');
        return { width, height, originX, originY };
    } else return { width: NaN, height: NaN, originX: NaN, originY: NaN };
};

const extractAttributeValue = (str: string, attribute: string) => {
    const regex = new RegExp(`${attribute}="(?<result>[0-9\\.?]+)"`);
    return parseFloat(str.match(regex)?.groups?.result ?? '');
};

const loadBackgroundDrawing = (svgText: string) => {
    return new Promise<void>((resolve) => {
        const newLoader = new PIXI.Loader();
        newLoader.add('bgDrawing', svgText);
        newLoader.load(() => {
            resolve();
        });
    });
};
