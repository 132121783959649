import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button, ButtonSize, Variant } from '@ndc/react-component-library';
import { useTranslation } from 'src/hooks';
import {
    subscriptionSelectors,
    subscriptionActions,
} from 'src/redux/Subscriptions';
import theme from 'src/theme';
import { Guid } from 'guid-typescript';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const StyledDescriptionContainer = styled.div`
    ${({ theme }) => `
    margin-top: ${theme.spacing(6)};
`}
`;

const CustomCheckbox = withStyles({
    root: {
        '&$checked': {
            color: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
            color: '#bdbdbd',
        },
        padding: '9px 9px 12px 0;',
    },
    checked: {},
})(Checkbox);

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 20px;
    margin-right: -70px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const CheckboxContainer = styled.div`
    padding-top: 24px;
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
    ${({ theme }) => `
    width: ${theme.spacing(3.75)};
    height: ${theme.spacing(3.75)};
    color: #1a6899;
    margin-bottom: ${theme.spacing(0.5)};
`}
`;

export type Props = {
    closeModal: () => void;
    showSubSelection: (status?: boolean) => void;
};

export type Actions = {};

const EulaModal = ({ closeModal, showSubSelection }: Props & Actions) => {
    const getTranslation = useTranslation();
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const subscriptions =
        useSelector(subscriptionSelectors.getSubscriptions) ?? [];

    const externalEulaLInk =
        'https://www.kollmorgen.com/sites/default/files/agv/kollmorgen_eula_51001-001Q.pdf';

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const onContinueClick = () => {
        const setEulaCallback = (status?: boolean) => {
            if (status) {
                showSubSelection(status);
            }
        };
        if (checked) {
            closeModal();
            dispatch(
                subscriptionActions.SetEula(
                    subscriptions[0].subscriptionId as unknown as Guid,
                    setEulaCallback
                )
            );
        }
    };

    return (
        <Container>
            <StyledDescriptionContainer>
                {getTranslation('Sidebar.EulaModal.Description', {
                    // @ts-ignore
                    a: (chunks: ReactNode) => (
                        <a
                            style={{ color: '#1A6899' }}
                            href={externalEulaLInk}
                            target="_blank"
                        >
                            {chunks}
                        </a>
                    ),
                    // @ts-ignore
                    icon: (
                        <a href={externalEulaLInk} target="_blank">
                            <StyledOpenInNewIcon />
                        </a>
                    ),
                })}
            </StyledDescriptionContainer>
            <CheckboxContainer>
                <CustomCheckbox
                    checked={checked}
                    onChange={handleChange}
                    id="checkbox"
                    disableRipple
                />
                <label htmlFor="checkbox">
                    {getTranslation('Sidebar.EulaModal.AcceptTerms.Label')}
                </label>
            </CheckboxContainer>
            <ButtonContainer>
                <Button
                    variant={Variant.secondary}
                    size={ButtonSize.small}
                    onClick={() => {
                        closeModal();
                        instance.logout();
                    }}
                >
                    {getTranslation('General.Exit.Label')}
                </Button>
                <Button
                    size={ButtonSize.small}
                    onClick={onContinueClick}
                    disabled={!checked}
                >
                    {getTranslation('General.Continue.Label')}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default EulaModal;
