import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { Button, ButtonVariant, TextField } from '@ndc/react-component-library';
import { validateEmail } from 'src/helpers/validator';
import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import { CreateSubscriptionCommand } from 'src/services/Dtos/CreateSubscriptionCommand';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { Scope } from 'src/constants/enums';
import { Guid } from 'guid-typescript';
import { AccessControl } from 'src/components/AccessControl';

const StyledButtonContainer = styled.div`
    ${({ theme }) => `
    margin-top: ${theme.spacing(10)};
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(4)};
    align-items: center;
}`}
`;

export type Props = {
    subscriptionId: Guid | undefined;
};

export type Actions = {
    CreateSubscription: (
        createSubscriptionCommand: CreateSubscriptionCommand,
        createdCallback: (
            success: boolean,
            subscription?: SubscriptionInfo
        ) => void
    ) => void;
};

const CreateSubscription = ({
    subscriptionId,
    CreateSubscription,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    const subscriptionNameRef = useRef() as MutableRefObject<HTMLInputElement>;
    const subscriptionDescriptionRef =
        useRef() as MutableRefObject<HTMLInputElement>;
    const subscriptionCompanyNumberRef =
        useRef() as MutableRefObject<HTMLInputElement>;
    const subscriptionOwnerRef = useRef() as MutableRefObject<HTMLInputElement>;

    const [subscriptionNameValid, setSubscriptionNameValid] = useState(true);
    const [subscriptionNameError, setSubscriptionNameError] = useState('');
    const [subscriptionCompanyNumberValid, setSubscriptionCompanyNumberValid] =
        useState(true);
    const [subscriptionCompanyNumberError, setSubscriptionCompanyNumberError] =
        useState('');
    const [subscriptionOwnerValid, setSubscriptionOwnerValid] = useState(true);
    const [subscriptionOwnerError, setSubscriptionOwnerError] = useState('');
    const [createInProgress, setCreateInProgress] = useState(false);

    const handleChangeSubscriptionName = () => {
        validateSubscriptionName(subscriptionNameRef.current.value);
    };
    const validateSubscriptionName = (value: string) => {
        const isValid = value.trim().length > 0;
        setSubscriptionNameValid(isValid);
        setSubscriptionNameError(
            isValid
                ? ''
                : getTranslation('CreateSubscription.Form.Name.ErrorRequired')
        );
        return isValid;
    };
    const handleChangeSubscriptionCompanyNumber = () => {
        validateSubscriptionCompanyNumber(
            subscriptionCompanyNumberRef.current.value
        );
    };
    const validateSubscriptionCompanyNumber = (value: string) => {
        const isValid = value.trim().length > 0;
        setSubscriptionCompanyNumberValid(isValid);
        setSubscriptionCompanyNumberError(
            isValid
                ? ''
                : getTranslation(
                      'CreateSubscription.Form.CompanyNumber.ErrorRequired'
                  )
        );
        return isValid;
    };

    const handleChangeSubscriptionOwner = () => {
        validateSubscriptionOwner(subscriptionOwnerRef.current.value);
    };
    const validateSubscriptionOwner = (value: string) => {
        const isValid = validateEmail(value);
        setSubscriptionOwnerValid(isValid);
        setSubscriptionOwnerError(
            isValid ? '' : getTranslation('General.InvalidEmail')
        );
        return isValid;
    };
    const subscriptionCreatedCallback = (
        success: boolean,
        subscription?: SubscriptionInfo
    ) => {
        setCreateInProgress(false);
        if (success) {
            clearForm();
        }
    };
    const clearForm = () => {
        subscriptionNameRef.current.value = '';
        setSubscriptionNameValid(true);
        setSubscriptionNameError('');
        subscriptionDescriptionRef.current.value = '';
        subscriptionCompanyNumberRef.current.value = '';
        setSubscriptionCompanyNumberValid(true);
        setSubscriptionCompanyNumberError('');
        subscriptionOwnerRef.current.value = '';
        setSubscriptionOwnerValid(true);
        setSubscriptionOwnerError('');
    };

    const handleCreateSubscription = () => {
        const nameValid = validateSubscriptionName(
            subscriptionNameRef.current.value
        );
        const companyNumberValid = validateSubscriptionCompanyNumber(
            subscriptionCompanyNumberRef.current.value
        );
        const ownerValid = validateSubscriptionOwner(
            subscriptionOwnerRef.current.value
        );
        if (!nameValid || !ownerValid || !companyNumberValid) return;

        setCreateInProgress(true);
        CreateSubscription(
            {
                displayName: subscriptionNameRef.current.value,
                description: subscriptionDescriptionRef.current.value,
                email: subscriptionOwnerRef.current.value,
                companyNumber: subscriptionCompanyNumberRef.current.value,
            },
            subscriptionCreatedCallback
        );
    };

    return (
        <AccessControl
            scope={Scope.Subscription}
            id={subscriptionId?.toString() ?? ''}
            allowedPermission="createSubscriptions"
        >
            <>
                <Typography
                    variant="h5"
                    data-testid="create-subscription-header"
                >
                    {getTranslation('CreateSubscription.Header')}
                </Typography>
                <TextField
                    data-testid="create-subscription-textfield-subscription-name"
                    ref={subscriptionNameRef}
                    label={getTranslation('CreateSubscription.Form.Name.Label')}
                    name="subscriptionName"
                    onChange={handleChangeSubscriptionName}
                    maxLength={255}
                    error={!subscriptionNameValid}
                    helperText={subscriptionNameError}
                />
                <TextField
                    data-testid="create-subscription-textfield-subscription-description"
                    ref={subscriptionDescriptionRef}
                    label={getTranslation(
                        'CreateSubscription.Form.Description.Label'
                    )}
                    maxLength={255}
                />
                <TextField
                    data-testid="create-subscription-textfield-subscription-companynumber"
                    ref={subscriptionCompanyNumberRef}
                    label={getTranslation(
                        'CreateSubscription.Form.CompanyNumber.Label'
                    )}
                    onChange={handleChangeSubscriptionCompanyNumber}
                    maxLength={255}
                    error={!subscriptionCompanyNumberValid}
                    helperText={subscriptionCompanyNumberError}
                />
                <TextField
                    data-testid="create-subscription-textfield-subscription-owner"
                    ref={subscriptionOwnerRef}
                    label={getTranslation(
                        'CreateSubscription.Form.Owner.Label'
                    )}
                    onChange={handleChangeSubscriptionOwner}
                    maxLength={255}
                    error={!subscriptionOwnerValid}
                    helperText={subscriptionOwnerError}
                />
                <StyledButtonContainer>
                    <Button
                        data-testid="create-subscription-button-create"
                        variant={ButtonVariant.primary}
                        onClick={handleCreateSubscription}
                        disabled={createInProgress}
                    >
                        {getTranslation('CreateSubscription.Button.Create')}
                    </Button>
                    {createInProgress && (
                        <CircularProgress color="secondary" size={24} />
                    )}
                </StyledButtonContainer>
            </>
        </AccessControl>
    );
};

export default CreateSubscription;
