import React, { useEffect, useState } from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Button } from 'src/components/Button';
import useTranslation from 'src/hooks/useTranslation';
import theme from 'src/theme';

export type Props = {
    cirrusInstallCreatedDate: Date | undefined;
    cirrusInstallInfoLoaded: boolean | undefined;
    cirrusInstallVersion: string | undefined;
};

export type Actions = {
    getCirrusInstallDownloadLink: (
        callback: (
            isSuccess: boolean,
            cirrusInstallDownloadLink?: string,
            cirrusInstallDownloadFileName?: string
        ) => void
    ) => void;
    getCirrusInstallVersionInformation: () => void;
};

const StyledContainer = styled.div`
    max-width: 520px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
`;

const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: '140px',
        width: '380px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    noFiles: {
        backgroundColor: theme.palette.grey[100],
        border: '2px dashed #939393',
        boxSizing: 'border-box',
        borderRadius: '7px',
    },
    spinnerWrapper: {
        position: 'relative',
        width: '0px',
        height: '0px',
    },
    spinner: {
        position: 'absolute',
        top: '-10px',
        marginLeft: theme.spacing(2),
    },
}));

const CirrusInstallInfoPage = ({
    cirrusInstallCreatedDate,
    cirrusInstallInfoLoaded,
    cirrusInstallVersion,
    getCirrusInstallDownloadLink,
    getCirrusInstallVersionInformation,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const intl = useIntl();

    const [downLoadInProgress, setDownloadInProgress] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getCirrusInstallVersionInformation();
    }, []);

    const onDownloadClick = () => {
        downloadCirrusInstall();
    };

    const downloadCirrusInstall = () => {
        setDownloadInProgress(true);
        getCirrusInstallDownloadLink(onfileCallback);
    };

    const onfileCallback = (
        isSuccess: boolean,
        cirrusInstallDownloadLink?: string,
        cirrusInstallDownloadFileName?: string
    ) => {
        setDownloadInProgress(false);
        if (
            !isEmpty(cirrusInstallDownloadLink) &&
            !isEmpty(cirrusInstallDownloadFileName) &&
            isSuccess
        ) {
            var link = document.createElement('a');
            link.setAttribute('download', 'download');
            link.href = cirrusInstallDownloadLink as string;
            document.body.appendChild(link);
            link.click();
            link.remove();
            enqueueSnackbar(
                getTranslation('General.DownloadSuccessNotification.Text'),
                {
                    variant: 'success',
                }
            );
        }
    };

    const renderCirrusInstallDownload = () => {
        if (cirrusInstallInfoLoaded) {
            if (cirrusInstallVersion) {
                return (
                    <>
                        <Typography
                            variant={'h6'}
                            style={{ marginBottom: theme.spacing(2) }}
                        >
                            {getTranslation('CirrusInstallInfo.LatestVersion')}
                        </Typography>
                        <Typography style={{ fontWeight: 600 }}>
                            {`${getTranslation(
                                'CirrusInstallInfo.Version'
                            )} ${cirrusInstallVersion}`}
                        </Typography>
                        <Typography
                            variant={'body2'}
                            style={{
                                fontWeight: 400,
                                color: theme.palette.neutral.carbon,
                                marginBottom: theme.spacing(6),
                            }}
                        >
                            {`${getTranslation('CirrusInstallInfo.Created')} ${
                                cirrusInstallCreatedDate === undefined
                                    ? ''
                                    : intl.formatDate(cirrusInstallCreatedDate)
                            }`}
                        </Typography>
                        <div
                            style={{
                                maxWidth: theme.spacing(40),
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                onClick={onDownloadClick}
                                $loading={downLoadInProgress}
                            >
                                {getTranslation('General.Download')}
                            </Button>
                        </div>
                    </>
                );
            } else {
                return (
                    <Typography>
                        {getTranslation('CirrusInstallInfo.NotFound')}
                    </Typography>
                );
            }
        } else {
            return (
                <div className={classes.spinnerWrapper}>
                    <CircularProgress
                        className={classes.spinner}
                        size={20}
                        color="secondary"
                    />
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            <StyledContainer>
                <Typography
                    variant={'h4'}
                    style={{ marginBottom: theme.spacing(6) }}
                >
                    {getTranslation('CirrusInstallInfo.Header')}
                </Typography>
                <Typography
                    style={{
                        marginBottom: theme.spacing(6),
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {getTranslation('CirrusInstallInfo.Text')}
                </Typography>
                {renderCirrusInstallDownload()}
            </StyledContainer>
        </React.Fragment>
    );
};

export default CirrusInstallInfoPage;
