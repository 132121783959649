import { Guid } from 'guid-typescript';
import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { NOT_FOUND } from './interfaces';

const getProject = (state: ApplicationState) => state.project;
const projectList = (state: ApplicationState) => state.project.projects;
const selectedProject = (state: ApplicationState) =>
    state.project.selectedProject;
const selectItemId = (state: ApplicationState, itemId: number) => itemId;
const selectItemGuid = (state: ApplicationState, itemGuid: Guid) => itemGuid;

export const getSelectedProject = createSelector(
    [projectList, selectedProject],
    (projectList, selectedProject) => {
        if (!projectList) return undefined;
        if (selectedProject === NOT_FOUND) return NOT_FOUND;
        return projectList[selectedProject?.id ?? ''] ?? undefined;
    }
);

export const getProjects = createSelector([projectList], (projectList) =>
    Object.values(projectList ?? {})
);

export const getSelectedLayoutUpdateContainer = createSelector(
    [getProject],
    (project) => project.selectedLayoutUpdateContainer
);

export const getUploadingLayoutFiles = createSelector(
    [getProject],
    (project) => project.uploadingLayoutFiles
);

export const getLayoutFileDownloadLink = createSelector(
    [getProject],
    (project) => project.layoutFileDownloadLink
);

export const getSelectedNewLayoutUpdatesCount = createSelector(
    getSelectedLayoutUpdateContainer,
    (selectedLayoutUpdateContainer) => {
        if (
            !selectedLayoutUpdateContainer ||
            selectedLayoutUpdateContainer === NOT_FOUND
        )
            return 0;
        const newPointUpdatesCount =
            selectedLayoutUpdateContainer.pointUpdates.filter(
                (o) => o.downloadedUpdate == undefined
            ).length;
        const newOperationUpdatesCount =
            selectedLayoutUpdateContainer.operationUpdates.filter(
                (o) =>
                    o.downloadedUpdate == undefined &&
                    o.archivedUpdate == undefined
            ).length;
        const newSegmentUpdatesCount =
            selectedLayoutUpdateContainer.segmentUpdates.filter(
                (o) => o.downloadedUpdate == undefined
            ).length;
        const newDrawingLineUpdatesCount =
            selectedLayoutUpdateContainer.drawingLineUpdates.filter(
                (o) => o.downloadedUpdate == undefined
            ).length;

        return (
            newPointUpdatesCount +
            newOperationUpdatesCount +
            newSegmentUpdatesCount +
            newDrawingLineUpdatesCount
        );
    }
);
