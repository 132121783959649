import * as PIXI from 'pixi.js';
import {
    drawAutoCurveSegment,
    drawSplineSegment,
} from '../helpers/drawingUtils';
import { segmentSettings } from '../settings/segmentSettings';
import LayoutObject, { ILayoutObjectProps, ObjectOrigin } from './LayoutObject';
import { lineToPolygon } from '../helpers';
import { SegmentInfo } from 'src/services/Dtos/LayoutVisualizationDataDTO';
import { Guid } from 'guid-typescript';

type segmentPoint = { id: number; x: number; y: number; angle: number };
export type controlPoint = { x: number; y: number };
type partPoint = { x: number; y: number; angle: number };
type rotationPoint = {
    x: number;
    y: number;
    rotationDirection: number;
    rotationSpeed: number;
};
export type curvePart = {
    startPoint: partPoint;
    endPoint: partPoint;
    dth424: number;
    factor: number;
};
export type SegmentDetails = {
    current: SegmentInfo;
    project: SegmentInfo;
};

export interface ISegmentProps extends ILayoutObjectProps {
    startPoint: segmentPoint;
    endPoint: segmentPoint;
    controlPoints: controlPoint[] | null;
    curveParts: curvePart[] | null;
    smallPoints: controlPoint[] | null;
    rotationPoint: rotationPoint | null;
    segmentInfo: SegmentDetails | null;
    layoutUpdateId: Guid;
}

export default class Segment extends LayoutObject {
    #graphic: PIXI.Graphics;
    segment: ISegmentProps;
    constructor(segment: ISegmentProps) {
        super({
            id: segment.id,
            x: segment.x,
            y: -segment.y,
            layerId: segment.layerId,
            origin: segment.origin ?? ObjectOrigin.Base,
        });
        this.segment = segment;
        this.segment.layoutUpdateId =
            segment.layoutUpdateId ?? Guid.parse(Guid.EMPTY);
        this.#graphic = new PIXI.Graphics();
        this.init();
    }

    private init = () => {
        this.#graphic.lineStyle({
            color: 0xffffff,
            width: this.getLineWidth(),
            native: true,
        });
        this.#graphic.tint = this.getLineColor();
        this.#graphic.interactive = true;
        this.#graphic.buttonMode = true;

        const {
            startPoint,
            endPoint,
            controlPoints,
            curveParts,
            smallPoints,
            rotationPoint,
        } = this.segment;
        const startPointWOffset = this.subtractOffset(startPoint, {
            x: this.x,
            y: this.y,
        });
        const endPointWOffset = this.subtractOffset(endPoint, {
            x: this.x,
            y: this.y,
        });
        const controlPointsWOffset = (controlPoints ?? []).map(
            (controlPoint) => {
                return this.subtractOffset(controlPoint, {
                    x: this.x,
                    y: this.y,
                });
            }
        );
        const rotationPointWOffset = !rotationPoint
            ? null
            : (this.subtractOffset(rotationPoint, {
                  x: this.x,
                  y: this.y,
              }) as rotationPoint);
        const curvePartsWOffset = (curveParts ?? []).map(
            (curvePart: curvePart) => {
                return {
                    ...curvePart,
                    startPoint: this.subtractOffset(curvePart.startPoint, {
                        x: this.x,
                        y: this.y,
                    }) as partPoint,
                    endPoint: this.subtractOffset(curvePart.endPoint, {
                        x: this.x,
                        y: this.y,
                    }) as partPoint,
                };
            }
        );
        const smallPointsWOffset = (smallPoints ?? []).map((smallPoint) => {
            return this.subtractOffset(smallPoint, {
                x: this.x,
                y: this.y,
            });
        });
        this.drawSegment(
            this.#graphic,
            {
                ...startPointWOffset,
                id: startPoint.id,
                angle: startPoint.angle,
            },
            { ...endPointWOffset, id: endPoint.id, angle: endPoint.angle },
            controlPointsWOffset,
            curvePartsWOffset,
            smallPointsWOffset,
            rotationPointWOffset
        );

        const shape = lineToPolygon(
            this.getLineWidth(),
            this.#graphic.currentPath.points
        );

        this.#graphic.lineStyle(0, 0xffffff);
        this.#graphic.beginFill(0xffffff);
        this.#graphic.drawShape(shape);
        this.#graphic.endFill();
        this.#graphic.hitArea = shape;

        this.container.addChild(this.#graphic);
    };

    private drawSegment = (
        graphic: PIXI.Graphics,
        startPoint: segmentPoint,
        endPoint: segmentPoint,
        controlPoints: controlPoint[],
        curveParts: curvePart[],
        smallPoints: controlPoint[],
        rotationPoint: rotationPoint | null
    ) => {
        graphic.moveTo(startPoint.x, -startPoint.y);
        if (controlPoints.length > 0) {
            drawSplineSegment(graphic, controlPoints);
        } else if (curveParts.length > 0) {
            drawAutoCurveSegment(graphic, curveParts);
        } else if (smallPoints.length > 0) {
            smallPoints.forEach((smallPoint) => {
                graphic.lineTo(smallPoint.x, -smallPoint.y);
            });
        } else if (!!rotationPoint) {
            graphic.lineTo(rotationPoint.x, -rotationPoint.y);
        }
        graphic.lineTo(endPoint.x, -endPoint.y);
    };
    private subtractOffset = (
        point: segmentPoint | controlPoint | partPoint,
        offset: { x: number; y: number }
    ) => {
        const newPoint = { ...point };
        newPoint.x -= offset.x;
        newPoint.y -= offset.y;
        return newPoint;
    };
    private getLineWidth = () => {
        if (this.origin === ObjectOrigin.Base) {
            if (this.selected)
                return segmentSettings.lineStyle.base.selected.width;
            return segmentSettings.lineStyle.base.default.width;
        } else if (this.origin === ObjectOrigin.Update) {
            if (this.selected)
                return segmentSettings.lineStyle.updated.selected.width;
            return segmentSettings.lineStyle.updated.default.width;
        }
        return segmentSettings.lineStyle.base.default.width;
    };
    private getLineColor = () => {
        if (this.origin === ObjectOrigin.Base) {
            if (this.selected)
                return segmentSettings.lineStyle.base.selected.color;
            if (this.faded) return segmentSettings.lineStyle.base.faded.color;
            return segmentSettings.lineStyle.base.default.color;
        } else if (this.origin === ObjectOrigin.Update) {
            if (this.selected)
                return segmentSettings.lineStyle.updated.selected.color;
            if (this.faded)
                return segmentSettings.lineStyle.updated.faded.color;
            return segmentSettings.lineStyle.updated.default.color;
        }
        return segmentSettings.lineStyle.base.default.color;
    };
    protected onSelected = () => {
        this.updateAppearence();
    };
    protected onDeselected = () => {
        this.updateAppearence();
    };
    protected onFaded = () => {
        this.updateAppearence();
    };
    protected onUnfaded = () => {
        this.updateAppearence();
    };
    private updateAppearence = () => {
        this.#graphic.tint = this.getLineColor();
    };
}
