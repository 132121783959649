import { makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { Guid } from 'guid-typescript';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar } from '../../Sidebar';
import { history } from 'src/routing';
import { apiActions } from 'src/redux/Api';
import {
    subscriptionSelectors,
    subscriptionActions,
} from 'src/redux/Subscriptions';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { LocalStorageEnum } from 'src/constants/enums';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '100px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.measurements.drawerWidth,
        },
        margin: '0px',
    },
}));

type Props = {
    children: ReactElement<React.ReactNode>[];
};

const Subscription = (props: Props) => {
    const NavigateTo = (path: string) => {
        history.push(path);
    };
    const classes = useStyles();
    const dispatch = useDispatch();
    const subscriptions =
        useSelector(subscriptionSelectors.getSubscriptions) ?? [];
    const url = window.location.pathname;
    const regex = /\/subscriptions\/([^/]+)/;
    const VisualizeRegex = /\/visualize(?=\/|$)/;
    const isVisualizePage = VisualizeRegex.test(url);
    const matchSubscriptionIdInUrl = url.match(regex);
    const subscriptionIdinLocalStorage = localStorage.getItem(
        LocalStorageEnum.ACTIVESUBSCRIPTIONID
    );
    const eula = useSelector(subscriptionSelectors.getEula) ?? {};

    const [showModal, setShowModal] = useState(false);
    const [showEulaModal, setShowEulaModal] = useState(false);

    const handleSubscriptionCheck = (
        id: string,
        list: Array<any>,
        localStorageId?: string
    ) => {
        const idInSubList = list.find(
            (obj) => obj.subscriptionId == Guid.parse(id)
        );

        if (idInSubList) {
            if (list.length > 1) {
                dispatch(
                    subscriptionActions.SetCurrrentSubscription(idInSubList)
                );
                localStorage.setItem(
                    LocalStorageEnum.ACTIVESUBSCRIPTIONID,
                    idInSubList.subscriptionId.toString()
                );
            } else if (list.length === 1) {
                dispatch(subscriptionActions.SetCurrrentSubscription(list[0]));
                localStorage.setItem(
                    LocalStorageEnum.ACTIVESUBSCRIPTIONID,
                    list[0].subscriptionId.toString()
                );
            }
        } else {
            subscriptions.length > 0 &&
                NavigateTo('/error/not-authorized-resource');
            dispatch(
                subscriptionActions.SetCurrrentSubscription(
                    {} as SubscriptionInfo
                )
            );
            localStorage.removeItem(LocalStorageEnum.ACTIVESUBSCRIPTIONID);
        }
    };

    useEffect(() => {
        dispatch(apiActions.getSubscription());
    }, []);

    useEffect(() => {
        if (eula.hasAccepted === true) {
            setShowEulaModal(false);
        } else if (eula.hasAccepted === false) {
            setShowEulaModal(true);
        }
    }, [eula]);

    useEffect(() => {
        if (matchSubscriptionIdInUrl) {
            const idInSubList =
                subscriptions.length > 0 &&
                subscriptions.find(
                    (obj) =>
                        obj.subscriptionId ==
                        Guid.parse(matchSubscriptionIdInUrl[1])
                );
            if (idInSubList) {
                dispatch(
                    subscriptionActions.SetCurrrentSubscription(idInSubList)
                );
                localStorage.setItem(
                    LocalStorageEnum.ACTIVESUBSCRIPTIONID,
                    idInSubList.subscriptionId.toString()
                );
            } else {
                subscriptions.length > 0 &&
                    NavigateTo('/error/not-authorized-resource');
                dispatch(
                    subscriptionActions.SetCurrrentSubscription(
                        {} as SubscriptionInfo
                    )
                );
                localStorage.removeItem(LocalStorageEnum.ACTIVESUBSCRIPTIONID);
            }
        } else {
            if (subscriptionIdinLocalStorage) {
                subscriptions.length > 0 &&
                    handleSubscriptionCheck(
                        subscriptionIdinLocalStorage,
                        subscriptions
                    );
            } else {
                if (subscriptions.length > 0 && subscriptions.length > 1) {
                    setShowModal(true);
                } else if (
                    subscriptions.length > 0 &&
                    subscriptions.length === 1
                ) {
                    dispatch(
                        subscriptionActions.SetCurrrentSubscription(
                            subscriptions[0]
                        )
                    );
                    localStorage.setItem(
                        LocalStorageEnum.ACTIVESUBSCRIPTIONID,
                        subscriptions[0].subscriptionId.toString()
                    );
                }
            }
        }
    }, [subscriptions, eula]);

    useEffect(() => {
        if (subscriptions.length > 0) {
            dispatch(
                apiActions.getEula(
                    // sending the first subscription id
                    subscriptions[0].subscriptionId as unknown as Guid
                )
            );
        }
    }, [subscriptions]);

    const handleShowSubSelection = (status?: boolean) => {
        setShowEulaModal(false);
    };

    if (!eula.hasAccepted && showEulaModal) {
        return (
            <Sidebar
                showSelectSubModal={false}
                showEulaModal={showEulaModal}
                showSubSelection={handleShowSubSelection}
            />
        );
    } else if (isVisualizePage) {
        return <div>{props.children}</div>;
    } else
        return (
            <>
                <Sidebar
                    showSelectSubModal={showModal}
                    showEulaModal={showEulaModal}
                    showSubSelection={handleShowSubSelection}
                />
                <div className={classes.root}>{props.children}</div>
            </>
        );
};

export default Subscription;
