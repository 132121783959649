import { useEffect, useState } from 'react';
import InputLabel from './InputLabel';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    Typography,
    Tooltip,
    Radio,
    RadioGroup as MuiRadioGroup,
    withStyles,
} from '@material-ui/core';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemDescription = styled.div`
    margin-left: 31px;
    margin-top: -12px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.neutral.dark};
`;

const ErrorDescription = styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.error.main};
`;

const StyledRadio = styled(Radio)`
    color: ${({ theme }) => theme.palette.primary.dark};
`;
const StyledTooltip = withStyles({
    popper: {
        zIndex: 5000,
    },
})(Tooltip);

type Props = {
    defaultSelectedItems?: any[];
    error?: boolean;
    errorText?: string;
    getDisabled?: (item: any) => boolean;
    getHelpText?: (item: any) => string;
    getItemText?: (item: any) => string;
    getTooltipText?: (item: any) => string;
    id?: string;
    items: any[];
    label: string;
    onChange: (item: any[]) => void;
    selectFirstAsDefault?: boolean;
};

const RadioGroup = ({
    defaultSelectedItems = [],
    error = false,
    errorText = '',
    getDisabled = (i) => false,
    getHelpText = (i) => '',
    getItemText = (i) => i,
    getTooltipText = (i) => '',
    id,
    items,
    label,
    onChange,
    selectFirstAsDefault = false,
}: Props) => {
    const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);

    useEffect(() => {
        if (defaultSelectedItems.length === 0 && selectFirstAsDefault) {
            handleChange(items[0], true);
        }
    }, [defaultSelectedItems]);

    const handleChange = (item: any, checked: boolean) => {
        if (
            checked &&
            !selectedItems
                .map((item) => item.toLowerCase())
                .includes(item.toLowerCase())
        ) {
            const newSelectedItems = [item];
            setSelectedItems(newSelectedItems);
            onChange(newSelectedItems);
        } else if (
            !checked &&
            selectedItems
                .map((item) => item.toLowerCase())
                .includes(item.toLowerCase())
        ) {
            const newSelectedItems = selectedItems.filter(
                (i) => i.toLowerCase() != item.toLowerCase()
            );
            setSelectedItems(newSelectedItems);
            onChange(newSelectedItems);
        }
    };

    const renderRadioItem = (item: any, index: number) => {
        const renderItem = () => (
            <div key={index}>
                <FormControlLabel
                    label={getItemText(item)}
                    control={
                        <StyledRadio
                            color="default"
                            value={item.toLowerCase()}
                            onChange={(_, checked) =>
                                handleChange(item, checked)
                            }
                            disabled={getDisabled(item)}
                        />
                    }
                />
                {getHelpText(item) && (
                    <ItemDescription>{getHelpText(item)}</ItemDescription>
                )}
            </div>
        );

        if (getTooltipText && getTooltipText(item))
            return (
                <StyledTooltip
                    title={getTooltipText(item)}
                    placement="bottom"
                    key={index}
                >
                    {renderItem()}
                </StyledTooltip>
            );
        else return renderItem();
    };

    return (
        <Container id={id}>
            <InputLabel label={label} style={{ marginTop: '10px' }} />
            {error && <ErrorDescription>{errorText}</ErrorDescription>}
            <MuiRadioGroup value={selectedItems[0]?.toLocaleLowerCase() ?? ''}>
                {items.map(renderRadioItem)}
            </MuiRadioGroup>
        </Container>
    );
};

export default RadioGroup;
