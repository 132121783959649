import { useState } from 'react';
import { useTranslation } from 'src/hooks';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, ButtonSize, Variant } from '@ndc/react-component-library';
import { NOT_FOUND } from 'src/redux/Projects/interfaces';
import { history } from 'src/routing';
import { Guid } from 'guid-typescript';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 9px;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 40px;
    margin-right: -70px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export type Props = {
    subscriptionGuid: Guid;
    selectedProject: any;
    deleteProject: any;
    closeModal: any;
    successCallback?: (isSuccessful: boolean) => void;
};

export type Actions = {};

const DeleteProjectModal = ({
    subscriptionGuid,
    selectedProject,
    deleteProject,
    closeModal,
    successCallback,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const NavigateTo = (path: string) => {
        history.push(path);
    };

    const [userPermissionsAreBeingUpdated, setUserPermissionsAreBeingUpdated] =
        useState(false);

    const onDeleteProject = () => {
        closeModal();
        const deleteCallback = (status: boolean) => {
            if (status) {
                successCallback && successCallback(true);
                subscriptionGuid &&
                    NavigateTo(`/subscriptions/${subscriptionGuid}/projects`);
                return null;
            }
        };
        if (selectedProject != NOT_FOUND && selectedProject != null) {
            deleteProject(
                subscriptionGuid,
                selectedProject?.id,
                deleteCallback
            );
        }
    };

    return (
        <Container>
            {`Are you sure you want to permanently delete the project ${selectedProject?.commissioningProject}?`}
            <ButtonContainer>
                {userPermissionsAreBeingUpdated && (
                    <CircularProgress size="35px" color="secondary" />
                )}
                <Button
                    variant={Variant.secondary}
                    size={ButtonSize.small}
                    key={'cancel-delete-project'}
                    data-testid="cancel-delete-project"
                    onClick={closeModal}
                >
                    {getTranslation(translationKeys.General_Cancel)}
                </Button>
                <Button
                    size={ButtonSize.small}
                    key={'delete-project'}
                    data-testid="delete-project"
                    onClick={onDeleteProject}
                    disabled={userPermissionsAreBeingUpdated}
                >
                    {'Delete Project'}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default DeleteProjectModal;
