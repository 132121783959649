import { ProjectStatus } from 'src/constants/enums';
import { LayoutFileType } from 'src/constants/layoutFileConstants';
import { LayoutUpdateContainerDTO } from 'src/services/Dtos';
import { User } from 'src/services/interfaces';

export const NOT_FOUND = 'NOT_FOUND';
export const FAILED_TO_GET_URL = 'FAILED_TO_GET_URL';

export interface ProjectList {
    [projectId: string]: ProjectInfo;
}

interface SelectedProject {
    id?: string;
    users?: User[];
}

export interface ProjectInfo {
    company: string;
    companyId: string;
    commissioningProject: string;
    id: string;
    layoutId: string | null;
    layoutName: string | null;
    status: ProjectStatus;
    systemId: string | null;
    systemName: string | null;
    siteId: string | null;
    siteName: string | null;
    layoutFiles: LayoutFile[];
    assignedPeople: number;
    lastUpdate: Date | null;
    wefFileInfo?: WefFileInfo;
    users?: User[];
}

export interface ProjectOverview {
    companyId: string;
    projectName: string;
    projectId: string;
    status: ProjectStatus;
}

export interface ProjectState {
    selectedProject: SelectedProject | undefined | typeof NOT_FOUND;
    selectedLayoutUpdateContainer:
        | LayoutUpdateContainerDTO
        | undefined
        | typeof NOT_FOUND;
    projects: ProjectList | undefined;
    uploadingLayoutFiles: UploadingFile[];
    layoutFileDownloadLink: URL | undefined | typeof FAILED_TO_GET_URL;
}

export interface UploadingFile {
    fileType: LayoutFileType;
    fileName: string;
    requestId: string;
}

export interface LayoutFile {
    id: string;
    userId: string;
    userEmail: string;
    revision: string;
    dateTimeUtc: Date;
    fileType: LayoutFileType;
    fileName: string;
    fileUrl: string;
}

export interface RoleAssignment {
    roleAssignmentId: string;
    subscriptionId: string;
    userOrGroupId: string;
    userOrGroup: string;
    scope: string;
    roleId: string;
    roleName: string;
}

export interface WefFileInfo {
    wefId: string;
    userId: string;
    userEmail?: string;
    fileName: string;
    uploadedAtUtc: Date;
}
