import React, { useState, useEffect, useRef } from 'react';
import useTranslation from 'src/hooks/useTranslation';
import { NDCTable, Column } from 'src/components/Table';
import { Chip, Menu, MenuItem, IconButton } from '@material-ui/core';
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { User } from 'src/services/interfaces';
import withStyles from '@material-ui/core/styles/withStyles';
import wrapEmail from 'src/helpers/wrapEmail';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { RoleIdToTranslationKeyMap, Scope } from 'src/constants/enums';
import { RoleAssignmentDTO } from 'src/services/Dtos';
import { formatRoleAssignments } from 'src/helpers/roles';

const translationKeys = require('src/translations').default;

const StyledChip = withStyles({
    root: {
        borderRadius: '3px',
        fontWeight: 600,
    },
})(Chip);

type UserMenuSettings = {
    anchorEl?: any;
    menuItems: React.ReactNode[];
};

export type Props = {
    activeUserId: string;
    preventUserMenu: boolean;
    project: ProjectInfo;
    subscriptionId: string;
};

export type Actions = {
    editRolesOfUser: (user: User) => void;
    removeUser: (user: User) => void;
};

const ProjectUsersTable = ({
    activeUserId,
    editRolesOfUser,
    removeUser,
    preventUserMenu,
    project,
    subscriptionId,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    const userCanAddUsers = useValidateAccess({
        scope: Scope.Project,
        id: project.id,
        allowed: 'createUsers',
    });
    const userCanDeleteUsers = useValidateAccess({
        scope: Scope.Project,
        id: project.id,
        allowed: 'deleteProjectUser',
    });

    const [userMenuSettings, setUserMenuSettings] = useState<UserMenuSettings>({
        anchorEl: undefined,
        menuItems: [],
    });
    const userMenuSettingsRef = useRef(userMenuSettings);

    const hideUserSideMenu = () =>
        setUserMenuSettings({
            menuItems: userMenuSettingsRef.current.menuItems,
            anchorEl: undefined,
        });

    useEffect(() => {
        userMenuSettingsRef.current = userMenuSettings;
    }, [userMenuSettings]);

    const handleMenuItemClick = (user: User) => {
        hideUserSideMenu();
        editRolesOfUser(user);
    };

    const getUserSideMenu = (user: User) => {
        const menuOptions: React.ReactNode[] = [];

        menuOptions.push(
            <MenuItem
                data-testid="add-global-access"
                key={'add-global-access'}
                onClick={() => handleMenuItemClick(user)}
            >
                {getTranslation(translationKeys.Users_EditProjectRoles_Label)}
            </MenuItem>
        );

        if (userCanDeleteUsers) {
            menuOptions.push(
                <MenuItem
                    data-testid="remove-user"
                    key={'remove-user'}
                    onClick={() => {
                        hideUserSideMenu();
                        removeUser(user);
                    }}
                >
                    {getTranslation(
                        translationKeys.Users_RemoveProjectUser_Label
                    )}
                </MenuItem>
            );
        }

        const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (
            event
        ) => {
            if (!preventUserMenu) {
                setUserMenuSettings({
                    anchorEl: event.target,
                    menuItems: menuOptions,
                });
            }
        };

        return (
            <IconButton
                onClick={handleOpenMenu}
                data-testid={'user-side-menu-button-' + user.userOrGroupId}
                style={{ marginTop: '-2px', marginBottom: '-2px' }}
            >
                <MoreIcon />
            </IconButton>
        );
    };

    const getUserEmail = (user: User) => {
        const emailRender = wrapEmail(user.email);
        const loggedInUserChip =
            user.userOrGroupId == activeUserId ? (
                <StyledChip
                    color="primary"
                    label={getTranslation(
                        translationKeys.UserInfo_LoggedInUser_Tag
                    )}
                    size="small"
                />
            ) : (
                ''
            );

        return (
            <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
                {emailRender}
                {loggedInUserChip}
            </div>
        );
    };

    const getProjectRoles = (user: User) => {
        const projectScope = `/subscriptions/${subscriptionId}/projects/${project.id}`;
        const assignedProjectRoleNames = [
            ...new Set(
                user.roles
                    .filter(
                        (r) =>
                            r.scope == projectScope &&
                            RoleIdToTranslationKeyMap[r.roleId.toUpperCase()]
                    )
                    .map(
                        (r) => RoleIdToTranslationKeyMap[r.roleId.toUpperCase()]
                    )
            ),
        ];

        if (assignedProjectRoleNames.length == 0) return '-';

        assignedProjectRoleNames.sort();

        return assignedProjectRoleNames
            .map((n) => getTranslation('Roles.' + n + '.Title'))
            .join(', ');
    };

    const getTableColumns = () => {
        const tableColumns: Column[] = [
            {
                headerLabel: getTranslation(
                    translationKeys.UserInfo_Email_Label
                ),
                getContent: getUserEmail,
                sortKey: (user: User) => user.email.toLowerCase(),
            },
            {
                headerLabel: getTranslation(
                    translationKeys.UserInfo_AccessLevel_ProjectRoles_Label
                ),
                getContent: getProjectRoles,
                thStyle: {
                    width: '20%',
                },
            },
        ];

        if (userCanAddUsers) {
            const userActionsButton = {
                headerLabel: '', // More button
                getContent: getUserSideMenu,
                thStyle: {
                    width: '2%',
                },
            };

            tableColumns.push(userActionsButton);
        } else {
            const spacer = {
                headerLabel: '',
                getContent: () => <div style={{ height: '44px' }} />,
                thStyle: {
                    width: '2%',
                },
            };
            tableColumns.push(spacer);
        }

        return tableColumns;
    };
    const projectUsers = project.users ?? [];

    return (
        <>
            <NDCTable columns={getTableColumns()} items={projectUsers} />
            <Menu
                anchorEl={userMenuSettings.anchorEl}
                keepMounted
                open={userMenuSettings.anchorEl != undefined}
                onClose={hideUserSideMenu}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {userMenuSettings.menuItems}
            </Menu>
        </>
    );
};

export default ProjectUsersTable;
