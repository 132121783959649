import { apiActions } from 'src/redux/Api';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { companyActions, companySelectors } from 'src/redux/Companies';
import { connect } from 'react-redux';
import {
    subscriptionSelectors,
    subscriptionActions,
} from 'src/redux/Subscriptions';
import Companies, { Actions, Props } from './components/Companies';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        companies: companySelectors.getCompanies(state),
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
    };
};

const mapDispatchToProps: Actions = {
    getAddresses: apiActions.getAddresses,
    getCompanies: apiActions.getCompanies,
    resetSelectedCompany: () => companyActions.resetSelectedCompany(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
