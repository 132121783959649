import { Guid } from 'guid-typescript';
import {
    DownloadUpdatesCommand,
    ArchiveOperationUpdatesCommand,
} from 'src/services/Dtos';
import { CreateSubscriptionCommand } from 'src/services/Dtos/CreateSubscriptionCommand';
import { CompanyInfo } from '../Companies/interfaces';
import { ProjectOverview } from '../Projects/interfaces';
import { SubscriptionInfo } from '../Subscriptions/interfaces';
import { OperationSettingCommand } from '../OperationSettings/interfaces';
import * as constants from './apiConstants';

export interface addUserToProjectAction {
    type: typeof constants.ADD_USER_TO_PROJECT;
    subscriptionId: Guid;
    projectId: Guid;
    userEmail: string;
    role: number;
}

export const addUserToProject = (
    subscriptionId: Guid,
    projectId: Guid,
    userEmail: string,
    role: number
) =>
    ({
        type: constants.ADD_USER_TO_PROJECT,
        subscriptionId,
        projectId,
        userEmail,
        role,
    } as addUserToProjectAction);

export interface deleteUserFromProjectAction {
    type: typeof constants.DELETE_USER_FROM_PROJECT;
    subscriptionId: Guid;
    projectId: Guid;
    userEmail: string;
}

export const deleteUserFromProject = (
    subscriptionId: Guid,
    projectId: Guid,
    userEmail: string
) =>
    ({
        type: constants.DELETE_USER_FROM_PROJECT,
        subscriptionId,
        projectId,
        userEmail,
    } as deleteUserFromProjectAction);

export interface getProjectsAction {
    type: typeof constants.GET_PROJECTS;
    subscriptionId: Guid;
}

export const getProjects = (subscriptionId: Guid) =>
    ({
        type: constants.GET_PROJECTS,
        subscriptionId,
    } as getProjectsAction);

export interface getProjectAction {
    type: typeof constants.GET_PROJECT;
    guid: Guid;
    subscriptionId: Guid;
}

export const getProject = (subscriptionId: Guid, guid: Guid) =>
    ({
        type: constants.GET_PROJECT,
        subscriptionId,
        guid,
    } as getProjectAction);

export interface uploadLayoutDbAction {
    type: typeof constants.UPLOAD_LAYOUT_DATABASE;
    subscriptionId: Guid;
    projectId: Guid;
    layoutGuid: Guid;
    layoutDbFile: File;
    wefFile: File;
    layoutDbFileRequestId: Guid;
    wefFileRequestId: Guid;
}

export interface getProjectRoleAssignmentsAction {
    type: typeof constants.GET_PROJECT_ROLE_ASSIGNMENTS;
    subscriptionId: Guid;
    projectId: Guid;
}

export const getProjectRoleAssignments = (
    subscriptionId: Guid,
    projectId: Guid
) =>
    ({
        type: constants.GET_PROJECT_ROLE_ASSIGNMENTS,
        subscriptionId,
        projectId,
    } as getProjectRoleAssignmentsAction);

export const uploadLayoutDb = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutGuid: Guid,
    layoutDbFile: File,
    wefFile: File
) =>
    ({
        type: constants.UPLOAD_LAYOUT_DATABASE,
        subscriptionId,
        projectId,
        layoutGuid,
        layoutDbFile,
        wefFile,
        layoutDbFileRequestId: Guid.create(),
        wefFileRequestId: Guid.create(),
    } as uploadLayoutDbAction);

export interface uploadDwgAction {
    type: typeof constants.UPLOAD_DWG;
    subscriptionId: Guid;
    projectId: Guid;
    layoutGuid: Guid;
    dwgFile: File;
    requestId: Guid;
}

export const uploadDwg = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutGuid: Guid,
    file: File
) =>
    ({
        type: constants.UPLOAD_DWG,

        subscriptionId,
        projectId,
        layoutGuid,
        dwgFile: file,
        requestId: Guid.create(),
    } as uploadDwgAction);

export interface createCompanyAction {
    type: typeof constants.CREATE_COMPANY;
    subscriptionId: Guid;
    createdCompanyCallback: (newCompanyId?: string) => void;
    companyInfo: CompanyInfo;
}

export const createCompany = (
    subscriptionId: Guid,
    companyInfo: CompanyInfo,
    createdCompanyCallback: (newCompanyId?: string) => void
) =>
    ({
        type: constants.CREATE_COMPANY,
        subscriptionId,
        companyInfo,
        createdCompanyCallback,
    } as createCompanyAction);

export interface getCompaniesAction {
    type: typeof constants.GET_COMPANIES;
    subscriptionId: Guid;
}

export const getCompanies = (subscriptionId: Guid) => {
    return {
        type: constants.GET_COMPANIES,
        subscriptionId,
    } as getCompaniesAction;
};

export interface getAddressesAction {
    type: typeof constants.GET_ADDRESSES;
    subscriptionId: Guid;
}

export const getAddresses = (subscriptionId: Guid) => {
    return {
        type: constants.GET_ADDRESSES,
        subscriptionId,
    } as getAddressesAction;
};

export interface getCompanyAction {
    type: typeof constants.GET_COMPANY;
    subscriptionId: Guid;
    companyId: string;
}

export const getCompany = (subscriptionId: Guid, companyId: string) => {
    return {
        type: constants.GET_COMPANY,
        subscriptionId,
        companyId,
    } as getCompanyAction;
};

export interface updateCompanyAction {
    type: typeof constants.UPDATE_COMPANY;
    subscriptionId: Guid;
    companyInfo: CompanyInfo;
    updatedCompanyCallback: (companyId?: string) => void;
}

export const updateCompany = (
    subscriptionId: Guid,
    companyInfo: CompanyInfo,
    updatedCompanyCallback: (companyId?: string) => void
) => {
    return {
        type: constants.UPDATE_COMPANY,
        subscriptionId,
        companyInfo,
        updatedCompanyCallback,
    } as updateCompanyAction;
};

export interface markOperationsAsArchivedAction {
    type: typeof constants.MARK_OPERATIONS_AS_ARCHIVED;
    subscriptionId: Guid;
    projectId: Guid;
    layoutId: Guid;
    operations: ArchiveOperationUpdatesCommand;
}

export const markOperationsAsArchived = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutId: Guid,
    operations: ArchiveOperationUpdatesCommand
) =>
    ({
        type: constants.MARK_OPERATIONS_AS_ARCHIVED,
        subscriptionId,
        projectId,
        layoutId,
        operations,
    } as markOperationsAsArchivedAction);

export interface CreateProjectAction {
    type: typeof constants.CREATE_PROJECT;
    subscriptionId: Guid;
    project: ProjectOverview;
    createdCallback: (projectId?: string) => void;
}

export const createProject = (
    subscriptionId: Guid,
    project: ProjectOverview,
    createdCallback: (projectId?: string) => void
) =>
    ({
        type: constants.CREATE_PROJECT,
        subscriptionId,
        project,
        createdCallback,
    } as CreateProjectAction);

export interface EditProjectAction {
    type: typeof constants.EDIT_PROJECT;
    subscriptionId: Guid;
    project: ProjectOverview;
    editedCallback: (projectId?: string) => void;
}

export const editProject = (
    subscriptionId: Guid,
    project: ProjectOverview,
    editedCallback: (projectId?: string) => void
) =>
    ({
        type: constants.EDIT_PROJECT,
        subscriptionId,
        project,
        editedCallback,
    } as EditProjectAction);

export interface DeleteProjectAction {
    type: typeof constants.DELETE_PROJECT;
    subscriptionId: Guid;
    projectId: string;
    deletedCallback: (status: boolean) => void;
}

export const deleteProject = (
    subscriptionId: Guid,
    projectId: string,
    deletedCallback: (status: boolean) => void
) =>
    ({
        type: constants.DELETE_PROJECT,
        subscriptionId,
        projectId,
        deletedCallback,
    } as DeleteProjectAction);

export interface createLayoutAction {
    type: typeof constants.CREATE_LAYOUT_WITH_NEW_SITE_AND_SYSTEM;
    subscriptionId: Guid;
    siteName: string;
    systemName: string;
    layoutName: string;
    projectId: Guid;
    createdCallback: (projectId?: string) => void;
}

export const createLayoutWithNewSiteAndSystem = (
    subscriptionId: Guid,
    siteName: string,
    systemName: string,
    layoutName: string,
    projectId: Guid,
    createdCallback: (projectId?: string) => void
) =>
    ({
        type: constants.CREATE_LAYOUT_WITH_NEW_SITE_AND_SYSTEM,

        subscriptionId,
        siteName,
        systemName,
        layoutName,
        projectId,
        createdCallback,
    } as createLayoutAction);

export interface getSubscriptionAction {
    type: typeof constants.GET_SUBSCRIPTION;
}

export const getSubscription = () =>
    ({
        type: constants.GET_SUBSCRIPTION,
    } as getSubscriptionAction);

export interface getEulaAction {
    type: typeof constants.GET_EULA;
    subscriptionId: Guid;
}

export const getEula = (subscriptionId: Guid) =>
    ({
        type: constants.GET_EULA,
        subscriptionId,
    } as getEulaAction);

export interface getSubscriptionRoleAssignmentsAction {
    type: typeof constants.GET_SUBSCRIPTION_ROLE_ASSIGNMENTS;
    subscriptionId: Guid;
}

export const getSubscriptionRoleAssignments = (subscriptionId: Guid) =>
    ({
        type: constants.GET_SUBSCRIPTION_ROLE_ASSIGNMENTS,
        subscriptionId,
    } as getSubscriptionRoleAssignmentsAction);

export interface GetLayoutFileDownloadLinkAction {
    type: typeof constants.GET_LAYOUT_FILE_DOWNLOAD_LINK;
    subscriptionId: Guid;
    projectId: Guid;
    layoutId: Guid;
    layoutFileId: Guid;
}

export const getLayoutFileDownloadLink = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutId: Guid,
    layoutFileId: Guid
) =>
    ({
        type: constants.GET_LAYOUT_FILE_DOWNLOAD_LINK,
        subscriptionId,
        projectId,
        layoutId,
        layoutFileId,
    } as GetLayoutFileDownloadLinkAction);

export interface DownloadLayoutUpdatesAction {
    type: typeof constants.DOWNLOAD_LAYOUT_UPDATES;
    subscriptionId: Guid;
    projectId: Guid;
    layoutId: Guid;
    downloadUpdatesCommand: DownloadUpdatesCommand;
}

export const downloadLayoutUpdates = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutId: Guid,
    downloadUpdatesCommand: DownloadUpdatesCommand
) =>
    ({
        type: constants.DOWNLOAD_LAYOUT_UPDATES,
        subscriptionId,
        projectId,
        layoutId,
        downloadUpdatesCommand,
    } as DownloadLayoutUpdatesAction);

export interface getCirrusInstallDownloadLinkAction {
    type: typeof constants.GET_CIRRUS_INSTALL_DOWNLOAD_LINK;
    fileCallback: (
        isSuccess: boolean,
        cirrusInstallDownloadLink?: string,
        cirrusInstallDownloadFileName?: string
    ) => void;
}

export const getCirrusInstallDownloadLink = (
    fileCallback: (
        isSuccess: boolean,
        cirrusInstallDownloadLink?: string,
        cirrusInstallDownloadFileName?: string
    ) => void
) =>
    ({
        type: constants.GET_CIRRUS_INSTALL_DOWNLOAD_LINK,
        fileCallback,
    } as getCirrusInstallDownloadLinkAction);

export interface getCirrusInstallVersionInformationAction {
    type: typeof constants.GET_CIRRUS_INSTALL_INFO;
}

export const getCirrusInstallVersionInformation = () => {
    return {
        type: constants.GET_CIRRUS_INSTALL_INFO,
    } as getCirrusInstallVersionInformationAction;
};

export interface GetLayoutVisualizationAction {
    type: typeof constants.GET_LAYOUT_VISUALIZATION;
    subscriptionId: Guid;
    projectId: Guid;
    layoutId: Guid;
}

export const getLayoutVisualization = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutId: Guid
) =>
    ({
        type: constants.GET_LAYOUT_VISUALIZATION,
        subscriptionId,
        projectId,
        layoutId,
    } as GetLayoutVisualizationAction);

export interface GetLayoutBackgroundDrawingAction {
    type: typeof constants.GET_LAYOUT_BACKGROUNDDRAWING;
    subscriptionId: Guid;
    projectId: Guid;
    layoutId: Guid;
}

export const getLayoutBackgroundDrawing = (
    subscriptionId: Guid,
    projectId: Guid,
    layoutId: Guid
) =>
    ({
        type: constants.GET_LAYOUT_BACKGROUNDDRAWING,
        subscriptionId,
        projectId,
        layoutId,
    } as GetLayoutBackgroundDrawingAction);

export interface CreateSubscriptionAction {
    type: typeof constants.CREATE_SUBSCRIPTION;
    createSubscriptionCommand: CreateSubscriptionCommand;
    createdCallback: (
        success: boolean,
        subscription?: SubscriptionInfo
    ) => void;
}

export const createSubscription = (
    createSubscriptionCommand: CreateSubscriptionCommand,
    createdCallback: (success: boolean, subscription?: SubscriptionInfo) => void
) =>
    ({
        type: constants.CREATE_SUBSCRIPTION,
        createSubscriptionCommand,
        createdCallback,
    } as CreateSubscriptionAction);

export interface getProjectOperationSettingsAction {
    type: typeof constants.GET_PROJECT_OPERATION_SETTINGS;
    subscriptionId: Guid;
    projectId: string;
}

export const getProjectOperationSettings = (
    subscriptionId: Guid,
    projectId: string
) => {
    return {
        type: constants.GET_PROJECT_OPERATION_SETTINGS,
        subscriptionId,
        projectId,
    } as getProjectOperationSettingsAction;
};

export interface getOperationSettingsAction {
    type: typeof constants.GET_OPERATION_SETTINGS;
    subscriptionId: Guid;
}

export const getOperationSettings = (subscriptionId: Guid) => {
    return {
        type: constants.GET_OPERATION_SETTINGS,
        subscriptionId,
    } as getOperationSettingsAction;
};

export interface createOperationSettingAction {
    type: typeof constants.CREATE_OPERATION_SETTINGS;
    subscriptionId: Guid;
    templateId: Guid;
    operationSettingCommand: OperationSettingCommand;
}

export const createOperationSetting = (
    subscriptionId: Guid,
    templateId: Guid,
    operationSettingCommand: OperationSettingCommand
) =>
    ({
        type: constants.CREATE_OPERATION_SETTINGS,
        subscriptionId,
        templateId,
        operationSettingCommand,
    } as createOperationSettingAction);

export interface updateOperationSettingAction {
    type: typeof constants.UPDATE_OPERATION_SETTINGS;
    subscriptionId: Guid;
    templateId: Guid;
    operationSettingId: Guid;
    operationSettingCommand: OperationSettingCommand;
}

export const updateOperationSetting = (
    subscriptionId: Guid,
    templateId: Guid,
    operationSettingId: Guid,
    operationSettingCommand: OperationSettingCommand
) =>
    ({
        type: constants.UPDATE_OPERATION_SETTINGS,
        subscriptionId,
        templateId,
        operationSettingId,
        operationSettingCommand,
    } as updateOperationSettingAction);

export interface updateOperationCodeDisplayFormatAction {
    type: typeof constants.UPDATE_OPERATION_CODE_DISPLAY_FORMAT;
    subscriptionId: Guid;
    templateId: Guid;
    operationCodeFormat: string;
}

export const updateOperationCodeDisplayFormat = (
    subscriptionId: Guid,
    templateId: Guid,
    operationCodeFormat: string
) =>
    ({
        type: constants.UPDATE_OPERATION_CODE_DISPLAY_FORMAT,
        subscriptionId,
        templateId,
        operationCodeFormat,
    } as updateOperationCodeDisplayFormatAction);

export interface deleteOperationSettingAction {
    type: typeof constants.DELETE_OPERATION_SETTINGS;
    subscriptionId: Guid;
    templateId: Guid;
    operationSettingIds: Guid[];
}

export const deleteOperationSetting = (
    subscriptionId: Guid,
    templateId: Guid,
    operationSettingIds: Guid[]
) =>
    ({
        type: constants.DELETE_OPERATION_SETTINGS,
        subscriptionId,
        templateId,
        operationSettingIds,
    } as deleteOperationSettingAction);

export type KnownAction =
    | addUserToProjectAction
    | createCompanyAction
    | createLayoutAction
    | CreateProjectAction
    | CreateSubscriptionAction
    | deleteUserFromProjectAction
    | DownloadLayoutUpdatesAction
    | EditProjectAction
    | getAddressesAction
    | getCirrusInstallDownloadLinkAction
    | getCirrusInstallVersionInformationAction
    | getCompaniesAction
    | getCompanyAction
    | GetLayoutBackgroundDrawingAction
    | GetLayoutFileDownloadLinkAction
    | GetLayoutVisualizationAction
    | getProjectAction
    | getProjectRoleAssignmentsAction
    | getProjectsAction
    | getSubscriptionAction
    | getSubscriptionRoleAssignmentsAction
    | markOperationsAsArchivedAction
    | updateCompanyAction
    | uploadDwgAction
    | uploadLayoutDbAction
    | getProjectOperationSettingsAction
    | getOperationSettingsAction
    | createOperationSettingAction
    | updateOperationSettingAction
    | updateOperationCodeDisplayFormatAction
    | deleteOperationSettingAction
    | DeleteProjectAction
    | getEulaAction;
