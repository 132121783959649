import { connect } from 'react-redux';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import Modals, { Actions, Props } from './components/Modals';
import { modalActions, modalSelectors } from 'src/redux/Modals';
import { Dispatch } from 'redux';
import { Guid } from 'guid-typescript';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        modals: modalSelectors.getModals(state),
    };
};

const mapDispatchToProps: (dispatch: Dispatch, ownProps: any) => Actions = (
    dispatch,
    ownProps
) => {
    return {
        close: (modalId: Guid) => dispatch(modalActions.CloseModal(modalId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
