const SegmentIcon = ({ iconColor = '#1A6899' }) => {
    let width = 16;
    let height = 16;
    let viewBox = '-2 -4 18 18';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={width}
            height={height}
            viewBox={viewBox}
            role="icon"
            aria-label="Segment"
            data-testid="SegmentIcon"
        >
            <rect
                x="13"
                y="0"
                width="2"
                height={height}
                transform="rotate(45 13 0)"
                fill={iconColor}
            />
        </svg>
    );
};

export default SegmentIcon;
