import { Guid } from 'guid-typescript';
import { apiActions } from 'src/redux/Api';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { connect } from 'react-redux';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import Settings, { Props, Actions } from './components/Settings';
import { operationSettingsSelectors } from 'src/redux/OperationSettings';
import { OperationSettingCommand } from 'src/redux/OperationSettings/interfaces';
import { modalActions } from 'src/redux/Modals';
import { ModalType } from 'src/constants/enums';
import { ReactNode } from 'react';
import { permissionSelectors } from 'src/redux/Permissions';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
        subscriptionRoleAssignments:
            subscriptionSelectors.getSubscriptionRoleAssignments(state),
        subscriptionInfo: subscriptionSelectors.getSubscriptionInfo(state),
        operationPermissions:
            permissionSelectors.getOperationSettingsPermissions(state),
        operationSettings:
            operationSettingsSelectors.getOperationSettings(state),
    };
};

const mapDispatchToProps: (dispatch: any) => Actions = (dispatch) => {
    return {
        getSubscriptionRoleAssignments: (subscriptionId: Guid) =>
            dispatch(apiActions.getSubscriptionRoleAssignments(subscriptionId)),
        showModalWithContents: (
            id: Guid,
            heading: string,
            content: ReactNode
        ) =>
            dispatch(
                modalActions.OpenModal({
                    id,
                    heading,
                    type: ModalType.Custom,
                    content,
                })
            ),
        showModalMessage: (id: Guid, heading: string, content: string) => {
            dispatch(
                modalActions.OpenModal({
                    id,
                    heading,
                    type: ModalType.Message,
                    content,
                    primaryButton: {
                        label: 'General.Close',
                        onClick: () => {
                            dispatch(modalActions.CloseModal(id));
                        },
                    },
                })
            );
        },
        closeModal: (id: Guid) => {
            dispatch(modalActions.CloseModal(id));
        },
        getOperationSettings: (subscriptionId: Guid) =>
            dispatch(apiActions.getOperationSettings(subscriptionId)),
        createOperationSetting: (
            subscriptionId: Guid,
            templateId: Guid,
            operationSettingCommand: OperationSettingCommand
        ) =>
            dispatch(
                apiActions.createOperationSetting(
                    subscriptionId,
                    templateId,
                    operationSettingCommand
                )
            ),
        updateOperationSetting: (
            subscriptionId: Guid,
            templateId: Guid,
            operationSettingId: Guid,
            operationSettingCommand: OperationSettingCommand
        ) =>
            dispatch(
                apiActions.updateOperationSetting(
                    subscriptionId,
                    templateId,
                    operationSettingId,
                    operationSettingCommand
                )
            ),
        updateOperationCodeDisplayFormat: (
            subscriptionId: Guid,
            templateId: Guid,
            operationCodeFormat: string
        ) =>
            dispatch(
                apiActions.updateOperationCodeDisplayFormat(
                    subscriptionId,
                    templateId,
                    operationCodeFormat
                )
            ),
        deleteOperationSetting: (
            subscriptionId: Guid,
            templateId: Guid,
            operationSettingIds: Guid[]
        ) =>
            dispatch(
                apiActions.deleteOperationSetting(
                    subscriptionId,
                    templateId,
                    operationSettingIds
                )
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
