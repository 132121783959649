import {
    LayoutVisualizationDataDTO,
    LayoutBackgroundDrawingDTO,
} from 'src/services/Dtos';
import * as layoutConstants from './layoutConstants';

export interface SetLayoutBackgroundDrawingDataAction {
    type: typeof layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA;
    layoutBackgroundDrawingData: LayoutBackgroundDrawingDTO | null;
}

export const SetLayoutBackgroundDrawingData = (
    layoutBackgroundDrawingData: LayoutBackgroundDrawingDTO
) =>
    ({
        type: layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA,
        layoutBackgroundDrawingData,
    } as SetLayoutBackgroundDrawingDataAction);

export interface RemoveLayoutBackgroundDrawingDataAction {
    type: typeof layoutConstants.REMOVE_LAYOUT_BACKGROUNDDRAWING_DATA;
}

export const RemoveLayoutBackgroundDrawingData = () =>
    ({
        type: layoutConstants.REMOVE_LAYOUT_BACKGROUNDDRAWING_DATA,
    } as RemoveLayoutBackgroundDrawingDataAction);

export interface SetLayoutBackgroundDrawingDataIsLoadingAction {
    type: typeof layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADING;
    value: boolean;
}
export const SetLayoutBackgroundDrawingDataIsLoading = (value: boolean) =>
    ({
        type: layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADING,
        value,
    } as SetLayoutBackgroundDrawingDataIsLoadingAction);

export interface SetLayoutBackgroundDrawingDataIsLoadedAction {
    type: typeof layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADED;
    value: boolean;
}
export const SetLayoutBackgroundDrawingDataIsLoaded = (value: boolean) =>
    ({
        type: layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADED,
        value,
    } as SetLayoutBackgroundDrawingDataIsLoadedAction);
export interface SetLayoutBackgroundDrawingDataErrorAction {
    type: typeof layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_ERROR;
    error: string;
}
export const SetLayoutBackgroundDrawingDataError = (error: string) =>
    ({
        type: layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_ERROR,
        error,
    } as SetLayoutBackgroundDrawingDataErrorAction);

export interface SetLayoutVisualizationDataAction {
    type: typeof layoutConstants.SET_LAYOUT_VISUALIZATION_DATA;
    layoutVisualizationData: LayoutVisualizationDataDTO | null;
}

export const SetLayoutVisualizationData = (
    layoutVisualizationData: LayoutVisualizationDataDTO
) =>
    ({
        type: layoutConstants.SET_LAYOUT_VISUALIZATION_DATA,
        layoutVisualizationData,
    } as SetLayoutVisualizationDataAction);

export interface RemoveLayoutVisualizationDataAction {
    type: typeof layoutConstants.REMOVE_LAYOUT_VISUALIZATION_DATA;
}

export const RemoveLayoutVisualizationData = () =>
    ({
        type: layoutConstants.REMOVE_LAYOUT_VISUALIZATION_DATA,
    } as RemoveLayoutVisualizationDataAction);

export interface SetLayoutVisualizationDataIsLoadingAction {
    type: typeof layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_IS_LOADING;
    value: boolean;
}
export const SetLayoutVisualizationDataIsLoading = (value: boolean) =>
    ({
        type: layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_IS_LOADING,
        value,
    } as SetLayoutVisualizationDataIsLoadingAction);

export interface SetLayoutVisualizationDataIsLoadedAction {
    type: typeof layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_IS_LOADED;
    value: boolean;
}
export const SetLayoutVisualizationDataIsLoaded = (value: boolean) =>
    ({
        type: layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_IS_LOADED,
        value,
    } as SetLayoutVisualizationDataIsLoadedAction);
export interface SetLayoutVisualizationDataErrorAction {
    type: typeof layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_ERROR;
    error: string;
}
export const SetLayoutVisualizationDataError = (error: string) =>
    ({
        type: layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_ERROR,
        error,
    } as SetLayoutVisualizationDataErrorAction);

export type KnownAction =
    | SetLayoutBackgroundDrawingDataAction
    | RemoveLayoutBackgroundDrawingDataAction
    | SetLayoutBackgroundDrawingDataIsLoadingAction
    | SetLayoutBackgroundDrawingDataIsLoadedAction
    | SetLayoutBackgroundDrawingDataErrorAction
    | SetLayoutVisualizationDataAction
    | RemoveLayoutVisualizationDataAction
    | SetLayoutVisualizationDataIsLoadingAction
    | SetLayoutVisualizationDataIsLoadedAction
    | SetLayoutVisualizationDataErrorAction;
