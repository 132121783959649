import { Reducer, Action } from 'redux';
import * as operationSettingsActions from './operationSettingsActions';
import * as operationSettingsConstants from './operationSettingsConstants';
import { OperationSettingState } from './interfaces';

export const initialState: OperationSettingState = {
    operationSettings: [],
    operation: {
        operationSettingTemplateId: '',
        id: '',
        code: 0,
        name: '',
        parameter1Name: '',
        parameter1Unit: '',
        parameter1Enabled: false,
        parameter2Name: '',
        parameter2Unit: '',
        parameter2Enabled: false,
        lastModifiedByUserId: '',
        lastModifiedByUserEmail: '',
        lastModifiedDateTime: undefined,
        prerequisite: '',
        expectedBehavior: '',
    },
};
export const reducer: Reducer<OperationSettingState> = (
    state: OperationSettingState = initialState,
    incomingAction: Action
): OperationSettingState => {
    const action = incomingAction as operationSettingsActions.KnownAction;
    switch (action.type) {
        case operationSettingsConstants.SET_OPERATION_SETTINGS:
            return { ...state, operationSettings: action.operationSettings };
        case operationSettingsConstants.SET_NEW_OPERATION_SETTINGS:
            return { ...state, operation: action.newOperation };
        default:
            return state;
    }
};
