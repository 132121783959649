import React, { useEffect, useState } from 'react';
import {
    Typography,
    CircularProgress,
    Tab,
    IconButton,
    MenuItem,
    Menu,
    Divider,
} from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { Guid } from 'guid-typescript';
import { history } from 'src/routing';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { Table } from 'src/components/Table';
import { ProjectStatus } from 'src/constants/enums';
import dateOrTimeFormatter from 'src/helpers/dateFormatter';
import { AccessControl } from 'src/components/AccessControl';
import { Scope } from 'src/constants/enums';
import { useIntl } from 'react-intl';
import { useTranslation } from 'src/hooks';
import { isGuidEmpty } from 'src/helpers/utils';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
    ProjectInfo,
    NOT_FOUND,
    ProjectOverview,
} from 'src/redux/Projects/interfaces';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import RestoreIcon from '@material-ui/icons/Restore';
import DeleteProjectModal from '../../ProjectViewer/components/DeleteProjectModal';
import { modalActions } from 'src/redux/Modals';
import theme from 'src/theme';

const StyledTab = styled(Tab)`
    ${({ theme }) => `
    min-width: ${theme.spacing(14)}`}
`;

const StyledDiv = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
`;

const StyledDivider = styled(Divider)`
    background-color: ${theme.palette.neutral.gainsboro};
    margin: 10px 16px;
`;

interface IMenuItemWithIcon {
    onClick: () => void;
    label: string;
    icon: React.ReactNode;
    dataTestId?: string;
}

export type Props = {
    selectedProject: ProjectInfo | undefined | typeof NOT_FOUND;
    projects: ProjectInfo[] | undefined;
    subscriptionId: Guid;
};

export type Actions = {
    getProjects: (subscriptionId: Guid) => any;
    resetProjects: () => any;
    EditProject: (
        subscriptionId: Guid,
        project: ProjectOverview,
        editedCallback: (projectId?: string) => void
    ) => void;
    DeleteProject: (
        subscriptionId: Guid,
        projectId: string,
        editedCallback: (status: boolean) => void
    ) => void;
    showModalWithContents: (
        id: Guid,
        heading: string,
        content: React.ReactNode
    ) => void;
    GetProject: (subscriptionId: Guid, guid: string) => void;
};

const Projects = ({
    projects = undefined,
    subscriptionId,
    selectedProject,
    getProjects,
    resetProjects,
    EditProject,
    DeleteProject,
    showModalWithContents,
    GetProject,
}: Props & Actions) => {
    const dispatch = useDispatch();
    const [projectStatusFilter, setProjectStatusFilter] = useState(
        ProjectStatus.Ongoing
    );
    const getTranslation = useTranslation();
    const intl = useIntl();
    const [menuAnchorEl, setMenuAnchorEl] = useState<{ [key: string]: any }>(
        {}
    );
    const [currentProjectId, setCurrentProjectId] = useState<string>('');
    const [projectStatus, setProjectStatus] = useState(
        selectedProject !== NOT_FOUND
            ? selectedProject?.status
            : ProjectStatus.Ongoing
    );
    const [activeModalId, setActiveModalId] = useState<Guid>(Guid.create());
    const [editDeleteSuccess, setEditDeleteSuccess] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        resetProjects();
    }, []);

    useEffect(() => {
        if (subscriptionId && currentProjectId) {
            GetProject(subscriptionId as Guid, currentProjectId);
        }
    }, [currentProjectId, subscriptionId]);

    useEffect(() => {
        if (subscriptionId && !isGuidEmpty(subscriptionId)) {
            getProjects(subscriptionId);
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (
            editDeleteSuccess &&
            subscriptionId &&
            !isGuidEmpty(subscriptionId)
        ) {
            getProjects(subscriptionId);
            setEditDeleteSuccess(false);
        }
    }, [editDeleteSuccess, subscriptionId]);

    const NavigateTo = (path: string) => {
        history.push(path);
    };

    const renderDate = (project: ProjectInfo) => {
        return new Date(project.lastUpdate!).getTime() > 0
            ? dateOrTimeFormatter(intl, project.lastUpdate!)
            : '';
    };

    const renderOpenButton = (project: ProjectInfo) => {
        return (
            <AccessControl
                scope={Scope.Project}
                id={project.id}
                allowedPermission="readProject"
            >
                <Button
                    colorScheme="secondary"
                    onClick={() =>
                        subscriptionId &&
                        NavigateTo(
                            `/subscriptions/${subscriptionId}/project/${project.id}`
                        )
                    }
                >
                    {getTranslation('Projects.Open.Label')}
                </Button>
            </AccessControl>
        );
    };

    const handleDeleteSuccess = (isSuccessful: boolean) => {
        setEditDeleteSuccess(isSuccessful);
    };

    const handleEditProjectClick = () => {
        if (!currentProjectId) return;
        const project = projects?.find((p) => p.id === currentProjectId);
        handleMenuClose(currentProjectId);
        const finishedCallback = (projectId?: string) => {
            setEditDeleteSuccess(true);
            setIsEditing(false);
            if (projectId !== undefined) {
                setProjectStatus(
                    selectedProject !== NOT_FOUND &&
                        selectedProject != null &&
                        selectedProject.status === ProjectStatus.Ongoing
                        ? ProjectStatus.Finished
                        : ProjectStatus.Ongoing
                );
            }
        };
        if (project) {
            const projectOverview: ProjectOverview = {
                companyId: project.companyId,
                projectName: project.commissioningProject,
                projectId: project.id,
                status:
                    project.status === ProjectStatus.Ongoing
                        ? ProjectStatus.Finished
                        : ProjectStatus.Ongoing,
            };
            setIsEditing(true);
            EditProject(
                subscriptionId as Guid,
                projectOverview,
                finishedCallback
            );
        }
    };

    const onDeleteProjectClick = (project: ProjectInfo) => {
        if (!currentProjectId) return;
        handleMenuClose(currentProjectId);
        setActiveModalId(Guid.create());
        activeModalId &&
            showModalWithContents(
                activeModalId,
                getTranslation('Projects.DeleteProject.Label'),
                <DeleteProjectModal
                    selectedProject={project}
                    deleteProject={DeleteProject}
                    subscriptionGuid={subscriptionId}
                    closeModal={() =>
                        dispatch(modalActions.CloseModal(activeModalId))
                    }
                    successCallback={handleDeleteSuccess}
                />
            );
    };

    const handleMenuOpen = (event: React.MouseEvent, projectId: string) => {
        setMenuAnchorEl((prev) => ({
            ...prev,
            [projectId]: event.currentTarget,
        }));
        setCurrentProjectId(projectId);
    };

    const handleMenuClose = (projectId: string) => {
        setMenuAnchorEl((prev) => ({ ...prev, [projectId]: null }));
        setCurrentProjectId('');
    };

    const MenuItemWithIcon = ({
        onClick,
        label,
        icon,
        dataTestId,
    }: IMenuItemWithIcon) => (
        <MenuItem
            style={{ display: 'flex', gap: '10px' }}
            onClick={onClick}
            data-testid={dataTestId}
        >
            {icon}
            <Typography variant="inherit">{label}</Typography>
        </MenuItem>
    );

    const renderProjectEditMenu = (project: ProjectInfo) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <AccessControl
                    scope={Scope.Project}
                    id={project.id}
                    allowedPermission="editProject"
                >
                    <IconButton
                        onClick={(e) => handleMenuOpen(e, project.id)}
                        style={{ marginTop: '-2px', marginBottom: '-2px' }}
                        data-testid={`more-icon-${project.id}`}
                    >
                        <MoreHorizIcon
                            style={{ cursor: 'pointer', textAlign: 'center' }}
                        />
                    </IconButton>
                </AccessControl>
                <Menu
                    id="dropdown-menu"
                    anchorEl={menuAnchorEl[project.id]}
                    open={Boolean(menuAnchorEl[project.id])}
                    onClose={() => handleMenuClose(project.id)}
                    transformOrigin={{
                        vertical: -100,
                        horizontal: 120,
                    }}
                >
                    <div>
                        <MenuItemWithIcon
                            onClick={() => {
                                subscriptionId &&
                                    NavigateTo(
                                        `/subscriptions/${subscriptionId}/edit-project/${currentProjectId}`
                                    );
                            }}
                            label={getTranslation(
                                'General.EditNameAndCompany.Label'
                            )}
                            icon={<EditIcon />}
                            dataTestId="projects-edit-name-company-button"
                        />
                        <MenuItemWithIcon
                            onClick={handleEditProjectClick}
                            label={
                                project.status === ProjectStatus.Ongoing
                                    ? getTranslation(
                                          'General.SetToFinished.Label'
                                      )
                                    : getTranslation(
                                          'General.SetToOngoing.Label'
                                      )
                            }
                            icon={
                                project.status === ProjectStatus.Ongoing ? (
                                    <CheckIcon />
                                ) : (
                                    <RestoreIcon />
                                )
                            }
                            dataTestId="projects-set-to-finish-ongoing-button"
                        />
                        <StyledDivider />
                        <MenuItemWithIcon
                            onClick={() => onDeleteProjectClick(project)}
                            label={getTranslation(
                                'General.DeleteProject.Label'
                            )}
                            icon={<DeleteOutlinedIcon />}
                            dataTestId="projects-delete-button"
                        />
                    </div>
                </Menu>
            </div>
        );
    };

    const projectTable = (
        <Table
            objects={projects?.filter((o) => o.status == projectStatusFilter)}
            headers={[
                getTranslation('Projects.Table.Header.CompanyName'),
                getTranslation('Projects.Table.Header.ProjectName'),
                getTranslation('Projects.Table.Header.ProjectUserCount'),
                getTranslation('Projects.Table.Header.LastLayoutUpdate'),
                '',
                '',
            ]}
            columnContents={[
                'company',
                'commissioningProject',
                'assignedPeople',
                (project: ProjectInfo) => renderDate(project),
                (project: ProjectInfo) => renderOpenButton(project),
                (project: ProjectInfo) => renderProjectEditMenu(project),
            ]}
        />
    );

    const noProjects = (
        <StyledDiv>{getTranslation('Projects.NoProject.Label')}</StyledDiv>
    );

    return (
        <React.Fragment>
            <div
                style={{
                    marginTop: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{ marginRight: '20px' }}
                        >
                            {'Commissioning projects'}
                        </Typography>
                        {!projects ? (
                            <CircularProgress color="secondary" />
                        ) : (
                            ''
                        )}
                    </div>
                    <div>
                        <AccessControl
                            scope={Scope.Subscription}
                            id={subscriptionId}
                            allowedPermission="createProjects"
                        >
                            <Button
                                colorScheme="primary"
                                onClick={() =>
                                    subscriptionId &&
                                    NavigateTo(
                                        `/subscriptions/${subscriptionId}/create-project`
                                    )
                                }
                            >
                                {getTranslation(
                                    'Projects.CreateNewProject.Label'
                                )}
                            </Button>
                        </AccessControl>
                    </div>
                </div>
                <TabContext value={projectStatusFilter.toString()}>
                    <TabList
                        onChange={(option, value) =>
                            setProjectStatusFilter(value as ProjectStatus)
                        }
                    >
                        <StyledTab
                            data-testid="ongoing-filter-tab"
                            label={'Ongoing'}
                            value={ProjectStatus.Ongoing.toString()}
                        />
                        <StyledTab
                            data-testid="finished-filter-tab"
                            label={'Finished'}
                            value={ProjectStatus.Finished.toString()}
                        />
                    </TabList>
                </TabContext>
                {projects && projects?.length > 0 ? projectTable : noProjects}
            </div>
        </React.Fragment>
    );
};

export default Projects;
