import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Grid,
    Button as MuiButton,
    LinearProgress,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import {
    FAILED_TO_GET_URL,
    LayoutFile,
    UploadingFile,
    WefFileInfo,
} from 'src/redux/Projects/interfaces';
import {
    Button,
    ButtonSize,
    ButtonVariant,
} from '@ndc/react-component-library';
import { ModalType, Scope } from 'src/constants/enums';
import {
    LayoutFileConfig,
    LayoutFileType,
} from 'src/constants/layoutFileConstants';
import { isEmpty } from 'lodash';
import { InlineMessage } from 'src/components/InlineMessage';
import useTranslation from 'src/hooks/useTranslation';
import { AccessControl } from 'src/components/AccessControl';
import { modalActions } from 'src/redux/Modals';
import { Guid } from 'guid-typescript';
import FileUploadComponent, { UploadItem } from './FileUploadComponent';
import { ButtonProps } from '@ndc/react-component-library/dist/components/Button';
import { Dispatch } from 'redux';
import { FormattedDate, FormattedTime } from 'react-intl';

export type Props = {
    fileList: (LayoutFile | WefFileInfo)[];
    uploadingLayoutFiles: UploadingFile[];
    fileType: LayoutFileType;
    layoutFileDownloadLink: URL | undefined | typeof FAILED_TO_GET_URL;
    uploadDisabled: boolean;
    projectId: string;
};

export type Actions = {
    uploadHandler: (files: File[]) => void;
    GetLayoutFileDownloadLink: (layoutFileId: string) => void;
    ResetLayoutFileDownloadLink: () => void;
};

const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: '140px',
        width: '380px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    },
    fileContainer: {
        minHeight: '86px',
        backgroundColor: theme.palette.grey[100],
        border: '1px solid #939393',
        boxSizing: 'border-box',
        borderRadius: '7px',
        padding: '12px 16px',
        marginBottom: theme.spacing(3),
    },
    loaderContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    noFiles: {
        justifyContent: 'flex-start',
        alignItems: 'start',

        minHeight: '70px',
    },
    spinnerWrapper: {
        position: 'absolute',
        width: '0px',
        height: '0px',
    },
    spinner: {
        position: 'relative',
        top: '-10px',
        marginLeft: theme.spacing(2),
    },
}));

type FileUploadButtonProps = {
    dispatch: Dispatch<any>;
    fileType: LayoutFileType;
    uploadModalTitle: string;
    uploadItems: UploadItem[];
    uploadHandler: (files: File[]) => void;
} & ButtonProps;

const FileUploadButton = ({
    dispatch,
    uploadModalTitle,
    uploadItems,
    uploadHandler,
    fileType,
    ...props
}: FileUploadButtonProps) => {
    return (
        <Button
            {...props}
            onClick={() => {
                const modalId = Guid.create();
                dispatch(
                    modalActions.OpenModal({
                        id: modalId,
                        heading: uploadModalTitle,
                        type: ModalType.Custom,
                        content: (
                            <FileUploadComponent
                                closeModal={() =>
                                    dispatch(modalActions.CloseModal(modalId))
                                }
                                uploadItems={uploadItems}
                                uploadHandler={uploadHandler}
                                fileType={fileType}
                            />
                        ),
                    })
                );
            }}
        />
    );
};

const LayoutFileComponent = ({
    fileList,
    uploadingLayoutFiles,
    fileType,
    layoutFileDownloadLink,
    uploadDisabled,
    uploadHandler,
    GetLayoutFileDownloadLink,
    ResetLayoutFileDownloadLink,
    projectId,
}: Props & Actions) => {
    const [downLoadInProgress, setDownloadInProgress] = useState(false);
    const [downLoadFileName, setDownloadFileName] = useState('');
    const classes = useStyles();
    const getTranslation = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const hasFiles = !isEmpty(fileList);
    let emptyMessage = getTranslation('ProjectViewer.Files.EmptyMessage', {
            n: 1,
        }),
        testId = '',
        buttonLabel = getTranslation('ProjectViewer.Files.UploadFile', {
            n: 1,
        }),
        allowedPermission = '',
        uploadItems = [] as UploadItem[],
        uploadModalTitle = '';
    switch (fileType) {
        case LayoutFileType.LayoutDatabase:
            emptyMessage = getTranslation('ProjectViewer.Files.EmptyMessage', {
                n: 2,
            });
            buttonLabel = getTranslation('ProjectViewer.Files.UploadFile', {
                n: 2,
            });
            testId = 'layout-file-upload';
            allowedPermission = 'uploadProjectFilesLayoutDb';
            uploadModalTitle = getTranslation(
                'ProjectViewer.FileUpload.Title.LayoutFiles'
            );
            uploadItems.push({
                id: Guid.create(),
                label: getTranslation('ProjectViewer.FileUpload.Select.File', {
                    fileType: LayoutFileConfig.databaseFile.fileType,
                }),
                accept: LayoutFileConfig.databaseFile.accept,
                maxSize: LayoutFileConfig.databaseFile.maxSize,
            } as UploadItem);
            uploadItems.push({
                id: Guid.create(),
                label: getTranslation('ProjectViewer.FileUpload.Select.File', {
                    fileType: LayoutFileConfig.wefFile.fileType,
                }),
                accept: LayoutFileConfig.wefFile.accept,
                maxSize: LayoutFileConfig.wefFile.maxSize,
            } as UploadItem);
            break;
        case LayoutFileType.BackgroundDrawing:
            testId = 'background-drawing-upload';
            allowedPermission = 'uploadProjectFilesDwg';
            uploadModalTitle = getTranslation(
                'ProjectViewer.FileUpload.Title.BackgroundDrawing'
            );
            uploadItems.push({
                id: Guid.create(),
                label: getTranslation('ProjectViewer.FileUpload.Select.File', {
                    fileType: LayoutFileConfig.backgroundDrawingFile.fileType,
                }),
                accept: LayoutFileConfig.backgroundDrawingFile.accept,
                maxSize: LayoutFileConfig.backgroundDrawingFile.maxSize,
            } as UploadItem);
            break;
    }

    const handleGetDownloadLink = (layoutFileId: string, fileName: string) => {
        setDownloadInProgress(true);
        setDownloadFileName(fileName);
        GetLayoutFileDownloadLink(layoutFileId);
    };

    useEffect(() => {
        if (layoutFileDownloadLink && downLoadInProgress) {
            if (layoutFileDownloadLink == FAILED_TO_GET_URL) {
                setDownloadInProgress(false);
                ResetLayoutFileDownloadLink();
                return;
            }
            const downloadUrl = layoutFileDownloadLink.href;

            fetch(downloadUrl)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to download blob');
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const element = document.createElement('a');
                    element.href = url;
                    element.setAttribute('download', downLoadFileName);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                    enqueueSnackbar(
                        getTranslation(
                            'General.DownloadSuccessNotification.Text'
                        ),
                        {
                            variant: 'success',
                        }
                    );
                })
                .catch(() => {
                    alert('Failed to download');
                })
                .finally(() => {
                    setDownloadFileName('');
                    ResetLayoutFileDownloadLink();
                    setDownloadInProgress(false);
                });
        }
    }, [layoutFileDownloadLink]);

    if (!isEmpty(uploadingLayoutFiles)) {
        const uploadingFiles = (
            <Box className={`${classes.main}`}>
                <Grid container>
                    {uploadingLayoutFiles.map((uploadingFile) => (
                        <Grid
                            item
                            xs={12}
                            key={uploadingFile.requestId}
                            className={`${classes.fileContainer} ${classes.loaderContent}`}
                        >
                            <Typography variant="subtitle1">
                                {uploadingFile.fileName}
                            </Typography>
                            <LinearProgress color="secondary" />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
        return <div>{uploadingFiles}</div>;
    }

    if (!hasFiles)
        return (
            <Box className={`${classes.main} ${classes.noFiles}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography color="textSecondary">
                            {emptyMessage}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AccessControl
                            scope={Scope.Project}
                            id={projectId}
                            allowedPermission={allowedPermission}
                        >
                            <FileUploadButton
                                variant={ButtonVariant.primary}
                                size={ButtonSize.small}
                                data-testid={testId}
                                dispatch={dispatch}
                                fileType={fileType}
                                uploadModalTitle={uploadModalTitle}
                                uploadItems={uploadItems}
                                uploadHandler={uploadHandler}
                            >
                                {buttonLabel}
                            </FileUploadButton>
                        </AccessControl>
                    </Grid>
                </Grid>
            </Box>
        );

    const checkIfLayoutFile = (
        file: LayoutFile | WefFileInfo
    ): file is LayoutFile => {
        return 'id' in (file as LayoutFile);
    };
    return (
        <Box className={`${classes.main}`}>
            <Grid container>
                {fileList?.map((currentFile: LayoutFile | WefFileInfo) => {
                    const isLayoutFile = checkIfLayoutFile(currentFile);
                    const uploadDate = new Date(
                        isLayoutFile
                            ? currentFile.dateTimeUtc
                            : currentFile.uploadedAtUtc
                    );
                    const key = isLayoutFile
                        ? currentFile.id
                        : currentFile.wefId;
                    return (
                        <Grid
                            item
                            xs={12}
                            key={`file-item-${key}`}
                            className={`${classes.fileContainer}`}
                        >
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        {currentFile.fileName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {isLayoutFile && (
                                        <AccessControl
                                            scope={Scope.Project}
                                            id={projectId}
                                            allowedPermission="downloadProjectFiles"
                                        >
                                            <MuiButton
                                                color="primary"
                                                disabled={
                                                    !isEmpty(
                                                        layoutFileDownloadLink
                                                    ) || downLoadInProgress
                                                }
                                                startIcon={
                                                    <GetAppOutlinedIcon />
                                                }
                                                onClick={() =>
                                                    handleGetDownloadLink(
                                                        currentFile.id,
                                                        currentFile.fileName
                                                    )
                                                }
                                            >
                                                {getTranslation(
                                                    'General.Download'
                                                )}
                                                {downLoadInProgress && (
                                                    <div
                                                        className={
                                                            classes.spinnerWrapper
                                                        }
                                                    >
                                                        <CircularProgress
                                                            className={
                                                                classes.spinner
                                                            }
                                                            size={20}
                                                            color="secondary"
                                                        />
                                                    </div>
                                                )}
                                            </MuiButton>
                                        </AccessControl>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {getTranslation('General.UploadedBy')}:{' '}
                                    {currentFile.userEmail || '(unknown)'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    <FormattedDate
                                        value={uploadDate}
                                        day="numeric"
                                        month="numeric"
                                        year="numeric"
                                        hour="numeric"
                                        minute="numeric"
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
                {uploadDisabled &&
                    fileType === LayoutFileType.LayoutDatabase && (
                        <Grid item xs={12}>
                            <AccessControl
                                scope={Scope.Project}
                                id={projectId}
                                allowedPermission="uploadProjectFilesLayoutDb"
                            >
                                <Box mb={3}>
                                    <InlineMessage
                                        variant="primary"
                                        messageText={getTranslation(
                                            'InlineMessage.UploadLayoutDisabled'
                                        )}
                                    />
                                </Box>
                            </AccessControl>
                        </Grid>
                    )}

                <Grid item xs={12}>
                    <Grid item xs={5}>
                        <AccessControl
                            scope={Scope.Project}
                            id={projectId}
                            allowedPermission={allowedPermission}
                        >
                            <FileUploadButton
                                variant={ButtonVariant.secondary}
                                size={ButtonSize.small}
                                data-testid={testId}
                                disabled={
                                    uploadDisabled &&
                                    fileType === LayoutFileType.LayoutDatabase
                                }
                                dispatch={dispatch}
                                fileType={fileType}
                                uploadModalTitle={uploadModalTitle}
                                uploadItems={uploadItems}
                                uploadHandler={uploadHandler}
                            >
                                {getTranslation(
                                    'ProjectViewer.Files.UpdateFile',
                                    {
                                        n: fileList?.length ?? 1,
                                    }
                                )}
                            </FileUploadButton>
                        </AccessControl>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>

                {!uploadDisabled &&
                    fileType === LayoutFileType.LayoutDatabase && (
                        <Grid item xs={12}>
                            <AccessControl
                                scope={Scope.Project}
                                id={projectId}
                                allowedPermission="uploadProjectFilesLayoutDb"
                            >
                                <Box mt={3}>
                                    <InlineMessage
                                        messageText={getTranslation(
                                            'InlineMessage.UploadLayoutInfo'
                                        )}
                                    />
                                </Box>
                            </AccessControl>
                        </Grid>
                    )}
            </Grid>
        </Box>
    );
};

export default LayoutFileComponent;
