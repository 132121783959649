import { Accept } from 'react-dropzone';

export enum LayoutFileType {
    LayoutDatabase = 0,
    BackgroundDrawing = 1,
}
interface LayoutFileConfig {
    fileType: string;
    accept: Accept;
    maxSize: number;
}
interface LayoutFileConfigProps {
    databaseFile: LayoutFileConfig;
    wefFile: LayoutFileConfig;
    backgroundDrawingFile: LayoutFileConfig;
}
export const LayoutFileConfig: LayoutFileConfigProps = {
    databaseFile: {
        fileType: '.mdb or .layf',
        accept: {
            'application/mdb': ['.mdb'],
            'application/layf': ['.layf'],
        },
        maxSize: 200 * 1024 * 1024, //200MiB
    },
    wefFile: {
        fileType: '.wef',
        accept: {
            'application/wef': ['.wef'],
        },
        maxSize: 100 * 1024 * 1024, //100MiB
    },
    backgroundDrawingFile: {
        fileType: '.dwg',
        accept: {
            'image/x-dwg': ['.dwg'],
        },
        maxSize: 300 * 1024 * 1024, //300MiB
    },
};
