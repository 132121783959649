import { connect } from 'react-redux';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import CreateEditProject, {
    Actions,
    Props,
} from './components/CreateEditProject';
import { apiActions } from 'src/redux/Api';
import { companyActions, companySelectors } from 'src/redux/Companies';
import { projectSelectors } from 'src/redux/Projects';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import { CompanyInfo } from 'src/redux/Companies/interfaces';
import { Guid } from 'guid-typescript';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        companies: companySelectors.getCompanies(state),
        editingProject: projectSelectors.getSelectedProject(
            state
        ) as ProjectInfo,
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
    };
};

const mapDispatchToProps: (dispatch: any) => Actions = (dispatch) => {
    return {
        createProject: (subscriptionId, projectOverview, finishedCallback) =>
            dispatch(
                apiActions.createProject(
                    subscriptionId,
                    projectOverview,
                    finishedCallback
                )
            ),
        editProject: (subscriptionId, projectOverview, finishedCallback) =>
            dispatch(
                apiActions.editProject(
                    subscriptionId,
                    projectOverview,
                    finishedCallback
                )
            ),
        getCompanies: (subscriptionId) =>
            dispatch(apiActions.getCompanies(subscriptionId)),
        setCompanies: (companyInfos: CompanyInfo[]) => {
            dispatch(companyActions.SetCompanies(companyInfos));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditProject);
