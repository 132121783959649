import { CreateSubscription } from 'src/components/CreateSubscription';
export type Props = {};

export type Actions = {};

const KMAdmin = ({}: Props & Actions) => {
    return <CreateSubscription />;
};

export default KMAdmin;
