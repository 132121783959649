import React, { ReactElement, useEffect } from 'react';
import { FullScreenLoading } from 'src/components/Loading';
import { Subscription } from 'src/components/Subscription';
import useValidateProductAccess from 'src/hooks/useValidateProductAccess';
import { Redirect, Route } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { accountActions } from 'src/redux/Account';

type Props = {
    children: ReactElement<React.ReactNode>[];
};

const Layout = (props: Props) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const [loading, allowed] = useValidateProductAccess();

    useEffect(() => {
        const account = instance.getActiveAccount();
        dispatch(accountActions.SetAccountInfo(account ?? undefined));
    }, [instance]);

    if (loading || allowed === undefined) return <FullScreenLoading />;

    return (
        <Route
            render={() =>
                allowed ? (
                    <Subscription {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/error/not-authorized',
                        }}
                    />
                )
            }
        />
    );
};

export default Layout;
