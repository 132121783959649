import { accountReducers } from '../redux/Account';
import { addressReducers } from '../redux/Addresses';
import { cirrusInstallInfoReducers } from '../redux/CirrusInstallInfo';
import { companyReducers } from '../redux/Companies';
import { layoutReducers } from '../redux/Layout';
import { modalReducers } from '../redux/Modals';
import { permissionReducers } from '../redux/Permissions';
import { projectReducers } from '../redux/Projects';
import { subscriptionReducers } from '../redux/Subscriptions';
import { operationSettingsReducers } from '../redux/OperationSettings';
import { syncServiceApi } from 'src/services/syncServiceApi';
import { projectApi } from 'src/services/projectApi';

export default {
    account: accountReducers,
    addresses: addressReducers,
    cirrusInstallInfo: cirrusInstallInfoReducers,
    companies: companyReducers,
    layout: layoutReducers,
    modals: modalReducers,
    permissions: permissionReducers,
    project: projectReducers,
    subscriptions: subscriptionReducers,
    operationSettings: operationSettingsReducers,
    [syncServiceApi.reducerPath]: syncServiceApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
};
