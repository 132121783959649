import { useState } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Guid } from 'guid-typescript';
import styled from 'styled-components';
import { FileUpload, OnDropProps, FileAccept } from 'src/components/FileUpload';
import { Button, ButtonVariant } from '@ndc/react-component-library';
import useTranslation from 'src/hooks/useTranslation';
import theme from 'src/theme';
import { LayoutFileType } from 'src/constants/layoutFileConstants';

const ErrorMessage = styled(Typography)`
    min-height: 24px;
`;

const MainContent = styled.div`
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(3)};
`;

const UploadedFileContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px 8px 10px;
    border-width: 1px;
    border-radius: 8px;
    border-color: ${theme.palette.neutral.dark};
    border-style: solid;
    background-color: ${theme.palette.neutral.light};
`;

export interface UploadItem {
    id: Guid;
    label: string;
    accept: FileAccept;
    maxSize: number;
}
interface UploadedFile {
    id: Guid;
    label: string;
    accept: FileAccept;
    fileName?: string;
    file?: File;
    hasError: boolean;
    errorMessage: string;
    maxSize: number;
}
interface UploadFiles {
    [key: string]: UploadedFile;
}

export type Props = {
    uploadItems: UploadItem[];
    fileType: LayoutFileType;
};
export type Actions = {
    closeModal: () => void;
    uploadHandler: (files: File[]) => void;
};

const FileUploadComponent = ({
    fileType,
    uploadItems,
    closeModal,
    uploadHandler,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const [uploadedFiles, setUploadedFiles] = useState<UploadFiles>(
        uploadItems.reduce((data: UploadFiles, item: UploadItem) => {
            data[item.id.toString()] = {
                ...item,
                hasError: false,
                errorMessage: '',
            };
            return data;
        }, {} as UploadFiles)
    );
    const handleUploadButtonClick = () => {
        let hasErrors = false;
        Object.entries(uploadedFiles).forEach(([key, item]) => {
            if (!item.file) {
                hasErrors = true;
                setUploadedFiles((uploadedFiles) => ({
                    ...uploadedFiles,
                    [key]: {
                        ...uploadedFiles[key],
                        hasError: true,
                        errorMessage: getTranslation(
                            'ProjectViewer.FileUpload.Error.FileMissing',
                            {
                                fileType: Object.values(item.accept).join('/'),
                            }
                        ),
                    },
                }));
            }
        });
        if (hasErrors) return;
        const fileArray = Object.entries(uploadedFiles).map(([key, item]) => {
            if (item.file) return item.file;
        });

        if (fileArray.every((file) => !!file)) {
            closeModal();
            uploadHandler(fileArray.filter((x) => x !== undefined) as File[]);
        }
    };
    const clearFile = (item: UploadedFile) => {
        const key = item.id.toString();
        setUploadedFiles((uploadedFiles) => ({
            ...uploadedFiles,
            [key]: {
                ...uploadedFiles[key],
                fileName: undefined,
                file: undefined,
                hasError: false,
                errorMessage: '',
            },
        }));
    };
    const getDropHandler = (item: UploadedFile) => (drop: OnDropProps) => {
        const key = item.id.toString();
        if (drop.acceptedFiles.length === 1) {
            const acceptedFile = drop.acceptedFiles[0];
            setUploadedFiles((uploadedFiles) => ({
                ...uploadedFiles,
                [key]: {
                    ...uploadedFiles[key],
                    fileName: acceptedFile.name,
                    file: acceptedFile,
                    hasError: false,
                    errorMessage: '',
                },
            }));
        } else {
            let error = getTranslation('FileUpload.Error.Default');
            if (drop.fileRejections.length > 0) {
                const errorCode = drop.fileRejections[0]?.errors[0]?.code ?? '';
                if (errorCode === 'file-invalid-type')
                    error = getTranslation(
                        'FileUpload.Error.file-invalid-type'
                    );
                else if (errorCode === 'file-too-large')
                    error = getTranslation('FileUpload.Error.file-too-large');
            }
            setUploadedFiles((uploadedFiles) => ({
                ...uploadedFiles,
                [key]: {
                    ...uploadedFiles[key],
                    fileName: undefined,
                    file: undefined,
                    hasError: true,
                    errorMessage: error,
                },
            }));
        }
    };

    const SubTitle = () => {
        let subTitleText = '';
        if (fileType === LayoutFileType.LayoutDatabase)
            subTitleText = getTranslation(
                'ProjectViewer.FileUpload.SubTitle.LayoutFiles'
            );
        else if (fileType === LayoutFileType.BackgroundDrawing)
            subTitleText = getTranslation(
                'ProjectViewer.FileUpload.SubTitle.BackgroundDrawing'
            );
        if (subTitleText.length > 0)
            return (
                <Typography
                    variant="body1"
                    color="textSecondary"
                    data-testid="subtitle"
                >
                    {subTitleText}
                </Typography>
            );
        return null;
    };

    return (
        <>
            <SubTitle />
            <MainContent>
                {Object.entries(uploadedFiles).map(([key, item]) => {
                    return (
                        <div
                            key={`fileuploaditem-${key}`}
                            data-testid={`fileuploaditem-${key}`}
                        >
                            <Typography variant="h6">{item.label}</Typography>
                            {!!item.fileName ? (
                                <UploadedFileContainer>
                                    <Typography variant="subtitle1">
                                        {item.fileName}
                                    </Typography>
                                    <IconButton
                                        aria-label="remove file"
                                        component="span"
                                        disableRipple
                                        onClick={() => clearFile(item)}
                                        data-testid="remove-file-button"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </UploadedFileContainer>
                            ) : (
                                <FileUpload
                                    accept={item.accept}
                                    onDropHandler={getDropHandler(item)}
                                    maxFiles={1}
                                    maxSize={item.maxSize}
                                    hasError={item.hasError}
                                />
                            )}
                            <ErrorMessage variant="body2" color="error">
                                {item.errorMessage}
                            </ErrorMessage>
                        </div>
                    );
                })}
            </MainContent>
            <Button
                variant={ButtonVariant.primary}
                onClick={() => handleUploadButtonClick()}
                data-testid="upload-button"
            >
                {getTranslation('ProjectViewer.FileUpload.Button.Label', {
                    n: Object.keys(uploadedFiles).length,
                })}
            </Button>
        </>
    );
};

export default FileUploadComponent;
