import * as cirrusInstallFileInfoConstants from './cirrusInstallInfoConstants';
import { CirrusInstallInfo } from './interfaces';

export interface SetCirrusInstallInfoAction {
    type: typeof cirrusInstallFileInfoConstants.SET_CIRRUS_INSTALL_INFO;
    info: CirrusInstallInfo | undefined;
}

export const SetCirrusInstallInfo = (info: CirrusInstallInfo | undefined) =>
    ({
        type: cirrusInstallFileInfoConstants.SET_CIRRUS_INSTALL_INFO,
        info,
    } as SetCirrusInstallInfoAction);

export type KnownAction = SetCirrusInstallInfoAction;
