import { red } from '@material-ui/core/colors';
import { createTheme, PaletteColorOptions } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
    interface PastelPaletteColorOptions {
        yellow?: string;
        blue?: string;
        green?: string;
        pink?: string;
    }
    interface PastelPaletteColor {
        yellow: string;
        blue: string;
        green: string;
        pink: string;
    }
    interface SignalPaletteColorOptions {
        red?: string;
        blue?: string;
    }
    interface SignalPaletteColor {
        red: string;
        blue: string;
    }
    interface Palette {
        neutral: Palette['primary'];
        pastel: PastelPaletteColor;
        signal: SignalPaletteColor;
    }
    interface PaletteColor {
        white: PaletteColorOptions;
        black: PaletteColorOptions;
        red: PaletteColorOptions;
        golden: string;
        carbon?: string;
        transparentWhite?: string;
        gainsboro?: string;
    }
    interface PaletteOptions {
        neutral?: PaletteOptions['primary'];
        signal?: SignalPaletteColorOptions;
        golden?: string;
        white?: string;
        carbon?: string;
        pastel: PastelPaletteColorOptions;
    }
    interface SimplePaletteColorOptions {
        white?: string;
        black?: string;
        red?: string;
        golden?: string;
        carbon?: string;
        blue?: string;
        transparentWhite?: string;
        gainsboro?: string;
    }
}
declare module '@material-ui/core/styles/createTheme' {
    interface Theme {
        measurements: {
            drawerWidth: number;
            paperWidth: string;
            topbarHeight: string;
            sidePanelWidth: number;
        };
        timings: {
            tooltipEnterDelay: number;
        };
    }
    interface ThemeOptions {
        measurements?: {
            drawerWidth: number;
            paperWidth: string;
            topbarHeight: string;
            sidePanelWidth: number;
        };
        timings: {
            tooltipEnterDelay: number;
        };
    }
}

const colors = {
    carbon: '#616161',
    charcoal: '#404040',
    darkBlue: '#14206C',
    darkGrey: '#939393',
    eggplant: '#6C1F45',
    golden: '#ffd700',
    transparentWhite: '#FFFFFF3F',
    lavender: '#EAECFB',
    lightBlue: '#A2DAF6',
    lightGrey: '#F5F5F5',
    midBlue: '#1A6899',
    midGrey: '#E8E8E8',
    mossGreen: '#B7DBA1',
    orange: '#DE8D20',
    pastelYellow: '#FDD78B',
    pastelBlue: '#D9EDF9',
    pastelGreen: '#D1E8C3',
    pastelPink: '#DD9494',
    plum: '#A2628D',
    red: '#CC0E0E',
    signalBlue: '#1C77CC',
    white: '#FFFFFF',
    yellow: '#FCBC40',
    gainsboro: '#D9D9D9',
};
// A custom theme for this app
const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
            'Helvetica',
            'Arial',
            'Microsoft Yahei',
            '微软雅黑',
            'sans-serif',
            'Red Hat Display',
        ].join(','),
        h1: {
            fontSize: '4.778rem',
            fontWeight: 600,
        },
        h2: {
            fontSize: '3.98rem',
            fontWeight: 600,
        },
        h3: {
            fontSize: '3.318rem',
            fontWeight: 600,
        },
        h4: {
            fontSize: '2.765rem',
            fontWeight: 600,
        },
        h5: { fontSize: '2.304rem', fontWeight: 600 },
        h6: { fontSize: '1.92rem', fontWeight: 600 },
        body1: {
            fontSize: '1.6rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '1.4rem',
            fontWeight: 400,
        },
        caption: {
            fontSize: '0.625rem',
        },
        button: {
            fontWeight: 600,
            fontSize: '1.6rem',
            fontStyle: 'normal',
            textTransform: 'none',
        },
        subtitle1: {
            fontSize: '1.6rem',
            fontWeight: 600,
        },
        subtitle2: {
            fontSize: '1.4rem',
            fontWeight: 600,
        },
        htmlFontSize: 10,
    },
    palette: {
        primary: {
            dark: colors.darkBlue,
            main: colors.midBlue,
            light: colors.lightBlue,
            white: colors.white,
            golden: colors.golden,
        },
        secondary: {
            dark: colors.orange,
            main: colors.yellow,
        },
        neutral: {
            black: colors.charcoal,
            dark: colors.darkGrey,
            main: colors.midGrey,
            light: colors.lightGrey,
            carbon: colors.carbon,
            transparentWhite: colors.transparentWhite,
            gainsboro: colors.gainsboro,
        },
        golden: colors.yellow,
        signal: {
            red: colors.red,
            blue: colors.signalBlue,
        },
        error: {
            main: colors.red,
        },
        background: {
            default: '#fff',
        },
        pastel: {
            yellow: colors.pastelYellow,
            blue: colors.pastelBlue,
            green: colors.pastelGreen,
            pink: colors.pastelPink,
        },
    },
    measurements: {
        drawerWidth: 245,
        paperWidth: '22.5rem',
        topbarHeight: '48px',
        sidePanelWidth: 315,
    },
    spacing: (factor) => `${0.4 * factor}rem`,
    timings: {
        tooltipEnterDelay: 500,
    },
    overrides: {
        MuiTooltip: {
            arrow: {
                '&::before': {
                    backgroundColor: colors.lightGrey,
                    border: '1px solid #E0E0E0',
                },
            },
            tooltip: {
                backgroundColor: colors.lightGrey,
                color: colors.charcoal,
                border: '1px solid #E0E0E0',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
                maxWidth: '340px',
                fontSize: '1.4rem',
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '1.4rem',
            },
        },
    },
});

export default theme;
