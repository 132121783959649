import { Reducer, Action } from 'redux';
import * as subscriptionActions from './subscriptionActions';
import * as subscriptionConstants from './subscriptionConstants';
import { SubscriptionsState } from './interfaces';
import { Guid } from 'guid-typescript';

export const initialState: SubscriptionsState = {
    subscriptionList: {},
    selectedSubscription: {},
    subscriptions: [],
    currentSubscription: {
        subscriptionId: Guid.createEmpty(),
        displayName: '',
        description: '',
        companyNumber: '',
        owner: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            displayName: '',
        },
    },
    eula: {},
};
export const reducer: Reducer<SubscriptionsState> = (
    state: SubscriptionsState = initialState,
    incomingAction: Action
): SubscriptionsState => {
    const action = incomingAction as subscriptionActions.KnownAction;
    switch (action.type) {
        case subscriptionConstants.SET_SUBSCRIPTION_LIST:
            return {
                ...state,
                subscriptionList: action.subscriptionList,
            };
        case subscriptionConstants.SET_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.subscriptions,
            };
        case subscriptionConstants.SET_CURRENT_SUBSCRIPTION:
            return {
                ...state,
                currentSubscription: action.currentSubscription,
                selectedSubscription: {
                    ...state.selectedSubscription,
                    id: action.currentSubscription.subscriptionId,
                },
            };
        case subscriptionConstants.SET_SUBSCRIPTION_ROLE_ASSIGNMENTS:
            return {
                ...state,
                selectedSubscription: {
                    ...state.selectedSubscription,
                    users: action.roleAssignments,
                },
            };
        case subscriptionConstants.SET_EULA_STATUS:
            return {
                ...state,
                eula: action.eula,
            };
        default:
            return state;
    }
};
