import { connect } from 'react-redux';
import { apiActions } from 'src/redux/Api';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { CreateSubscriptionCommand } from 'src/services/Dtos/CreateSubscriptionCommand';
import CreateSubscription, {
    Actions,
    Props,
} from './components/CreateSubscription';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
    };
};

const mapDispatchToProps: Actions = {
    CreateSubscription: (
        createSubscriptionCommand: CreateSubscriptionCommand,
        createdCallback
    ) =>
        apiActions.createSubscription(
            createSubscriptionCommand,
            createdCallback
        ),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription);
