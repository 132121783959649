import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

type Props = {
    anchor?: DrawerProps['anchor'];
    children?: React.ReactNode;
    isLayoutVisualization?: boolean;
};

const SidePanel = ({
    anchor = 'right',
    children,
    isLayoutVisualization = true,
}: Props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        drawer: {
            paddingTop: isLayoutVisualization
                ? theme.measurements.topbarHeight
                : 0,
            width: isLayoutVisualization
                ? theme.measurements.sidePanelWidth
                : '420px',
            flexShrink: 0,
            boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 8px',
            borderLeft: 'none',
        },
    }));

    const classes = useStyles();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        if (children) {
            setSidebarOpen(true);
        } else {
            setSidebarOpen(false);
        }
    }, [children]);

    return (
        <>
            <CssBaseline />
            {sidebarOpen && (
                <Drawer
                    data-testid="sidepanel-drawer"
                    classes={{
                        paper: classes.drawer,
                    }}
                    variant="permanent"
                    anchor={anchor}
                >
                    {children}
                </Drawer>
            )}
        </>
    );
};

export default SidePanel;
