import * as companyConstants from './companyConstants';
import { CompanyInfo } from './interfaces';

export interface SetCompaniesAction {
    type: typeof companyConstants.SET_COMPANIES;
    companies: CompanyInfo[] | undefined;
}

export const SetCompanies = (companies: CompanyInfo[]) => ({
    type: companyConstants.SET_COMPANIES,
    companies,
});

export interface setSelectedCompanyAction {
    type: typeof companyConstants.SET_SELECTED_COMPANY;
    company: CompanyInfo | undefined;
}

export const setSelectedCompany = (company: CompanyInfo) => ({
    type: companyConstants.SET_SELECTED_COMPANY,
    company,
});

export const resetSelectedCompany = () => ({
    type: companyConstants.SET_SELECTED_COMPANY,
    company: undefined,
});

export type KnownAction = SetCompaniesAction | setSelectedCompanyAction;
