import { Guid } from 'guid-typescript';
import * as PIXI from 'pixi.js';
import { PointSpriteName } from 'src/constants/enums';
import { pointSettings } from '../settings/pointSettings';
import LayoutObject, { ILayoutObjectProps, ObjectOrigin } from './LayoutObject';

export interface IPointProps extends ILayoutObjectProps {
    angle: number;
    layoutUpdateId?: Guid;
}

type Textures = PIXI.utils.Dict<PIXI.Texture<PIXI.Resource>> | undefined;

export default class Point extends LayoutObject {
    #sprite: PIXI.Sprite;
    angle: number;
    layoutUpdateId?: Guid;
    textures: Textures;
    constructor(point: IPointProps, textures: Textures) {
        super({
            id: point.id,
            x: point.x,
            y: -point.y,
            layerId: point.layerId,
            origin: point.origin ?? ObjectOrigin.Base,
        });
        this.angle = point.angle;
        this.layoutUpdateId = point.layoutUpdateId;
        this.textures = textures;
        this.#sprite = new PIXI.Sprite(this.getTexture());
        this.init();
    }
    private init = () => {
        this.#sprite.angle = -this.angle;
        this.#sprite.width = this.#sprite.height = pointSettings.size;
        this.#sprite.anchor.set(0.5);
        this.#sprite.interactive = true;
        this.#sprite.buttonMode = true;
        this.container.addChild(this.#sprite);
    };
    private getTextureKey = () => {
        const type =
            this.origin == ObjectOrigin.Base
                ? PointSpriteName.ORIGINAL
                : PointSpriteName.UPDATED;
        return `${type}_${Number(this.selected)}_${Number(this.faded)}.png`;
    };

    private getTexture = () => {
        if (!this.textures) return PIXI.Texture.WHITE;
        return this.textures[this.getTextureKey()];
    };
    protected updateTexture = () => {
        this.#sprite.texture = this.getTexture();
    };
    protected onFaded = () => {
        this.updateTexture();
    };
    protected onUnfaded = () => {
        this.updateTexture();
    };
}
