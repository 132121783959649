import * as operationSettingsConstants from './operationSettingsConstants';
import { OperationSetting, OperationSettingTemplate } from './interfaces';

export interface SetOperationSettingsAction {
    type: typeof operationSettingsConstants.SET_OPERATION_SETTINGS;
    operationSettings: OperationSettingTemplate[];
}

export const SetOperationSettings = (
    operationSettings: OperationSettingTemplate[]
) =>
    ({
        type: operationSettingsConstants.SET_OPERATION_SETTINGS,
        operationSettings,
    } as SetOperationSettingsAction);

export interface SetNewOperationSettingsAction {
    type: typeof operationSettingsConstants.SET_NEW_OPERATION_SETTINGS;
    newOperation: OperationSetting;
}

export const SetNewOperationSettings = (newOperation: OperationSetting) =>
    ({
        type: operationSettingsConstants.SET_NEW_OPERATION_SETTINGS,
        newOperation,
    } as SetNewOperationSettingsAction);

export type KnownAction =
    | SetOperationSettingsAction
    | SetNewOperationSettingsAction;
