import ErrorContainer from './ErrorContainer';
import { Typography } from '@material-ui/core';
import useTranslation from 'src/hooks/useTranslation';

const InviteExpired = () => {
    const getTranslation = useTranslation();
    return (
        <ErrorContainer>
            <Typography variant="h6" gutterBottom>
                {getTranslation('ErrorPages.InviteLinkExpired.Title')}
            </Typography>
            <Typography variant="body1">
                {getTranslation('ErrorPages.InviteLinkExpired.Description')}
            </Typography>
        </ErrorContainer>
    );
};

export default InviteExpired;
