import { useState } from 'react';
import { useTranslation } from 'src/hooks';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { UsersService } from 'src/services';
import { Button, ButtonSize, Variant } from '@ndc/react-component-library';
import { RoleAssignment, User } from 'src/services/interfaces';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-end;
    margin-top: 40px;
    margin-right: -70px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
`;

export type Props = {
    subscriptionId: string;
    resource: ProjectInfo | SubscriptionInfo;
    scopeType: 'Global' | 'Project' | 'All';
    user: User;
    removeUserRolesCallback: (
        scopeType: string,
        userName: string,
        userChanged: boolean,
        error?: string
    ) => void;
};

export type Actions = {};

const RemoveUserRoles = ({
    subscriptionId,
    resource,
    scopeType,
    user,
    removeUserRolesCallback,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    const [userPermissionsAreBeingUpdated, setUserPermissionsAreBeingUpdated] =
        useState(false);

    const removeUserRoles = async (roles: RoleAssignment[]) => {
        const usersService = new UsersService();
        return Promise.all(
            roles.map((role) =>
                usersService.removeRoleAssignment(
                    role.scope,
                    role.roleAssignmentId
                )
            )
        );
    };

    const removeUser = () => {
        if (userPermissionsAreBeingUpdated) return;

        const rolesToRemove: RoleAssignment[] = [];

        switch (scopeType) {
            case 'All': {
                user.roles.forEach((role: RoleAssignment) => {
                    rolesToRemove.push(role);
                });

                break;
            }
            case 'Global': {
                user.roles.forEach((role: RoleAssignment) => {
                    if (role.scope === '/subscriptions/' + subscriptionId) {
                        rolesToRemove.push(role);
                    }
                });

                break;
            }
            case 'Project': {
                user.roles.forEach((role: RoleAssignment) => {
                    if (
                        role.scope ===
                        '/subscriptions/' +
                            subscriptionId +
                            '/projects/' +
                            (resource as ProjectInfo).id
                    ) {
                        rolesToRemove.push(role);
                    }
                });

                break;
            }

            default:
                break;
        }

        setUserPermissionsAreBeingUpdated(true);
        removeUserRoles(rolesToRemove)
            .then((result) => {
                setUserPermissionsAreBeingUpdated(false);
                removeUserRolesCallback(scopeType, user.name, true);
            })
            .catch((error) => {
                setUserPermissionsAreBeingUpdated(false);
                removeUserRolesCallback(scopeType, user.name, false, error);
            });
    };

    const renderRemoveConfirmation = () => {
        return (
            <Typography>
                {getTranslation(
                    translationKeys.RemoveUser_Confirmation_Message,
                    {
                        userName: user.email,
                        resourceName:
                            scopeType == 'Project'
                                ? (resource as ProjectInfo).commissioningProject
                                : (resource as SubscriptionInfo).displayName,
                        scopeType,
                    }
                )}
            </Typography>
        );
    };

    return (
        <Container>
            {renderRemoveConfirmation()}
            <ButtonContainer>
                {userPermissionsAreBeingUpdated && (
                    <CircularProgress size="35px" color="secondary" />
                )}
                <Button
                    variant={Variant.secondary}
                    size={ButtonSize.small}
                    key={'cancel-remove-roles'}
                    data-testid="cancel-remove-roles"
                    onClick={() =>
                        removeUserRolesCallback(scopeType, user.name, false)
                    }
                >
                    {getTranslation(translationKeys.General_Cancel)}
                </Button>
                <Button
                    size={ButtonSize.small}
                    key={'remove-roles'}
                    data-testid="remove-roles"
                    onClick={removeUser}
                    disabled={userPermissionsAreBeingUpdated}
                >
                    {scopeType === 'All'
                        ? getTranslation(translationKeys.RemoveUser_All)
                        : getTranslation(
                              translationKeys.RemoveUser_Permissions
                          )}
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default RemoveUserRoles;
