import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'src/components/Button';
import { useTranslation } from 'src/hooks';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { DefaultAutocomplete } from 'src/components/Input';

const StyledTypography = styled(Typography)`
    ${({ theme }) => `
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(6)};
`}
`;

const StyledButton = styled(Button)`
    ${({ theme }) => `
    margin-top: ${theme.spacing(10)};
    width: ${theme.spacing(44)} !important;
`}
`;

export type Props = {
    subsciption: SubscriptionInfo[];
    closeModal: () => void;
    setSelectedSubscription: (selectedSubscription: SubscriptionInfo) => void;
};

export type Actions = {};

const SelectAccountModal = ({
    subsciption,
    closeModal,
    setSelectedSubscription,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const [subscripitonLocal, setSubscripitonLocal] =
        useState<SubscriptionInfo>(subsciption[0]);

    const handleOnSelectAccount = () => {
        subscripitonLocal && setSelectedSubscription(subscripitonLocal);
        localStorage.setItem(
            'activeSubscriptionId',
            subscripitonLocal?.subscriptionId.toString()
        );
        closeModal();
    };

    return (
        <div>
            <StyledTypography>
                {getTranslation('Sidebar.SubscriptionModal.Description')}
            </StyledTypography>
            {subsciption.length > 0 && (
                <DefaultAutocomplete
                    items={subsciption}
                    label={'Account'}
                    onChange={(selectedSub: SubscriptionInfo) => {
                        setSubscripitonLocal(selectedSub);
                    }}
                    getItemText={(sub: SubscriptionInfo) =>
                        sub?.displayName ?? ''
                    }
                    getOptionSelected={(option, value) =>
                        option.subscriptionId === value.subscriptionId
                    }
                    helpMessage={getTranslation(
                        'Sidebar.SelectSubscription.HelperText'
                    )}
                    defaultValue={subsciption[0]}
                />
            )}
            <StyledButton
                variant="contained"
                onClick={handleOnSelectAccount}
                id="select-account-button"
                data-testid="select-account-button"
                disabled={!subscripitonLocal}
            >
                {getTranslation(
                    'Sidebar.SubscriptionModal.SelectAccount.Label'
                )}
            </StyledButton>
        </div>
    );
};

export default SelectAccountModal;
