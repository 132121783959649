import { Chip, Divider, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const StyledContainer = styled(Paper)`
    box-shadow: none;
    padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};
`;

export const StyledStatusRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(3)};
    align-items: center;

    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledTextRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const StyledDivider = styled(Divider)`
    background-color: ${({ theme }) => theme.palette.grey[300]};
`;
export const PositionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    min-height: 45px;
`;
export const PositionValue = styled('span')`
    font-weight: 600;
`;

export const StyledChip = styled(Chip)`
    align-self: center;
    height: ${({ theme }) => theme.spacing(5)};
    span {
        padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
        font-weight: 600;
        font-size: ${({ theme }) => theme.typography.body2.fontSize};
    }
    background-color: ${({ theme }) => theme.palette.grey[500]};
    color: #fff;
`;

export const Circle = styled('div')<{ updated: boolean }>`
    background-color: ${({ theme, updated }) =>
        updated ? theme.palette.secondary.dark : theme.palette.neutral.carbon};
    width: 16px;
    height: 16px;
    border-radius: 50%;
`;

export const SegmentInfoSection = styled('div')`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(0.5)};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
