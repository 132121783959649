import styled from 'styled-components';
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    CircularProgress,
} from '@material-ui/core';

const StyledButton = styled(MuiButton)`
    ${({ theme }) => `
    && {
        color: ${theme.palette.primary.white};
        background: ${theme.palette.primary.dark};
        height: ${theme.spacing(12)}; 
        width: 100%;
        border-radius: 7px;
        line-height: normal;
    }
    &.half {
        width: 49%
    }
    &.small {
        height: ${theme.spacing(8)}
    }
    &.primary {
        color: ${theme.palette.primary.white};
        background: ${theme.palette.primary.dark};
        &.disabled {            
            background: ${theme.palette.grey[300]};
        }
    }
    &.secondary {
        border: 1.6px solid ${theme.palette.neutral.dark};
        color: ${theme.palette.neutral.black};
        background: ${theme.palette.primary.white};
        &.disabled {            
            border-color: ${theme.palette.grey[500]};
        }
    }
    &.orange {
        // border: 1.6px solid ${theme.palette.neutral.dark};
        color: ${theme.palette.primary.white};
        background: ${theme.palette.secondary.dark};
        &.disabled {            
            background: ${theme.palette.grey[300]};
        }
    }
    &.disabled {
        color: ${theme.palette.grey[500]};
    }`}
`;

type ButtonWidth = 'half';
type ButtonHeight = 'small';
type ColorScheme = 'primary' | 'secondary' | 'orange';
interface ButtonProps extends MuiButtonProps {
    buttonHeight?: ButtonHeight;
    buttonWidth?: ButtonWidth;
    colorScheme?: ColorScheme;
    $loading?: boolean;
}

const Button = (props: ButtonProps) => {
    let className = '';
    let thisProps = { ...props };

    const buttonDisabled = thisProps.disabled || thisProps.$loading;

    if (thisProps.buttonWidth) {
        if (thisProps.buttonWidth == 'half') {
            className += ' half';
        }
        delete thisProps.buttonWidth;
    }
    if (thisProps.buttonHeight) {
        if (thisProps.buttonHeight == 'small') {
            className += ' small';
        }
        delete thisProps.buttonHeight;
    }
    if (thisProps.colorScheme) {
        if (thisProps.colorScheme == 'secondary') {
            className += ' secondary';
        } else if (thisProps.colorScheme == 'orange') {
            className += ' orange';
        } else {
            className += ' primary';
        }
        delete thisProps.colorScheme;
    } else if (buttonDisabled) {
        className += ' secondary';
    }
    if (buttonDisabled) {
        className += ' disabled';
    }

    return (
        <StyledButton
            disabled={buttonDisabled}
            className={className}
            {...thisProps}
        >
            {thisProps.$loading ? (
                <CircularProgress size="1.5em" color="secondary" />
            ) : (
                props.children
            )}
        </StyledButton>
    );
};

export default Button;
