import { useState } from 'react';
import InputLabel from './InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, Tooltip } from '@material-ui/core';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemDescription = styled(Typography)`
    margin-left: 31px;
    margin-top: -12px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.neutral.dark};
`;

const ErrorDescription = styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.error.main};
`;

type Props = {
    defaultSelectedItems?: any[];
    error?: boolean;
    errorText?: string;
    getDisabled?: (item: any) => boolean;
    getHelpText?: (item: any) => string;
    getItemText?: (item: any) => string;
    getTooltipText?: (item: any) => string;
    id?: string;
    items: any[];
    label: string;
    onChange: (item: any[]) => void;
};

const CheckboxGroup = ({
    defaultSelectedItems = [],
    error = false,
    errorText = '',
    getDisabled = (i) => false,
    getHelpText = (i) => '',
    getItemText = (i) => i,
    getTooltipText = (i) => '',
    id,
    items,
    label,
    onChange,
}: Props) => {
    const [selectedItems, setSelectedItems] = useState(defaultSelectedItems);

    const handleCheckboxChange = (item: any, checked: boolean) => {
        if (
            checked &&
            !selectedItems
                .map((item) => item.toLowerCase())
                .includes(item.toLowerCase())
        ) {
            const newSelectedItems = [...selectedItems, item];
            setSelectedItems(newSelectedItems);
            onChange(newSelectedItems);
        } else if (
            !checked &&
            selectedItems
                .map((item) => item.toLowerCase())
                .includes(item.toLowerCase())
        ) {
            const newSelectedItems = selectedItems.filter(
                (i) => i.toLowerCase() != item.toLowerCase()
            );
            setSelectedItems(newSelectedItems);
            onChange(newSelectedItems);
        }
    };

    const renderCheckboxItem = (item: any, index: number) => {
        const renderItem = () => (
            <div key={index}>
                <FormControlLabel
                    label={getItemText(item)}
                    control={
                        <Checkbox
                            color="primary"
                            checked={selectedItems
                                .map((item) => item.toLowerCase())
                                .includes(item.toLowerCase())}
                            value={item}
                            onChange={(_, checked) =>
                                handleCheckboxChange(item, checked)
                            }
                            disabled={getDisabled(item)}
                        />
                    }
                />
                {getHelpText(item) && (
                    <ItemDescription>{getHelpText(item)}</ItemDescription>
                )}
            </div>
        );

        if (getTooltipText && getTooltipText(item))
            return (
                <Tooltip
                    title={getTooltipText(item)}
                    placement="bottom"
                    key={index}
                >
                    {renderItem()}
                </Tooltip>
            );
        else return renderItem();
    };

    return (
        <Container id={id}>
            <InputLabel label={label} style={{ marginTop: '10px' }} />
            {error && <ErrorDescription>{errorText}</ErrorDescription>}
            <FormGroup>{items.map(renderCheckboxItem)}</FormGroup>
        </Container>
    );
};

export default CheckboxGroup;
