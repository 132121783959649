export const SET_LAYOUT_BACKGROUNDDRAWING_DATA =
    'SET_LAYOUT_BACKGROUNDDRAWING_DATA';
export const SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADED =
    'SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADED';
export const SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADING =
    'SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADING';
export const SET_LAYOUT_BACKGROUNDDRAWING_DATA_ERROR =
    'SET_LAYOUT_BACKGROUNDDRAWING_DATA_ERROR';
export const REMOVE_LAYOUT_BACKGROUNDDRAWING_DATA =
    'REMOVE_LAYOUT_BACKGROUNDDRAWING_DATA';

export const SET_LAYOUT_VISUALIZATION_DATA = 'SET_LAYOUT_VISUALIZATION_DATA';
export const SET_LAYOUT_VISUALIZATION_DATA_IS_LOADED =
    'SET_LAYOUT_VISUALIZATION_DATA_IS_LOADED';
export const SET_LAYOUT_VISUALIZATION_DATA_IS_LOADING =
    'SET_LAYOUT_VISUALIZATION_DATA_IS_LOADING';
export const SET_LAYOUT_VISUALIZATION_DATA_ERROR =
    'SET_LAYOUT_VISUALIZATION_DATA_ERROR';
export const REMOVE_LAYOUT_VISUALIZATION_DATA =
    'REMOVE_LAYOUT_VISUALIZATION_DATA';
