import { ReactNode, useEffect, useState } from 'react';
import {
    Typography,
    IconButton,
    Box,
    MenuItem,
    Radio,
    Button as MuiButton,
    Menu,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Guid } from 'guid-typescript';
import useTranslation from 'src/hooks/useTranslation';
import SettingsTable from './SettingsTable';
import styled from 'styled-components';
import translationKeys from 'src/translations';
import { Button, ButtonSize } from '@ndc/react-component-library';
import { Scope, OperationCodeFormat } from 'src/constants/enums';
import { OperationPermissions } from 'src/redux/Permissions/interfaces';
import { useSnackbar } from 'src/utils/snackbar';
import { RichPopper } from 'src/components/Popper';
import InfoIcon from 'src/assets/icons/InfoIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { SidePanel } from 'src/components/SidePanel';
import RemoveOperation from '../SettingsActions/RemoveOperation';
import SidePanelContent from './SidePanelContent';
import theme from 'src/theme';
import { OperationSettingsService } from 'src/services';
import {
    OperationSetting,
    OperationSettingCommand,
    OperationSettingTemplate,
    OperationSettingTemplateCommand,
} from 'src/redux/OperationSettings/interfaces';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { decimalToHex, isGuidEmpty } from 'src/helpers/utils';

interface ConvertedOperationSetting extends OperationSetting {
    code: string | number;
}

const Container = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
`;

const UsersHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const UsersHelp = styled(Typography)`
    margin-top: 12px;
    color: ${({ theme }) => theme.palette.neutral.carbon};
`;

const StyledSidePanel = styled(SidePanel)`
    drawer: {
        padding-top: 0;
    }
`;

const StyledBox = styled(Box)`
    padding: 8px 0 8px 0;
    display: grid;
    width: 320px;
`;

const FormatChangeInfoTextContent = styled.div`
    background: #e0e0e0;
    width: 300px;
    padding: 8px;
    display: flex;
`;

const CustomRadio = withStyles({
    root: {
        '&$checked': {
            color: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
            color: '#bdbdbd',
        },
    },
    checked: {},
})(Radio);

export type Props = {
    operationPermissions: OperationPermissions | undefined;
    subscriptionId: Guid | undefined;
    operationSettings: OperationSettingTemplate[];
};

export type Actions = {
    getOperationSettings: (subscriptionId: Guid) => any;
    createOperationSetting: (
        subscriptionId: Guid,
        templateId: Guid,
        operationSettingCommand: OperationSettingCommand
    ) => void;
    updateOperationSetting: (
        subscriptionId: Guid,
        templateId: Guid,
        operationSettingId: Guid,
        operationSettingCommand: OperationSettingCommand
    ) => void;
    updateOperationCodeDisplayFormat: (
        subscriptionId: Guid,
        templateId: Guid,
        operationCodeFormat: string
    ) => void;
    deleteOperationSetting: (
        subscriptionId: Guid,
        templateId: Guid,
        operationSettingIds: Guid[]
    ) => void;
    getSubscriptionRoleAssignments: (subscriptionId: Guid) => any;
    showModalWithContents: (
        id: Guid,
        heading: string,
        content: ReactNode
    ) => void;
    showModalMessage: (id: Guid, heading: string, content: string) => void;
    closeModal: (id: Guid) => void;
};

const Settings = ({
    operationPermissions,
    subscriptionId,
    operationSettings,
    getOperationSettings,
    createOperationSetting,
    updateOperationSetting,
    updateOperationCodeDisplayFormat,
    deleteOperationSetting,
    getSubscriptionRoleAssignments,
    showModalWithContents,
    showModalMessage,
    closeModal,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const projectApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;
    const operationSettingsService = new OperationSettingsService(
        projectApiUrl
    );
    const [activeModalId, setActiveModalId] = useState<Guid>(Guid.create());
    const [openOperationsPopper, setOpenOperationsPopper] = useState(false);
    const [sidePanelContent, setSidePanelContent] =
        useState<React.ReactNode>(undefined);
    const [selectedValue, setSelectedValue] = useState<string>(
        OperationCodeFormat.Hexadecimal
    );
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [operationsAreUpdating, setOperationsAreUpdating] = useState(true);
    const [operationSettingsUpdated, setOperationSettingsUpdated] = useState<
        OperationSetting[]
    >([]);
    const [templateId, setTemplateId] = useState<Guid>(Guid.create());
    const [changeFormatButtonDisabled, setChangeFormatButtonDisabled] =
        useState(true);
    const [operationCodeFormat, setOperationCodeFormat] = useState<string>(
        OperationCodeFormat.Hexadecimal
    );
    const [convertedOperationCodes, setConvertedOperationCodes] = useState<
        ConvertedOperationSetting[]
    >([]);

    const userCanEditOperationSettings = useValidateAccess({
        scope: Scope.OperationSettings,
        id: templateId,
        allowed: 'createOperation',
    });
    const userCanCreateOperationSettingsTemplate = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId?.toString(),
        allowed: 'createOperationSettings',
    });

    useEffect(() => {
        if (subscriptionId && !isGuidEmpty(subscriptionId)) {
            getSubscriptionRoleAssignments(subscriptionId);
            getOperationSettings(subscriptionId);
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (operationSettings) {
            const operationSettingsTemplate = operationSettings[0];
            const codeFormat =
                operationSettingsTemplate?.operationCodeFormat ??
                OperationCodeFormat.Hexadecimal;
            setTemplateId(
                operationSettingsTemplate?.templateId as unknown as Guid
            );
            setOperationSettingsUpdated(
                operationSettingsTemplate?.operationSettings
            );
            setConvertedOperationCodes(convertedCodes(codeFormat));
            setOperationCodeFormat(codeFormat);
            setSelectedValue(codeFormat);
        }
    }, [operationSettings]);

    const updateOperations = () => {
        setOperationsAreUpdating(true);
        setTimeout(() => {
            if (subscriptionId) getOperationSettings(subscriptionId);
        }, 500);
    };

    const updateOperationsCallback = (
        isNewOperation: any,
        operation: string,
        error?: any
    ) => {
        updateOperations();
        if (operation === 'create') {
            if (error) {
                const snackbarMessage = getTranslation(
                    translationKeys.Settings_AddOperation_ErrorMessage
                );
                enqueueSnackbar(snackbarMessage, { variant: 'error' });
            } else {
                const snackbarMessage = getTranslation(
                    translationKeys.Settings_AddOperation_SuccessMessage
                );
                enqueueSnackbar(snackbarMessage, { variant: 'success' });
            }
        } else if (operation === 'delete') {
            if (error) {
                const snackbarMessage = getTranslation(
                    translationKeys.Settings_DeleteOperation_ErrorMessage
                );
                enqueueSnackbar(snackbarMessage, { variant: 'error' });
            } else {
                const snackbarMessage = getTranslation(
                    translationKeys.Settings_DeleteOperation_SuccessMessage
                );
                enqueueSnackbar(snackbarMessage, { variant: 'success' });
            }
        } else if (operation === 'edit') {
            if (error) {
                const snackbarMessage = getTranslation(
                    translationKeys.Settings_UpdateOperation_ErrorMessage
                );
                enqueueSnackbar(snackbarMessage, { variant: 'error' });
            }
        }
    };

    const sidePanelCloseHandler = () => {
        setSidePanelContent(undefined);
    };
    const addNewOperationSettingsTemplate = (
        operationSettingTemplateCommand: OperationSettingTemplateCommand
    ) => {
        return operationSettingsService
            .CreateOperationSettingTemplate(
                subscriptionId as unknown as Guid,
                operationSettingTemplateCommand
            )
            .then((isNewOperation) => {
                updateOperationsCallback(isNewOperation, 'create');
            })
            .catch((error) => {
                setOperationsAreUpdating(false);
                updateOperationsCallback(true, 'create', error);
            });
    };
    const addNewOperationCode = (
        updatedOperationValues: OperationSettingCommand
    ) => {
        return operationSettingsService
            .CreateOperationSetting(
                subscriptionId as unknown as Guid,
                templateId as unknown as Guid,
                updatedOperationValues
            )
            .then((isNewOperation) => {
                updateOperationsCallback(isNewOperation, 'create');
            })
            .catch((error) => {
                setOperationsAreUpdating(false);
                updateOperationsCallback(true, 'create', error);
            });
    };

    const editOperationCode = (
        operationSettingId: Guid,
        updatedOperationValues: OperationSettingCommand
    ) => {
        return operationSettingsService
            .UpdateOperationSetting(
                subscriptionId as unknown as Guid,
                templateId as unknown as Guid,
                operationSettingId,
                updatedOperationValues
            )
            .then((updateOperation) => {
                updateOperationsCallback(updateOperation, 'edit');
            })
            .catch((error) => {
                setOperationsAreUpdating(false);
                updateOperationsCallback(false, 'edit', error);
            });
    };

    const deleteOperationSettingCallback = (operationSettingIds: Guid[]) => {
        return operationSettingsService
            .DeleteOperationSetting(
                subscriptionId as unknown as Guid,
                templateId,
                operationSettingIds
            )
            .then((removedOperation) => {
                updateOperationsCallback(removedOperation, 'delete');
            })
            .catch((error) => {
                setOperationsAreUpdating(false);
                updateOperationsCallback(false, 'delete', error);
            });
    };

    const onSaveOperationClick = (
        operation: string,
        updatedOperationValues: OperationSettingCommand,
        operationSettingId?: Guid
    ) => {
        if (operation === 'edit') {
            operationSettingId &&
                editOperationCode(operationSettingId, updatedOperationValues);
        } else if (templateId === undefined) {
            addNewOperationSettingsTemplate({
                ...updatedOperationValues,
                operationCodeFormat,
            } as OperationSettingTemplateCommand);
        } else addNewOperationCode(updatedOperationValues);
    };

    const addOperationCodes = () => {
        setSidePanelContent(
            <SidePanelContent
                operation={'add'}
                sidePanelCloseHandler={sidePanelCloseHandler}
                onSaveOperationClick={onSaveOperationClick}
                operationSettings={operationSettingsUpdated}
                operationCodeFormat={operationCodeFormat}
            />
        );
    };

    const editOperationCodes = (operationDetails: any) => {
        setSidePanelContent(
            <SidePanelContent
                operation={'edit'}
                sidePanelCloseHandler={sidePanelCloseHandler}
                onSaveOperationClick={onSaveOperationClick}
                operationDetails={operationDetails}
                operationSettings={operationSettingsUpdated}
                operationCodeFormat={operationCodeFormat}
            />
        );
    };

    const cloneOperationCodes = (operationDetails: any) => {
        setSidePanelContent(
            <SidePanelContent
                operation={'clone'}
                sidePanelCloseHandler={sidePanelCloseHandler}
                onSaveOperationClick={onSaveOperationClick}
                operationDetails={operationDetails}
                operationSettings={operationSettingsUpdated}
                operationCodeFormat={operationCodeFormat}
            />
        );
    };

    const removeOperationCodes = (operationDetails: any) => {
        if (subscriptionId) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation(
                    translationKeys.Settings_RemoveOperationModal_Header
                ),
                <RemoveOperation
                    operationDetails={operationDetails}
                    closeModal={() => closeModal(activeModalId)}
                    deleteOperationSettingCallback={
                        deleteOperationSettingCallback
                    }
                />
            );
        }
    };

    const OperationsPopupTips = (
        <>
            <StyledBox>
                {getTranslation(
                    translationKeys.Settings_OperationsPopupTips_Content
                )}
            </StyledBox>
            <StyledBox>
                {getTranslation(
                    translationKeys.Settings_OperationsPopupTips_Note
                )}
            </StyledBox>
        </>
    );

    const convertedCodes = (newFormat: string) => {
        return operationSettings[0]?.operationSettings?.map((operation) => {
            if (newFormat === OperationCodeFormat.Hexadecimal) {
                return {
                    ...operation,
                    code: decimalToHex(operation.code),
                };
            } else {
                return {
                    ...operation,
                    code: operation.code,
                };
            }
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFormat = event.target.value;
        setSelectedValue(newFormat);
        setChangeFormatButtonDisabled(newFormat === operationCodeFormat);
    };

    const handleSave = () => {
        updateOperationCodeDisplayFormat(
            subscriptionId as unknown as Guid,
            templateId as unknown as Guid,
            selectedValue
        );

        setOperationCodeFormat(selectedValue);
        setConvertedOperationCodes(convertedCodes(selectedValue));
        handleMenuClose();
    };

    const handleMenuOpen = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
        setSelectedValue(operationCodeFormat);
        setChangeFormatButtonDisabled(true);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    return (
        <>
            <Container>
                <UsersHeader>
                    <div>
                        <Typography variant="h3">
                            {getTranslation(translationKeys.General_Settings)}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                                paddingTop: '32px',
                            }}
                        >
                            <Typography variant="h5">
                                {getTranslation(
                                    translationKeys.General_Operations
                                )}
                            </Typography>
                            <div style={{ alignSelf: 'center' }}>
                                <RichPopper
                                    content={OperationsPopupTips}
                                    open={openOperationsPopper}
                                    placement="bottom"
                                    onClose={() =>
                                        setOpenOperationsPopper(false)
                                    }
                                >
                                    <IconButton
                                        onClick={() =>
                                            setOpenOperationsPopper(
                                                !openOperationsPopper
                                            )
                                        }
                                        style={{ padding: '0px' }}
                                    >
                                        <InfoIcon />
                                    </IconButton>
                                </RichPopper>
                            </div>
                        </div>
                        <UsersHelp>
                            {getTranslation(
                                translationKeys.Settings_OperationCodeFormat_Label
                            )}
                        </UsersHelp>
                        <div style={{ marginBottom: '24px' }}>
                            <MuiButton
                                aria-controls="dropdown-menu"
                                aria-haspopup="true"
                                onClick={handleMenuOpen}
                                endIcon={<ExpandMoreIcon />}
                                style={{ paddingLeft: '0' }}
                                data-testid="format-change-dropdown"
                            >
                                {operationCodeFormat
                                    ? operationCodeFormat ===
                                      OperationCodeFormat.Hexadecimal
                                        ? getTranslation(
                                              translationKeys.General_Hexadecimal
                                          )
                                        : getTranslation(
                                              translationKeys.General_Decimal
                                          )
                                    : ''}
                            </MuiButton>
                            <Menu
                                id="dropdown-menu"
                                anchorEl={menuAnchorEl}
                                open={Boolean(menuAnchorEl)}
                                onClose={handleMenuClose}
                                transformOrigin={{
                                    vertical: -50,
                                    horizontal: 5,
                                }}
                            >
                                <MenuItem>
                                    <CustomRadio
                                        onChange={handleChange}
                                        value="Hexadecimal"
                                        checked={
                                            selectedValue ===
                                            OperationCodeFormat.Hexadecimal
                                        }
                                        color="primary"
                                    />
                                    {getTranslation(
                                        translationKeys.General_Hexadecimal
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    <CustomRadio
                                        onChange={handleChange}
                                        value="Decimal"
                                        checked={
                                            selectedValue ===
                                            OperationCodeFormat.Decimal
                                        }
                                        color="primary"
                                    />
                                    {getTranslation(
                                        translationKeys.General_Decimal
                                    )}
                                </MenuItem>
                                <MenuItem
                                    value="info"
                                    style={{
                                        pointerEvents: 'none',
                                        whiteSpace: 'break-spaces',
                                    }}
                                >
                                    <FormatChangeInfoTextContent>
                                        <InfoOutlinedIcon />
                                        <div style={{ marginLeft: '8px' }}>
                                            {getTranslation(
                                                translationKeys.Settings_FormatChange_InfoText
                                            )}
                                        </div>
                                    </FormatChangeInfoTextContent>
                                </MenuItem>
                                <MenuItem onClick={handleSave}>
                                    <Button
                                        color="primary"
                                        disabled={changeFormatButtonDisabled}
                                    >
                                        {getTranslation(
                                            translationKeys.Settings_ChangeFormat_ButtonLabel
                                        )}
                                    </Button>
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div style={{ paddingTop: '130px' }}>
                        {(userCanEditOperationSettings ||
                            userCanCreateOperationSettingsTemplate) && (
                            <Button
                                data-testid="add-operation-button"
                                key={'add-operation-button'}
                                size={ButtonSize.small}
                                onClick={() => addOperationCodes()}
                            >
                                {getTranslation(
                                    translationKeys.Settings_AddOperation_ButtonLabel
                                )}
                            </Button>
                        )}
                    </div>
                </UsersHeader>
                <SettingsTable
                    editOperationCodes={editOperationCodes}
                    removeOperationCodes={removeOperationCodes}
                    removeMultipleOperationCodes={removeOperationCodes}
                    cloneOperationCodes={cloneOperationCodes}
                    templateId={templateId}
                    operationSettings={
                        convertedOperationCodes &&
                        convertedOperationCodes.length
                            ? convertedOperationCodes
                            : operationSettingsUpdated
                    }
                    operationCodeFormat={operationCodeFormat}
                />
            </Container>
            <StyledSidePanel isLayoutVisualization={false}>
                {sidePanelContent}
            </StyledSidePanel>
        </>
    );
};

export default Settings;
