import { getTokenPopup } from 'src/auth/authUtils';
import { customApiScopes } from '../auth/apiScopes';
import { flattenObject, unflattenObject } from 'src/helpers/utils';
import {
    AccessValidatorQuery,
    PathAccessResult,
    AccessValidatorResponse,
} from './interfaces';
import { backends } from '../common/backends';
import api from './api';

export default class {
    validateAccess(
        accessValidatorQuery: AccessValidatorQuery,
        accessToken?: string
    ) {
        const meRoute =
            backends.syncServiceApi + '/api/me/action/validateaccess';

        let flattenedQuery: Record<string, string> =
            flattenObject(accessValidatorQuery);

        let actionPathToPropertyMap: Record<string, string> = {};
        let pathActionsToEvaluate: { path: string; action: string }[] = [];

        Object.entries(flattenedQuery).forEach(([property, actionPath]) => {
            actionPath = actionPath.toUpperCase();
            flattenedQuery[property] = actionPath;
            actionPathToPropertyMap[actionPath] = property;
            const [action, path] = actionPath.split(':');
            pathActionsToEvaluate.push({
                action,
                path,
            });
        });

        const formatOutput = (apiResponse: any) => {
            const pathAccessResult: PathAccessResult =
                apiResponse.pathAccessResult;

            let flattenedOutput: Record<string, boolean> = {};
            Object.entries(pathAccessResult).forEach(([action, pathResults]) =>
                Object.entries(pathResults).forEach(([path, result]) => {
                    const propertyName =
                        actionPathToPropertyMap[action + ':' + path];
                    flattenedOutput[propertyName] = result;
                })
            );
            return unflattenObject(flattenedOutput) as AccessValidatorResponse;
        };

        let callApi = (accessToken: string) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify({
                    pathActionsToEvaluate,
                }),
            };

            return api({
                url: meRoute,
                method: 'POST',
                options,
            }).then(formatOutput);
        };

        if (!accessToken) {
            const requestConfig = customApiScopes.syncServiceApiRequestConfig;
            return getTokenPopup(requestConfig).then((accessToken) =>
                callApi(accessToken as string)
            );
        } else {
            return callApi(accessToken);
        }
    }
}
