import React from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from '@material-ui/core';
import useTranslation from 'src/hooks/useTranslation';
import { InputLabel } from 'src/components/Input';
import styled from 'styled-components';
import AccessLevelType from 'src/constants/AccessLevelType';

const translationKeys = require('src/translations').default;

const RadioHelpContainer = styled(Typography)`
    margin-left: 31px;
    margin-top: -15px;
    font-size: 14px;
    color: ${({ theme }) => `${theme.palette.neutral.dark}`};
`;
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledRadio = styled(Radio)`
    color: ${({ theme }) => theme.palette.primary.dark};
`;

export type Props = {
    value: string;
    onChange:
        | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
        | undefined;
};

export type Actions = {};

const AccessLevelModule = ({ value, onChange }: Props & Actions) => {
    const getTranslation = useTranslation();
    return (
        <InputContainer>
            <InputLabel
                label={getTranslation(
                    translationKeys.UserInfo_AccessLevel_Label
                )}
            />
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="access-level"
                    name="access-level"
                    value={value}
                    onChange={onChange}
                >
                    <FormControlLabel
                        value={AccessLevelType.Global}
                        control={<StyledRadio color="default" />}
                        label={getTranslation(
                            translationKeys.UserInfo_AccessLevel_Global_Label
                        )}
                    />
                    <RadioHelpContainer>
                        {getTranslation(
                            translationKeys.UserInfo_AccessLevel_Global_Help
                        )}
                    </RadioHelpContainer>
                    <FormControlLabel
                        value={AccessLevelType.Project}
                        control={<StyledRadio color="default" />}
                        label={getTranslation(
                            translationKeys.UserInfo_AccessLevel_Project_Label
                        )}
                    />
                    <RadioHelpContainer>
                        {getTranslation(
                            translationKeys.UserInfo_AccessLevel_Project_Help
                        )}
                    </RadioHelpContainer>
                </RadioGroup>
            </FormControl>
        </InputContainer>
    );
};

export default AccessLevelModule;
