import * as permissionConstants from './permissionConstants';
import { SubscriptionPermissions, ProjectPermissions } from './interfaces';

export interface SetSubscriptionsPermissionsAction {
    type: typeof permissionConstants.SET_SUBSCRIPTIONS_PERMISSIONS;
    permissions: SubscriptionPermissions;
}
export const SetSubscriptionsPermissions = (
    permissions: SubscriptionPermissions
) =>
    ({
        type: permissionConstants.SET_SUBSCRIPTIONS_PERMISSIONS,
        permissions,
    } as SetSubscriptionsPermissionsAction);

export interface SetProjectsPermissionsAction {
    type: typeof permissionConstants.SET_PROJECTS_PERMISSIONS;
    permissions: ProjectPermissions;
}
export const SetProjectsPermissions = (permissions: ProjectPermissions) =>
    ({
        type: permissionConstants.SET_PROJECTS_PERMISSIONS,
        permissions,
    } as SetProjectsPermissionsAction);

export interface SetOperationPermissionsAction {
    type: typeof permissionConstants.SET_OPERATION_PERMISSIONS;
    permissions: any;
}
export const SetOperationSettingsPermissions = (permissions: any) =>
    ({
        type: permissionConstants.SET_OPERATION_PERMISSIONS,
        permissions,
    } as SetOperationPermissionsAction);

export type KnownAction =
    | SetSubscriptionsPermissionsAction
    | SetProjectsPermissionsAction
    | SetOperationPermissionsAction;
