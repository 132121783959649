import { groupBy } from 'lodash';
import {
    GlobalRoles,
    ProjectRoles,
    RoleIdToRoleMap,
} from 'src/constants/enums';
import { RoleAssignmentDTO } from 'src/services/Dtos';
import { User } from 'src/services/interfaces';

type RoleContext = {
    scopes: string[];
    roleId: string;
};

export const getGlobalRoleContext = (
    subscriptionId: string,
    roleId: string
): RoleContext => {
    if (!GlobalRoles.includes(RoleIdToRoleMap[roleId.toUpperCase()]))
        throw Error('Invalid role');
    const scopes = ['/subscriptions/' + subscriptionId];
    return {
        scopes,
        roleId: roleId,
    };
};
export const getProjectRoleContext = (
    subscriptionId: string,
    projectIds: string[],
    roleId: string
): RoleContext => {
    if (!ProjectRoles.includes(RoleIdToRoleMap[roleId.toUpperCase()]))
        throw Error('Invalid role');
    const scopes = [
        ...projectIds.map(
            (projectId) =>
                '/subscriptions/' + subscriptionId + '/projects/' + projectId
        ),
    ];
    return {
        scopes,
        roleId: roleId,
    };
};

export const formatRoleAssignments = (
    assignments: RoleAssignmentDTO[]
): User[] =>
    Object.entries(groupBy(assignments, 'userOrGroupId')).map(
        ([userOrGroupIdKey, roleAssignments]: any): User => ({
            userOrGroupId: userOrGroupIdKey,
            email: roleAssignments[0].email,
            name: roleAssignments[0].userOrGroup,
            principalType: roleAssignments[0].principalType,
            roles: (roleAssignments as RoleAssignmentDTO[]).map(
                ({
                    userOrGroupId,
                    userOrGroup,
                    email,
                    principalType,
                    ...excludingUserData
                }) => excludingUserData
            ),
        })
    );
