import { LayoutService } from '../services';
import { apiConstants, apiActions } from '../redux/Api';
import { Middleware } from 'redux';
import { ApplicationState } from '../reduxSetup/interfaces';
import {
    LayoutBackgroundDrawingDTO,
    LayoutVisualizationDataDTO,
} from '../services/Dtos';
import { layoutActions } from '../redux/Layout';

const layoutApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;

const layoutMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const knownAction = action as apiActions.KnownAction;
    const layoutService = new LayoutService(layoutApiUrl);

    switch (knownAction.type) {
        case apiConstants.GET_LAYOUT_VISUALIZATION:
            dispatch(layoutActions.RemoveLayoutVisualizationData());
            dispatch(layoutActions.SetLayoutVisualizationDataIsLoading(true));
            await layoutService
                .GetLayoutVisualization(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutId
                )
                .then((response) => {
                    if (
                        response &&
                        (response as unknown as LayoutVisualizationDataDTO)
                    ) {
                        dispatch(
                            layoutActions.SetLayoutVisualizationData(
                                response as unknown as LayoutVisualizationDataDTO
                            )
                        );
                        dispatch(
                            layoutActions.SetLayoutVisualizationDataIsLoaded(
                                true
                            )
                        );
                    } else
                        dispatch(
                            layoutActions.SetLayoutVisualizationDataError(
                                'Api.Error.Unhandled'
                            )
                        );
                })
                .catch((error) => {
                    dispatch(
                        layoutActions.SetLayoutVisualizationDataIsLoaded(false)
                    );
                    if (error.status == 404) {
                        dispatch(
                            layoutActions.SetLayoutVisualizationDataError(
                                'Api.Error.NotFound'
                            )
                        );
                    } else {
                        //TODO: error handling
                        dispatch(
                            layoutActions.SetLayoutVisualizationDataError(
                                'Api.Error.Unhandled'
                            )
                        );
                        console.log(error);
                    }
                })
                .finally(() => {
                    dispatch(
                        layoutActions.SetLayoutVisualizationDataIsLoading(false)
                    );
                });
            break;
        case apiConstants.GET_LAYOUT_BACKGROUNDDRAWING:
            dispatch(layoutActions.RemoveLayoutBackgroundDrawingData());
            dispatch(
                layoutActions.SetLayoutBackgroundDrawingDataIsLoading(true)
            );
            await layoutService
                .GetLayoutBackgroundDrawing(
                    knownAction.subscriptionId,
                    knownAction.projectId,
                    knownAction.layoutId
                )
                .then((response) => {
                    if (
                        response &&
                        (response as unknown as LayoutBackgroundDrawingDTO)
                    ) {
                        dispatch(
                            layoutActions.SetLayoutBackgroundDrawingData(
                                response as unknown as LayoutBackgroundDrawingDTO
                            )
                        );
                        dispatch(
                            layoutActions.SetLayoutBackgroundDrawingDataIsLoaded(
                                true
                            )
                        );
                    } else
                        dispatch(
                            layoutActions.SetLayoutBackgroundDrawingDataError(
                                'Api.Error.Unhandled'
                            )
                        );
                })
                .catch((error) => {
                    dispatch(
                        layoutActions.SetLayoutBackgroundDrawingDataIsLoaded(
                            false
                        )
                    );
                    if (error.status == 404) {
                        dispatch(
                            layoutActions.SetLayoutBackgroundDrawingDataError(
                                'Api.Error.NotFound'
                            )
                        );
                    } else {
                        //TODO: error handling
                        dispatch(
                            layoutActions.SetLayoutBackgroundDrawingDataError(
                                'Api.Error.Unhandled'
                            )
                        );
                        console.log(error);
                    }
                })
                .finally(() => {
                    dispatch(
                        layoutActions.SetLayoutBackgroundDrawingDataIsLoading(
                            false
                        )
                    );
                });
            break;
    }
};

export default layoutMiddleware;
