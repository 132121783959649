import {
    CircularProgress,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { CompanyInfo } from 'src/redux/Companies/interfaces';
import styled from 'styled-components';
import { Guid } from 'guid-typescript';
import { InputTextField } from 'src/components/Input';
import { useParams } from 'react-router';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ProjectInfo, ProjectOverview } from 'src/redux/Projects/interfaces';
import { CreateEditCompany } from '../../CreateEditCompany';
import { ProjectStatus, Scope } from 'src/constants/enums';
import { history } from 'src/routing';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { AccessControl } from 'src/components/AccessControl';

export type Props = {
    companies: CompanyInfo[] | undefined;
    editingProject: ProjectInfo;
    subscriptionId: Guid | undefined;
};

export type Actions = {
    createProject: (
        subscriptionId: Guid,
        project: ProjectOverview,
        createdCallback: (projectId?: string) => void
    ) => void;
    editProject: (
        subscriptionId: Guid,
        project: ProjectOverview,
        editedCallback: (projectId?: string) => void
    ) => void;
    getCompanies: (subscriptionId: Guid) => any;
    setCompanies: (CompanyInfo: CompanyInfo[]) => void;
};

const StyledButtonContainer = styled.div`
    margin: 8px;
`;

const CreateEditProject = ({
    companies,
    editingProject,
    subscriptionId,
    createProject,
    editProject,
    getCompanies,
    setCompanies,
}: Props & Actions) => {
    const NavigateTo = (path: string) => {
        history.push(path);
    };
    const readCompanies = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'readCompanies',
    });
    const createCompanies = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'createCompanies',
    });
    const createProjects = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'createProjects',
    });

    const { projectGuid } = useParams<{
        projectGuid: string;
    }>();

    const isCreatingProject = projectGuid == undefined;

    if (
        !isCreatingProject &&
        editingProject?.commissioningProject === undefined
    ) {
        NavigateTo('/projects');
    }

    const editProjectPermission = useValidateAccess({
        scope: Scope.Project,
        id: projectGuid ?? '',
        allowed: 'editProject',
    });

    const [newProjectName, setNewProjectName] = useState(
        !isCreatingProject ? editingProject?.commissioningProject : ''
    );
    const [selectedCompanyId, setSelectedCompanyId] = useState(
        !isCreatingProject ? editingProject?.companyId : ''
    );
    const [projectStatus, setProjectStatus] = useState(
        !isCreatingProject ? editingProject?.status : ProjectStatus.Ongoing
    );

    const [projectNameInvalid, setProjectNameInvalid] = useState(false);
    const [companyInvalid, setCompanyInvalid] = useState(false);
    const [displayCompanyTooltip, setDisplayCompanyTooltip] = useState(false);
    const [creatingNewCompany, setCreatingNewCompany] = useState(false);
    const [createOrEditInProgress, setCreateOrEditInsProgress] =
        useState(false);
    const companyWasCreatedRef = useRef(false);

    useEffect(() => {
        if (subscriptionId && readCompanies) {
            getCompanies(subscriptionId as Guid);
            if (
                companies &&
                companies.length > 0 &&
                isCreatingProject &&
                !selectedCompanyId
            ) {
                setSelectedCompanyId(companies[0].id.toString());
            }
        } else if (
            subscriptionId &&
            !readCompanies &&
            !isCreatingProject &&
            !creatingNewCompany &&
            editingProject
        ) {
            if (!selectedCompanyId)
                setSelectedCompanyId(editingProject?.companyId);
            setCompanies([
                {
                    id: Guid.parse(editingProject?.companyId),
                    name: editingProject?.company,
                    address: {
                        addressId: '',
                        name: '',
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        country: '',
                        longitude: '',
                        latitude: '',
                    },
                },
            ]);
        }
    }, [subscriptionId, readCompanies]);

    useEffect(() => {
        if (
            companies &&
            companies.length > 0 &&
            isCreatingProject &&
            !selectedCompanyId
        ) {
            setSelectedCompanyId(companies[0].id.toString());
        }
        if (companyWasCreatedRef.current) {
            companyWasCreatedRef.current = false;
            setCreatingNewCompany(false);
        }
    }, [companies]);

    const validateProjectName = (projectName: string) => {
        return (
            projectName != null && projectName != undefined && projectName != ''
        );
    };

    useEffect(() => {
        if (newProjectName !== '' && validateProjectName(newProjectName)) {
            setProjectNameInvalid(false);
        }
    }, [newProjectName]);

    if (isCreatingProject && !createProjects)
        NavigateTo('/error/not-authorized-resource');
    if (!isCreatingProject && !editProjectPermission)
        NavigateTo('/error/not-authorized-resource');

    if (!isCreatingProject && !editingProject) {
        subscriptionId &&
            NavigateTo(
                `/subscriptions/${subscriptionId}/project/${projectGuid}`
            );
        return null;
    }

    const handleCreateProjectClick = () => {
        setCreateOrEditInsProgress(true);
        if (!newProjectName) {
            setProjectNameInvalid(true);
            setCreateOrEditInsProgress(false);
            return;
        } else if (!selectedCompanyId) {
            setCompanyInvalid(true);
            setDisplayCompanyTooltip(true);
            setTimeout(() => {
                setCompanyInvalid(false);
                setDisplayCompanyTooltip(false);
            }, 1500);
            setCreateOrEditInsProgress(false);
            return;
        }

        const finishedCallback = (projectId?: string) => {
            setCreateOrEditInsProgress(false);
            if (projectId !== undefined) {
                subscriptionId &&
                    NavigateTo(
                        `/subscriptions/${subscriptionId}/project/${projectId}`
                    );
            }
        };

        const projectOverview: ProjectOverview = {
            companyId: selectedCompanyId,
            projectName: newProjectName,
            projectId: isCreatingProject
                ? Guid.EMPTY.toString()
                : editingProject.id,
            status: projectStatus,
        };

        if (isCreatingProject)
            createProject(
                subscriptionId as Guid,
                projectOverview,
                finishedCallback
            );
        else
            editProject(
                subscriptionId as Guid,
                projectOverview,
                finishedCallback
            );
    };

    return (
        <React.Fragment>
            {creatingNewCompany ? (
                <CreateEditCompany
                    createdCompanyCallback={(newCompanyId?: string) => {
                        if (newCompanyId) {
                            getCompanies(subscriptionId as Guid);
                            setSelectedCompanyId(newCompanyId);
                            companyWasCreatedRef.current = true;
                        }
                    }}
                    cancelCallback={() => setCreatingNewCompany(false)}
                />
            ) : companies ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            marginTop: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '400px',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{ maxWidth: 'fit-content' }}
                        >
                            {isCreatingProject
                                ? 'Create new project'
                                : 'Edit project'}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        margin: '4px',
                                    }}
                                >
                                    Project Name
                                </Typography>
                                <InputTextField
                                    id="project-name"
                                    label="Project name"
                                    value={newProjectName}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setNewProjectName(e.target.value)}
                                    required
                                    error={projectNameInvalid}
                                    helperText={
                                        projectNameInvalid
                                            ? 'Project name is required'
                                            : ''
                                    }
                                    disabled={createOrEditInProgress}
                                />
                                <Typography
                                    style={{
                                        margin: '4px',
                                    }}
                                >
                                    Company
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        margin: '4px',
                                    }}
                                >
                                    <Select
                                        data-testid="company-selector"
                                        id="company-selector"
                                        value={selectedCompanyId}
                                        variant="outlined"
                                        disabled={
                                            !companies ||
                                            companies.length == 0 ||
                                            !readCompanies ||
                                            createOrEditInProgress
                                        }
                                        onChange={(e: any) => {
                                            setSelectedCompanyId(
                                                e.target.value as string
                                            );
                                        }}
                                        required
                                        style={{
                                            width: createCompanies
                                                ? '85%'
                                                : '100%',
                                        }}
                                        error={companyInvalid}
                                    >
                                        {companies?.map((c) => (
                                            <MenuItem
                                                key={c.id.toString()}
                                                value={c.id.toString()}
                                                id={c.id.toString()}
                                            >
                                                {c.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <AccessControl
                                        scope={Scope.Subscription}
                                        id={subscriptionId}
                                        allowedPermission="createCompanies"
                                    >
                                        <Tooltip
                                            arrow={true}
                                            title="Add company"
                                            aria-label="add"
                                            onOpen={() =>
                                                setDisplayCompanyTooltip(true)
                                            }
                                            onClose={() =>
                                                setDisplayCompanyTooltip(false)
                                            }
                                            open={displayCompanyTooltip}
                                        >
                                            <IconButton
                                                color="secondary"
                                                aria-label="add-company"
                                                component="span"
                                                onClick={() => {
                                                    setDisplayCompanyTooltip(
                                                        false
                                                    );
                                                    setCreatingNewCompany(true);
                                                }}
                                                disabled={
                                                    createOrEditInProgress
                                                }
                                            >
                                                <AddCircleIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </AccessControl>
                                </div>
                            </Paper>
                            <StyledButtonContainer>
                                <Button
                                    colorScheme="secondary"
                                    onClick={() => {
                                        if (isCreatingProject) {
                                            subscriptionId &&
                                                NavigateTo(
                                                    `/subscriptions/${subscriptionId}/projects`
                                                );
                                        } else {
                                            subscriptionId &&
                                                NavigateTo(
                                                    `/subscriptions/${subscriptionId}/project/${projectGuid}`
                                                );
                                        }
                                    }}
                                    disabled={createOrEditInProgress}
                                >
                                    Cancel
                                </Button>
                            </StyledButtonContainer>
                            <StyledButtonContainer>
                                <Button
                                    $loading={createOrEditInProgress}
                                    onClick={() => {
                                        handleCreateProjectClick();
                                    }}
                                >
                                    {isCreatingProject
                                        ? 'Create project'
                                        : 'Save'}
                                </Button>
                            </StyledButtonContainer>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Typography variant="h3" style={{ marginRight: '20px' }}>
                        Loading...
                    </Typography>
                    <CircularProgress color="secondary" />
                </div>
            )}
        </React.Fragment>
    );
};
export default CreateEditProject;
