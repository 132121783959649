import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const accountState = (state: ApplicationState) => state.account;
const accountInfo = (state: ApplicationState) => state.account.accountInfo;

export const getAccountInfo = createSelector(
    [accountState],
    (accountState) => accountState.accountInfo
);

export const getPrincipalId = createSelector(
    [accountInfo],
    (accountInfo) => accountInfo?.localAccountId
);
