import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const getOperationSetting = (state: ApplicationState) =>
    state.operationSettings;

export const getOperationSettings = createSelector(
    [getOperationSetting],
    (OperationSettingState) => OperationSettingState.operationSettings
);
