import { Guid } from 'guid-typescript';

export const isValidJSON = (text: string) => {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
};

export const addLeadingSlash = (text: string) => {
    return text.startsWith('/') ? text : `/${text}`;
};

/**
 * Flatten object
 * //stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array
 * @param ob Object                 The object to flatten
 * @param prefix String (Optional)  The prefix to add before each key, also used for recursion
 **/
export const flattenObject = (ob: any, prefix = '', result: any = null) => {
    result = result || {};

    // Preserve empty objects and arrays, they are lost otherwise
    if (
        prefix &&
        typeof ob === 'object' &&
        ob !== null &&
        Object.keys(ob).length === 0
    ) {
        result[prefix] = Array.isArray(ob) ? [] : {};
        return result;
    }

    prefix = prefix ? prefix + '.' : '';

    for (const i in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, i)) {
            if (typeof ob[i] === 'object' && ob[i] !== null) {
                // Recursion on deeper objects
                flattenObject(ob[i], prefix + i, result);
            } else {
                result[prefix + i] = ob[i];
            }
        }
    }
    return result;
};

/**
 * Function to unflatten an object
 * https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array
 * @param ob Object     The object to unflatten
 */
export const unflattenObject = (ob: any) => {
    const result = {};
    for (const i in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, i)) {
            const keys = i.match(
                /^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g
            ); // Just a complicated regex to only match a single dot in the middle of the string

            if (keys)
                keys.reduce((r: any, e: string, j) => {
                    return (
                        r[e] ||
                        (r[e] = isNaN(Number(keys[j + 1]))
                            ? keys.length - 1 === j
                                ? ob[i]
                                : {}
                            : [])
                    );
                }, result);
        }
    }
    return result;
};

export const getPointsMinMaxValues = (points: { x: number; y: number }[]) => {
    let minX = points[0].x,
        minY = points[0].y,
        maxX = points[0].x,
        maxY = points[0].y;
    points.forEach((p) => {
        if (p.x < minX) minX = p.x;
        if (p.x > maxX) maxX = p.x;
        if (p.y < minY) minY = p.y;
        if (p.y > maxY) maxY = p.y;
    });
    return { minX, maxX, minY, maxY };
};

export const formatDiffLabel = (n: number) => (n > 0 ? '+' : '-') + Math.abs(n);

export const formatTravelTimeDiffLabel = (n: number) => {
    if (n > -0.05 && n < 0.05) return '±' + Math.abs(n).toFixed(0);
    return (n > 0 ? '+' : '-') + Math.abs(n).toFixed(1);
};

export const hexToDecimal = (hex: any) => {
    if (typeof hex !== 'string') {
        return NaN;
    }

    // Remove any leading "0x" if present
    if (hex.startsWith('0x')) {
        hex = hex.slice(2);
    }

    // Convert the hexadecimal value to decimal
    return parseInt(hex, 16);
};

export const decimalToHex = (decimal: any) => {
    if (typeof decimal !== 'number') {
        return '';
    }

    return decimal.toString(16).toUpperCase();
};

export const isHex = (value: string) => {
    return /^[0-9A-Fa-f]+$/.test(value);
};

export const splitNameFromEmail = (email: string) => {
    const [name, domain] = email.split('@');
    return name.replace(/\./g, ' ');
};

export const isGuidEmpty = (guid: Guid): boolean => {
    return guid?.toString() === '00000000-0000-0000-0000-000000000000';
};
