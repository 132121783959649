import { Guid } from 'guid-typescript';
import { connect } from 'react-redux';
import { apiActions } from 'src/redux/Api';
import { layoutSelectors } from 'src/redux/Layout';
import { projectSelectors } from 'src/redux/Projects';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import LayoutViewer, { Props, Actions } from './components/LayoutViewer';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        selectedProject: projectSelectors.getSelectedProject(state),
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
        subscriptions: subscriptionSelectors.getSubscriptions(state),
        layoutVisualizationData:
            layoutSelectors.getLayoutVisualizationData(state),
        layoutVisualizationIsLoading:
            layoutSelectors.getLayoutVisualizationDataIsLoading(state),
        layoutVisualizationIsLoaded:
            layoutSelectors.getLayoutVisualizationDataIsLoaded(state),
        layoutVisualizationError:
            layoutSelectors.getLayoutVisualizationDataError(state),
        layoutBackgroundDrawingData:
            layoutSelectors.getLayoutBackgroundDrawingData(state),
    };
};

const mapDispatchToProps: Actions = {
    GetProject: (subscriptionId: Guid, guid: string) =>
        apiActions.getProject(subscriptionId, Guid.parse(guid)),
    GetLayoutVisualizationData: (
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) => apiActions.getLayoutVisualization(subscriptionId, projectId, layoutId),
    GetLayoutBackgroundDrawingData: (
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid
    ) =>
        apiActions.getLayoutBackgroundDrawing(
            subscriptionId,
            projectId,
            layoutId
        ),
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutViewer);
