import * as projectConstants from './projectConstants';
import {
    ProjectInfo,
    NOT_FOUND,
    FAILED_TO_GET_URL,
    UploadingFile,
    WefFileInfo,
    ProjectList,
    LayoutFile,
} from './interfaces';
import { Guid } from 'guid-typescript';

export interface SetProjectsAction {
    type: typeof projectConstants.SET_PROJECTS;
    projects: ProjectList | undefined;
}

export const SetProjects = (projects: ProjectList) =>
    ({
        type: projectConstants.SET_PROJECTS,
        projects,
    } as SetProjectsAction);

export const ResetProjects = () =>
    ({
        type: projectConstants.SET_PROJECTS,
        projects: undefined,
    } as SetProjectsAction);

export interface SetSelectedProjectAction {
    type: typeof projectConstants.SET_SELECTED_PROJECT;
    selectedProject: ProjectInfo | undefined | typeof NOT_FOUND;
}

export const SetSelectedProject = (
    selectedProject: ProjectInfo | undefined | typeof NOT_FOUND
) =>
    ({
        type: projectConstants.SET_SELECTED_PROJECT,
        selectedProject,
    } as SetSelectedProjectAction);

export const ResetSelectedProject = () =>
    ({
        type: projectConstants.SET_SELECTED_PROJECT,
        selectedProject: undefined,
    } as SetSelectedProjectAction);

export interface AddUploadingLayoutFileAction {
    type: typeof projectConstants.ADD_UPLOADING_LAYOUTFILE;
    fileUpload: UploadingFile;
}

export const AddUploadingLayoutFile = (fileUpload: UploadingFile) =>
    ({
        type: projectConstants.ADD_UPLOADING_LAYOUTFILE,
        fileUpload,
    } as AddUploadingLayoutFileAction);

export interface RemoveUploadingLayoutFileAction {
    type: typeof projectConstants.REMOVE_UPLOADING_LAYOUTFILE;
    requestId: string;
}

export const RemoveUploadingLayoutFile = (requestId: string) =>
    ({
        type: projectConstants.REMOVE_UPLOADING_LAYOUTFILE,
        requestId,
    } as RemoveUploadingLayoutFileAction);

export interface SetLayoutFileDownloadLinkAction {
    type: typeof projectConstants.SET_LAYOUTFILE_DOWNLOAD_LINK;
    url: URL | undefined | typeof FAILED_TO_GET_URL;
}

export const SetLayoutFileDownloadLink = (
    url: URL | undefined | typeof FAILED_TO_GET_URL
) =>
    ({
        type: projectConstants.SET_LAYOUTFILE_DOWNLOAD_LINK,
        url,
    } as SetLayoutFileDownloadLinkAction);

export const ResetLayoutFileDownloadLink = () =>
    ({
        type: projectConstants.SET_LAYOUTFILE_DOWNLOAD_LINK,
        url: undefined,
    } as SetLayoutFileDownloadLinkAction);

export interface SetSelectedProjectWefFileInfoAction {
    type: typeof projectConstants.SET_SELECTED_PROJECT_WEFFILEINFO;
    wefFileInfo: WefFileInfo;
}

export const SetSelectedProjectWefFileInfo = (wefFileInfo: WefFileInfo) =>
    ({
        type: projectConstants.SET_SELECTED_PROJECT_WEFFILEINFO,
        wefFileInfo,
    } as SetSelectedProjectWefFileInfoAction);

export interface SetProjectRoleAssignmentsAction {
    type: typeof projectConstants.SET_PROJECT_ROLE_ASSIGNMENTS;
    projectId: Guid;
    roleAssignments: any[];
}

export const SetProjectRoleAssignments = (
    projectId: Guid,
    roleAssignments: any[]
) =>
    ({
        type: projectConstants.SET_PROJECT_ROLE_ASSIGNMENTS,
        projectId,
        roleAssignments,
    } as SetProjectRoleAssignmentsAction);

export interface AddProjectLayoutFileAction {
    type: typeof projectConstants.ADD_PROJECT_LAYOUT_FILE;
    projectId: string;
    layoutFile: LayoutFile;
}

export const AddProjectLayoutFile = (
    projectId: string,
    layoutFile: LayoutFile
) =>
    ({
        type: projectConstants.ADD_PROJECT_LAYOUT_FILE,
        projectId,
        layoutFile,
    } as AddProjectLayoutFileAction);

export type KnownAction =
    | SetSelectedProjectAction
    | SetProjectsAction
    | AddUploadingLayoutFileAction
    | RemoveUploadingLayoutFileAction
    | SetLayoutFileDownloadLinkAction
    | SetSelectedProjectWefFileInfoAction
    | SetProjectRoleAssignmentsAction
    | AddProjectLayoutFileAction;
