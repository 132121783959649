import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import ErrorContainer from './ErrorContainer';
import useTranslation from 'src/hooks/useTranslation';
import { Button } from 'src/components/Button';

const ButtonContainer = styled.div`
    margin-top: 25px;
`;

const NotAuthorized = () => {
    const getTranslation = useTranslation();
    const { instance } = useMsal();
    return (
        <ErrorContainer>
            <Typography variant="h6" gutterBottom>
                {getTranslation('ErrorPages.NotAuthorized.Title')}
            </Typography>
            <Typography variant="body1">
                {getTranslation('ErrorPages.NotAuthorized.Description')}
            </Typography>
            <ButtonContainer>
                <Button
                    key={'sign-out-btn'}
                    variant="contained"
                    style={{ width: 'auto' }}
                    onClick={() => instance.logout()}
                >
                    {getTranslation('MenuItem.Logout.Label')}
                </Button>
            </ButtonContainer>
        </ErrorContainer>
    );
};

export default NotAuthorized;
