import { makeStyles, Paper } from '@material-ui/core';
import * as React from 'react';
import { ReactElement } from 'react';
import kollmorgenLogo from 'src/assets/kollmorgen_logo.png';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: `100%`,
        backgroundColor: '#e5e5e5',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
    },
    paper: {
        backgroundColor: theme.palette.background.default,
        margin: 0,
        height: '100vh',
    },
    content: {
        maxWidth: '470px',
        width: '100%',
        padding: '70px 30px',
        display: 'inline-block',
        minHeight: '501px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '0px',
        },
    },
    logo: {
        marginBottom: '16px',
    },
}));

type Props = {
    children: ReactElement<React.ReactNode>[];
};

const ErrorContainer = (props: Props) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <div className={classes.container}>
                    <Paper className={classes.content}>
                        <img
                            className={classes.logo}
                            src={kollmorgenLogo}
                            title="Kollmorgen logotype"
                        />
                        {props.children}
                    </Paper>
                </div>
            </Paper>
        </React.Fragment>
    );
};

export default ErrorContainer;
