import { Box, CircularProgress } from '@material-ui/core';

const FullScreenLoading = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <CircularProgress
                variant="indeterminate"
                color="secondary"
                size={60}
            />
        </Box>
    );
};

export default FullScreenLoading;
