import React, { useState, cloneElement, ReactElement } from 'react';
import {
    Box,
    ClickAwayListener,
    Fade,
    makeStyles,
    Paper,
    Popper,
    PopperPlacementType,
} from '@material-ui/core';

interface Props {
    content: ReactElement;
    children: ReactElement;
    open: boolean;
    onClose?: () => void;
    arrow?: boolean;
    placement?: PopperPlacementType;
}

const useStyles = makeStyles((theme) => {
    const color = theme.palette.background.paper;
    return {
        popoverRoot: {
            backgroundColor: '#FFFFFF',
            maxWidth: 1000,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
        },
        content: {
            padding: '8px 12px 8px 12px',
        },

        popper: {
            zIndex: 2000,
            border: '1px solid #E0E0E0',
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: '-12px',
                marginLeft: '4px',
                marginRight: '4px',
                '&::before': {
                    transformOrigin: '0 100%',
                },
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: '-12px',
                marginLeft: '4px',
                marginRight: '4px',
                '&::before': {
                    transformOrigin: '100% 0',
                },
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: '-12px',
                height: '16px',
                width: '12px',
                marginTop: '4px',
                marginBottom: '4px',
                '&::before': {
                    transformOrigin: '100% 100%',
                },
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: '-12px',
                height: '16px',
                width: '12px',
                marginTop: '4px',
                marginBottom: '4px',
                '&::before': {
                    transformOrigin: '0 0',
                },
            },
        },

        arrow: {
            overflow: 'hidden',
            position: 'absolute',
            width: '16px',
            height: '12px',
            boxSizing: 'border-box',
            color,
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: '100%',
                height: '100%',
                boxShadow: theme.shadows[1],
                backgroundColor: '#FFFFFF',
                transform: 'rotate(45deg)',
                border: '1px solid #E0E0E0',
            },
        },
    };
});

const RichPopper = ({
    placement = 'top',
    arrow = true, // could be optional as per the requirement, for now it's kept true
    open,
    onClose = () => {},
    content,
    children,
}: Props) => {
    const classes = useStyles();
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const [childNode, setChildNode] = useState<HTMLElement | null>(null);

    return (
        <ClickAwayListener onClickAway={onClose}>
            <div>
                {cloneElement(children, {
                    ...children.props,
                    ref: setChildNode,
                })}
                <Popper
                    open={open}
                    anchorEl={childNode}
                    placement={placement}
                    transition
                    className={classes.popper}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'window',
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.popoverRoot}>
                                {arrow ? (
                                    <span
                                        className={classes.arrow}
                                        ref={setArrowRef}
                                    />
                                ) : null}
                                <Box className={classes.content}>{content}</Box>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default RichPopper;
