import {
    configureStore as rtkConfigureStore,
    combineReducers,
} from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
    addressMiddleware,
    companyMiddleware,
    projectMiddleware,
    cirrusInstallFileMiddleware,
    layoutMiddleware,
    subscriptionMiddleware,
    operationSettingsMiddleware,
} from '../middlewares';
import { ApplicationState } from './interfaces';
import rootReducer from './rootReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { History } from 'history';
import { syncServiceApi } from 'src/services/syncServiceApi';
import { projectApi } from 'src/services/projectApi';

export default function configureStore(
    history: History,
    useLogger: boolean = true,
    initialState?: ApplicationState
) {
    return rtkConfigureStore({
        reducer: combineReducers({
            ...rootReducer,
            router: connectRouter(history),
        }),
        middleware: (getDefaultMiddleware) => {
            const middleware = getDefaultMiddleware({
                serializableCheck: false, //TODO fix the serializeability error!!!
            }).concat(
                syncServiceApi.middleware,
                projectApi.middleware,
                thunk,
                projectMiddleware,
                companyMiddleware,
                addressMiddleware,
                subscriptionMiddleware,
                routerMiddleware(history),
                cirrusInstallFileMiddleware,
                layoutMiddleware,
                operationSettingsMiddleware
            );
            if (useLogger) {
                middleware.push(logger);
            }
            return middleware;
        },
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: initialState,
    });
}
