import styled from 'styled-components';
import { Paper, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const StyledCloseIcon = styled(CloseIcon)`
    cursor: pointer;
    pointer-events: all;
`;

const StyledHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)}`};
    padding-right: ${({ theme }) => theme.spacing(5)};
    background-color: ${({ theme }) => theme.palette.neutral.light};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StyledInteractiveModulePaper = styled(Paper)`
    background-color: #fff;
    box-shadow: none;
    height: 100%;
    width: 100%;
    pointer-events: all;
    overflow-y: auto;
`;

export type Props = {
    onCloseBehaviour?: () => void;
    headerText?: string;
    children?: React.ReactNode;
};
const InteractiveModule = ({
    onCloseBehaviour,
    headerText,
    children,
}: Props) => {
    const handleOnCloseClick = () => {
        if (onCloseBehaviour) {
            onCloseBehaviour();
        }
    };
    return (
        <>
            {headerText ? (
                <>
                    <StyledHeaderContainer>
                        <Typography
                            variant="h6"
                            data-testid="interactive-module-header-text"
                        >
                            {headerText}
                        </Typography>
                        <IconButton
                            data-testid="close-button"
                            onClick={handleOnCloseClick}
                            style={{ padding: '0px' }}
                        >
                            <StyledCloseIcon />
                        </IconButton>
                    </StyledHeaderContainer>
                </>
            ) : null}
            <StyledInteractiveModulePaper>
                {children}
            </StyledInteractiveModulePaper>
        </>
    );
};

export default InteractiveModule;
