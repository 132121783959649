import { Guid } from 'guid-typescript';
import { apiActions } from 'src/redux/Api';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { connect } from 'react-redux';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import Users, { Props, Actions } from './components/Users';
import { projectActions, projectSelectors } from 'src/redux/Projects';
import { modalActions } from 'src/redux/Modals';
import { ModalType } from 'src/constants/enums';
import { ReactNode } from 'react';
import { permissionSelectors } from 'src/redux/Permissions';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
        subscriptionRoleAssignments:
            subscriptionSelectors.getSubscriptionRoleAssignments(state),
        subscriptionInfo: subscriptionSelectors.getSubscriptionInfo(state),
        projects: projectSelectors.getProjects(state),
        projectPermissions: permissionSelectors.getProjectsPermissions(state),
    };
};

const mapDispatchToProps: (dispatch: any) => Actions = (dispatch) => {
    return {
        getProjects: (subscriptionId: Guid) =>
            dispatch(apiActions.getProjects(subscriptionId)),
        resetProjects: () => dispatch(projectActions.ResetProjects()),
        getSubscriptionRoleAssignments: (subscriptionId: Guid) =>
            dispatch(apiActions.getSubscriptionRoleAssignments(subscriptionId)),
        showModalWithContents: (
            id: Guid,
            heading: string,
            content: ReactNode
        ) =>
            dispatch(
                modalActions.OpenModal({
                    id,
                    heading,
                    type: ModalType.Custom,
                    content,
                })
            ),
        showModalMessage: (id: Guid, heading: string, content: string) => {
            dispatch(
                modalActions.OpenModal({
                    id,
                    heading,
                    type: ModalType.Message,
                    content,
                    primaryButton: {
                        label: 'General.Close',
                        onClick: () => {
                            dispatch(modalActions.CloseModal(id));
                        },
                    },
                })
            );
        },
        closeModal: (id: Guid) => {
            dispatch(modalActions.CloseModal(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
