import { CircularProgress, Typography } from '@material-ui/core';
import { Guid } from 'guid-typescript';
import React, { useEffect } from 'react';
import { history } from 'src/routing';
import { AccessControl } from 'src/components/AccessControl';
import { Button } from 'src/components/Button';
import { Table } from 'src/components/Table';
import { Scope } from 'src/constants/enums';
import { CompanyInfo } from 'src/redux/Companies/interfaces';
import { isGuidEmpty } from 'src/helpers/utils';

export type Props = {
    companies: CompanyInfo[] | undefined;
    subscriptionId: Guid | undefined;
};

export type Actions = {
    getAddresses: (subscriptionId: Guid) => any;
    getCompanies: (subscriptionId: Guid) => any;
    resetSelectedCompany: () => void;
};

const Companies = ({
    companies = undefined,
    subscriptionId,
    getAddresses,
    getCompanies,
    resetSelectedCompany,
}: Props & Actions) => {
    useEffect(() => {
        resetSelectedCompany();
    }, []);

    useEffect(() => {
        if (subscriptionId && !isGuidEmpty(subscriptionId)) {
            getCompanies(subscriptionId);
            getAddresses(subscriptionId);
        }
    }, [subscriptionId]);

    const NavigateTo = (path: string) => {
        history.push(path);
    };

    const createLocationString = (obj: CompanyInfo) => {
        var address = obj.address;
        var fullLocation = '';
        if (address.city) {
            fullLocation += address.city;
        }

        if (address.country) {
            if (fullLocation) {
                fullLocation += ', ';
            }
            fullLocation += address.country;
        }

        return fullLocation;
    };
    const renderOpenButton = (obj: any) => {
        return (
            <AccessControl
                scope={Scope.Subscription}
                id={subscriptionId?.toString() ?? ''}
                allowedPermission="createCompanies"
            >
                <Button
                    colorScheme="secondary"
                    onClick={() => {
                        subscriptionId &&
                            NavigateTo(
                                `/subscriptions/${subscriptionId}/company/${obj.id}`
                            );
                    }}
                >
                    Open
                </Button>
            </AccessControl>
        );
    };

    return (
        <React.Fragment>
            <div
                style={{
                    marginTop: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{ marginRight: '20px' }}
                        >
                            {'Companies'}
                        </Typography>
                        {!companies ? (
                            <CircularProgress color="secondary" />
                        ) : (
                            ''
                        )}
                    </div>
                    <AccessControl
                        scope={Scope.Subscription}
                        id={subscriptionId?.toString() ?? ''}
                        allowedPermission="createCompanies"
                    >
                        <div>
                            <Button
                                colorScheme="primary"
                                onClick={() =>
                                    subscriptionId &&
                                    NavigateTo(
                                        `/subscriptions/${subscriptionId}/create-company`
                                    )
                                }
                            >
                                Add new company
                            </Button>
                        </div>
                    </AccessControl>
                </div>
                <Table
                    objects={companies}
                    headers={[
                        'Company name',
                        'Office',
                        'Location',
                        'Address',
                        '',
                    ]}
                    columnContents={[
                        'name',
                        'address.name',
                        (obj: any) => createLocationString(obj),
                        'address.addressLine1',
                        (obj: any) => renderOpenButton(obj),
                    ]}
                ></Table>
            </div>
        </React.Fragment>
    );
};

export default Companies;
