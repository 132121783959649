import { Guid } from 'guid-typescript';
import { useSelector } from 'react-redux';
import { Scope } from 'src/constants/enums';
import { permissionSelectors } from 'src/redux/Permissions';
import {
    ProjectPermissions,
    SubscriptionPermissions,
    OperationPermissions,
} from 'src/redux/Permissions/interfaces';

const checkPermission = (
    id: string,
    allowed: string,
    permissions:
        | SubscriptionPermissions
        | ProjectPermissions
        | OperationPermissions
        | undefined
) => {
    if (id.length === 0 || allowed.length === 0 || permissions === undefined)
        return false;
    let result;
    for (const [key, value] of Object.entries(permissions[id] ?? {})) {
        if (key === allowed) {
            result = value;
            break;
        }
    }
    return result ?? false;
};

export type Props = {
    scope: Scope;
    id: string | Guid | undefined;
    allowed: string;
};

const useValidateAccess = ({ scope, id, allowed }: Props) => {
    id = id?.toString() ?? '';
    const subscriptionsPermissions = useSelector(
        permissionSelectors.getSubscriptionsPermissions
    );
    const projectPermissions = useSelector(
        permissionSelectors.getProjectsPermissions
    );
    const operationSettingsPermissions = useSelector(
        permissionSelectors.getOperationSettingsPermissions
    );
    const getPermissionsFromScope = () => {
        if (scope === Scope.Subscription) return subscriptionsPermissions;
        else if (scope === Scope.Project) return projectPermissions;
        else if (scope === Scope.OperationSettings)
            return operationSettingsPermissions;
        else return undefined;
    };

    return checkPermission(id, allowed, getPermissionsFromScope());
};

export default useValidateAccess;
