import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { LayoutObjectType } from 'src/constants/enums';
import { PointIcon, SegmentIcon } from 'src/assets/icons';
import { segmentSettings } from 'src/components/PixiObjects/settings/segmentSettings';

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minWidth: '220px',
    },
    listItemText: {
        margin: 0,
    },
    icon: {
        minWidth: theme.spacing(6),
    },
}));

export type Props = {
    objectType: LayoutObjectType;
    text: string;
    id: number;
    onClickHandler: (objectType: LayoutObjectType, id: number) => void;
    testId: string;
};

const OverlappingObjectsMenuItem = ({
    objectType,
    text,
    id,
    onClickHandler,
    testId,
}: Props) => {
    const classes = useStyles();
    const color =
        objectType === LayoutObjectType.UPDATEDPOINT ||
        objectType === LayoutObjectType.UPDATEDSEGMENT
            ? segmentSettings.lineStyle.updated.default.color
            : segmentSettings.lineStyle.base.default.color;

    const Icon =
        objectType === LayoutObjectType.POINT ||
        objectType === LayoutObjectType.UPDATEDPOINT
            ? PointIcon
            : SegmentIcon;
    return (
        <ListItem
            data-testid={testId}
            className={classes.listItem}
            button
            onClick={(event) => {
                onClickHandler(objectType, id);
                event.stopPropagation();
            }}
        >
            <ListItemIcon className={classes.icon}>
                <Icon iconColor={`#${color.toString(16)}`} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary={text} />
        </ListItem>
    );
};

export default OverlappingObjectsMenuItem;
