import { Reducer, Action } from 'redux';
import * as permissionActions from './permissionActions';
import * as permissionConstants from './permissionConstants';
import { PermissionsState } from './interfaces';

export const initialState: PermissionsState = {
    subscriptions: undefined,
    projects: undefined,
    operationSettings: undefined,
};

export const reducer: Reducer<PermissionsState> = (
    state: PermissionsState = initialState,
    incomingAction: Action
): PermissionsState => {
    const action = incomingAction as permissionActions.KnownAction;

    switch (action.type) {
        case permissionConstants.SET_SUBSCRIPTIONS_PERMISSIONS:
            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    ...action.permissions,
                },
            };
        case permissionConstants.SET_PROJECTS_PERMISSIONS:
            return {
                ...state,
                projects: { ...state.projects, ...action.permissions },
            };
        case permissionConstants.SET_OPERATION_PERMISSIONS:
            return {
                ...state,
                operationSettings: {
                    ...state.operationSettings,
                    ...action.permissions,
                },
            };
        default:
            return state;
    }
};
