import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import useTranslation from 'src/hooks/useTranslation';
import theme from 'src/theme';
import styled from 'styled-components';
import raw from 'raw.macro';

const CirrusDocumentation = raw(`../../../assets/Cirrus_documentation.htm`);

export type Props = {};

export type Actions = {};

const StyledContainer = styled.div`
    max-width: 620px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
`;

const AboutCirrusPage = ({}: Props & Actions) => {
    const getTranslation = useTranslation();

    var __html = CirrusDocumentation;
    var template = { __html: __html };
    return (
        <StyledContainer>
            <Typography
                variant={'h4'}
                data-testid="about-cirrus-header"
                style={{ marginBottom: theme.spacing(6) }}
            >
                {getTranslation('AboutCirrus.Header')}
            </Typography>
            <div dangerouslySetInnerHTML={template} />
        </StyledContainer>
    );
};

export default AboutCirrusPage;
