import { CirrusInstallFileService } from '../services';
import { apiConstants, apiActions } from '../redux/Api';
import { Middleware } from 'redux';
import { ApplicationState } from '../reduxSetup/interfaces';
import {
    CirrusInstallInfoDTO,
    FileInfoDTO,
} from 'src/services/Dtos/CirrusInstallInfoDTO';
import { cirrusInstallInfoActions } from 'src/redux/CirrusInstallInfo';
import { CirrusInstallInfo } from 'src/redux/CirrusInstallInfo/interfaces/index';

const projectApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;

const cirrusInstallFileMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const knownAction = action as apiActions.KnownAction;
    const cirrusInstallFileService = new CirrusInstallFileService(
        projectApiUrl
    );

    switch (knownAction.type) {
        case apiConstants.GET_CIRRUS_INSTALL_DOWNLOAD_LINK:
            await cirrusInstallFileService
                .getFileDownloadLink()
                .then((result) => {
                    var fileInfo = result as FileInfoDTO;

                    if (!fileInfo) {
                        knownAction.fileCallback(false);
                    } else {
                        knownAction.fileCallback(
                            true,
                            fileInfo.uri,
                            fileInfo.fileName
                        );
                    }
                })
                .catch((error) => {
                    console.log(
                        'Failed to get Cirrus Install download link, ' + error
                    );
                    knownAction.fileCallback(false);
                });
            break;
        case apiConstants.GET_CIRRUS_INSTALL_INFO:
            await cirrusInstallFileService
                .getCirrusInstallVersionInformation()
                .then((info: any) => {
                    const dto = info as CirrusInstallInfoDTO;
                    const model = {
                        createdDate: new Date(info.createdDate),
                        loaded: true,
                        version: dto.version,
                    } as CirrusInstallInfo;
                    dispatch(
                        cirrusInstallInfoActions.SetCirrusInstallInfo(model)
                    );
                })
                .catch((error) => {
                    const model = {
                        loaded: true,
                    } as CirrusInstallInfo;
                    dispatch(
                        cirrusInstallInfoActions.SetCirrusInstallInfo(model)
                    );
                    console.log(error);
                });
    }
};

export default cirrusInstallFileMiddleware;
