import { OperationSettingsService, AccessValidatorService } from '../services';
import { apiConstants, apiActions } from '../redux/Api';
import { operationSettingsActions } from '../redux/OperationSettings';
import { permissionActions } from '../redux/Permissions';
import { Middleware } from 'redux';
import { AccessValidatorQuery } from 'src/services/interfaces';
import { ApplicationState } from '../reduxSetup/interfaces';
import { OperationSettingDTO } from '../services/Dtos';
import { OperationPermissions } from 'src/redux/Permissions/interfaces';
import {
    OperationSettingTemplate,
    OperationSettingList,
} from 'src/redux/OperationSettings/interfaces';
import { Guid } from 'guid-typescript';

const projectApiUrl = process.env.REACT_APP_SYNC_SERVICE_API_URL as string;

export const createOperationAccessValidatorQuery = (
    subscriptionId: Guid,
    templateId: Guid,
    operationSettingId?: Guid
) => {
    return {
        createOperation: `POST:/api/subscriptions/${subscriptionId}/operationsettingstemplates/${templateId}/operationsetting`,
    };
};

const normalizeOperationSettingList = (
    operationSettings: OperationSettingTemplate[] | string
) => {
    const result: OperationSettingList = {};
    if (typeof operationSettings === 'string') return {};

    operationSettings.forEach((p) => {
        result[p.templateId] = { ...p };
    });

    return result;
};

const operationSettingsMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const state = store.getState();
    const knownAction = action as apiActions.KnownAction;
    const operationSettingsService = new OperationSettingsService(
        projectApiUrl
    );
    const accessValidatorService = new AccessValidatorService();

    const handleAccessValidation = (
        subscriptionId: Guid,
        operationSettingsTemplate: OperationSettingTemplate[]
    ) => {
        let accessValidatorQuery: AccessValidatorQuery = {};
        operationSettingsTemplate.forEach(({ templateId }) => {
            accessValidatorQuery[templateId] =
                createOperationAccessValidatorQuery(
                    subscriptionId,
                    templateId as unknown as Guid
                );
        });
        accessValidatorService
            .validateAccess(accessValidatorQuery)
            .then((accessValidatorResult) => {
                dispatch(
                    permissionActions.SetOperationSettingsPermissions(
                        accessValidatorResult as unknown as OperationPermissions
                    )
                );
            });
    };

    switch (knownAction.type) {
        case apiConstants.GET_OPERATION_SETTINGS:
            await operationSettingsService
                .GetOperationSettingsTemplates(knownAction.subscriptionId)
                .then((operationSettingsTemplates) => {
                    if (
                        operationSettingsTemplates &&
                        typeof operationSettingsTemplates !== 'string'
                    ) {
                        const normalizedOperationSettingList =
                            normalizeOperationSettingList(
                                operationSettingsTemplates
                            );
                        dispatch(
                            operationSettingsActions.SetOperationSettings(
                                operationSettingsTemplates
                            )
                        );
                        handleAccessValidation(
                            knownAction.subscriptionId,
                            operationSettingsTemplates as OperationSettingTemplate[]
                        );
                    }
                })
                .catch((error) => {
                    console.error('GET_OPERATION_SETTINGS error', error);
                });
            break;
        case apiConstants.CREATE_OPERATION_SETTINGS:
            await operationSettingsService
                .CreateOperationSetting(
                    knownAction.subscriptionId,
                    knownAction.templateId,
                    knownAction.operationSettingCommand
                )
                .then((response) => {
                    if (response != undefined) {
                        var newOperation = response as OperationSettingDTO;
                        if (newOperation) {
                            dispatch(
                                operationSettingsActions.SetNewOperationSettings(
                                    newOperation
                                )
                            );
                        }
                    }
                });
            break;
        case apiConstants.UPDATE_OPERATION_SETTINGS:
            await operationSettingsService.UpdateOperationSetting(
                knownAction.subscriptionId,
                knownAction.templateId,
                knownAction.operationSettingId,
                knownAction.operationSettingCommand
            );
            break;
        case apiConstants.UPDATE_OPERATION_CODE_DISPLAY_FORMAT:
            await operationSettingsService.UpdateOperationCodeDisplayFormat(
                knownAction.subscriptionId,
                knownAction.templateId,
                knownAction.operationCodeFormat
            );
            break;
        case apiConstants.DELETE_OPERATION_SETTINGS:
            await operationSettingsService.DeleteOperationSetting(
                knownAction.subscriptionId,
                knownAction.templateId,
                knownAction.operationSettingIds
            );
            break;
    }
};

export default operationSettingsMiddleware;
