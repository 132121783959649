import { CircularProgress, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { CompanyInfo } from 'src/redux/Companies/interfaces';
import { AddressDTO } from 'src/services/Dtos/AddressDTO';
import styled from 'styled-components';
import { Guid } from 'guid-typescript';
import { InputTextField } from 'src/components/Input';
import { useParams } from 'react-router';
import { history } from 'src/routing';
import { NOT_FOUND } from 'src/redux/Projects/interfaces';
import { Scope } from 'src/constants/enums';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { isGuidEmpty } from 'src/helpers/utils';

export type Props = {
    cancelCallback: (() => void) | undefined;
    createdCompanyCallback: ((newCompanyId?: string) => void) | undefined;
    selectedCompany: CompanyInfo | undefined | typeof NOT_FOUND;
    subscriptionId: Guid | undefined;
};

export type Actions = {
    createCompany: (
        subscriptionId: Guid,
        companyInfo: CompanyInfo,
        createdCompanyCallback: (newCompanyId?: string) => void
    ) => void;
    getCompany: (subscriptionId: Guid, guid: string) => void;
    resetSelectedCompany: () => void;
    updateCompany: (
        subscriptionId: Guid,
        companyInfo: CompanyInfo,
        updatedCompanyCallback: (companyId?: string) => void
    ) => void;
};

const StyledButtonContainer = styled.div`
    margin: 8px;
`;

const CreateEditCompany = ({
    selectedCompany,
    subscriptionId,
    cancelCallback,
    createCompany,
    createdCompanyCallback,
    getCompany,
    resetSelectedCompany,
    updateCompany,
}: Props & Actions) => {
    const NavigateTo = (path: string) => {
        history.push(path);
    };
    const createCompanies = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId?.toString() ?? '',
        allowed: 'createCompanies',
    });
    if (!createCompanies) NavigateTo('/error/not-authorized-resource');

    const { companyGuid } = useParams<{
        companyGuid: string;
    }>();
    const isCreatingCompany = companyGuid == undefined;

    const _createEditCompanyCallback = (companyId?: string) => {
        setCreateOrEditInsProgress(false);
        if (companyId !== undefined) {
            if (subscriptionId)
                NavigateTo(`/subscriptions/${subscriptionId}/companies`);
        }
    };
    const usedCompanyCallback = createdCompanyCallback
        ? (newCompanyId?: string) => {
              setCreateOrEditInsProgress(false);
              createdCompanyCallback(newCompanyId);
          }
        : _createEditCompanyCallback;

    const usedCancelCallback = cancelCallback
        ? cancelCallback
        : () =>
              subscriptionId &&
              NavigateTo(`/subscriptions/${subscriptionId}/companies`);

    const [newCompanyName, setNewCompanyName] = useState('');
    const [companyAddressId, setCompanyAddressId] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [addressName, setAddressName] = useState('');
    const [companyNameIsBad, setCompanyNameIsBad] = useState(false);
    const [createOrEditInProgress, setCreateOrEditInsProgress] =
        useState(false);

    useEffect(() => {
        if (!isCreatingCompany) resetSelectedCompany();
    }, []);

    useEffect(() => {
        if (
            subscriptionId &&
            !isCreatingCompany &&
            !isGuidEmpty(subscriptionId)
        ) {
            getCompany(subscriptionId, companyGuid);
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (selectedCompany && !isCreatingCompany) {
            const selectedAsCompanyInfo = selectedCompany as CompanyInfo;
            setNewCompanyName(
                selectedAsCompanyInfo.name ? selectedAsCompanyInfo.name : ''
            );
            if (selectedAsCompanyInfo.address) {
                setAddressLine1(
                    selectedAsCompanyInfo.address.addressLine1
                        ? selectedAsCompanyInfo.address.addressLine1
                        : ''
                );
                setCity(
                    selectedAsCompanyInfo.address.city
                        ? selectedAsCompanyInfo.address.city
                        : ''
                );
                setState(
                    selectedAsCompanyInfo.address.state
                        ? selectedAsCompanyInfo.address.state
                        : ''
                );
                setCountry(
                    selectedAsCompanyInfo.address.country
                        ? selectedAsCompanyInfo.address.country
                        : ''
                );
                setCompanyAddressId(
                    selectedAsCompanyInfo.address.addressId.toString()
                        ? selectedAsCompanyInfo.address.addressId.toString()
                        : ''
                );
                setAddressName(
                    selectedAsCompanyInfo.address.name
                        ? selectedAsCompanyInfo.address.name
                        : ''
                );
            }
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (validateCompanyName(newCompanyName)) {
            setCompanyNameIsBad(false);
        }
    }, [newCompanyName]);

    const handleCreateCompanyClick = () => {
        if (newCompanyName) {
            const companyInfo = {
                name: newCompanyName,
                address: {
                    addressId:
                        companyAddressId && companyAddressId != Guid.EMPTY
                            ? companyAddressId
                            : Guid.EMPTY,
                    addressLine1: addressLine1,
                    city: city,
                    state: state,
                    country: country,
                    name: addressName,
                } as AddressDTO,
            } as CompanyInfo;

            setCreateOrEditInsProgress(true);
            if (isCreatingCompany) {
                createCompany(
                    subscriptionId as Guid,
                    companyInfo,
                    usedCompanyCallback
                );
            } else {
                companyInfo.id = Guid.parse(companyGuid);
                updateCompany(
                    subscriptionId as Guid,
                    companyInfo,
                    usedCompanyCallback
                );
            }
        } else {
            setCompanyNameIsBad(true);
        }
    };

    const validateCompanyName = (companyName: string) => {
        return (
            companyName != null && companyName != undefined && companyName != ''
        );
    };

    return (
        <React.Fragment>
            {(!isCreatingCompany && !selectedCompany) || !subscriptionId ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Typography variant="h3" style={{ marginRight: '20px' }}>
                        Loading...
                    </Typography>
                    <CircularProgress color="secondary" />
                </div>
            ) : !isCreatingCompany && selectedCompany == NOT_FOUND ? (
                <Typography variant="h6" style={{ marginRight: '20px' }}>
                    {'Copmany with id ' +
                        companyGuid +
                        " wasn't found on the server"}
                </Typography>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            marginTop: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: '670px',
                            minWidth: '400px',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{ maxWidth: 'fit-content' }}
                        >
                            {isCreatingCompany
                                ? 'Add new company'
                                : (selectedCompany as CompanyInfo).name +
                                  ' @' +
                                  addressName}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        margin: '4px',
                                    }}
                                >
                                    Company
                                </Typography>
                                <InputTextField
                                    id="company-name"
                                    label="Company name"
                                    value={newCompanyName}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setNewCompanyName(e.target.value)}
                                    required
                                    error={companyNameIsBad}
                                    helperText={
                                        companyNameIsBad
                                            ? 'Company name is required'
                                            : ''
                                    }
                                    disabled={createOrEditInProgress}
                                />
                            </Paper>
                            <Paper
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '8px',
                                }}
                            >
                                <Typography
                                    style={{
                                        margin: '4px',
                                    }}
                                >
                                    Company address
                                </Typography>
                                <InputTextField
                                    id="address-name"
                                    label="Location name"
                                    value={addressName}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setAddressName(e.target.value)}
                                    disabled={createOrEditInProgress}
                                />
                                <InputTextField
                                    id="address-line-1"
                                    label="Address"
                                    value={addressLine1}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setAddressLine1(e.target.value)}
                                    disabled={createOrEditInProgress}
                                />
                                <InputTextField
                                    id="address-city"
                                    label="City"
                                    value={city}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setCity(e.target.value)}
                                    disabled={createOrEditInProgress}
                                />
                                <InputTextField
                                    id="address-state"
                                    label="State"
                                    value={state}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setState(e.target.value)}
                                    disabled={createOrEditInProgress}
                                />
                                <InputTextField
                                    id="address-country"
                                    label="Country"
                                    value={country}
                                    onChange={(
                                        e: React.ChangeEvent<
                                            | HTMLInputElement
                                            | HTMLTextAreaElement
                                        >
                                    ) => setCountry(e.target.value)}
                                    disabled={createOrEditInProgress}
                                />
                            </Paper>
                            <StyledButtonContainer>
                                <Button
                                    colorScheme="secondary"
                                    onClick={usedCancelCallback}
                                    disabled={createOrEditInProgress}
                                >
                                    Cancel
                                </Button>
                            </StyledButtonContainer>
                            <StyledButtonContainer>
                                <Button
                                    onClick={() => {
                                        handleCreateCompanyClick();
                                    }}
                                    $loading={createOrEditInProgress}
                                >
                                    {isCreatingCompany
                                        ? 'Create company'
                                        : 'Save changes'}
                                </Button>
                            </StyledButtonContainer>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};
export default CreateEditCompany;
