import { Guid } from 'guid-typescript';
import React, { ReactElement } from 'react';
import { Scope } from 'src/constants/enums';
import useValidateAccess from 'src/hooks/useValidateAccess';

export type Props = {
    scope: Scope;
    id: string | Guid | undefined;
    allowedPermission: string;
    children: ReactElement<React.ReactNode>;
};

const AccessControl = (props: Props) => {
    const permitted = useValidateAccess({
        scope: props.scope,
        id: props.id,
        allowed: props.allowedPermission,
    });

    if (!permitted) return null;

    return <>{props.children}</>;
};

export default AccessControl;
