import { useState, useEffect } from 'react';
import { useTranslation } from 'src/hooks';
import { InputLabel, DefaultAutocomplete } from 'src/components/Input';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import GlobalAccessSelector from './GlobalAccessSelector';
import ProjectAccessSelector from './ProjectAccessSelector';
import { UsersService } from 'src/services';
import { Button } from '@ndc/react-component-library';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { User } from 'src/services/interfaces';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-start;
    margin-top: 40px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
`;

export type Props = {
    subscriptionId: string;
    resources: ProjectInfo[] | SubscriptionInfo[];
    scopeType: 'Global' | 'Project';
    user: User;
    addedUserPermissionsCallback: (
        scopeType: string,
        userName: string,
        resourceName: string,
        error?: string
    ) => void;
};

export type Actions = {};

const AddUserRoles = ({
    subscriptionId,
    resources,
    scopeType,
    user,
    addedUserPermissionsCallback,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const isGlobalScope = scopeType == 'Global';
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [showMissingProjectError, setShowMissingProjectError] =
        useState<boolean>(false);
    const [showMissingRolesError, setShowMissingRolesError] =
        useState<boolean>(false);
    const [userPermissionsAreBeingUpdated, setUserPermissionsAreBeingUpdated] =
        useState<boolean>(false);
    const [selectedProjects, setSelectedProjects] = useState<ProjectInfo[]>([]);

    useEffect(() => {
        setShowMissingRolesError(false);
    }, [selectedRoles]);

    useEffect(() => {
        setShowMissingProjectError(false);
    }, [selectedProjects]);

    const addUserRoles = (selectedRoles: string[], scope: string) => {
        const usersService = new UsersService();
        const email = user.email;

        return Promise.all(
            selectedRoles.map((roleId) =>
                usersService.addRoleAssignmentAndSendInvite(
                    scope,
                    roleId,
                    email
                )
            )
        );
    };

    const validateAndSaveUser = () => {
        if (userPermissionsAreBeingUpdated) return;

        let validationPassed = true;

        if (selectedRoles.length == 0) {
            setShowMissingRolesError(true);
            validationPassed = false;
        }

        if (
            !isGlobalScope &&
            resources.length > 1 &&
            selectedProjects.length == 0
        ) {
            setShowMissingProjectError(true);
            validationPassed = false;
        }

        if (validationPassed) {
            const scopesToAdd: string[] = [];
            const selectedResources: ProjectInfo[] | SubscriptionInfo[] =
                resources.length == 1 ? resources : selectedProjects;
            const resourceNames: string[] = [];
            if (isGlobalScope) {
                (selectedResources as SubscriptionInfo[]).forEach(
                    (subscription) => {
                        scopesToAdd.push(
                            '/subscriptions/' + subscription.subscriptionId
                        );
                        resourceNames.push(subscription.displayName);
                    }
                );
            } else {
                (selectedResources as ProjectInfo[]).forEach((project) => {
                    scopesToAdd.push(
                        '/subscriptions/' +
                            subscriptionId +
                            '/projects/' +
                            project.id
                    );
                    resourceNames.push(project.commissioningProject);
                });
            }

            const userActionPromises = scopesToAdd.map((scope) =>
                addUserRoles(selectedRoles, scope)
            );

            setUserPermissionsAreBeingUpdated(true);
            const resourceName =
                resourceNames.length > 1
                    ? getTranslation('General.Project').toLowerCase()
                    : resourceNames[0];
            Promise.all(userActionPromises)
                .then(() => {
                    setUserPermissionsAreBeingUpdated(false);
                    addedUserPermissionsCallback(
                        scopeType,
                        user.name,
                        resourceName
                    );
                })
                .catch((error) => {
                    setUserPermissionsAreBeingUpdated(false);
                    addedUserPermissionsCallback(
                        scopeType,
                        user.name,
                        resourceName,
                        error
                    );
                });
        }
    };

    const renderResourceSelector = () => {
        if (isGlobalScope)
            return (
                <Typography data-testid="resource-name">
                    {(resources[0] as SubscriptionInfo).displayName}
                </Typography>
            );
        if (resources.length > 1)
            return (
                <DefaultAutocomplete
                    items={resources as ProjectInfo[]}
                    label={getTranslation(
                        translationKeys.Users_ProjectPermissions_Label
                    )}
                    onChange={(projects: ProjectInfo[]) =>
                        setSelectedProjects(projects)
                    }
                    multiple={true}
                    getItemText={(project: ProjectInfo) =>
                        project.commissioningProject
                    }
                    helpMessage={getTranslation(
                        translationKeys.Settings_AddProjects
                    )}
                    noOptionsText={getTranslation(
                        translationKeys.General_NoProjects
                    )}
                    error={showMissingProjectError}
                    errorText={getTranslation(
                        translationKeys.Settings_AddProjects_ErrorText
                    )}
                />
            );
        return (
            <>
                <InputLabel
                    label={getTranslation(
                        translationKeys.Users_ProjectPermissions_Label
                    )}
                />
                <Typography data-testid="resource-name">
                    {(resources[0] as ProjectInfo).commissioningProject}
                </Typography>
            </>
        );
    };

    const renderAccessModule = () => {
        const props = {
            setSelectedRoles,
            defaultSelectedRoles: selectedRoles,
            showMissingRolesError,
        };

        return isGlobalScope ? (
            <GlobalAccessSelector {...props} />
        ) : (
            <ProjectAccessSelector {...props} />
        );
    };

    return (
        <Container>
            <Container>
                {renderResourceSelector()}
                {renderAccessModule()}
            </Container>
            <ButtonContainer>
                <Button
                    data-testid="invite-user-button"
                    key={'inviteUserButton'}
                    onClick={validateAndSaveUser}
                    disabled={userPermissionsAreBeingUpdated}
                >
                    {getTranslation(
                        translationKeys.AddUserPermissions_Apply_Label,
                        {
                            scopeType,
                            n: selectedProjects.length,
                        }
                    )}
                </Button>
                {userPermissionsAreBeingUpdated && (
                    <CircularProgress size="35px" color="secondary" />
                )}
            </ButtonContainer>
        </Container>
    );
};

export default AddUserRoles;
