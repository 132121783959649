import { useState, useEffect } from 'react';
import { AccessValidatorService } from 'src/services';
import { AccessValidatorQuery } from 'src/services/interfaces';

const createProductAccessValidatorQuery = () => {
    return {
        allowed: `GET:/api/v1.0/subscriptions`,
    };
};

interface AccessResponse {
    product: {
        allowed: boolean;
    };
}

const useValidateProductAccess = () => {
    const [loading, setLoading] = useState(true);
    const [allowed, setAllowed] = useState<boolean>();
    const accessValidatorService = new AccessValidatorService();

    let accessValidatorQuery: AccessValidatorQuery = {};

    accessValidatorQuery['product'] = createProductAccessValidatorQuery();
    useEffect(() => {
        accessValidatorService
            .validateAccess(accessValidatorQuery)
            .then((accessValidatorResult) => {
                const response =
                    accessValidatorResult as unknown as AccessResponse;
                setAllowed(response.product.allowed);
                setLoading(false);
            });
    }, []);
    return [loading, allowed];
};

export default useValidateProductAccess;
