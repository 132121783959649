export const pointSpritesJson = JSON.parse(`{
"frames": {
	"originalPoint_0_0.png": {
		"frame": {"x":19, "y":0, "w":18, "h":18},
		"spriteSourceSize": {"x":3,"y":3,"w":24,"h":24},
		"sourceSize": {"w":24,"h":24}
	},
	"originalPoint_0_1.png": {
		"frame": {"x":19, "y":19, "w":18, "h":18},
		"spriteSourceSize": {"x":3,"y":3,"w":24,"h":24},
		"sourceSize": {"w":24,"h":24}
	},
	"originalPoint_1_0.png": {
		"frame": {"x":19, "y":0, "w":18, "h":18},
		"spriteSourceSize": {"x":3,"y":3,"w":24,"h":24},
		"sourceSize": {"w":24,"h":24}
	},
	"updatedPoint_0_0.png": {
		"frame": {"x":0, "y":19, "w":18, "h":18},
		"spriteSourceSize": {"x":3,"y":3,"w":24,"h":24},
		"sourceSize": {"w":24,"h":24}
	},
	"updatedPoint_0_1.png": {
		"frame": {"x":0, "y":0, "w":18, "h":18},
		"spriteSourceSize": {"x":3,"y":3,"w":24,"h":24},
		"sourceSize": {"w":24,"h":24}
	},
	"updatedPoint_1_0.png": {
		"frame": {"x":0, "y":19, "w":18, "h":18},
		"spriteSourceSize": {"x":3,"y":3,"w":24,"h":24},
		"sourceSize": {"w":24,"h":24}
	}

},
"meta": {
	"image": "pointSprites.png",
	"size": {"w": 38, "h": 38},
	"scale": "1"
}
}`);
