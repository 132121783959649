import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../Button';
import { InputTextField } from '../../Input';

const StyledButtonContainer = styled.div`
    margin: 8px;
`;

export type Props = {
    defaultButtonLabel: string | undefined;
    headerText: string | undefined;
    sections: any[];
    createEditInProgress?: boolean;
};

export type Actions = {
    defaultButtonAction: Function;
    onCancelCallback?: Function;
};

const CreateEditComponent = ({
    defaultButtonLabel,
    headerText,
    sections,
    defaultButtonAction,
    onCancelCallback,
    createEditInProgress = false,
}: Props & Actions) => {
    const renderSections = () => {
        let sectionsToRender = [];

        for (let section of sections) {
            let inputs = [];
            for (let inputField of section.inputFields) {
                if (inputField.type == 'textfield') {
                    const newInput = (
                        <InputTextField
                            key={inputField.id}
                            id={inputField.id}
                            label={inputField.inputLabel}
                            value={inputField.value}
                            onChange={inputField.onChange}
                            required={inputField.required}
                            error={inputField.error}
                            helperText={inputField.helperText}
                            disabled={createEditInProgress}
                        />
                    );
                    inputs.push(newInput);
                } else {
                    inputs.push(inputField.element);
                }
            }
            let sectionTitle = (
                <Typography
                    style={{
                        margin: '4px',
                    }}
                    key={section.title + '-title'}
                >
                    {section.title}
                </Typography>
            );
            sectionsToRender.push(sectionTitle);
            sectionsToRender.push(inputs);
        }

        return sectionsToRender;
    };

    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        marginTop: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '670px',
                        minWidth: '400px',
                    }}
                >
                    {headerText ? (
                        <Typography variant="h3">{headerText}</Typography>
                    ) : null}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Paper
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '8px',
                            }}
                        >
                            {renderSections()}
                        </Paper>
                        {onCancelCallback && (
                            <StyledButtonContainer>
                                <Button
                                    onClick={() => {
                                        onCancelCallback();
                                    }}
                                    colorScheme="secondary"
                                    disabled={createEditInProgress}
                                >
                                    Cancel
                                </Button>
                            </StyledButtonContainer>
                        )}

                        <StyledButtonContainer>
                            <Button
                                onClick={() => {
                                    defaultButtonAction();
                                }}
                                $loading={createEditInProgress}
                            >
                                {defaultButtonLabel
                                    ? defaultButtonLabel
                                    : 'Confirm'}
                            </Button>
                        </StyledButtonContainer>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CreateEditComponent;
