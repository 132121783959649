import * as PIXI from 'pixi.js';
import { pointSpritesJson } from 'src/assets/sprites/pointSprites';
export const setupTextureMaps = async (
    resources: PIXI.utils.Dict<PIXI.LoaderResource>
) => {
    let pointSheet;
    if (resources.pointSpritesPng.texture) {
        pointSheet = new PIXI.Spritesheet(
            resources.pointSpritesPng.texture,
            pointSpritesJson
        );
        await pointSheet.parse();
    }
    return {
        pointTextures: pointSheet?.textures,
    };
};
