import { Guid } from 'guid-typescript';
import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';
import {
    NOT_FOUND,
    ProjectInfo,
    ProjectOverview,
} from '../redux/Projects/interfaces';
import api from './api';
import { ProjectDTO } from './Dtos';
import { OperationSettingTemplate } from './Dtos/OperationSettingTemplate';

export default class ProjectService {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    GetProjects(subscriptionId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<ProjectDTO[]>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to load projects');
                throw error;
            });
        });
    }

    GetProject(subscriptionId: Guid, projectId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<ProjectInfo>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId.toString()}`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                if (error.status == 404) {
                    return NOT_FOUND;
                }
                alert('Failed to load project');
                throw error;
            });
        });
    }
    getProjectRoleAssignments(subscriptionId: Guid, projectId: Guid) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            return api({
                url: `${backends.projectApi}/api/subscriptions/${subscriptionId}/projects/${projectId}/roleAssignments`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to get project role assignment');
                throw error;
            });
        });
    }

    CreateProject(subscriptionId: Guid, project: ProjectOverview) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(project),
            };
            return api<ProjectOverview>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects`,
                method: 'POST',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to create project');
                throw error;
            });
        });
    }
    EditProject = (
        subscriptionId: Guid,
        project: ProjectOverview
    ): Promise<ProjectOverview> => {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(project),
            };

            return api<ProjectOverview>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${
                    project.projectId
                }`,
                method: 'PUT',
                options,
            })
                .then((response) => {
                    if (typeof response === 'string') {
                        throw new Error('Unexpected response type');
                    }
                    return response as ProjectOverview;
                })
                .catch((error) => {
                    if (error.status === 403) {
                        throw error;
                    }
                    alert('Failed to update project');
                    throw error;
                });
        });
    };

    DeleteProject(subscriptionId: Guid, projectId: string) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            return api<string>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects?projectId=${projectId.toString()}`,
                method: 'DELETE',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to delete project');
                throw error;
            });
        });
    }

    GetLayoutFileDownloadLink(
        subscriptionId: Guid,
        projectId: Guid,
        layoutId: Guid,
        guid: Guid
    ) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<string>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId}/layouts/${layoutId}/files/${guid.toString()}/action/downloaduri`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                if (error.status == 404) {
                    return NOT_FOUND;
                }
                throw error;
            });
        });
    }

    GetProjectOperationSettings(subscriptionId: Guid, projectId: string) {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<OperationSettingTemplate[]>({
                url: `${backends.projectApi}/api${
                    backends.projectApiVersion
                }/subscriptions/${subscriptionId.toString()}/projects/${projectId}/operationsettingstemplates`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                alert('Failed to retrieve operation settings');
                throw error;
            });
        });
    }
}
