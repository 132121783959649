import { Guid } from 'guid-typescript';
import { ModalObj } from 'src/redux/Modals/interfaces';
import Modal from './Modal';

export type Props = {
    modals: ModalObj[];
};

export type Actions = {
    close: (modalId: Guid) => void;
};

const Modals = ({ modals, close }: Props & Actions) => {
    const activeModal = modals[modals.length - 1];
    return !!activeModal ? (
        <Modal
            modalObj={activeModal}
            onClose={(obj: ModalObj) => close(obj.id)}
        />
    ) : null;
};

export default Modals;
