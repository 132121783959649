import { merge } from 'lodash';
import { MsalMinimalSilentRequestConfig } from 'src/auth/apiScopes';
import { getTokenPopup } from 'src/auth/authUtils';
import { history } from 'src/routing';
import { isDevelopment } from '../helpers/isDevelopment';

const handleError = (response: Response) => {
    if (!response.ok) {
        throw response;
    }
    return response;
};
type OptionsType = {
    method: string;
    headers: { [key: string]: string };
};

export default <T>({
    url,
    options = {},
    method = 'GET',
    requestConfig,
}: {
    url: RequestInfo;
    options?: any;
    method: string;
    requestConfig?: MsalMinimalSilentRequestConfig;
}) => {
    const fetchPromise = (accessToken?: string | void) => {
        let defaultOptions: OptionsType = {
            method,
            headers: {
                Accept: 'application/json',
                'x-api-version': '1.0',
            },
        };
        if (accessToken)
            defaultOptions.headers = {
                ...defaultOptions.headers,
                Authorization: `Bearer ${accessToken}`,
            };

        const mergedOptions = merge(defaultOptions, options);

        return fetch(url, mergedOptions)
            .then(handleError)
            .then((response) => response.text())
            .then((data) => {
                if (!data) return undefined;

                try {
                    return JSON.parse(data) as T;
                } catch (error) {
                    return data;
                }
            })
            .catch((error) => {
                if (isDevelopment) {
                    console.error(error);
                }
                if (error.status == 403) {
                    history.push('/error/not-authorized-resource');
                }
                throw error;
            });
    };

    if (requestConfig) {
        return getTokenPopup(requestConfig).then(fetchPromise);
    } else return fetchPromise();
};
