import * as accountConstants from './accountConstants';
import { AccountInfo } from '@azure/msal-browser';

export interface SetAccountAction {
    type: typeof accountConstants.SET_ACCOUNT_INFO;
    accountInfo: AccountInfo | undefined;
}

export const SetAccountInfo = (accountInfo: AccountInfo | undefined) =>
    ({
        type: accountConstants.SET_ACCOUNT_INFO,
        accountInfo,
    } as SetAccountAction);

export type KnownAction = SetAccountAction;
