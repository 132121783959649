import { getTokenPopup } from 'src/auth/authUtils';
import { customApiScopes } from 'src/auth/apiScopes';
import { getGlobalRoleContext, getProjectRoleContext } from 'src/helpers/roles';
import AccessLevelType from 'src/constants/AccessLevelType';
import { UserInfo } from './interfaces';
import { backends } from '../common/backends';
import api from './api';
import { formatRoleAssignments } from 'src/helpers/roles';
import { RoleAssignmentDTO } from './Dtos';

export interface RoleContext {
    scopes: string[];
    roleId: string;
}

export default class UsersService {
    _createAndOrInviteUser = async (
        subscriptionId: string,
        email: string,
        roleId: string,
        projectIds?: string[],
        accessToken?: string
    ) => {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        let route: string;

        if (projectIds && projectIds.length > 0) {
            route = `${backends.projectApi}/api/subscriptions/${subscriptionId}/projects/${projectIds[0]}/roleassignments/action/userinvite`;
        } else {
            route = `${backends.projectApi}/api/subscriptions/${subscriptionId}/roleassignments/action/userinvite`;
        }

        const options: any = {
            body: JSON.stringify({ email, roleId }),
            headers: { 'Content-Type': 'application/json' },
        };
        if (accessToken)
            options.headers = {
                ...options.headers,
                Authorization: 'Bearer ' + accessToken,
            };

        return api({
            url: route,
            options,
            method: 'post',
            requestConfig: accessToken == undefined ? requestConfig : undefined,
        });
    };

    addRoleAssignment = (
        scope: string,
        roleId: string,
        principalId: string,
        accessToken?: string
    ) => {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        const route = `${backends.projectApi}/api/${scope.substring(
            1
        )}/roleassignments`;
        const options: any = {
            body: JSON.stringify({ roleId, principalId }),
            headers: { 'Content-Type': 'application/json' },
        };
        if (accessToken)
            options.headers = {
                ...options.headers,
                Authorization: 'Bearer ' + accessToken,
            };

        return api({
            url: route,
            options,
            method: 'post',
            requestConfig: accessToken == undefined ? requestConfig : undefined,
        });
    };

    addRoleAssignmentAndSendInvite = (
        scope: string,
        roleId: string,
        email: string,
        accessToken?: string
    ) => {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        const route = `${backends.projectApi}/api/${scope.substring(
            1
        )}/roleassignments/action/userinvite`;
        const options: any = {
            body: JSON.stringify({ roleId, email }),
            headers: { 'Content-Type': 'application/json' },
        };
        if (accessToken)
            options.headers = {
                ...options.headers,
                Authorization: 'Bearer ' + accessToken,
            };

        return api({
            url: route,
            options,
            method: 'post',
            requestConfig: accessToken == undefined ? requestConfig : undefined,
        });
    };

    removeRoleAssignment = (
        scope: string,
        roleAssignmentId: string,
        accessToken?: string
    ) => {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        const route = `${backends.projectApi}/api/${scope.substring(
            1
        )}/roleassignments/${roleAssignmentId}`;
        const options: any = {
            headers: { 'Content-Type': 'application/json' },
        };

        if (accessToken)
            options.headers = {
                ...options.headers,
                Authorization: 'Bearer ' + accessToken,
            };

        return api({
            url: route,
            options,
            method: 'DELETE',
            requestConfig: accessToken == undefined ? requestConfig : undefined,
        });
    };

    inviteNewUser = async (subscriptionId: string, userInfo: UserInfo) => {
        const requestConfig = customApiScopes.projectApiRequestConfig;
        const accessToken = (await getTokenPopup(requestConfig)) as string;

        const roleContexts: RoleContext[] =
            userInfo.accessLevel == AccessLevelType.Global
                ? userInfo.roles.map((role) =>
                      getGlobalRoleContext(subscriptionId, role)
                  )
                : userInfo.roles.map((role) =>
                      getProjectRoleContext(
                          subscriptionId,
                          userInfo.projects ?? [],
                          role
                      )
                  );
        let roleAssignment: RoleAssignmentDTO | unknown =
            await this._createAndOrInviteUser(
                subscriptionId,
                userInfo.email,
                roleContexts[0].roleId,
                userInfo.projects,
                accessToken
            );
        let userId: string | unknown;
        if (
            typeof roleAssignment === 'object' &&
            roleAssignment !== null &&
            'userOrGroupId' in roleAssignment
        )
            userId =
                (roleAssignment as RoleAssignmentDTO).userOrGroupId ??
                undefined;
        if (!userId) throw Error;
        let addRolePromises: Promise<any>[] = [];

        roleContexts.forEach((roleContext) =>
            roleContext.scopes.forEach((scope) => {
                const ra = roleAssignment as RoleAssignmentDTO;
                if (
                    roleContext.roleId.toLocaleLowerCase() ===
                        ra.roleId.toLocaleLowerCase() &&
                    scope.toLocaleLowerCase() === ra.scope.toLocaleLowerCase()
                )
                    return;
                addRolePromises.push(
                    this.addRoleAssignment(
                        scope,
                        roleContext.roleId,
                        userId as string,
                        accessToken
                    )
                );
            })
        );

        await Promise.all(addRolePromises);

        return true;
    };
}
