import { Guid } from 'guid-typescript';
import * as subscriptionConstants from './subscriptionConstants';
import {
    SubscriptionInfo,
    SubscriptionList,
    Subscriptions,
    EulaState,
} from './interfaces';

export interface SetSubscriptionListAction {
    type: typeof subscriptionConstants.SET_SUBSCRIPTION_LIST;
    subscriptionList: SubscriptionList<SubscriptionInfo>;
}

export const SetSubscriptionList = (
    subscriptionList: SubscriptionList<SubscriptionInfo>
) =>
    ({
        type: subscriptionConstants.SET_SUBSCRIPTION_LIST,
        subscriptionList,
    } as SetSubscriptionListAction);

export interface SetSubscriptionsAction {
    type: typeof subscriptionConstants.SET_SUBSCRIPTIONS;
    subscriptions: Subscriptions;
}

export const SetSubscriptions = (subscriptions: Subscriptions) => ({
    type: subscriptionConstants.SET_SUBSCRIPTIONS,
    subscriptions,
});

export interface SetCurrrentSubscriptionAction {
    type: typeof subscriptionConstants.SET_CURRENT_SUBSCRIPTION;
    currentSubscription: SubscriptionInfo;
}

export const SetCurrrentSubscription = (
    currentSubscription: SubscriptionInfo
) => ({
    type: subscriptionConstants.SET_CURRENT_SUBSCRIPTION,
    currentSubscription,
});

export interface SetSubscriptionRoleAssignmentsAction {
    type: typeof subscriptionConstants.SET_SUBSCRIPTION_ROLE_ASSIGNMENTS;
    roleAssignments: any[];
}

export const SetSubscriptionRoleAssignments = (roleAssignments: any[]) => ({
    type: subscriptionConstants.SET_SUBSCRIPTION_ROLE_ASSIGNMENTS,
    roleAssignments,
});

export interface SetEulaStatusAction {
    type: typeof subscriptionConstants.SET_EULA_STATUS;
    eula: EulaState;
}

export const SetEulaStatus = (eula: EulaState) =>
    ({
        type: subscriptionConstants.SET_EULA_STATUS,
        eula,
    } as SetEulaStatusAction);

export interface SetEulaAction {
    type: typeof subscriptionConstants.SET_EULA;
    subscriptionId: Guid;
    setEulaCallback: (status?: boolean) => void;
}

export const SetEula = (
    subscriptionId: Guid,
    setEulaCallback: (status?: boolean) => void
) =>
    ({
        type: subscriptionConstants.SET_EULA,
        subscriptionId,
        setEulaCallback,
    } as SetEulaAction);

export type KnownAction =
    | SetSubscriptionListAction
    | SetSubscriptionRoleAssignmentsAction
    | SetSubscriptionsAction
    | SetCurrrentSubscriptionAction
    | SetEulaStatusAction
    | SetEulaAction;
