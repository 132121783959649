const syncServiceApiScopes = (
    process.env.REACT_APP_SYNC_SERVICE_API_SCOPES || 'missing-scopes'
).split(' ');
const projectApiServiceScopes = (
    process.env.REACT_APP_PROJECT_API_SCOPES || 'missing-scopes'
).split(' ');

export interface MsalMinimalSilentRequestConfig {
    scopes: Array<string>;
    claims?: string;
    autority?: string;
    forceRefresh?: boolean;
    redirectUri?: string;
}

export interface CustomApiScopesConfig {
    syncServiceApiRequestConfig: MsalMinimalSilentRequestConfig;
    projectApiRequestConfig: MsalMinimalSilentRequestConfig;
}

export const customApiScopes: CustomApiScopesConfig = {
    syncServiceApiRequestConfig: {
        scopes: [...syncServiceApiScopes],
    },
    projectApiRequestConfig: {
        scopes: [...projectApiServiceScopes],
    },
};
