import { Tooltip, Typography } from '@material-ui/core';
import { InteractiveModule } from 'src/components/InteractiveModule';
import useTranslation from 'src/hooks/useTranslation';
import { formatTravelTimeDiffLabel } from 'src/helpers/utils';
import {
    Circle,
    PositionValue,
    SegmentInfoSection,
    StyledChip,
    StyledContainer,
    StyledDivider,
    StyledStatusRow,
    StyledTextRow,
} from './Styling';
import { InlineMessage } from 'src/components/InlineMessage';
import { useSelector } from 'react-redux';
import { getSelectedLayoutUpdateContainer } from 'src/redux/Projects/projectSelectors';
import { SegmentUpdateDTO } from 'src/services/Dtos/LayoutUpdate';
import { NOT_FOUND } from 'src/redux/Projects/interfaces';
import { Guid } from 'guid-typescript';
import dateOrTimeFormatter from 'src/helpers/dateFormatter';
import { useIntl } from 'react-intl';
import theme from 'src/theme';

type Segment = {
    id: number;
};
type segmentInfo = {
    travelTime?: number;
    templateId?: number;
    templateName?: string;
};
interface UpdatedSegment extends Segment {
    current: segmentInfo;
    project: segmentInfo;
    layoutUpdateId: Guid;
}
export type Props = {
    selectedSegment: { base: Segment; updated: UpdatedSegment | null };
    onCloseBehaviour: () => void;
};
const SelectedSegmentModule = ({
    selectedSegment,
    onCloseBehaviour,
}: Props) => {
    const getTranslation = useTranslation();
    const intl = useIntl();
    const isUpdated = !!selectedSegment.updated;
    const updates = useSelector(getSelectedLayoutUpdateContainer);
    const updatedSegmentInfo: SegmentUpdateDTO | null =
        !updates || updates === NOT_FOUND || !updates.segmentUpdates
            ? null
            : updates.segmentUpdates.find(
                  (o) =>
                      o.segmentId === selectedSegment.base.id &&
                      o.layoutUpdateId ===
                          selectedSegment.updated?.layoutUpdateId.toString()
              ) ?? null;

    const renderTravelTimeSection = (segment: UpdatedSegment) => {
        return (
            <SegmentInfoSection>
                <Typography variant="body1" color="textSecondary">
                    {getTranslation('SelectedSegmentModule.Segment.TravelTime')}
                </Typography>
                <PositionValue>
                    {getTranslation('General.Units.Seconds.Short', {
                        n: segment.current.travelTime?.toFixed(1),
                    })}
                </PositionValue>
                {segment.current.travelTime && segment.project.travelTime && (
                    <div>
                        <Tooltip
                            title={getTranslation(
                                'SelectedSegmentModule.Segment.TravelTime.Tooltip'
                            )}
                            enterDelay={theme.timings.tooltipEnterDelay}
                            arrow
                        >
                            <StyledChip
                                label={getTranslation(
                                    'General.Units.Seconds.Short',
                                    {
                                        n: formatTravelTimeDiffLabel(
                                            segment.current.travelTime -
                                                segment.project.travelTime
                                        ),
                                    }
                                )}
                            />
                        </Tooltip>
                    </div>
                )}
            </SegmentInfoSection>
        );
    };

    const renderSegmentTemplateSection = (segment: UpdatedSegment) => {
        const title = (
            <Typography variant="body1" color="textSecondary">
                {getTranslation('SelectedSegmentModule.Segment.Template')}
            </Typography>
        );
        if (
            (segment.current.templateId ?? 0) ===
            (segment.project.templateId ?? 0)
        )
            return (
                <SegmentInfoSection>
                    {title}
                    <Typography>
                        {getTranslation('General.NotChanged')}
                    </Typography>
                </SegmentInfoSection>
            );
        return (
            <SegmentInfoSection>
                {title}
                <Typography>{segment.current.templateName}</Typography>
                <div style={{ marginBottom: '13px' }}>
                    <StyledChip label={segment.project.templateName} />
                </div>
                <InlineMessage
                    variant="error"
                    messageText={getTranslation(
                        'SelectedSegmentModule.Segment.ChangedTemplate'
                    )}
                />
            </SegmentInfoSection>
        );
    };

    return (
        <InteractiveModule
            headerText={getTranslation('General.Segment')}
            onCloseBehaviour={onCloseBehaviour}
        >
            <StyledContainer>
                <Typography variant="subtitle1">{`${getTranslation(
                    'General.Segment'
                )} ${selectedSegment.base.id}`}</Typography>
                <StyledStatusRow>
                    <Circle updated={isUpdated} />
                    <Typography variant="body1">
                        {getTranslation(
                            isUpdated ? 'General.Updated' : 'General.NotUpdated'
                        )}
                    </Typography>
                </StyledStatusRow>
                {isUpdated &&
                    !!selectedSegment.updated?.current.travelTime &&
                    renderTravelTimeSection(selectedSegment.updated)}
                {isUpdated &&
                    !!selectedSegment.updated &&
                    renderSegmentTemplateSection(selectedSegment.updated)}
            </StyledContainer>
            {isUpdated && updatedSegmentInfo && (
                <>
                    <StyledDivider />
                    <StyledContainer>
                        <StyledTextRow>
                            <Typography variant="subtitle1">
                                {getTranslation('General.LastUpdated')}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {dateOrTimeFormatter(
                                    intl,
                                    updatedSegmentInfo.dateTimeUTC
                                )}
                            </Typography>
                        </StyledTextRow>
                        <StyledTextRow style={{ wordBreak: 'break-all' }}>
                            <Typography variant="body1" color="textSecondary">
                                {`${getTranslation('General.By')} ${
                                    updatedSegmentInfo.createdByUserEmail
                                }`}
                            </Typography>
                        </StyledTextRow>
                    </StyledContainer>
                </>
            )}
        </InteractiveModule>
    );
};

export default SelectedSegmentModule;
