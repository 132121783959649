import { apiActions } from 'src/redux/Api';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import { ProjectOverview } from 'src/redux/Projects/interfaces';
import { connect } from 'react-redux';
import { projectSelectors, projectActions } from 'src/redux/Projects';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import Project, { Props, Actions } from './components/Projects';
import { Guid } from 'guid-typescript';
import { modalActions } from 'src/redux/Modals';
import { ModalType } from 'src/constants/enums';
import { ReactNode } from 'react';

const mapStateToProps: (state: ApplicationState) => Props = (state) => {
    return {
        projects: projectSelectors.getProjects(state),
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
        subscriptions: subscriptionSelectors.getSubscriptions(state),
        selectedProject: projectSelectors.getSelectedProject(state),
    };
};

const mapDispatchToProps: Actions = {
    getProjects: (subscriptionId: Guid) =>
        apiActions.getProjects(subscriptionId),
    resetProjects: () => projectActions.ResetProjects(),
    EditProject: (
        subscriptionId: Guid,
        projectOverview: ProjectOverview,
        finishedCallback: (projectId?: string) => void
    ) =>
        apiActions.editProject(
            subscriptionId,
            projectOverview,
            finishedCallback
        ),
    DeleteProject: (
        subscriptionId: Guid,
        projectId: string,
        deleteCallback: (status: boolean) => void
    ) => apiActions.deleteProject(subscriptionId, projectId, deleteCallback),
    showModalWithContents: (id: Guid, heading: string, content: ReactNode) =>
        modalActions.OpenModal({
            id,
            heading,
            type: ModalType.Custom,
            content,
        }),
    GetProject: (subscriptionId: Guid, guid: string) =>
        apiActions.getProject(subscriptionId, Guid.parse(guid)),
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
