import * as PIXI from 'pixi.js';
import { IPointContainer, ISegmentContainer } from '../PixiApp';
export const getInteractionHits = (
    mPoint: PIXI.Point,
    objectList: IPointContainer | ISegmentContainer
) => {
    return new Promise<number[]>((resolve) => {
        let results: number[] = [];
        Object.entries(objectList).forEach(([key, value]) => {
            const item = value.object;
            if (item && item.container && item.container.visible)
                item.container.children.forEach(
                    (child: PIXI.Sprite | PIXI.Graphics) => {
                        if (child.interactive) {
                            let hit = false;
                            if (child.hitArea) {
                                let _tempPoint = new PIXI.Point();
                                child.worldTransform.applyInverse(
                                    mPoint,
                                    _tempPoint
                                );
                                hit = child.hitArea.contains(
                                    _tempPoint.x,
                                    _tempPoint.y
                                );
                            } else {
                                hit = child.containsPoint(mPoint);
                            }
                            if (hit) {
                                results.push(item.id);
                            }
                        }
                    }
                );
        });
        resolve(results);
    });
};
