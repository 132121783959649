export interface ValidatorResult {
    isValid: boolean;
    errorMessage: string;
}

export const validateSiteName = (siteName: string) => {
    let isValid = true;
    let errorMessage = '';
    if (!siteName) {
        isValid = false;
        errorMessage = "Site name can't be empty";
    }
    return { isValid, errorMessage } as ValidatorResult;
};
export const validateSystemName = (systemName: string) => {
    return validate8CharacterNames(systemName);
};

export const validateLayoutName = (layoutName: string) => {
    return validate8CharacterNames(layoutName);
};

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

/** Validation logic based on System Application Designer rules:
 *  The names are restricted to 8 characters, the first must be a letter. The following characters must be a letter, a digit, or an '_' (underscore).
 */
const validate8CharacterNames = (name: string) => {
    let isValid = true;
    let errorMessage = '';

    if (!name) {
        isValid = false;
        errorMessage = 'Name must be between 1 and 8 characters long';
    } else {
        const trimmedName = name.trim();
        const regexp = new RegExp('^[a-zA-Z0-9_]*$'); // TODO Verify naming validation. Are layout name with number first ok? new RegExp('^[a-zA-Z][a-zA-Z0-9_]*$');
        if (!regexp.test(trimmedName)) {
            isValid = false;
            errorMessage =
                'Name must only contain letters and numbers (beginning with a letter), and no spaces'; // TODO Better error messages, based on what is wrong
        } else if (trimmedName.length < 1 || trimmedName.length > 8) {
            isValid = false;
            errorMessage = 'Name must be between 1 and 8 characters long';
        }
    }

    return { isValid, errorMessage } as ValidatorResult;
};
