import { useState, useEffect, useRef } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppBar,
    CssBaseline,
    List,
    makeStyles,
    Toolbar,
    Button as MuiButton,
    MenuItem as MuiMenuItem,
    Menu,
    Tooltip,
} from '@material-ui/core';
import { Guid } from 'guid-typescript';
import { ExpandMore, ExpandLess, Check } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import useTranslation from 'src/hooks/useTranslation';
import { MenuItem } from '../../MenuItem';
import { history } from 'src/routing';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { Scope } from 'src/constants/enums';
import {
    subscriptionSelectors,
    subscriptionActions,
} from 'src/redux/Subscriptions';
import { modalActions } from 'src/redux/Modals';
import { ModalType } from 'src/constants/enums';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import SelectAccountModal from './SelectAccountModal';
import EulaModal from './EulaModal';
import styled from 'styled-components';
import theme from 'src/theme';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.measurements.drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            left: 0,
            width: theme.measurements.drawerWidth,
        },
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.primary.dark,
        width: 100 + '%',
    },
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        [theme.breakpoints.up('sm')]: {
            width: theme.measurements.drawerWidth,
        },
        border: '0px',
        background: theme.palette.primary.dark,
        color: '#fff',
        width: 100 + '%',
    },
    content: {
        flexGrow: 1,
    },
    header: {
        paddingLeft: '23px',
        marginRight: 'auto',
        marginTop: '25px',
        fontWeight: 700,
        display: 'flow',
    },
    titleContainer: {
        paddingLeft: '23px !important',
        marginRight: 'auto',
        fontWeight: 700,
    },
}));

const StyledMuiButton = styled(MuiButton)`
    ${({ theme }) => `
&& {
    color: ${theme.palette.primary.white};
}`}
`;

const StyledSubscriptionNameTextContainer = styled(Typography)`
    ${({ theme }) => `
    max-width: ${theme.spacing(40)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`}
`;

export type Props = {
    showSelectSubModal: boolean;
    showEulaModal: boolean;
    showSubSelection: (status?: boolean) => void;
};

function Sidebar({
    showSelectSubModal,
    showEulaModal,
    showSubSelection,
}: Props) {
    const getTranslation = useTranslation();
    const dispatch = useDispatch();
    const [activeModalId, setActiveModalId] = useState<Guid>(Guid.create());
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedSubscription, setSelectedSubscription] =
        useState<SubscriptionInfo>();
    const subscriptionId =
        useSelector(subscriptionSelectors.getSubscriptionId) ??
        Guid.createEmpty();
    const subscriptions =
        useSelector(subscriptionSelectors.getSubscriptions) ?? [];
    const currentSubscription =
        useSelector(subscriptionSelectors.getCurrentSubscription) ?? {};
    const [activeTab, setActiveTab] = useState(window.location.pathname);
    const readCompanies = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'readCompanies',
    });
    const readUsers = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'readUsers',
    });
    const createSubscriptions = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'createSubscriptions',
    });

    const readOperationSettings = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'readOperationSettings',
    });

    const { instance } = useMsal();

    const [highlightedState, setHighlightedState] = useState({
        [window.location.pathname]: true,
    } as Record<string, boolean>);

    const classes = useStyles();

    const NavigateTo = (path: string) => {
        history.push(path);
    };
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const subscriptionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = subscriptionRef.current;
        if (element) {
            setIsTooltipVisible(element.offsetWidth < element.scrollWidth);
        }
    }, [selectedSubscription, currentSubscription]);

    useEffect(() => {
        showSelectSubModal && showSelectAccountModal(subscriptions);
    }, [showSelectSubModal]);

    useEffect(() => {
        if (showEulaModal) {
            showEulaAcceptModal();
        }
    }, [showEulaModal]);

    // useEffect to determine the activeTab
    useEffect(() => {
        const pathname = window.location.pathname;
        const pathSegments = pathname.split('/');
        let highlightedTab = '';

        if (
            pathSegments.includes('project') ||
            pathSegments.includes('edit-project') ||
            pathSegments.includes('create-project') ||
            !pathname ||
            pathname === '/'
        ) {
            highlightedTab = 'projects';
        } else if (
            pathSegments.includes('company') ||
            pathSegments.includes('create-company')
        ) {
            highlightedTab = 'companies';
        } else {
            for (const segment of pathSegments) {
                if (['projects', 'companies'].includes(segment)) {
                    highlightedTab = segment;
                    break;
                }
            }
        }

        if (highlightedTab) {
            setHighlightedState({ [highlightedTab]: true });
        } else {
            setHighlightedState({});
        }

        setActiveTab(pathname);

        const unsubscribe = history.listen(() => {
            const newTab = window.location.pathname;
            setActiveTab(newTab);
        });
        return () => {
            unsubscribe();
        };
    }, [selectedSubscription, activeTab, history]);

    useEffect(() => {
        if (
            selectedSubscription &&
            selectedSubscription !== currentSubscription
        ) {
            dispatch(
                subscriptionActions.SetCurrrentSubscription(
                    selectedSubscription
                )
            );
            NavigateTo(
                `/subscriptions/${selectedSubscription.subscriptionId}/projects`
            );
        }
    }, [selectedSubscription]);

    const handleMenuOpen = (event: any) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const topMenuItems = [
        /*{
            id: 1,
            text: getTranslation('MenuItem.Home.Label'),
            disabled: false,
            url: '/',
        },*/
        {
            id: 1,
            text: getTranslation('MenuItem.Projects.Label'),
            disabled: false,
            url: currentSubscription.subscriptionId
                ? `/subscriptions/${currentSubscription.subscriptionId}/projects`
                : '/projects',
            default: true,
        },
        readCompanies
            ? {
                  id: 4,
                  text: getTranslation('MenuItem.Companies.Label'),
                  disabled: false,
                  url: currentSubscription.subscriptionId
                      ? `/subscriptions/${currentSubscription.subscriptionId}/companies`
                      : '/companies',
              }
            : null,
        {
            id: 6,
            text: getTranslation('MenuItem.CirrusInstall.Label'),
            disabled: false,
            url: '/cirrus-install',
        },
        readUsers
            ? {
                  id: 2,
                  text: getTranslation('MenuItem.Users.Label'),
                  disabled: false,
                  url: currentSubscription.subscriptionId
                      ? `/subscriptions/${currentSubscription.subscriptionId}/users`
                      : '/users',
              }
            : null,
        readOperationSettings
            ? {
                  id: 9,
                  text: 'Settings',
                  disabled: false,
                  url: currentSubscription.subscriptionId
                      ? `/subscriptions/${currentSubscription.subscriptionId}/settings`
                      : '/settings',
              }
            : null,
        {
            id: 7,
            text: getTranslation('MenuItem.AboutCirrus.Label'),
            disabled: false,
            url: '/about-cirrus',
        },
        createSubscriptions
            ? {
                  id: 8,
                  text: getTranslation('MenuItem.KMAdmin.Label'),
                  disabled: false,
                  url: '/km-admin',
              }
            : null,
        // {
        //     id: 5,
        //     text: 'User info',
        //     disabled: false,
        //     url: '/temp-user-info',
        // },
    ];
    const bottomMenuItems = [
        {
            id: 4,
            text: getTranslation('MenuItem.Logout.Label'),
            disabled: false,
            open: false,
            onClick: () => instance.logout(),
        },
    ];

    const drawer = (
        <div style={{ height: 'calc(100% - 65px)', marginTop: '60px' }}>
            <div className={classes.toolbar} />
            <div
                style={{
                    display: 'flex ',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    height: 'calc(100% - 60px)',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <List style={{ width: 100 + '%' }}>
                        {topMenuItems.map(
                            (item, index) =>
                                item !== null && (
                                    <MenuItem
                                        key={item.id}
                                        index={item.id}
                                        text={item.text}
                                        disabled={
                                            item.disabled || showEulaModal
                                        }
                                        open={
                                            Object.keys(highlightedState).some(
                                                (key) => item.url.includes(key)
                                            ) || item.url === activeTab
                                        }
                                        onClick={() => {
                                            setActiveTab(item.url);
                                            NavigateTo(item.url);
                                        }}
                                    />
                                )
                        )}
                    </List>
                </div>
                <div style={{ display: 'flex ' }}>
                    <List style={{ width: 100 + '%', marginBottom: 10 }}>
                        {bottomMenuItems.map((item, index) => (
                            <MenuItem
                                key={item.id}
                                index={item.id}
                                text={item.text}
                                disabled={item.disabled}
                                open={item.open}
                                onClick={item.onClick}
                            />
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );

    const handleMenuItemClick = (clickedSub: SubscriptionInfo) => {
        setSelectedSubscription(clickedSub);
        localStorage.setItem(
            'activeSubscriptionId',
            clickedSub.subscriptionId.toString()
        );
        handleMenuClose();
    };

    const showEulaAcceptModal = () => {
        setActiveModalId(Guid.create());
        dispatch(
            modalActions.OpenModal({
                id: activeModalId,
                heading: getTranslation('Sidebar.EulaModal.Header'),
                type: ModalType.Custom,
                showCloseButton: false,
                content: (
                    <EulaModal
                        closeModal={() =>
                            dispatch(modalActions.CloseModal(activeModalId))
                        }
                        showSubSelection={showSubSelection}
                    />
                ),
            })
        );
    };

    const showSelectAccountModal = (subs: SubscriptionInfo[]) => {
        setActiveModalId(Guid.create());
        dispatch(
            modalActions.OpenModal({
                id: activeModalId,
                heading: 'Select Account',
                type: ModalType.Custom,
                showCloseButton: false,
                content: (
                    <SelectAccountModal
                        subsciption={subs}
                        closeModal={() =>
                            dispatch(modalActions.CloseModal(activeModalId))
                        }
                        setSelectedSubscription={(selectedSubscription) =>
                            setSelectedSubscription(selectedSubscription)
                        }
                    />
                ),
            })
        );
    };

    const displaySubscription =
        selectedSubscription?.displayName ||
        currentSubscription?.displayName ||
        getTranslation('Sidebar.SelectSubscription.HelperText');

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    elevation={0}
                >
                    <Toolbar className={classes.header}>
                        <Typography
                            variant="h5"
                            noWrap
                            className={classes.header}
                        >
                            {getTranslation('Sidebar.CirrusCloud.Header')}
                        </Typography>
                        {subscriptions && (
                            <StyledMuiButton
                                aria-controls="dropdown-menu"
                                aria-haspopup="true"
                                onClick={handleMenuOpen}
                                endIcon={
                                    (subscriptions?.length > 1 &&
                                        (menuAnchorEl ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        ))) ??
                                    null
                                }
                                data-testid="subscription-change-dropdown"
                                className={classes.titleContainer}
                                style={{
                                    pointerEvents:
                                        subscriptions?.length > 1
                                            ? 'all'
                                            : 'none',
                                }}
                                disabled={showEulaModal}
                            >
                                <StyledSubscriptionNameTextContainer
                                    ref={subscriptionRef}
                                >
                                    <Tooltip
                                        title={displaySubscription}
                                        enterDelay={
                                            theme.timings.tooltipEnterDelay
                                        }
                                        arrow
                                        disableHoverListener={!isTooltipVisible}
                                        disableFocusListener={!isTooltipVisible}
                                    >
                                        <span>{displaySubscription}</span>
                                    </Tooltip>
                                </StyledSubscriptionNameTextContainer>
                            </StyledMuiButton>
                        )}
                        <Menu
                            id="dropdown-menu"
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                            transformOrigin={{
                                vertical: -35,
                                horizontal: -20,
                            }}
                            data-testid="subscription-change-dropdown-content"
                        >
                            {subscriptions &&
                                subscriptions.map((sub) => {
                                    const isSelected =
                                        currentSubscription?.subscriptionId ===
                                        sub.subscriptionId;
                                    return (
                                        <MuiMenuItem
                                            key={sub.subscriptionId.toString()}
                                            onClick={() =>
                                                handleMenuItemClick(sub)
                                            }
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                padding: isSelected
                                                    ? undefined
                                                    : '6px 50px',
                                            }}
                                        >
                                            {isSelected && <Check />}
                                            <StyledSubscriptionNameTextContainer>
                                                {sub.displayName}
                                            </StyledSubscriptionNameTextContainer>
                                        </MuiMenuItem>
                                    );
                                })}
                        </Menu>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="top"
                            // open={mobileOpen}
                            // onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        </>
    );
}

export default Sidebar;
