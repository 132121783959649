import {
    AppBar,
    IconButton,
    makeStyles,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.primary.dark,
    },
    toolBar: {
        minHeight: theme.measurements.topbarHeight,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    helpButton: {
        marginLeft: 0,
    },
    title: {
        marginRight: theme.spacing(3),
    },
    subtitle: { flexGrow: 1 },
}));

export type Props = {
    title: string;
    subtitle: string;
};

const Topbar = ({ title, subtitle }: Props) => {
    const classes = useStyles();
    const openDocumentation = () => {
        let path = '';
        path += window.location.origin;
        if (!path.endsWith('/')) {
            path += '/';
        }
        path += 'about-cirrus';
        window.open(path);
    };
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <Typography variant="body1" className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                    <IconButton
                        edge="end"
                        className={classes.helpButton}
                        color="inherit"
                        aria-label="help"
                        aria-controls="help-appbar"
                        aria-haspopup="true"
                        onClick={() => openDocumentation()}
                    >
                        <HelpOutline />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Topbar;
