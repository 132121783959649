import { ReactNode, useEffect, useState } from 'react';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import { Guid } from 'guid-typescript';
import { CircularProgress, Typography } from '@material-ui/core';
import { AccessControl } from 'src/components/AccessControl';
import { ModalType, Scope } from 'src/constants/enums';
import useTranslation from 'src/hooks/useTranslation';
import ProjectUsersTable from './ProjectUsersTable';
import styled from 'styled-components';
import { Button, ButtonSize } from '@ndc/react-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'src/redux/Modals';
import { useSnackbar } from 'notistack';
import {
    EditUserRoles,
    InviteUser,
    RemoveUserRoles,
} from 'src/components/UserActions';
import { User } from 'src/services/interfaces';
import { apiActions } from 'src/redux/Api';
import { accountSelectors } from 'src/redux/Account';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const UsersHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
const UsersTitle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;
const UsersHelp = styled(Typography)`
    margin-bottom: 20px;
    color: ${({ theme }) => theme.palette.neutral.carbon};
`;

export type Props = {
    selectedProject: ProjectInfo;
    subscriptionId: Guid;
};

export type Actions = {};

const UsersTabContent = ({
    selectedProject,
    subscriptionId,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [activeModalId, setActiveModalId] = useState<Guid>(Guid.create());
    const [usersAreUpdating, setUsersAreUpdating] = useState(true);
    const activeUserId = useSelector(accountSelectors.getPrincipalId) ?? '';

    useEffect(() => {
        if (selectedProject && !selectedProject.users) {
            dispatch(
                apiActions.getProjectRoleAssignments(
                    subscriptionId,
                    Guid.parse(selectedProject.id)
                )
            );
        } else if (selectedProject && !!selectedProject.users) {
            setUsersAreUpdating(false);
        }
    }, [selectedProject]);

    const showModalWithContents = (
        id: Guid,
        heading: string,
        content: ReactNode
    ) => {
        dispatch(
            modalActions.OpenModal({
                id,
                heading,
                type: ModalType.Custom,
                content,
            })
        );
    };
    const showModalMessage = (id: Guid, heading: string, content: string) => {
        dispatch(
            modalActions.OpenModal({
                id,
                heading,
                type: ModalType.Message,
                content,
                primaryButton: {
                    label: 'General.Close',
                    onClick: () => {
                        dispatch(modalActions.CloseModal(id));
                    },
                },
            })
        );
    };
    const closeModal = (id: Guid) => {
        dispatch(modalActions.CloseModal(id));
    };

    const updateUsers = () => {
        setUsersAreUpdating(true);
        setTimeout(() => {
            dispatch(
                apiActions.getProjectRoleAssignments(
                    subscriptionId,
                    Guid.parse(selectedProject.id)
                )
            );
        }, 500);
    };

    const invitedUserCallback = (
        isNewUser: boolean,
        email: string,
        accessLevel: string,
        error: any
    ) => {
        updateUsers();
        if (error) {
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                getTranslation('UserInvite.Failed.Title', { accessLevel }),
                getTranslation('UserInvite.Failed.Message', {
                    email,
                    accessLevel,
                })
            );
        } else {
            const snackbarMessage = getTranslation(
                'UserInvite.Successful.Message'
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
            closeModal(activeModalId);
        }
    };

    const editUserRolesCallback = (
        scopeType: string,
        userName: string,
        userChanged: boolean,
        error: any
    ) => {
        if (error) {
            updateUsers();
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                getTranslation('EditUser.Failed.Title', { scopeType }),
                getTranslation('EditUser.Failed.Message', { userName })
            );
        } else if (userChanged) {
            updateUsers();
            const snackbarMessage = getTranslation(
                'EditUser.Successful.Message',
                {
                    scopeType,
                }
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
            closeModal(activeModalId);
        } else {
            closeModal(activeModalId);
        }
    };

    const removeUserRolesCallback = (
        scopeType: string,
        userName: string,
        userChanged: boolean,
        error: any
    ) => {
        closeModal(activeModalId);
        if (error) {
            updateUsers();
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                'RemoveUser.Failed.Title',
                getTranslation('RemoveUser.Failed.Message', { userName })
            );
        } else if (userChanged) {
            updateUsers();
            const snackbarMessage = getTranslation(
                'RemoveUser.Successful.Message',
                {
                    scopeType,
                }
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
        }
    };

    const editRolesOfUser = (user: User) => {
        if (selectedProject) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation('Users.EditProjectRoles.Label'),
                <EditUserRoles
                    scopeType="Project"
                    user={user}
                    resource={selectedProject}
                    editUserRolesCallback={editUserRolesCallback}
                    subscriptionId={subscriptionId.toString()}
                />
            );
        }
    };

    const removeUser = (user: User) => {
        if (selectedProject) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation('Users.RemoveProjectUser.Label'),
                <RemoveUserRoles
                    scopeType="Project"
                    user={user}
                    resource={selectedProject}
                    removeUserRolesCallback={removeUserRolesCallback}
                    subscriptionId={subscriptionId.toString()}
                />
            );
        }
    };

    return (
        <Container>
            <UsersHeader>
                <UsersTitle>
                    <Typography variant="h5">
                        {getTranslation('Users.Project.Header')}
                    </Typography>
                    {usersAreUpdating && (
                        <CircularProgress color="secondary" size={30} />
                    )}
                </UsersTitle>
                <AccessControl
                    scope={Scope.Project}
                    id={selectedProject.id}
                    allowedPermission="addProjectUser"
                >
                    <Button
                        data-testid="add-users-button"
                        key={'add-users-button'}
                        size={ButtonSize.small}
                        onClick={() => {
                            setActiveModalId(Guid.create());
                            showModalWithContents(
                                activeModalId,
                                'Users.AddProjectUser.Label',
                                <InviteUser
                                    subscriptionId={
                                        subscriptionId?.toString() ?? ''
                                    }
                                    selectedProject={selectedProject}
                                    invitedUserCallback={invitedUserCallback}
                                    existingUserEmails={
                                        selectedProject.users?.map(
                                            (u) => u.email
                                        ) ?? []
                                    }
                                />
                            );
                        }}
                    >
                        {getTranslation('Users.AddUser.Label')}
                    </Button>
                </AccessControl>
            </UsersHeader>
            <UsersHelp>{getTranslation('Users.Project.Help')}</UsersHelp>
            <ProjectUsersTable
                activeUserId={activeUserId}
                editRolesOfUser={editRolesOfUser}
                removeUser={removeUser}
                preventUserMenu={false}
                project={selectedProject}
                subscriptionId={subscriptionId.toString()}
            />
        </Container>
    );
};

export default UsersTabContent;
