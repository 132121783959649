type SegmentSettings = {
    lineStyle: SegmentLineType;
};
type SegmentLineType = {
    base: SegmentLineState;
    updated: SegmentLineState;
};
type SegmentLineState = {
    default: LineStyle;
    selected: LineStyle;
    faded: LineStyle;
};
type LineStyle = {
    color: number;
    width: number;
};
export const segmentSettings: SegmentSettings = {
    lineStyle: {
        base: {
            default: {
                color: 0x616161,
                width: 25,
            },
            selected: {
                color: 0x616161,
                width: 25,
            },
            faded: {
                color: 0xc1c1c1,
                width: 25,
            },
        },
        updated: {
            default: {
                color: 0xde8b20,
                width: 25,
            },
            selected: {
                color: 0xde8b20,
                width: 25,
            },
            faded: {
                color: 0xedd0aa,
                width: 25,
            },
        },
    },
};
