import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { customApiScopes } from 'src/auth/apiScopes';
import { FullScreenLoading } from 'src/components/Loading';

const Login = () => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={customApiScopes.syncServiceApiRequestConfig}
            loadingComponent={() => <FullScreenLoading />}
        />
    );
};

export default Login;
