import { Guid } from 'guid-typescript';
import { ModalObj } from './interfaces';
import * as modalConstants from './modalConstants';

export interface OpenModalAction {
    type: typeof modalConstants.OPEN_MODAL;
    modalObj: ModalObj;
}

export const OpenModal = (modalObj: ModalObj) =>
    ({
        type: modalConstants.OPEN_MODAL,
        modalObj,
    } as OpenModalAction);

export interface CloseModalAction {
    type: typeof modalConstants.CLOSE_MODAL;
    modalId: Guid;
}

export const CloseModal = (modalId: Guid) =>
    ({
        type: modalConstants.CLOSE_MODAL,
        modalId,
    } as CloseModalAction);

export type KnownAction = OpenModalAction | CloseModalAction;
