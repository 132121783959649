import React, { useState, useEffect, useRef } from 'react';
import {
    Menu,
    MenuItem,
    IconButton,
    Checkbox,
    Typography,
} from '@material-ui/core';
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Guid } from 'guid-typescript';
import {
    Button as CustomButton,
    ButtonSize,
    ButtonVariant,
} from '@ndc/react-component-library';
import useTranslation from 'src/hooks/useTranslation';
import { NDCTable, Column } from 'src/components/Table';
import { User } from 'src/services/interfaces';
import useValidateAccess from 'src/hooks/useValidateAccess';
import { Scope } from 'src/constants/enums';
import dateOrTimeFormatter from 'src/helpers/dateFormatter';
import theme from 'src/theme';
import AdditionalInfoPopper from './AdditionalInfoPopper';
import { OperationCodeFormat } from 'src/constants/enums';
import { OperationSetting } from 'src/redux/OperationSettings/interfaces';
import { splitNameFromEmail } from 'src/helpers/utils';
import { AccessControl } from 'src/components/AccessControl';

const translationKeys = require('src/translations').default;

const StyledUnitContent = styled.div`
    padding-top: 1px;
    color: ${theme.palette.neutral.carbon};
    font-size: 14px;
`;

const CustomCheckbox = withStyles({
    root: {
        '&$checked': {
            color: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
            color: '#bdbdbd',
        },
    },
    checked: {},
})(Checkbox);

type UserMenuSettings = {
    anchorEl?: any;
    menuItems: React.ReactNode[];
};

export type Props = {
    operationSettings: OperationSetting[];
    templateId: string | Guid;
    operationCodeFormat: string;
};

export type Actions = {
    editOperationCodes: (user: User) => void;
    removeOperationCodes: (user: User[]) => void;
    removeMultipleOperationCodes: (operationSettingIds: Guid[]) => void;
    cloneOperationCodes: (user: User) => void;
};

const SettingsTable = ({
    editOperationCodes,
    removeOperationCodes,
    removeMultipleOperationCodes,
    cloneOperationCodes,
    operationSettings,
    templateId,
    operationCodeFormat,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const intl = useIntl();

    const userCanEditOperationSettings = useValidateAccess({
        scope: Scope.OperationSettings,
        id: templateId,
        allowed: 'createOperation',
    });

    const [userMenuSettings, setUserMenuSettings] = useState<UserMenuSettings>({
        anchorEl: undefined,
        menuItems: [],
    });
    const [selectedOperationCodes, setSelectedOperationCodes] = useState<
        Guid[]
    >([]);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const [rowCheckboxes, setRowCheckboxes] = useState<{
        [key: string]: boolean;
    }>({});

    const userMenuSettingsRef = useRef(userMenuSettings);

    const hideUserSideMenu = () =>
        setUserMenuSettings({
            menuItems: userMenuSettingsRef.current.menuItems,
            anchorEl: undefined,
        });

    useEffect(() => {
        userMenuSettingsRef.current = userMenuSettings;
    }, [userMenuSettings]);

    const areAllRowCheckboxesSelected = () => {
        return rowCheckboxes && Object.values(rowCheckboxes)?.every(Boolean);
    };

    useEffect(() => {
        const initialCheckboxesState = operationSettings?.reduce<
            Record<string, boolean>
        >((acc, operation) => {
            acc[operation.code] = false;
            return acc;
        }, {});
        setRowCheckboxes(initialCheckboxesState);
    }, [operationSettings]);

    useEffect(() => {
        setHeaderCheckbox(areAllRowCheckboxesSelected());
    }, [rowCheckboxes, operationSettings]);

    const getUserSideMenu = (operationDetails: any) => {
        const menuOptions: React.ReactNode[] = [];

        menuOptions.push(
            <div key={operationDetails.id}>
                <MenuItem
                    data-testid="edit-global-access"
                    key={'edit-operation'}
                    onClick={() => {
                        hideUserSideMenu();
                        editOperationCodes(operationDetails);
                    }}
                >
                    {getTranslation(
                        translationKeys.SettingsTable_EditOperation_Label
                    )}
                </MenuItem>
                <MenuItem
                    data-testid="remove-global-roles"
                    key={'clone-operation'}
                    onClick={() => {
                        hideUserSideMenu();
                        cloneOperationCodes(operationDetails);
                    }}
                >
                    {getTranslation(
                        translationKeys.SettingsTable_CloneOperation_Label
                    )}
                </MenuItem>
                <MenuItem
                    data-testid="remove-global-roles"
                    key={'remove-operation'}
                    onClick={() => {
                        hideUserSideMenu();
                        removeOperationCodes(operationDetails);
                    }}
                >
                    {getTranslation(
                        translationKeys.SettingsTable_RemoveOperation_Label
                    )}
                </MenuItem>
            </div>
        );

        const handleOpenMenu: React.MouseEventHandler<HTMLButtonElement> = (
            event
        ) => {
            setUserMenuSettings({
                anchorEl: event.target,
                menuItems: menuOptions,
            });
        };

        return (
            <IconButton
                onClick={handleOpenMenu}
                data-testid={
                    'operations-side-menu-button-' + operationDetails.code
                }
                style={{ marginTop: '-2px', marginBottom: '-2px' }}
            >
                <MoreIcon />
            </IconButton>
        );
    };

    const getOperationCode = (operationDetails: any) => {
        return (
            <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
                {operationCodeFormat === OperationCodeFormat.Hexadecimal
                    ? `$${operationDetails.code}`
                    : operationDetails.code}
            </div>
        );
    };

    const getOperationName = (operationDetails: any) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '5px',
                    wordBreak: 'break-word',
                }}
            >
                {operationDetails.name}
            </div>
        );
    };

    const getPLC1Value = (operationDetails: any) => {
        const plc1ColumnContent = (
            <>
                <span>{operationDetails.parameter1Name}</span>
                <StyledUnitContent>
                    {operationDetails.parameter1Unit}
                </StyledUnitContent>
            </>
        );
        return (
            <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
                {operationDetails.parameter1Name ? plc1ColumnContent : '-'}
            </div>
        );
    };

    const getPLC2Value = (operationDetails: any) => {
        const plc2ColumnContent = (
            <>
                <span>{operationDetails.parameter2Name}</span>
                <StyledUnitContent>
                    {operationDetails.parameter2Unit}
                </StyledUnitContent>
            </>
        );
        return (
            <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
                {operationDetails.parameter2Name ? plc2ColumnContent : '-'}
            </div>
        );
    };

    const getLastModified = (operationDetails: any) => {
        const formatedDate = dateOrTimeFormatter(
            intl,
            operationDetails.lastModifiedDateTime
        );
        const name = splitNameFromEmail(
            operationDetails.lastModifiedByUserEmail
        );
        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '5px',
                    wordBreak: 'break-word',
                }}
            >
                {`${name}, ${formatedDate}`}
            </div>
        );
    };

    const getAdditionalInfo = (operationDetails: any) => {
        return (
            <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
            >
                {operationDetails.prerequisite ||
                operationDetails.expectedBehavior ||
                operationDetails.expectedFeedback ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                            {getTranslation(translationKeys.General_Yes)}
                        </span>
                        <div
                            style={{ paddingLeft: '4px', paddingBottom: '2px' }}
                        >
                            <AdditionalInfoPopper
                                operationDetails={operationDetails}
                            />
                        </div>
                    </div>
                ) : (
                    '-'
                )}
            </div>
        );
    };

    const renderHeaderCheckbox = () => {
        return (
            <CustomCheckbox
                onChange={toggleAllCheckboxes}
                checked={headerCheckbox}
            />
        );
    };

    const onChangeHandler = (checked: boolean, selectedOperation: any) => {
        setRowCheckboxes({
            ...rowCheckboxes,
            [selectedOperation.id]: checked,
        });
        setSelectedOperationCodes((prevSelectedCodes) => {
            if (checked) {
                return [...prevSelectedCodes, selectedOperation.id];
            } else {
                return prevSelectedCodes.filter(
                    (id) => id !== selectedOperation.id
                );
            }
        });
    };

    const toggleAllCheckboxes = () => {
        const allChecked = areAllRowCheckboxesSelected();
        const updatedCheckboxes = {} as Record<string, boolean>;
        for (const operationSetting of operationSettings) {
            updatedCheckboxes[operationSetting.id] = !allChecked;
        }
        setRowCheckboxes(updatedCheckboxes);

        if (allChecked) {
            setSelectedOperationCodes([]);
        } else {
            setSelectedOperationCodes(
                operationSettings.map(
                    (operationSettings) =>
                        operationSettings.id as unknown as Guid
                )
            );
        }
    };

    const renderRowCheckbox = (selectedOperation: any) => {
        const isChecked = rowCheckboxes?.[selectedOperation.id] || false;
        return (
            <CustomCheckbox
                onChange={(e) =>
                    onChangeHandler(e.target.checked, selectedOperation)
                }
                checked={isChecked}
            />
        );
    };

    const getTableColumns = () => {
        const getSpacerContent = () => <div style={{ height: '44px' }} />;
        const tableColumns: Column[] = [
            {
                headerLabel: getTranslation(
                    translationKeys.Settings_OperationCode_Label
                ),
                getContent: getOperationCode,
                sortKey: (operationDetails) => operationDetails.code,
            },
            {
                headerLabel: getTranslation(
                    translationKeys.Settings_OperationName_Label
                ),
                getContent: getOperationName,
                thStyle: {
                    width: '20%',
                },
            },
            {
                headerLabel: getTranslation(
                    translationKeys.Settings_Plc1_Label
                ),
                getContent: getPLC1Value,
                thStyle: {
                    width: '20%',
                },
            },
            {
                headerLabel: getTranslation(
                    translationKeys.Settings_Plc2_Label
                ),
                getContent: getPLC2Value,
                thStyle: {
                    width: '20%',
                },
            },
            {
                headerLabel: getTranslation(
                    translationKeys.Settings_LastModified_Label
                ),
                getContent: getLastModified,
                thStyle: {
                    width: '20%',
                },
            },
            {
                headerLabel: getTranslation(
                    translationKeys.Settings_AdditionalInfo_Label
                ),
                getContent: getAdditionalInfo,
                thStyle: {
                    width: '20%',
                },
            },
        ];

        if (userCanEditOperationSettings) {
            const userActionsButton = {
                headerLabel: '', // More button
                getContent: getUserSideMenu,
                thStyle: {
                    width: '2%',
                },
            };

            const checkboxColumn = {
                headerLabel: renderHeaderCheckbox(),
                getContent: (selectedOperation: any) =>
                    renderRowCheckbox(selectedOperation),
                hidden: false,
            };

            tableColumns.unshift(checkboxColumn);
            tableColumns.push(userActionsButton);
        } else {
            const spacer = {
                headerLabel: '',
                getContent: getSpacerContent,
                thStyle: {
                    width: '2%',
                },
            };
            tableColumns.unshift(spacer);
            tableColumns.push(spacer);
        }

        return tableColumns;
    };

    const removeOperation = () => {
        removeMultipleOperationCodes(selectedOperationCodes);
        setSelectedOperationCodes([]);
    };

    return operationSettings?.length ? (
        <>
            <NDCTable columns={getTableColumns()} items={operationSettings} />
            <AccessControl
                scope={Scope.OperationSettings}
                id={templateId}
                allowedPermission="createOperation"
            >
                <CustomButton
                    size={ButtonSize.small}
                    variant={ButtonVariant.secondary}
                    onClick={removeOperation}
                    disabled={selectedOperationCodes.length === 0}
                    sx={{
                        marginBottom: '40px',
                        marginTop: '16px',
                    }}
                    data-testid="remove-multiple-operations"
                >
                    {getTranslation('Settings.RemoveOperations.ButtonLabel', {
                        n: selectedOperationCodes.length,
                    })}
                </CustomButton>
            </AccessControl>
            <Menu
                anchorEl={userMenuSettings.anchorEl}
                keepMounted
                open={userMenuSettings.anchorEl != undefined}
                onClose={hideUserSideMenu}
                data-testid="menu-operations"
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {userMenuSettings.menuItems}
            </Menu>
        </>
    ) : (
        <Typography
            variant="h6"
            style={{ textAlign: 'center', fontWeight: 500 }}
        >
            {getTranslation('SettingsTable.NoOperationsAdded.Label')}
        </Typography>
    );
};

export default SettingsTable;
