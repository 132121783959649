import {
    Box,
    Checkbox,
    CircularProgress,
    Tab,
    Typography,
    withStyles,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { NOT_FOUND } from 'src/redux/Projects/interfaces';
import {
    OperationUpdateDTO,
    PointUpdateDTO,
    DownloadUpdatesCommand,
    ArchiveOperationUpdatesCommand,
} from 'src/services/Dtos';
import dateOrTimeFormatter from 'src/helpers/dateFormatter';
import {
    DrawingLineUpdateDTO,
    LayoutUpdateDTO,
    SegmentUpdateDTO,
} from 'src/services/Dtos/LayoutUpdate';
import { Button } from 'src/components/Button';
import {
    Button as CustomButton,
    ButtonSize,
    ButtonVariant,
} from '@ndc/react-component-library';
import useTranslation from 'src/hooks/useTranslation';
import { AccessControl } from 'src/components/AccessControl';
import {
    ProjectViewerTabValue,
    Scope,
    OperationCodeFormat,
    ArchiveType,
} from 'src/constants/enums';
import theme from 'src/theme';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { RichPopper } from 'src/components/Popper';
import InfoIcon from 'src/assets/icons/InfoIcon';
import { useValidateAccess } from 'src/hooks';
import { LocalStorageEnum } from 'src/constants/enums';
import { projectSelectors } from 'src/redux/Projects';
import {
    useGetNewLayoutUpdatesQuery,
    useGetArchivedLayoutUpdatesQuery,
} from 'src/services/layoutService';
import LayoutUpdatesTableContainer from './LayoutUpdatesTableContainer';
import { subscriptionSelectors } from 'src/redux/Subscriptions';

const StyledTab = styled(Tab)`
    ${({ theme }) => `
    min-width: ${theme.spacing(14)};
        &:nth-child(1) {
            padding-left: 0;
        }`}
`;

const StyledButtonContainer = styled.div`
    max-width: 245px;
    margin-left: auto;
    margin-right: 0;
`;

const StyledLink = styled.a`
    ${({ theme }) => `
        margin: auto;
        color: ${theme.palette.primary.main};
        cursor: pointer;

        svg {
            margin-left: ${theme.spacing(1)};
            font-size: 1.25rem;
        }
    `}
`;

const PopperTextContainer = styled.div`
    width: 320px;
    height: fit-content;
`;

const CustomCheckbox = withStyles({
    root: {
        '&$checked': {
            color: theme.palette.primary.dark,
        },
        '&.Mui-disabled': {
            color: '#bdbdbd',
        },
    },
    checked: {},
})(Checkbox);

export type Props = {
    hasUploadedWefFile: boolean;
    operationCodeFormat: string;
};

export type Actions = {
    DownloadLayoutUpdates: (
        downloadUpdatesCommand: DownloadUpdatesCommand
    ) => void;
    MarkOperationUpdatesAsArchive: (
        archiveUpdatesCommand: ArchiveOperationUpdatesCommand
    ) => void;
    SetProjectTabValue: (tab: ProjectViewerTabValue) => void;
};

const LayoutUpdatesComponent = ({
    MarkOperationUpdatesAsArchive,
    DownloadLayoutUpdates,
    hasUploadedWefFile,
    SetProjectTabValue,
    operationCodeFormat,
}: Props & Actions) => {
    const subscriptionId = useSelector(subscriptionSelectors.getSubscriptionId);
    const selectedProject = useSelector(projectSelectors.getSelectedProject);
    const projectId =
        selectedProject === NOT_FOUND ? undefined : selectedProject?.id;
    const layoutId =
        selectedProject === NOT_FOUND ? undefined : selectedProject?.layoutId;
    const getTranslation = useTranslation();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const userAllowedToDownloadProjectUpdates = useValidateAccess({
        scope: Scope.Project,
        id: projectId,
        allowed: 'downloadProjectUpdates',
    });
    const userAllowedToArchiveOperationUpdates = useValidateAccess({
        scope: Scope.Project,
        id: projectId,
        allowed: 'archiveOperationUpdates',
    });
    const subscriptionIdInLocalStorage = localStorage.getItem(
        LocalStorageEnum.ACTIVESUBSCRIPTIONID
    );

    const [tabValue, setTabValue] = useState('new');
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [pointUpdatesToDownload, setPointUpdatesToDownload] = useState(
        [] as PointUpdateDTO[]
    );
    const [operationUpdatesToDownload, setOperationUpdatesToDownload] =
        useState([] as OperationUpdateDTO[]);
    const [segmentUpdatesToDownload, setSegmentUpdatesToDownload] = useState(
        [] as SegmentUpdateDTO[]
    );
    const [drawingLineUpdatesToDownload, setDrawingLineUpdatesToDownload] =
        useState([] as DrawingLineUpdateDTO[]);
    const [downloadAllPointUpdates, setDownloadAllPointUpdates] =
        useState(false);
    const [downloadAllOperationUpdates, setDownloadAllOperationUpdates] =
        useState(false);
    const [downloadAllSegmentUpdates, setDownloadAllSegmentUpdates] =
        useState(false);
    const [downloadAllDrawingLineUpdates, setDownloadAllDrawingLineUpdates] =
        useState(false);
    const [open, setOpen] = useState(false);

    const getNewLayoutUpdatesQuery = useGetNewLayoutUpdatesQuery(
        {
            subscriptionId: subscriptionId?.toString(),
            projectId: projectId!,
            layoutId: layoutId!,
        },
        {
            skip: !subscriptionId || !projectId || !layoutId,
        }
    );

    const getArchivedLayoutUpdatesQuery = useGetArchivedLayoutUpdatesQuery(
        {
            subscriptionId: subscriptionId?.toString(),
            projectId: projectId!,
            layoutId: layoutId!,
        },
        {
            skip: !subscriptionId || !projectId || !layoutId,
        }
    );

    const emptyLayoutUpdatesData: LayoutUpdateDTO = {
        operations: [],
        points: [],
        segments: [],
        drawingLines: [],
    };
    const newUpdates = getNewLayoutUpdatesQuery.data ?? emptyLayoutUpdatesData;
    const archivedUpdates =
        getArchivedLayoutUpdatesQuery.data ?? emptyLayoutUpdatesData;

    useEffect(() => {
        if (downloadAllPointUpdates) {
            setPointUpdatesToDownload(archivedUpdates.points);
        } else {
            setPointUpdatesToDownload([]);
        }
    }, [downloadAllPointUpdates]);

    useEffect(() => {
        if (downloadAllOperationUpdates && archivedTabActive) {
            setOperationUpdatesToDownload(archivedUpdates.operations);
        } else if (downloadAllOperationUpdates && newUpdatesTabActive) {
            setOperationUpdatesToDownload(newUpdates.operations);
        } else {
            setOperationUpdatesToDownload([]);
        }
    }, [downloadAllOperationUpdates]);

    useEffect(() => {
        if (downloadAllSegmentUpdates) {
            setSegmentUpdatesToDownload(archivedUpdates.segments);
        } else {
            setSegmentUpdatesToDownload([]);
        }
    }, [downloadAllSegmentUpdates]);

    useEffect(() => {
        if (downloadAllDrawingLineUpdates) {
            setDrawingLineUpdatesToDownload(archivedUpdates.drawingLines);
        } else {
            setDrawingLineUpdatesToDownload([]);
        }
    }, [downloadAllDrawingLineUpdates]);
    useEffect(() => {
        if (!getNewLayoutUpdatesQuery.isFetching && downloadInProgress) {
            setDownloadInProgress(false);
        }
    }, [getNewLayoutUpdatesQuery.isFetching, downloadInProgress]);
    useEffect(() => {
        if (!getArchivedLayoutUpdatesQuery.isFetching && downloadInProgress) {
            setDownloadInProgress(false);
        }
    }, [getArchivedLayoutUpdatesQuery.isFetching, downloadInProgress]);

    useEffect(() => {
        if (
            getNewLayoutUpdatesQuery.isSuccess &&
            !getNewLayoutUpdatesQuery.isFetching &&
            getArchivedLayoutUpdatesQuery.isSuccess &&
            !getArchivedLayoutUpdatesQuery.isFetching
        ) {
            setDownloadAllPointUpdates(false);
            setDownloadAllOperationUpdates(false);
            setDownloadAllSegmentUpdates(false);
            setDownloadAllDrawingLineUpdates(false);
        }
    }, [
        getNewLayoutUpdatesQuery.isSuccess,
        getNewLayoutUpdatesQuery.isFetching,
        getArchivedLayoutUpdatesQuery.isSuccess,
        getArchivedLayoutUpdatesQuery.isFetching,
    ]);

    const archivedTabActive = tabValue == 'archived';
    const newUpdatesTabActive = tabValue == 'new';
    const activeUpdatesData = archivedTabActive ? archivedUpdates : newUpdates;
    const showLoader =
        getNewLayoutUpdatesQuery.isFetching ||
        getArchivedLayoutUpdatesQuery.isFetching;
    const errorLoadingLayoutUpdates =
        getNewLayoutUpdatesQuery.isError ||
        getArchivedLayoutUpdatesQuery.isError;

    const newUpdatesCount = Object.values(newUpdates).reduce(
        (acc, item) => acc + item.length,
        0
    );
    const archivedUpdatesCount = Object.values(archivedUpdates).reduce(
        (acc, item) => acc + item.length,
        0
    );

    const selectedNewUpdatesToDownloadCount =
        operationUpdatesToDownload.length +
        newUpdates.points.length +
        newUpdates.drawingLines.length +
        newUpdates.segments.length;

    const selectedArchivedUpdatesToDownloadCount =
        pointUpdatesToDownload.length +
        operationUpdatesToDownload.length +
        segmentUpdatesToDownload.length +
        drawingLineUpdatesToDownload.length;

    const HandleCheckedDownloadPoint = (
        checked: boolean,
        point: PointUpdateDTO
    ) => {
        const _newPointUpdatesToDownload = checked
            ? pointUpdatesToDownload.concat([point])
            : pointUpdatesToDownload.filter((p) => p != point);
        if (
            _newPointUpdatesToDownload.length == activeUpdatesData.points.length
        )
            setDownloadAllPointUpdates(true);
        else if (_newPointUpdatesToDownload.length == 0)
            setDownloadAllPointUpdates(false);
        setPointUpdatesToDownload(_newPointUpdatesToDownload);
    };

    const HandleCheckedDownloadOperation = (
        checked: boolean,
        operation: OperationUpdateDTO
    ) => {
        const _newOperationUpdatesToDownload = checked
            ? operationUpdatesToDownload.concat([operation])
            : operationUpdatesToDownload.filter((o) => o != operation);
        if (
            _newOperationUpdatesToDownload.length ==
            activeUpdatesData.operations.length
        )
            setDownloadAllOperationUpdates(true);
        else if (_newOperationUpdatesToDownload.length == 0)
            setDownloadAllOperationUpdates(false);
        setOperationUpdatesToDownload(_newOperationUpdatesToDownload);
    };

    const HandleCheckedArchiveOperation = (
        checked: boolean,
        operation: OperationUpdateDTO
    ) => {
        const _newOperationUpdatesToDownload = checked
            ? operationUpdatesToDownload.concat([operation])
            : operationUpdatesToDownload.filter((o) => o != operation);
        if (
            _newOperationUpdatesToDownload.length ==
            activeUpdatesData?.operations.length
        )
            setDownloadAllOperationUpdates(true);
        else if (_newOperationUpdatesToDownload.length == 0)
            setDownloadAllOperationUpdates(false);
        else setDownloadAllOperationUpdates(false);
        setOperationUpdatesToDownload(_newOperationUpdatesToDownload);
    };

    const HandleCheckedDownloadSegment = (
        checked: boolean,
        segment: SegmentUpdateDTO
    ) => {
        const _newSegmentUpdatesToDownload = checked
            ? segmentUpdatesToDownload.concat([segment])
            : segmentUpdatesToDownload.filter((o) => o != segment);
        if (
            _newSegmentUpdatesToDownload.length ==
            activeUpdatesData?.segments.length
        )
            setDownloadAllSegmentUpdates(true);
        else if (_newSegmentUpdatesToDownload.length == 0)
            setDownloadAllSegmentUpdates(false);
        setSegmentUpdatesToDownload(_newSegmentUpdatesToDownload);
    };

    const HandleCheckedDownloadDrawingLine = (
        checked: boolean,
        drawingLine: DrawingLineUpdateDTO
    ) => {
        const _newDrawingLineUpdatesToDownload = checked
            ? drawingLineUpdatesToDownload.concat([drawingLine])
            : drawingLineUpdatesToDownload.filter((o) => o != drawingLine);
        if (
            _newDrawingLineUpdatesToDownload.length ==
            activeUpdatesData?.drawingLines.length
        )
            setDownloadAllDrawingLineUpdates(true);
        else if (_newDrawingLineUpdatesToDownload.length == 0)
            setDownloadAllDrawingLineUpdates(false);
        setDrawingLineUpdatesToDownload(_newDrawingLineUpdatesToDownload);
    };
    const handleDownloadNewUpdates = () => {
        setDownloadInProgress(true);
        const downloadUpdatesCommand = {
            pointIds: newUpdates.points.map((item) => {
                return {
                    layoutUpdateId: item.layoutUpdateId,
                    id: item.pointId,
                };
            }),
            operationIds: operationUpdatesToDownload.map((item) => {
                return {
                    layoutUpdateId: item.layoutUpdateId,
                    id: item.stationId,
                    actionNumber: item.actionNumber,
                };
            }),
            segmentIds: newUpdates.segments.map((item) => {
                return {
                    layoutUpdateId: item.layoutUpdateId,
                    id: item.segmentId,
                };
            }),
            drawingLineIds: newUpdates.drawingLines.map(
                (item) => item.drawingLineId
            ),
        } as DownloadUpdatesCommand;
        DownloadLayoutUpdates(downloadUpdatesCommand);
        setOperationUpdatesToDownload([]);
    };
    const handleDownloadSelectedUpdates = () => {
        setDownloadInProgress(true);
        const downloadUpdatesCommand = {
            pointIds: pointUpdatesToDownload.map((item) => {
                return {
                    layoutUpdateId: item.layoutUpdateId,
                    id: item.pointId,
                };
            }),
            operationIds: operationUpdatesToDownload.map((item) => {
                return {
                    layoutUpdateId: item.layoutUpdateId,
                    id: item.stationId,
                    actionNumber: item.actionNumber,
                };
            }),
            segmentIds: segmentUpdatesToDownload.map((item) => {
                return {
                    layoutUpdateId: item.layoutUpdateId,
                    id: item.segmentId,
                };
            }),
            drawingLineIds: drawingLineUpdatesToDownload.map(
                (item) => item.drawingLineId
            ),
        } as DownloadUpdatesCommand;
        DownloadLayoutUpdates(downloadUpdatesCommand);
        setOperationUpdatesToDownload([]);
        setPointUpdatesToDownload([]);
        setSegmentUpdatesToDownload([]);
        setDrawingLineUpdatesToDownload([]);
    };
    const handleMovetoArchived = async () => {
        setDownloadInProgress(true);
        const archiveUpdatesCommand = {
            operationUpdates: operationUpdatesToDownload.map((item) => {
                return {
                    actionNumber: item.actionNumber,
                    layoutUpdateId: item.layoutUpdateId,
                    stationId: item.stationId,
                };
            }),
        } as ArchiveOperationUpdatesCommand;
        await MarkOperationUpdatesAsArchive(archiveUpdatesCommand);

        const snackbarMessage = getTranslation(
            'ProjectViewer.Updates.OperationsArchived',
            { n: operationUpdatesToDownload.length }
        );
        enqueueSnackbar(snackbarMessage, { variant: 'success' });
    };
    const formatLabel = (n: number) => {
        if (n > -0.05 && n < 0.05) return '± ' + Math.abs(n).toFixed(1);
        return (n > 0 ? '+ ' : '− ') + Math.abs(n).toFixed(1);
    };
    const renderTravelTimeWithDiff = (obj: any) => {
        const diff = obj?.travelTime - obj?.projectLayoutTravelTime;
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <span>{obj?.travelTime}</span>
                <span
                    style={{
                        color: theme.palette.neutral.carbon,
                        fontSize: '14px',
                    }}
                >
                    <Tooltip
                        title={getTranslation(
                            'ProjectViewer.Updates.Tooltip.DifferenceLabel'
                        )}
                        arrow
                        placement="bottom"
                    >
                        <span>{formatLabel(diff)}</span>
                    </Tooltip>
                </span>
            </div>
        );
    };
    const renderSegmentTemplateUpdate = (obj: any) => {
        if (!obj || obj.projectLayoutTemplateId == obj.templateId) {
            return (
                <Tooltip
                    title={getTranslation(
                        'ProjectViewer.Updates.Tooltip.NoChangeLabel'
                    )}
                    arrow
                    placement="bottom"
                >
                    <span>-</span>
                </Tooltip>
            );
        }
        return (
            <>
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        margin: 0,
                    }}
                >
                    <Tooltip
                        title={`${getTranslation(
                            'ProjectViewer.Updates.Tooltip.NewTemplate'
                        )}: ${obj.templateName}`}
                        arrow
                        placement="bottom"
                    >
                        <span>{obj.templateName}</span>
                    </Tooltip>
                </div>
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        margin: 0,
                        color: theme.palette.neutral.carbon,
                        fontSize: '14px',
                    }}
                >
                    <Tooltip
                        title={`${getTranslation(
                            'ProjectViewer.Updates.Tooltip.OldTemplate'
                        )}: ${obj.projectLayoutTemplateName}`}
                        arrow
                        placement="bottom"
                    >
                        <span>{obj.projectLayoutTemplateName}</span>
                    </Tooltip>
                </div>
            </>
        );
    };
    const renderArchivedType = (obj: any, fieldName: string) => {
        return (
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {obj[fieldName] === ArchiveType.Downloaded
                    ? getTranslation('General.Downloaded')
                    : getTranslation('ProjectViewer.Updates.MovedToArchived')}
            </div>
        );
    };
    const renderEmail = (obj: any, fieldName: string) => (
        <Tooltip title={obj[fieldName]} arrow placement="top">
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {obj[fieldName]}
            </div>
        </Tooltip>
    );
    const renderHeaderCheckbox = (
        testId: string,
        container: object[],
        checked: boolean,
        onChangeHandler: Function
    ) => (
        <CustomCheckbox
            data-testid={testId}
            checked={checked}
            disabled={container.length == 0}
            onChange={(e) => onChangeHandler(e.target.checked)}
            style={{
                paddingTop: 0,
                paddingBottom: 0,
            }}
        />
    );
    const renderRowCheckbox = (
        checkboxDisabled: boolean,
        obj: any,
        checked: boolean,
        onChangeHandler: Function
    ) => (
        <CustomCheckbox
            disabled={checkboxDisabled}
            checked={checked}
            onChange={(e) => onChangeHandler(e.target.checked, obj)}
        />
    );
    const renderFormattedDate = (obj: any, fieldName: string) =>
        dateOrTimeFormatter(intl, obj[fieldName]);

    const renderTemplateName = (obj: any, fieldName: string) => {
        const noTemplateName = obj[fieldName] === null;
        return (
            <span
                style={{
                    color: noTemplateName
                        ? theme.palette.neutral.carbon
                        : 'inherit',
                }}
            >
                {noTemplateName
                    ? getTranslation('ProjectViewer.Updates.NoTemplateData')
                    : obj[fieldName]}
            </span>
        );
    };

    const renderOperationCode = (obj: any, fieldName: string) => {
        return operationCodeFormat === OperationCodeFormat.Hexadecimal ? (
            <span>{`$ ${obj[fieldName]}`}</span>
        ) : (
            <span>{obj[fieldName]}</span>
        );
    };

    const tablesConfig = {
        pointUpdates: {
            headers: [
                renderHeaderCheckbox(
                    'markAllPoints',
                    archivedTabActive && userAllowedToDownloadProjectUpdates
                        ? archivedUpdates.points
                        : [],
                    archivedTabActive ? downloadAllPointUpdates : true,
                    setDownloadAllPointUpdates
                ),
                getTranslation('General.PointId'),
                'X',
                'Y',
                getTranslation('General.Angle'),
                getTranslation('General.UpdatedBy'),
                getTranslation('General.Updated'),
            ],
            columnContents: [
                (obj: any) =>
                    renderRowCheckbox(
                        archivedTabActive && userAllowedToDownloadProjectUpdates
                            ? false
                            : true,
                        obj,
                        archivedTabActive
                            ? pointUpdatesToDownload.includes(obj)
                            : true,
                        HandleCheckedDownloadPoint
                    ),
                'pointId',
                'x',
                'y',
                'angle',
                (obj: any) => renderEmail(obj, 'createdByUserEmail'),
                (obj: any) => renderFormattedDate(obj, 'dateTimeUTC'),
            ],
        },
        operationUpdates: {
            headers: [
                renderHeaderCheckbox(
                    'markAllOperations',
                    userAllowedToArchiveOperationUpdates &&
                        userAllowedToDownloadProjectUpdates
                        ? newUpdates.operations
                        : [],
                    downloadAllOperationUpdates,
                    setDownloadAllOperationUpdates
                ),
                getTranslation('General.StationId'),
                getTranslation('General.OperationCode'),
                getTranslation('ProjectViewer.Updates.Template'),
                `${getTranslation('General.Parameter')} 1`,
                `${getTranslation('General.Parameter')} 2`,
                getTranslation('General.UpdatedBy'),
                getTranslation('General.Updated'),
            ],
            columnContents: [
                (obj: any) =>
                    renderRowCheckbox(
                        !userAllowedToArchiveOperationUpdates ||
                            !userAllowedToDownloadProjectUpdates,
                        obj,
                        operationUpdatesToDownload.includes(obj),
                        HandleCheckedArchiveOperation
                    ),
                'stationId',
                (obj: any) => renderOperationCode(obj, 'operationCode'),
                (obj: any) => renderTemplateName(obj, 'templateName'),
                'parameter1',
                'parameter2',
                (obj: any) => renderEmail(obj, 'createdByUserEmail'),
                (obj: any) => renderFormattedDate(obj, 'dateTimeUTC'),
            ],
        },
        operationUpdatesArchivedTab: {
            headers: [
                renderHeaderCheckbox(
                    'markAllOperations',
                    userAllowedToDownloadProjectUpdates
                        ? archivedUpdates.operations
                        : [],
                    downloadAllOperationUpdates,
                    setDownloadAllOperationUpdates
                ),
                getTranslation('General.StationId'),
                getTranslation('General.OperationCode'),
                getTranslation('ProjectViewer.Updates.Template'),
                `${getTranslation('General.Parameter')} 1`,
                `${getTranslation('General.Parameter')} 2`,
                getTranslation('General.UpdatedBy'),
                getTranslation('General.Updated'),
                getTranslation('General.ArchivedAs'),
            ],
            columnContents: [
                (obj: any) =>
                    renderRowCheckbox(
                        !userAllowedToDownloadProjectUpdates,
                        obj,
                        operationUpdatesToDownload.includes(obj),
                        HandleCheckedDownloadOperation
                    ),
                'stationId',
                'operationCode',
                (obj: any) => renderTemplateName(obj, 'templateName'),
                'parameter1',
                'parameter2',
                (obj: any) => renderEmail(obj, 'createdByUserEmail'),
                (obj: any) => renderFormattedDate(obj, 'dateTimeUTC'),
                (obj: any) =>
                    obj.archivedUpdate &&
                    renderArchivedType(obj.archivedUpdate, 'archiveType'),
            ],
        },
        segmentUpdates: {
            headers: [
                renderHeaderCheckbox(
                    'markAllSegments',
                    archivedTabActive && userAllowedToDownloadProjectUpdates
                        ? archivedUpdates.segments
                        : [],
                    archivedTabActive ? downloadAllSegmentUpdates : true,
                    setDownloadAllSegmentUpdates
                ),
                getTranslation('General.SegmentId'),
                getTranslation('General.TravelTime'),
                getTranslation('ProjectViewer.Updates.Template'),
                getTranslation('General.UpdatedBy'),
                getTranslation('General.Updated'),
            ],
            columnContents: [
                (obj: any) =>
                    renderRowCheckbox(
                        archivedTabActive && userAllowedToDownloadProjectUpdates
                            ? false
                            : true,
                        obj,
                        archivedTabActive
                            ? segmentUpdatesToDownload.includes(obj)
                            : true,
                        HandleCheckedDownloadSegment
                    ),
                'segmentId',
                (obj: any) => renderTravelTimeWithDiff(obj),
                (obj: any) => renderSegmentTemplateUpdate(obj),
                (obj: any) => renderEmail(obj, 'createdByUserEmail'),
                (obj: any) => renderFormattedDate(obj, 'dateTimeUTC'),
            ],
        },
        drawingLineUpdates: {
            headers: [
                renderHeaderCheckbox(
                    'markAllDrawingLines',
                    archivedTabActive && userAllowedToDownloadProjectUpdates
                        ? archivedUpdates.drawingLines
                        : [],
                    archivedTabActive ? downloadAllDrawingLineUpdates : true,
                    setDownloadAllDrawingLineUpdates
                ),
                getTranslation('General.DrawingLineId'),
                getTranslation('ProjectViewer.Updates.TypeOfUpdate'),
                getTranslation('General.UpdatedBy'),
                getTranslation('General.Updated'),
            ],
            columnContents: [
                (obj: any) =>
                    renderRowCheckbox(
                        archivedTabActive && userAllowedToDownloadProjectUpdates
                            ? false
                            : true,
                        obj,
                        archivedTabActive
                            ? drawingLineUpdatesToDownload.includes(obj)
                            : true,
                        HandleCheckedDownloadDrawingLine
                    ),
                'drawingLineIntId',
                (obj: any) =>
                    getTranslation(
                        'ProjectViewer.Updates.DrawingLineOperation',
                        { operation: obj['operation'] }
                    ),
                (obj: any) => renderEmail(obj, 'createdByUserEmail'),
                (obj: any) => renderFormattedDate(obj, 'dateTimeUtc'),
            ],
        },
    };

    const OperationsPopupTipsText = getTranslation(
        'ProjectViewer.Updates.OperationsPopupTips',
        {
            // @ts-ignore
            b: (chunks: ReactNode) => <b>{chunks}</b>,
        }
    );

    const OperationsPopupTips = (
        <PopperTextContainer>{OperationsPopupTipsText}</PopperTextContainer>
    );

    const pointsUpdatesContainer = (
        <LayoutUpdatesTableContainer
            title={getTranslation('General.Plural.Point', {
                n: activeUpdatesData?.points.length ?? 0,
            })}
            noDataMessage={getTranslation(
                'ProjectViewer.Updates.NoPointUpdates'
            )}
            tableData={activeUpdatesData?.points ?? []}
            tableConfig={tablesConfig.pointUpdates}
            marginBottom={10}
        />
    );

    const operationUpdatesContainer = (
        <Box marginBottom={10}>
            <LayoutUpdatesTableContainer
                title={getTranslation('General.Plural.Operation', {
                    n: activeUpdatesData?.operations.length ?? 0,
                })}
                noDataMessage={getTranslation(
                    'ProjectViewer.Updates.NoOperationUpdates'
                )}
                tableData={activeUpdatesData?.operations ?? []}
                tableConfig={
                    archivedTabActive
                        ? tablesConfig.operationUpdatesArchivedTab
                        : tablesConfig.operationUpdates
                }
                marginBottom={0}
                popperContent={OperationsPopupTips}
                displayPopper
            />
            {!archivedTabActive &&
            (activeUpdatesData?.operations.length ?? 0) > 0 ? (
                <AccessControl
                    scope={Scope.Project}
                    id={projectId}
                    allowedPermission="archiveOperationUpdates"
                >
                    <CustomButton
                        data-testid="button-MoveToArchived"
                        size={ButtonSize.small}
                        variant={ButtonVariant.secondary}
                        onClick={handleMovetoArchived}
                        disabled={operationUpdatesToDownload.length == 0}
                    >
                        {getTranslation(
                            'ProjectViewer.Updates.MoveToArchived',
                            {
                                n: operationUpdatesToDownload.length,
                            }
                        )}
                    </CustomButton>
                </AccessControl>
            ) : null}
        </Box>
    );

    const segmentUpdatesContainer = (
        <LayoutUpdatesTableContainer
            title={getTranslation('General.Plural.Segment', {
                n: activeUpdatesData?.segments.length ?? 0,
            })}
            noDataMessage={getTranslation(
                'ProjectViewer.Updates.NoSegmentUpdates'
            )}
            tableData={activeUpdatesData?.segments ?? []}
            tableConfig={tablesConfig.segmentUpdates}
            marginBottom={10}
        />
    );

    const drawingLineUpdatesContainer = (
        <LayoutUpdatesTableContainer
            title={getTranslation('General.Plural.DrawingLine', {
                n: activeUpdatesData?.drawingLines.length ?? 0,
            })}
            noDataMessage={getTranslation(
                'ProjectViewer.Updates.NoDrawingLineUpdates'
            )}
            tableData={activeUpdatesData?.drawingLines ?? []}
            tableConfig={tablesConfig.drawingLineUpdates}
            marginBottom={10}
        />
    );

    const LayoutVisualizationLink = () => {
        if (errorLoadingLayoutUpdates || showLoader) return null;
        if (hasUploadedWefFile) {
            return (
                <StyledLink
                    data-testid="link-view-layout-updates"
                    onClick={(e) => {
                        const path = `${window.location.origin.replace(
                            /\/?$/,
                            '/'
                        )}subscriptions/${
                            subscriptionId
                                ? subscriptionId
                                : subscriptionIdInLocalStorage
                        }/project/${projectId}/visualize`;
                        window.open(path);
                        e.preventDefault();
                    }}
                >
                    <Typography variant="body1">
                        {getTranslation('LayoutViewer.VisualizeUpdates')}
                        <OpenInNewIcon />
                    </Typography>
                </StyledLink>
            );
        } else {
            return (
                <Typography variant="body1">
                    {getTranslation('ProjectViewer.Updates.NoWefFile', {
                        //TODO: Fix typescript issue. Maybe solved in React 18? See https://github.com/formatjs/formatjs/issues/3633
                        // @ts-ignore
                        a: (chunks: ReactNode) => (
                            <StyledLink
                                onClick={(e) => {
                                    SetProjectTabValue(
                                        ProjectViewerTabValue.Files
                                    );
                                    e.preventDefault();
                                }}
                            >
                                {chunks}
                            </StyledLink>
                        ),
                    })}
                </Typography>
            );
        }
    };

    const UpdatesPopupTipsText = getTranslation(
        'ProjectViewer.Updates.UpdatesPopupTips',
        {
            // @ts-ignore
            b: (chunks: ReactNode) => <b>{chunks}</b>,
        }
    );

    const UpdatesPopupTips = (
        <PopperTextContainer>{UpdatesPopupTipsText}</PopperTextContainer>
    );

    const LayoutUpdatesHeader = (
        <Box sx={{ display: 'flex' }} mb={8}>
            <Box sx={{ display: 'flex', alignItems: 'center' }} flexGrow={1}>
                <Typography style={{ paddingRight: '10px' }} variant="h5">
                    {getTranslation('ProjectViewer.Updates.UpdatesInLayout')}
                </Typography>
                <RichPopper
                    content={UpdatesPopupTips}
                    open={open}
                    placement="bottom"
                    onClose={() => setOpen(false)}
                >
                    <IconButton
                        data-testid="icon-button-popper"
                        onClick={() => setOpen(!open)}
                        style={{ padding: '0px' }}
                    >
                        <InfoIcon />
                    </IconButton>
                </RichPopper>
            </Box>
            <Box sx={{ display: 'flex', alignContent: 'center' }}>
                {LayoutVisualizationLink()}
            </Box>
        </Box>
    );
    if (getNewLayoutUpdatesQuery.data && getArchivedLayoutUpdatesQuery.data) {
        return (
            <>
                {LayoutUpdatesHeader}
                <>
                    <TabContext value={tabValue}>
                        <TabList
                            onChange={(option, value) => setTabValue(value)}
                        >
                            <StyledTab
                                label={`${getTranslation(
                                    'General.New'
                                )} (${newUpdatesCount})`}
                                value="new"
                            />
                            <StyledTab
                                label={`${getTranslation(
                                    'General.Archived'
                                )} (${archivedUpdatesCount})`}
                                value="archived"
                            />
                        </TabList>
                        <TabPanel value="new">
                            <StyledButtonContainer>
                                <AccessControl
                                    scope={Scope.Project}
                                    id={projectId}
                                    allowedPermission="downloadProjectUpdates"
                                >
                                    <Button
                                        disabled={
                                            downloadInProgress ||
                                            selectedNewUpdatesToDownloadCount ===
                                                0
                                        }
                                        colorScheme="primary"
                                        onClick={handleDownloadNewUpdates}
                                    >
                                        {getTranslation(
                                            'ProjectViewer.Updates.DownloadUpdates',
                                            {
                                                n: selectedNewUpdatesToDownloadCount,
                                            }
                                        )}
                                    </Button>
                                </AccessControl>
                            </StyledButtonContainer>
                        </TabPanel>
                        <TabPanel value="archived">
                            <StyledButtonContainer>
                                <AccessControl
                                    scope={Scope.Project}
                                    id={projectId}
                                    allowedPermission="downloadProjectUpdates"
                                >
                                    <Button
                                        disabled={
                                            downloadInProgress ||
                                            selectedArchivedUpdatesToDownloadCount ===
                                                0
                                        }
                                        colorScheme="secondary"
                                        onClick={handleDownloadSelectedUpdates}
                                    >
                                        {getTranslation(
                                            'ProjectViewer.Updates.DownloadUpdates',
                                            {
                                                n: selectedArchivedUpdatesToDownloadCount,
                                            }
                                        )}
                                    </Button>
                                </AccessControl>
                            </StyledButtonContainer>
                        </TabPanel>
                    </TabContext>
                    {operationUpdatesContainer}
                    {pointsUpdatesContainer}
                    {segmentUpdatesContainer}
                    {drawingLineUpdatesContainer}
                </>
            </>
        );
    }

    if (errorLoadingLayoutUpdates) {
        return (
            <>
                {LayoutUpdatesHeader}
                <Typography>
                    {getTranslation('LayoutViewer.LayoutUpdates.Load.Error')}
                </Typography>
            </>
        );
    }

    return (
        <>
            {LayoutUpdatesHeader}
            <CircularProgress size={30} color="secondary" />
        </>
    );
};

export default LayoutUpdatesComponent;
