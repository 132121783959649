import { Tooltip, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { InteractiveModule } from 'src/components/InteractiveModule';
import useTranslation from 'src/hooks/useTranslation';
import { formatDiffLabel } from 'src/helpers/utils';
import {
    Circle,
    PositionContainer,
    PositionValue,
    StyledChip,
    StyledContainer,
    StyledDivider,
    StyledStatusRow,
    StyledTextRow,
} from './Styling';
import { Guid } from 'guid-typescript';
import { getSelectedLayoutUpdateContainer } from 'src/redux/Projects/projectSelectors';
import { NOT_FOUND } from 'src/redux/Projects/interfaces';
import { PointUpdateDTO } from 'src/services/Dtos';
import dateOrTimeFormatter from 'src/helpers/dateFormatter';
import { useIntl } from 'react-intl';
import theme from 'src/theme';

type Point = {
    id: number;
    x: number;
    y: number;
    angle: number;
};
interface UpdatedPoint extends Point {
    layoutUpdateId?: Guid;
}
export type Props = {
    selectedPoint: { base: Point; updated: UpdatedPoint | null };
    onCloseBehaviour: () => void;
};

const SelectedPointModule = ({ selectedPoint, onCloseBehaviour }: Props) => {
    const getTranslation = useTranslation();
    const intl = useIntl();
    const updates = useSelector(getSelectedLayoutUpdateContainer);
    const isUpdated = !!selectedPoint.updated;
    const updatedPointInfo: PointUpdateDTO | null =
        !updates || updates === NOT_FOUND || !updates.pointUpdates
            ? null
            : updates.pointUpdates.find(
                  (o) =>
                      o.pointId === selectedPoint.base.id &&
                      o.layoutUpdateId ===
                          selectedPoint.updated?.layoutUpdateId?.toString()
              ) ?? null;

    const renderPositionContainer = (
        baseValue: number,
        updatedValue?: number,
        label?: string,
        unit?: string
    ) => {
        const diff = !updatedValue
            ? 0
            : Math.round((updatedValue - baseValue) * 100) / 100;
        return (
            <PositionContainer>
                <Typography variant="body1">
                    {label}
                    <PositionValue>{updatedValue ?? baseValue}</PositionValue>
                    {unit}
                </Typography>
                {updatedValue && diff != 0 && (
                    <Tooltip
                        title={getTranslation(
                            'SelectedPointModule.Point.Position.Tooltip'
                        )}
                        enterDelay={theme.timings.tooltipEnterDelay}
                        arrow
                    >
                        <StyledChip label={formatDiffLabel(diff)} />
                    </Tooltip>
                )}
            </PositionContainer>
        );
    };

    return (
        <InteractiveModule
            headerText={getTranslation('General.Point')}
            onCloseBehaviour={onCloseBehaviour}
        >
            <StyledContainer>
                <Typography variant="subtitle1">{`${getTranslation(
                    'General.Point'
                )} ${selectedPoint.base.id}`}</Typography>
                <StyledStatusRow>
                    <Circle updated={isUpdated} />
                    <Typography variant="body1">
                        {getTranslation(
                            isUpdated ? 'General.Updated' : 'General.NotUpdated'
                        )}
                    </Typography>
                </StyledStatusRow>
                <StyledTextRow>
                    {renderPositionContainer(
                        selectedPoint.base.x,
                        selectedPoint.updated?.x,
                        'X '
                    )}
                    {renderPositionContainer(
                        selectedPoint.base.y,
                        selectedPoint.updated?.y,
                        'Y '
                    )}
                    {renderPositionContainer(
                        selectedPoint.base.angle,
                        selectedPoint.updated?.angle,
                        undefined,
                        '°'
                    )}
                </StyledTextRow>
            </StyledContainer>
            {isUpdated && updatedPointInfo && (
                <>
                    <StyledDivider />
                    <StyledContainer>
                        <StyledTextRow>
                            <Typography variant="subtitle1">
                                {getTranslation('General.LastUpdated')}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {dateOrTimeFormatter(
                                    intl,
                                    updatedPointInfo.dateTimeUTC
                                )}
                            </Typography>
                        </StyledTextRow>
                        <StyledTextRow style={{ wordBreak: 'break-all' }}>
                            <Typography variant="body1" color="textSecondary">
                                {`${getTranslation('General.By')} ${
                                    updatedPointInfo.createdByUserEmail
                                }`}
                            </Typography>
                        </StyledTextRow>
                    </StyledContainer>
                </>
            )}
        </InteractiveModule>
    );
};

export default SelectedPointModule;
