import * as PIXI from 'pixi.js';
import { Cull } from '@pixi-essentials/cull';
import { Stage } from '@pixi/layers';
import { IRendererOptions } from 'pixi.js';
import LayerComponent from './components/LayerComponent';
import Point, { IPointProps } from './layoutObjects/Point';
import ViewportComponent, {
    IViewportClickEventData,
} from './components/ViewportComponent';
import pointSpritesPng from 'src/assets/sprites/pointSprites.png';
import {
    createBackgroundDrawing,
    getInteractionHits,
    setupTextureMaps,
} from './helpers';
import Segment, { ISegmentProps } from './layoutObjects/Segment';
import EventHandler, { EVENTS } from 'src/utils/eventHandler';
import { IClickedMapObjects, ISelectedObjects } from './interfaces';
import { ObjectOrigin } from './layoutObjects/LayoutObject';
import { LayoutLayers } from 'src/constants/enums';
import { drawCoordinatesHelpLines } from './helpers/drawingUtils';

export interface PixiApp {}

export interface IPixiAppOptions extends IRendererOptions {}
export interface IPointContainer {
    [key: string]: { id: number; object: Point };
}
export interface ISegmentContainer {
    [key: string]: { id: number; object: Segment };
}
const eventHandler = EventHandler.getInstance();

export class PixiApp {
    private static instance: PixiApp | null;
    layers: { [key: string]: { id: string; object: LayerComponent } };
    points: IPointContainer;
    segments: ISegmentContainer;
    updatedPoints: IPointContainer;
    updatedSegments: ISegmentContainer;
    stage: PIXI.Container;
    renderer: PIXI.Renderer;
    viewportComponent: ViewportComponent;
    loader: PIXI.Loader;
    textureMap: {
        [key: string]: PIXI.utils.Dict<PIXI.Texture<PIXI.Resource>> | undefined;
    };
    lockComponentAdding: boolean;
    cull: Cull;

    private constructor(options?: IPixiAppOptions) {
        options = Object.assign(
            {
                forceCanvas: false,
                backgroundColor: 0xf5f5f5,
                antialias: true,
            },
            options
        );

        this.loader = PIXI.Loader.shared;
        this.renderer = new PIXI.Renderer(options);
        this.cull = new Cull();
        this.layers = {};
        this.points = {};
        this.segments = {};
        this.updatedPoints = {};
        this.updatedSegments = {};
        this.stage = new Stage();
        this.viewportComponent = new ViewportComponent(this);
        this.lockComponentAdding = false;
        this.textureMap = {};
        this.init();
    }

    public static getInstance(): PixiApp {
        if (!PixiApp.instance) {
            PixiApp.instance = new PixiApp();
        }

        return PixiApp.instance;
    }

    init = () => {
        this.loadResources();
        this.renderer.render(this.stage);
        this.cull.cull(this.renderer.screen);
        eventHandler.add(EVENTS.ON_SELECT_OBJECTS, this.selectObjects);
        eventHandler.add(EVENTS.ON_DESELECT_ALL_OBJECTS, this.deselectAll);
        this.addLayer(LayoutLayers.BackgroundDrawingLayer);
        this.addLayer(LayoutLayers.SelectionLayer, 99999);
    };
    public render(): void {
        if (this.viewportComponent.viewport.dirty) {
            this.cull.cull(this.renderer.screen);
            this.viewportComponent.viewport.dirty = false;
        }
        this.renderer.render(this.stage);
    }
    public addLayer = (layerId: string, zIndex?: number) => {
        const layer = new LayerComponent(
            this,
            this.renderer.width,
            this.renderer.height,
            layerId,
            zIndex
        );
        this.viewportComponent.viewport.addChild(layer);
        this.layers[layerId] = { id: layerId, object: layer };
    };
    public addPoint = (props: IPointProps, isUpdate: boolean) => {
        if (isUpdate) props.origin = ObjectOrigin.Update;
        const point = new Point(props, this.textureMap.pointTextures);
        if (isUpdate)
            this.updatedPoints[props.id] = { id: props.id, object: point };
        else this.points[props.id] = { id: props.id, object: point };
        const layer = this.layers[props.layerId].object;
        point.container.parentLayer = layer;
        layer.addChild(point.container);
        this.cull.add(point.container);
    };
    public addSegment = (props: ISegmentProps, isUpdate: boolean) => {
        if (isUpdate) props.origin = ObjectOrigin.Update;
        const segment = new Segment(props);
        if (isUpdate)
            this.updatedSegments[props.id] = { id: props.id, object: segment };
        else this.segments[props.id] = { id: props.id, object: segment };
        const layer = this.layers[props.layerId].object;
        layer.addChild(segment.container);
        this.cull.add(segment.container);
    };
    public addBackgroundDrawing = async (svgPath: string) => {
        const layer = this.layers[LayoutLayers.BackgroundDrawingLayer].object;
        createBackgroundDrawing(svgPath, layer)
            .then(() => {
                this.render();
            })
            .catch((reason) => {
                console.warn(
                    `Unable to load background drawing. Reason: ${reason}`
                );
            });
    };
    public clickHandler = async (data: IViewportClickEventData) => {
        const pointsHit = await getInteractionHits(data.screen, this.points);
        const segmentsHit = await getInteractionHits(
            data.screen,
            this.segments
        );
        const updatedPointsHit = await getInteractionHits(
            data.screen,
            this.updatedPoints
        );
        const updatedSegmentsHit = await getInteractionHits(
            data.screen,
            this.updatedSegments
        );
        Promise.all([
            pointsHit,
            segmentsHit,
            updatedPointsHit,
            updatedSegmentsHit,
        ]).then(() => {
            const response: IClickedMapObjects = {
                points: pointsHit,
                segments: segmentsHit,
                updatedPoints: updatedPointsHit,
                updatedSegments: updatedSegmentsHit,
            };

            //Remove segment hits underneath clicked points
            if (
                response.points.length > 0 ||
                response.updatedPoints.length > 0
            ) {
                response.segments = [];
                response.updatedSegments = [];
            }

            //Remove from points if contained in updatedPoints
            response.points = response.points.filter(
                (el) => !response.updatedPoints.includes(el)
            );

            //Remove from segments if contained in updatedSegments
            response.segments = response.segments.filter(
                (el) => !response.updatedSegments.includes(el)
            );

            eventHandler.trigger(EVENTS.ON_MAP_CLICKED, {
                position: data.screen,
                clickedObjects: response,
            });
        });
    };
    public resize = (width: number, height: number) => {
        this.renderer.resize(width, height);
        this.viewportComponent.resizeViewport();
    };
    public setVieportSizeAndPosition = (
        boundaries: {
            minX: number;
            maxX: number;
            minY: number;
            maxY: number;
        },
        screenWidth: number,
        screenHeight: number
    ) => {
        this.viewportComponent.setSizeandPosition(
            boundaries,
            screenWidth,
            screenHeight
        );
    };
    public destroy = () => {
        this.viewportComponent.destroy();
        this.stage.destroy({ children: true });
        this.renderer.destroy(true);
        PixiApp.instance = null;
        eventHandler.remove(EVENTS.ON_SELECT_OBJECTS, this.selectObjects);
        eventHandler.remove(EVENTS.ON_DESELECT_ALL_OBJECTS, this.deselectAll);
    };
    private loadResources = () => {
        if (!this.loader.resources.pointSpritesPng)
            this.loader.add('pointSpritesPng', pointSpritesPng);
        PIXI.utils.clearTextureCache();
        this.loader.load(async (loader, resources) => {
            this.textureMap = await setupTextureMaps(resources);
        });
    };
    private selectObjects = (objects: ISelectedObjects) => {
        Object.values(this.points).forEach((point) => {
            this.fadeObject(point.object);
        });
        Object.values(this.updatedPoints).forEach((point) => {
            this.fadeObject(point.object);
        });
        Object.values(this.segments).forEach((segment) => {
            this.fadeObject(segment.object);
        });
        Object.values(this.updatedSegments).forEach((segment) => {
            this.fadeObject(segment.object);
        });
        objects.points.forEach((point) => {
            this.selectObject(this.points[point].object);
            if (!!this.updatedPoints[point]) {
                this.selectObject(this.updatedPoints[point].object);
            }
        });
        objects.segments.forEach((segment) => {
            this.selectObject(this.segments[segment].object);
            if (!!this.updatedSegments[segment]) {
                this.selectObject(this.updatedSegments[segment].object);
            }
        });
        this.render();
    };
    private deselectAll = () => {
        Object.values(this.points).forEach((point) => {
            this.deselectObject(point.object);
        });
        Object.values(this.segments).forEach((segment) => {
            this.deselectObject(segment.object);
        });
        Object.values(this.updatedPoints).forEach((point) => {
            this.deselectObject(point.object);
        });
        Object.values(this.updatedSegments).forEach((segment) => {
            this.deselectObject(segment.object);
        });
        this.render();
    };
    private selectObject = (object: Point | Segment) => {
        object.select();
        object.unfade();
        object.container.parentLayer =
            this.layers[LayoutLayers.SelectionLayer].object;
    };
    private fadeObject = (object: Point | Segment) => {
        object.deselect();
        object.fade();
        object.container.parentLayer = this.layers[object.layerId].object;
    };
    private deselectObject = (object: Point | Segment) => {
        object.deselect();
        object.unfade();
        object.container.parentLayer = this.layers[object.layerId].object;
    };
    public createCoordinatesHelpLines = (screenHeight: number) => {
        const coordinatesHelpLines = new PIXI.Graphics();
        coordinatesHelpLines.lineStyle(1, 0x000000, 1);
        drawCoordinatesHelpLines(coordinatesHelpLines, screenHeight);

        this.stage.addChild(coordinatesHelpLines);
    };

    get view(): HTMLCanvasElement {
        return this.renderer.view;
    }
}
