import { useState, useRef } from 'react';
import { TextField, Popper } from '@material-ui/core';
import InputLabel from './InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles({
    root: {
        margin: '5px 0',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1.6px solid #9e9e9e',
                borderRadius: '2px',
            },
        },
        '& .MuiFormHelperText-root': {
            marginTop: '6px',
            marginLeft: 0,
            fontSize: '14px',
        },
    },
})(TextField);

const StyledAutocomplete = withStyles({
    root: {
        marginTop: '5px',
        marginBottom: '10px',
    },
})(Autocomplete);

const useStyles = makeStyles((theme) => ({
    rootDiv: {
        '& .MuiAutocomplete-popperDisablePortal': {
            position: 'fixed',
        },
    },
}));

type Props = {
    id?: string;
    defaultValue?: any[] | {};
    label: string;
    onChange: (items: any) => void;
    items: any[];
    multiple?: boolean;
    helpMessage?: string;
    getItemText: (item: any) => string;
    getOptionSelected?: (option: any, value: any) => boolean;
    noOptionsText?: string;
    error?: boolean;
    errorText?: string;
};

const DefaultAutocomplete = ({
    id,
    defaultValue = [],
    label,
    onChange,
    items,
    multiple = false,
    helpMessage = '',
    getItemText = (i) => i,
    getOptionSelected,
    noOptionsText = '',
    error = false,
    errorText = '',
}: Props) => {
    const classes = useStyles();
    return (
        <>
            <InputLabel label={label} />
            <div className={classes.rootDiv}>
                <StyledAutocomplete
                    id={id}
                    options={items}
                    defaultValue={defaultValue}
                    onChange={(event, value) => onChange(value)}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                        value.map((item, index) => (
                            <Chip
                                label={getItemText(item)}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <StyledTextField
                            {...params}
                            variant="outlined"
                            placeholder={helpMessage}
                            error={error}
                            helperText={error ? errorText : ''}
                        />
                    )}
                    multiple={multiple}
                    noOptionsText={noOptionsText}
                    disablePortal={true}
                    getOptionLabel={(item) => getItemText(item)}
                    getOptionSelected={getOptionSelected}
                    freeSolo={true}
                    forcePopupIcon={true}
                ></StyledAutocomplete>
            </div>
        </>
    );
};

export default DefaultAutocomplete;
