import { Reducer, Action } from 'redux';
import * as accountActions from './accountActions';
import * as accountConstants from './accountConstants';
import { AccountState } from './interfaces';

export const initialState: AccountState = {
    accountInfo: undefined,
};
export const reducer: Reducer<AccountState> = (
    state: AccountState = initialState,
    incomingAction: Action
): AccountState => {
    const action = incomingAction as accountActions.KnownAction;
    switch (action.type) {
        case accountConstants.SET_ACCOUNT_INFO:
            return { ...state, accountInfo: action.accountInfo };
        default:
            return state;
    }
};
