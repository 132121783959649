import { Reducer, Action } from 'redux';
import * as addressActions from './addressActions';
import * as addressConstants from './addressConstants';
import { AddressesState } from './interfaces';

export const initialState: AddressesState = {
    addresses: undefined,
};
export const reducer: Reducer<AddressesState> = (
    state: AddressesState = initialState,
    incomingAction: Action
): AddressesState => {
    const action = incomingAction as addressActions.KnownAction;
    switch (action.type) {
        case addressConstants.SET_ADDRESSES:
            return { ...state, addresses: action.addresses };
        default:
            return state;
    }
};
