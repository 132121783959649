import * as PIXI from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import LayerComponent from './LayerComponent';
import { PixiApp } from '../PixiApp';
import { clampSettings } from '../settings/viewportSettings';

export interface IViewportOptions {
    worldWidth: number;
    worldHeight: number;
}
export interface IViewportClickEventData {
    event: PIXI.InteractionEvent;
    screen: PIXI.Point;
    world: PIXI.Point;
    viewport: Viewport;
}

export default class ViewportComponent {
    app: PixiApp;
    options: IViewportOptions;
    viewport: Viewport;
    constructor(
        app: PixiApp,
        options: IViewportOptions = {
            worldWidth: 10000,
            worldHeight: 10000,
        }
    ) {
        this.options = options;
        this.app = app;
        this.viewport = new Viewport({
            divWheel: app.renderer.view,
            screenHeight: app.view.height,
            screenWidth: app.view.width,
            worldWidth: this.options.worldWidth,
            worldHeight: this.options.worldHeight,
            passiveWheel: false,
            interaction: app.renderer.plugins.interaction,
        });
        this.viewport.sortableChildren = true;
        this.init();
    }

    init = () => {
        this.viewport
            .on('zoomed', this.zoomed)
            .on('moved', this.moved)
            .on('moved-end', this.moved)
            .on('clicked', this.clicked);

        this.viewport
            .wheel({ smooth: 3 })
            .pinch({ percent: 4 })
            .drag()
            .moveCenter(
                this.options.worldWidth / 2,
                -this.options.worldHeight / 2
            );

        this.zoomPercent(1.25);
        window.onresize = this.resizeViewport;

        this.app.stage.addChild(this.viewport);
        this.viewport.fitWorld();

        return this.viewport;
    };
    zoomed = (data: { viewport: Viewport; type: string }) => {};

    moved = (data: { viewport: Viewport; type: string }) => {
        this.app.render();
    };
    clicked = (data: IViewportClickEventData) => {
        this.app.clickHandler(data);
    };

    lock = () => {
        this.viewport.plugins.pause('drag');
    };

    unlock = () => {
        this.viewport.plugins.resume('drag');
    };

    getZoom = () => {
        return this.viewport ? this.viewport.scale.x : 1;
    };

    getViewport = () => {
        return this.viewport;
    };

    addChild = (child: LayerComponent) => {
        this.viewport.addChild(child);
    };

    resizeViewport = () => {
        const center = this.viewport.center;
        this.viewport.resize(
            this.app.view.width,
            this.app.view.height,
            this.options.worldWidth,
            this.options.worldHeight
        );
        this.viewport.moveCenter(center);
    };

    setSizeandPosition = (
        boundaries: {
            minX: number;
            maxX: number;
            minY: number;
            maxY: number;
        },
        screenWidth: number,
        screenHeight: number
    ) => {
        let widthHeightRatio = screenWidth / screenHeight;
        let worldWidth;
        let worldHeight;
        const { minX, maxX, minY, maxY } = boundaries;
        const worldMargin = Math.max(0.1 * (maxX - minX), 0.1 * (maxY - minY));

        if (widthHeightRatio < 1) {
            worldWidth = maxX - minX + worldMargin;
            worldHeight = (maxY - minY + worldMargin) / widthHeightRatio;
        } else {
            worldWidth = (maxX - minX + worldMargin) * widthHeightRatio;
            worldHeight = maxY - minY + worldMargin;
        }
        this.options.worldWidth = worldWidth;
        this.options.worldHeight = worldHeight;
        this.viewport.resize(
            screenWidth,
            screenHeight,
            worldWidth,
            worldHeight
        );

        this.viewport
            .fit(false, worldWidth, worldHeight)
            .moveCenter((maxX + minX) / 2, -(maxY + minY) / 2)
            .clampZoom(this.getClampZoomOptions());
    };

    getClampZoomOptions = () => {
        return {
            minWidth: clampSettings.minWidth,
            minHeight: clampSettings.minHeight,
            maxWidth: this.viewport.worldScreenWidth,
            maxHeight: this.viewport.worldScreenHeight,
        };
    };

    zoomPercent = (percent: number) => {
        this.viewport.zoomPercent(percent, true);
        this.viewport.emit('zoomed', { viewport: this.viewport });
    };

    setZoom = (zoom: number) => {
        this.viewport.setZoom(zoom);
        this.viewport.emit('zoomed', { viewport: this.viewport });
    };

    destroy = () => {
        this.viewport.removeAllListeners();
    };
    getVisibleBounds = () => {
        return this.viewport.getVisibleBounds();
    };
}
