import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const subscriptionsPermissions = (state: ApplicationState) =>
    state.permissions.subscriptions;
const projectsPermissions = (state: ApplicationState) =>
    state.permissions.projects;
const operationSettingsPermissions = (state: ApplicationState) =>
    state.permissions.operationSettings;

export const getSubscriptionsPermissions = createSelector(
    [subscriptionsPermissions],
    (permissions) => permissions
);
export const getProjectsPermissions = createSelector(
    [projectsPermissions],
    (permissions) => permissions
);
export const getOperationSettingsPermissions = createSelector(
    [operationSettingsPermissions],
    (permissions) => permissions
);
