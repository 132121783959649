import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Variant } from '@material-ui/core/styles/createTypography';

const StyledTypography = styled(Typography)`
    font-weight: 600;
    font-size: 16px;
`;

type Props = {
    label: string;
    variant?: Variant;
    style?: {};
};

const InputLabel = ({ label, variant = 'h6', style = {} }: Props) => {
    return (
        <StyledTypography variant={variant} style={{ ...style }}>
            {label}
        </StyledTypography>
    );
};

export default InputLabel;
