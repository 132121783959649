import PropTypes from 'prop-types';
import { makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: (props: any) => props.direction,
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        alignContent: 'stretch',
        backgroundColor: (props: any) =>
            props.variant === 'error'
                ? theme.palette.secondary.main
                : props.variant === 'primary'
                ? theme.palette.pastel.blue
                : 'none',
        borderRadius: '4px',
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    icon: {
        color: 'black',
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: (props: any) =>
            props.direction === 'row' ? theme.spacing(2) : 'inherit',
        marginBottom: (props) =>
            props.direction === 'column' ? theme.spacing(2) : 'inherit',
    },
}));

interface StyleProps {
    variant?: string;
    direction?: string;
}
interface IInlineMessage extends StyleProps {
    messageText: string;
}

const InlineMessage = ({
    messageText,
    variant = 'info',
    direction = 'row',
}: IInlineMessage) => {
    const classes = useStyles({ variant, direction });
    const icon = variant === 'error' ? 'warning_amber' : 'info_outline';
    return (
        <div className={classes.root}>
            <span className={`${classes.icon} material-icons`}>{icon}</span>
            <Typography variant="body1">{messageText}</Typography>
        </div>
    );
};

InlineMessage.propTypes = {
    messageText: PropTypes.string,
    variant: PropTypes.string,
    direction: PropTypes.string,
};

export default InlineMessage;
