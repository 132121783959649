import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import AccessLevelModule from './AccessLevelModule';
import ProjectAccessSelector from './ProjectAccessSelector';
import GlobalAccessSelector from './GlobalAccessSelector';
import { UsersService } from 'src/services';
import { UserInfo } from 'src/services/interfaces';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccessLevelType from 'src/constants/AccessLevelType';
import { DefaultAutocomplete } from 'src/components/Input';
import { Button, TextField } from '@ndc/react-component-library';
import { validateEmail } from 'src/helpers/validator';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import { GlobalRoles, ProjectRoles, RoleIds, Scope } from 'src/constants/enums';
import useValidateAccess from 'src/hooks/useValidateAccess';
import useTranslation from 'src/hooks/useTranslation';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    align-self: flex-start;
    margin-top: 40px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const ProjectContainer = styled.div`
    margin-top: ${({ theme }) => `${theme.spacing(4)}`};
`;

export type Props = {
    selectedProject?: ProjectInfo;
    subscriptionId: string;
    projects?: ProjectInfo[];
    invitedUserCallback: (
        isNewUser: boolean,
        userEmail: string,
        accessLevel: string,
        error?: string
    ) => void;
    existingUserEmails: string[];
};

export type Actions = {};

const InviteUser = ({
    selectedProject = undefined,
    subscriptionId,
    projects = [],
    invitedUserCallback = () => {},
    existingUserEmails,
}: Props & Actions) => {
    const hasSelectedProject = selectedProject != undefined;
    const usersService = new UsersService();
    const inviteNewUser = (user: UserInfo) => {
        return usersService.inviteNewUser(subscriptionId, user);
    };

    const canUserAddSubscriptionUsers = useValidateAccess({
        scope: Scope.Subscription,
        id: subscriptionId,
        allowed: 'createUsers',
    });

    const getTranslation = useTranslation();

    const [email, setEmail] = useState<string>('');
    const [accessLevel, setAccessLevel] = useState<string>(
        selectedProject != undefined ? AccessLevelType.Project : ''
    );
    const [selectedProjects, setSelectedProjects] = useState<ProjectInfo[]>(
        selectedProject ? [selectedProject] : []
    );
    const [showMissingProjectError, setShowMissingProjectError] =
        useState<boolean>(false);
    const [showEmailExistsError, setShowEmailExistsError] =
        useState<boolean>(false);
    const [showInvalidEmailError, setShowInvalidEmailError] =
        useState<boolean>(false);
    const [showMissingRolesError, setShowMissingRolesError] =
        useState<boolean>(false);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [addingUser, setAddingUser] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!inputRef.current) return;
        inputRef.current?.focus();
    });

    useEffect(() => {
        if (accessLevel == AccessLevelType.Global) {
            setSelectedProjects([]);
            if (
                !selectedRoles.every((selectedRole) =>
                    Object.values(GlobalRoles)
                        .map((role) => RoleIds[role].toString())
                        .includes(selectedRole)
                )
            )
                setSelectedRoles([]);
        } else if (accessLevel == AccessLevelType.Project) {
            if (
                !selectedRoles.every((selectedRole) =>
                    Object.values(ProjectRoles)
                        .map((role) => RoleIds[role].toString())
                        .includes(selectedRole)
                )
            )
                setSelectedRoles([]);
        } else {
            setSelectedRoles([]);
        }
    }, [accessLevel]);

    useEffect(() => {
        if (!canUserAddSubscriptionUsers || selectedProject != undefined)
            setAccessLevel(AccessLevelType.Project);
        else setAccessLevel(AccessLevelType.Global);
    }, [canUserAddSubscriptionUsers]);

    useEffect(() => {
        setShowInvalidEmailError(false);
        setShowEmailExistsError(false);
    }, [email]);

    useEffect(() => {
        if (selectedProjects.length > 0) setShowMissingProjectError(false);
    }, [selectedProjects]);

    useEffect(() => {
        setShowMissingRolesError(false);
    }, [selectedRoles]);

    const validateAndInviteUser = () => {
        if (addingUser) return;

        let validationPassed = true;
        let projectsToAdd = {};

        if (
            accessLevel == AccessLevelType.Project &&
            selectedProjects.length == 0
        ) {
            setShowMissingProjectError(true);
            validationPassed = false;
        } else {
            setShowMissingProjectError(false);
            if (accessLevel == AccessLevelType.Project)
                projectsToAdd = {
                    projects: selectedProjects.map((project) => project.id),
                };
        }

        if (!validateEmail(email)) {
            setShowInvalidEmailError(true);
            validationPassed = false;
        } else if (
            existingUserEmails
                .map((e) => e.toLowerCase())
                .includes(email.toLowerCase())
        ) {
            setShowEmailExistsError(true);
            validationPassed = false;
        }

        if (selectedRoles.length == 0) {
            setShowMissingRolesError(true);
            validationPassed = false;
        }

        if (validationPassed) {
            const userInfo: UserInfo = {
                email,
                accessLevel,
                roles: selectedRoles,
                ...projectsToAdd,
            };
            setAddingUser(true);
            inviteNewUser(userInfo)
                .then((isNewUser) => {
                    setAddingUser(false);
                    invitedUserCallback(isNewUser, email, accessLevel);
                })
                .catch((error) => {
                    setAddingUser(false);
                    invitedUserCallback(false, email, accessLevel, error);
                });
        }
    };

    return (
        <Container>
            <Container>
                <TextField
                    ref={inputRef}
                    id="emailInput"
                    label={getTranslation(translationKeys.UserInfo_Email_Label)}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    error={showInvalidEmailError || showEmailExistsError}
                    helperText={
                        showInvalidEmailError
                            ? getTranslation(
                                  translationKeys.UserInfo_Email_ErrorText
                              )
                            : showEmailExistsError
                            ? getTranslation(
                                  translationKeys.UserInfo_Email_AlreadyExists
                              )
                            : undefined
                    }
                    sx={{
                        width: '100%',
                    }}
                />
                {canUserAddSubscriptionUsers && !hasSelectedProject && (
                    <AccessLevelModule
                        value={accessLevel}
                        onChange={(e) => {
                            if (accessLevel !== e.target.value)
                                setAccessLevel(e.target.value);
                        }}
                    />
                )}
                {accessLevel == AccessLevelType.Project && (
                    <ProjectContainer>
                        {selectedProject == undefined ? (
                            <DefaultAutocomplete
                                items={projects}
                                label={getTranslation(
                                    translationKeys.Users_ProjectPermissions_Label
                                )}
                                onChange={(projects: ProjectInfo[]) =>
                                    setSelectedProjects(projects)
                                }
                                multiple={true}
                                getItemText={(project: ProjectInfo) =>
                                    project.commissioningProject
                                }
                                helpMessage={getTranslation(
                                    translationKeys.Settings_AddProjects
                                )}
                                noOptionsText={getTranslation(
                                    translationKeys.General_NoProjects
                                )}
                                error={showMissingProjectError}
                                errorText={getTranslation(
                                    translationKeys.Settings_AddProjects_ErrorText
                                )}
                            />
                        ) : (
                            ''
                        )}
                        <ProjectAccessSelector
                            defaultSelectedRoles={selectedRoles}
                            showMissingRolesError={showMissingRolesError}
                            setSelectedRoles={setSelectedRoles}
                        />
                    </ProjectContainer>
                )}
                {accessLevel == AccessLevelType.Global && (
                    <GlobalAccessSelector
                        defaultSelectedRoles={selectedRoles}
                        setSelectedRoles={setSelectedRoles}
                        showMissingRolesError={showMissingRolesError}
                    />
                )}
            </Container>
            <ButtonContainer>
                <Button
                    data-testid="invite-user-button"
                    key={'inviteUserButton'}
                    onClick={validateAndInviteUser}
                    disabled={addingUser}
                >
                    {getTranslation(translationKeys.Users_AddUser_Label)}
                </Button>
                {addingUser && (
                    <CircularProgress size="35px" color="secondary" />
                )}
            </ButtonContainer>
        </Container>
    );
};

export default InviteUser;
