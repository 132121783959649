import React, { ReactNode, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import useTranslation from 'src/hooks/useTranslation';
import theme from 'src/theme';
import styled from 'styled-components';

const getColor = (props: any) => {
    if (props.hasError) return theme.palette.error.main;
    return theme.palette.neutral.dark;
};

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
    border-width: 1px;
    border-radius: 8px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: ${theme.palette.neutral.light};
    color: ${theme.palette.neutral.carbon};
    outline: none;
    transition: border 0.24s ease-in-out;
`;
const StyledLabel = styled(Typography)`
    u {
        color: ${theme.palette.primary.main};
        cursor: pointer;
    }
`;

interface FileUpLoadProps {
    accept?: Accept;
    onDropHandler: Function;
    hasError?: boolean;
    minSize?: number;
    maxSize?: number;
    maxFiles?: number;
}

const FileUpload = ({
    accept,
    onDropHandler,
    hasError = false,
    minSize = 0,
    maxSize = 104857600, //100Mb
    maxFiles = 1,
}: FileUpLoadProps) => {
    const getTranslation = useTranslation();
    const onDrop = useCallback((acceptedFiles, fileRejections, event) => {
        onDropHandler({ acceptedFiles, fileRejections, event });
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        onDrop,
        accept,
        minSize,
        maxSize,
        maxFiles,
    });

    return (
        <Container
            {...{ hasError }}
            {...getRootProps({
                isFocused,
                isDragAccept,
                isDragReject,
                acceptedFiles,
            })}
            data-testid="drop-container"
        >
            <input {...getInputProps()} data-testid="drop-input" />
            {isDragActive ? (
                <StyledLabel>
                    {getTranslation('FileUpload.DropFiles.Label')}
                </StyledLabel>
            ) : (
                <StyledLabel variant="body1">
                    {getTranslation('FileUpload.BrowseFile.Label', {
                        //TODO: Fix typescript issue. Maybe solved in React 18? See https://github.com/formatjs/formatjs/issues/3633
                        // @ts-ignore
                        u: (chunks: ReactNode) => <u>{chunks}</u>,
                    })}
                </StyledLabel>
            )}
        </Container>
    );
};

export interface OnDropProps {
    acceptedFiles: File[];
    fileRejections: FileRejection[];
    event: DropEvent;
}

export type FileAccept = Accept;

export default FileUpload;
