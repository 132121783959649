import styled from 'styled-components';
import useTranslation from 'src/hooks/useTranslation';
import translationKeys from 'src/translations';
import { Modal as NDCModal } from '@ndc/react-component-library';
import { ModalObj } from 'src/redux/Modals/interfaces';
import { ModalType } from 'src/constants/enums';

const InnerModal = styled.div`
    width: 90%;
    height: 100%;
    flex-grow: 1;
`;
const StyledMessage = styled.p`
    white-space: pre-wrap;
`;

export type Props = {
    modalObj: ModalObj;
};
export type Actions = {
    onClose: (modalObj: ModalObj) => void;
};

const Modal = ({ modalObj, onClose }: Props & Actions) => {
    const { id, heading, content, type, zIndex, showCloseButton } = modalObj;
    const getTranslation = useTranslation();
    const translate = (str: string | undefined) => {
        if (str === undefined) return '';
        if (str.replaceAll('.', '_') in translationKeys)
            return getTranslation(str);
        return str;
    };
    const { primaryButton, secondaryButton, tertiaryButton } = modalObj;
    if (!!primaryButton)
        modalObj.primaryButton = {
            ...primaryButton,
            label: translate(primaryButton.label),
        };
    if (!!secondaryButton)
        modalObj.secondaryButton = {
            ...secondaryButton,
            label: translate(secondaryButton.label),
        };
    if (!!tertiaryButton)
        modalObj.tertiaryButton = {
            ...tertiaryButton,
            label: translate(tertiaryButton.label),
        };

    const handleModalClose = () => {
        if (showCloseButton || showCloseButton === undefined) onClose(modalObj);
    };
    return (
        <NDCModal
            show={true}
            sx={{ zIndex: (zIndex ?? 0 + 1) * 10 }}
            onClose={handleModalClose}
            {...modalObj}
            id={`modal-${id}`}
            heading={translate(heading)}
        >
            <InnerModal>
                {type === ModalType.Message ? (
                    <StyledMessage>{translate(content)}</StyledMessage>
                ) : (
                    <>{content}</>
                )}
            </InnerModal>
        </NDCModal>
    );
};

export default Modal;
