import { connect } from 'react-redux';
import { ApplicationState } from 'src/reduxSetup/interfaces';
import CreateCompany, { Actions, Props } from './components/CreateEditCompany';
import { apiActions } from 'src/redux/Api';
import { CompanyInfo } from 'src/redux/Companies/interfaces';
import { companyActions, companySelectors } from 'src/redux/Companies';
import { subscriptionSelectors } from 'src/redux/Subscriptions';
import { Guid } from 'guid-typescript';

export interface OwnProps {
    cancelCallback: (() => void) | undefined;
    createdCompanyCallback: ((newCompanyId?: string) => void) | undefined;
}

const mapStateToProps: (
    state: ApplicationState,
    ownProps: OwnProps
) => Props = (state, ownProps) => {
    return {
        selectedCompany: companySelectors.getSelectedCompany(state),
        subscriptionId: subscriptionSelectors.getSubscriptionId(state),
        ...ownProps,
    };
};

const mapDispatchToProps: Actions = {
    createCompany: (
        subscriptionId: Guid,
        companyInfo: CompanyInfo,
        createdCompanyCallback: (newCompanyId?: string) => void
    ) =>
        apiActions.createCompany(
            subscriptionId,
            companyInfo,
            createdCompanyCallback
        ),
    getCompany: (subscriptionId: Guid, guid: string) =>
        apiActions.getCompany(subscriptionId, guid),
    resetSelectedCompany: () => companyActions.resetSelectedCompany(),
    updateCompany: (
        subscriptionId: Guid,
        company: CompanyInfo,
        updatedCompanyCallback: (companyId?: string) => void
    ) =>
        apiActions.updateCompany(
            subscriptionId,
            company,
            updatedCompanyCallback
        ),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
