import { ReactNode, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { Guid } from 'guid-typescript';
import useTranslation from 'src/hooks/useTranslation';
import UsersTable from './UsersTable';
import { User } from 'src/services/interfaces';
import { SubscriptionInfo } from 'src/redux/Subscriptions/interfaces';
import { ProjectInfo } from 'src/redux/Projects/interfaces';
import styled from 'styled-components';
import translationKeys from 'src/translations';
import { AccessControl } from 'src/components/AccessControl';
import { Button, ButtonSize } from '@ndc/react-component-library';
import { ProjectStatus, Scope } from 'src/constants/enums';
import {
    AddUserPermissions,
    EditUserRoles,
    InviteUser,
    RemoveUserRoles,
} from 'src/components/UserActions';
import { ProjectPermissions } from 'src/redux/Permissions/interfaces';
import { useSnackbar } from 'src/utils/snackbar';
import { useSelector } from 'react-redux';
import { accountSelectors } from 'src/redux/Account';
import { isGuidEmpty } from 'src/helpers/utils';

const Container = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
`;

const UsersHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const UsersHelp = styled(Typography)`
    margin-bottom: 20px;
    color: ${({ theme }) => theme.palette.neutral.carbon};
`;

export type Props = {
    projects: ProjectInfo[] | undefined;
    projectPermissions: ProjectPermissions | undefined;
    subscriptionId: Guid | undefined;
    subscriptionRoleAssignments?: User[];
    subscriptionInfo: SubscriptionInfo | undefined;
};

export type Actions = {
    getProjects: (subscriptionId: Guid) => any;
    resetProjects: () => any;
    getSubscriptionRoleAssignments: (subscriptionId: Guid) => any;
    showModalWithContents: (
        id: Guid,
        heading: string,
        content: ReactNode
    ) => void;
    showModalMessage: (id: Guid, heading: string, content: string) => void;
    closeModal: (id: Guid) => void;
};

const Users = ({
    projects,
    projectPermissions,
    subscriptionId,
    subscriptionRoleAssignments,
    subscriptionInfo,
    getProjects,
    resetProjects,
    getSubscriptionRoleAssignments,
    showModalWithContents,
    showModalMessage,
    closeModal,
}: Props & Actions) => {
    const getTranslation = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [usersAreUpdating, setUsersAreUpdating] = useState(true);
    const [activeModalId, setActiveModalId] = useState<Guid>(Guid.create());

    const activeUserId = useSelector(accountSelectors.getPrincipalId) ?? '';

    const filteredProjects = projects?.filter(
        (project) =>
            (project.status === ProjectStatus.Ongoing &&
                projectPermissions &&
                projectPermissions[project.id]?.createUsers) ??
            false
    );
    useEffect(() => {
        resetProjects();
    }, []);

    useEffect(() => {
        if (subscriptionId && !isGuidEmpty(subscriptionId)) {
            getProjects(subscriptionId);
            getSubscriptionRoleAssignments(subscriptionId);
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (subscriptionRoleAssignments !== undefined)
            setUsersAreUpdating(false);
    }, [subscriptionRoleAssignments]);

    const updateUsers = () => {
        setUsersAreUpdating(true);
        setTimeout(() => {
            if (subscriptionId) getSubscriptionRoleAssignments(subscriptionId);
        }, 500);
    };

    const invitedUserCallback = (
        isNewUser: boolean,
        email: string,
        accessLevel: string,
        error: any
    ) => {
        updateUsers();
        if (error) {
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                getTranslation('UserInvite.Failed.Title', {
                    accessLevel,
                }),
                getTranslation('UserInvite.Failed.Message', {
                    email,
                    accessLevel,
                })
            );
        } else {
            const snackbarMessage = getTranslation(
                translationKeys.UserInvite_Successful_Message
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
            closeModal(activeModalId);
        }
    };

    const editUserRolesCallback = (
        scopeType: string,
        userName: string,
        userChanged: boolean,
        error: any
    ) => {
        if (error) {
            updateUsers();
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                getTranslation(translationKeys.EditUser_Failed_Title, {
                    scopeType,
                }),
                getTranslation(translationKeys.EditUser_Failed_Message, {
                    userName,
                })
            );
        } else if (userChanged) {
            updateUsers();
            const snackbarMessage = getTranslation(
                translationKeys.EditUser_Successful_Message,
                {
                    scopeType,
                }
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
            closeModal(activeModalId);
        } else {
            closeModal(activeModalId);
        }
    };

    const removeUserRolesCallback = (
        scopeType: string,
        userName: string,
        userChanged: boolean,
        error: any
    ) => {
        closeModal(activeModalId);
        if (error) {
            updateUsers();
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                translationKeys.RemoveUser_Failed_Title,
                getTranslation(translationKeys.RemoveUser_Failed_Message, {
                    userName,
                })
            );
        } else if (userChanged) {
            updateUsers();
            const snackbarMessage = getTranslation(
                translationKeys.RemoveUser_Successful_Message,
                {
                    scopeType,
                }
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
        }
    };

    const addedUserPermissionsCallback = (
        scopeType: string,
        userName: string,
        resourceName: string,
        error: any
    ) => {
        updateUsers();
        if (error) {
            const modalId = Guid.create();
            showModalMessage(
                modalId,
                getTranslation(
                    translationKeys.AddUserPermissions_Failed_Title,
                    {
                        scopeType,
                    }
                ),
                getTranslation(
                    translationKeys.AddUserPermissions_Failed_Message,
                    {
                        userName,
                        resourceName,
                    }
                )
            );
        } else {
            const snackbarMessage = getTranslation(
                translationKeys.AddUserPermissions_Successful_Message,
                {
                    scopeType,
                }
            );
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
            closeModal(activeModalId);
        }
    };

    const addGlobalAccessToUser = (user: User) => {
        if (subscriptionInfo) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation(
                    translationKeys.Users_AddGlobalPermissions_Label
                ),
                <AddUserPermissions
                    scopeType="Global"
                    user={user}
                    resources={[subscriptionInfo]}
                    addedUserPermissionsCallback={addedUserPermissionsCallback}
                    subscriptionId={subscriptionInfo.subscriptionId.toString()}
                />
            );
        }
    };
    const addProjectAccessToUser = (
        user: User,
        nonAssignedProjects: ProjectInfo[]
    ) => {
        if (subscriptionId) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation(
                    translationKeys.Users_AddProjectPermissions_Label
                ),
                <AddUserPermissions
                    scopeType="Project"
                    user={user}
                    resources={nonAssignedProjects}
                    addedUserPermissionsCallback={addedUserPermissionsCallback}
                    subscriptionId={subscriptionId.toString()}
                />
            );
        }
    };

    const removeGlobalRolesOfUser = (user: User) => {
        if (subscriptionInfo) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation(translationKeys.Users_RemoveGlobalRoles_Label),
                <RemoveUserRoles
                    scopeType="Global"
                    user={user}
                    resource={subscriptionInfo}
                    removeUserRolesCallback={removeUserRolesCallback}
                    subscriptionId={subscriptionInfo.subscriptionId.toString()}
                />
            );
        }
    };

    const editGlobalRolesOfUser = (user: User) => {
        if (subscriptionInfo) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation(translationKeys.Users_EditGlobalRoles_Label),
                <EditUserRoles
                    scopeType="Global"
                    subscriptionOwnerEmail={
                        subscriptionInfo
                            ? subscriptionInfo.owner.email
                            : undefined
                    }
                    user={user}
                    resource={subscriptionInfo}
                    editUserRolesCallback={editUserRolesCallback}
                    subscriptionId={subscriptionInfo.subscriptionId.toString()}
                />
            );
        }
    };

    const removeUser = (user: User) => {
        if (subscriptionInfo) {
            setActiveModalId(Guid.create());
            showModalWithContents(
                activeModalId,
                getTranslation(translationKeys.Users_RemoveUser_Label),
                <RemoveUserRoles
                    scopeType="All"
                    user={user}
                    resource={subscriptionInfo}
                    removeUserRolesCallback={removeUserRolesCallback}
                    subscriptionId={subscriptionInfo.subscriptionId.toString()}
                />
            );
        }
    };

    return (
        <Container>
            <UsersHeader>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                    }}
                >
                    <Typography variant="h3">
                        {getTranslation(translationKeys.Users_Header)}
                    </Typography>
                    {usersAreUpdating && (
                        <CircularProgress color="secondary" size={30} />
                    )}
                </div>
                <AccessControl
                    scope={Scope.Subscription}
                    id={subscriptionId}
                    allowedPermission="createUsers"
                >
                    <Button
                        data-testid="add-users-button"
                        key={'add-users-button'}
                        size={ButtonSize.small}
                        onClick={() => {
                            setActiveModalId(Guid.create());
                            showModalWithContents(
                                activeModalId,
                                translationKeys.Users_AddUser_Label,
                                <InviteUser
                                    subscriptionId={
                                        subscriptionId?.toString() ?? ''
                                    }
                                    projects={filteredProjects}
                                    invitedUserCallback={invitedUserCallback}
                                    existingUserEmails={
                                        subscriptionRoleAssignments?.map(
                                            (u) => u.email
                                        ) ?? []
                                    }
                                />
                            );
                        }}
                    >
                        {getTranslation(translationKeys.Users_AddUser_Label)}
                    </Button>
                </AccessControl>
            </UsersHeader>
            <UsersHelp>{getTranslation(translationKeys.Users_Help)}</UsersHelp>
            {subscriptionRoleAssignments && (
                <UsersTable
                    activeUserId={activeUserId}
                    addGlobalAccessToUser={addGlobalAccessToUser}
                    addProjectAccessToUser={addProjectAccessToUser}
                    editGlobalRolesOfUser={editGlobalRolesOfUser}
                    filteredProjects={filteredProjects ?? []}
                    removeGlobalRolesOfUser={removeGlobalRolesOfUser}
                    removeUser={removeUser}
                    preventUserMenu={usersAreUpdating}
                    subscriptionId={subscriptionId?.toString() ?? ''}
                    subscriptionUsers={subscriptionRoleAssignments}
                    subscriptionOwnerEmail={
                        subscriptionInfo
                            ? subscriptionInfo.owner.email
                            : undefined
                    }
                />
            )}
        </Container>
    );
};

export default Users;
