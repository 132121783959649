import { Guid } from 'guid-typescript';
import { Reducer, Action } from 'redux';
import * as companyActions from './companyActions';
import * as companyConstants from './companyConstants';
import { CompanyState } from './interfaces';

export const initialState: CompanyState = {
    companies: undefined,
    selectedCompany: undefined,
};
export const reducer: Reducer<CompanyState> = (
    state: CompanyState = initialState,
    incomingAction: Action
): CompanyState => {
    const action = incomingAction as companyActions.KnownAction;
    switch (action.type) {
        case companyConstants.SET_COMPANIES:
            return { ...state, companies: action.companies };
        case companyConstants.SET_SELECTED_COMPANY:
            return { ...state, selectedCompany: action.company };
        default:
            return state;
    }
};
