import React, { useState } from 'react';
import { CreateEditComponent } from 'src/components/CreateEditComponent';
import {
    validateLayoutName,
    validateSiteName,
    validateSystemName,
    ValidatorResult,
} from 'src/helpers/validator';

export type Props = {
    createLayoutInProgress: boolean;
};

export type Actions = {
    CreateLayout: (
        siteName: string,
        systemName: string,
        layoutName: string
    ) => void;
};

const AddNewLayoutComponent = ({
    createLayoutInProgress,
    CreateLayout,
}: Props & Actions) => {
    const [siteName, setSiteName] = useState('');
    const [siteNameInvalid, setSiteNameInvalid] = useState(false);
    const [siteNameHelperText, setSiteNameHelperText] = useState('');
    const [systemName, setSystemName] = useState('');
    const [systemNameInvalid, setSystemNameInvalid] = useState(false);
    const [systemNameHelperText, setSystemNameHelperText] = useState('');
    const [layoutName, setLayoutName] = useState('');
    const [layoutNameInvalid, setLayoutNameInvalid] = useState(false);
    const [layoutNameHelperText, setLayoutNameHelperText] = useState('');

    const handleOnNameChange = (
        input: string,
        setHelperText: (helperText: string) => void,
        setName: (name: string) => void,
        setNameIsBad: (isBad: boolean) => void,
        validator: (name: string) => ValidatorResult
    ) => {
        const validtorResult: ValidatorResult = validator(input);
        setNameIsBad(!validtorResult.isValid);
        setHelperText(validtorResult.errorMessage);
        setName(input);
    };

    const handleOnSaveNamesClick = () => {
        const validSiteName = validateSiteName(siteName);
        const validSystemName = validateSystemName(systemName);
        const validLayoutName = validateLayoutName(layoutName);

        setSiteNameInvalid(!validSiteName.isValid);
        setSystemNameInvalid(!validSystemName.isValid);
        setLayoutNameInvalid(!validLayoutName.isValid);
        setSiteNameHelperText(validSiteName.errorMessage);
        setSystemNameHelperText(validSystemName.errorMessage);
        setLayoutNameHelperText(validLayoutName.errorMessage);
        if (
            validSiteName.isValid &&
            validSystemName.isValid &&
            validLayoutName.isValid
        ) {
            CreateLayout(siteName.trim(), systemName.trim(), layoutName.trim());
        } else {
            // TODO Indicate what's up. Shake input fields or something
        }
    };

    return (
        <>
            <CreateEditComponent
                headerText={'Add new layout'}
                defaultButtonLabel={'Create layout'}
                createEditInProgress={createLayoutInProgress}
                sections={[
                    {
                        title: 'Layout',
                        inputFields: [
                            {
                                id: 'site-name-input',
                                inputLabel: 'Site name',
                                type: 'textfield',
                                value: siteName,
                                onChange: (
                                    e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                    >
                                ) => {
                                    handleOnNameChange(
                                        e.target.value,
                                        setSiteNameHelperText,
                                        setSiteName,
                                        setSiteNameInvalid,
                                        validateSiteName
                                    );
                                },
                                required: true,
                                error: siteNameInvalid,
                                helperText: siteNameHelperText,
                            },
                            {
                                id: 'system-name-input',
                                inputLabel: 'System name',
                                type: 'textfield',
                                value: systemName,
                                onChange: (
                                    e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                    >
                                ) => {
                                    handleOnNameChange(
                                        e.target.value,
                                        setSystemNameHelperText,
                                        setSystemName,
                                        setSystemNameInvalid,
                                        validateSystemName
                                    );
                                },
                                required: true,
                                error: systemNameInvalid,
                                helperText: systemNameHelperText,
                            },
                            {
                                id: 'layout-name-input',
                                inputLabel: 'Layout name',
                                type: 'textfield',
                                value: layoutName,
                                onChange: (
                                    e: React.ChangeEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                    >
                                ) => {
                                    handleOnNameChange(
                                        e.target.value,
                                        setLayoutNameHelperText,
                                        setLayoutName,
                                        setLayoutNameInvalid,
                                        validateLayoutName
                                    );
                                },
                                required: true,
                                error: layoutNameInvalid,
                                helperText: layoutNameHelperText,
                            },
                        ],
                    },
                    /*// TODO Handle file upload at the same time as creating layout. Not a quick fix, since you need layoutId when uploading.
                                            {
                                                title: 'Files',
                                                inputFields: [
                                                    {
                                                        type: 'fileUpload',
                                                        element: layoutFileOverview,
                                                    },
                                                    {
                                                        type: 'fileUpload',
                                                        element: backgroundDrawingOverview,
                                                    },
                                                ],
                                            },*/
                ]}
                defaultButtonAction={() => handleOnSaveNamesClick()}
            ></CreateEditComponent>
        </>
    );
};
export default AddNewLayoutComponent;
