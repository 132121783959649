import useTranslation from 'src/hooks/useTranslation';
import styled from 'styled-components';
import {
    ProjectRoles,
    RoleIds,
    RoleIdToTranslationKeyMap,
} from 'src/constants/enums';
import RadioGroup from '../Input/components/RadioGroup';

const translationKeys = require('src/translations').default;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export type Props = {
    defaultSelectedRoles?: string[];
    showMissingRolesError: boolean;
};

export type Actions = {
    setSelectedRoles: (newRoles: string[]) => void;
};

const ProjectAccessSelector = ({
    defaultSelectedRoles = [],
    showMissingRolesError,
    setSelectedRoles,
}: Props & Actions) => {
    const getTranslation = useTranslation();

    return (
        <Container>
            <RadioGroup
                items={Object.values(ProjectRoles).map((role) =>
                    RoleIds[role].toString()
                )}
                defaultSelectedItems={defaultSelectedRoles}
                label={getTranslation(
                    translationKeys.UserInfo_AccessLevel_ProjectRoles_Label
                )}
                onChange={(selectedRoles: string[]) =>
                    setSelectedRoles(selectedRoles)
                }
                getItemText={(role: string) =>
                    getTranslation(
                        `Roles.${
                            RoleIdToTranslationKeyMap[role.toUpperCase()]
                        }.Title`
                    )
                }
                getHelpText={(role: string) =>
                    getTranslation(
                        `Roles.${
                            RoleIdToTranslationKeyMap[role.toUpperCase()]
                        }.Description`
                    )
                }
                error={showMissingRolesError}
                errorText={getTranslation(
                    translationKeys.Settings_AddRoles_ErrorText
                )}
                selectFirstAsDefault={true}
            />
        </Container>
    );
};

export default ProjectAccessSelector;
