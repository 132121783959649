import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const getSelectedSubscription = (state: ApplicationState) =>
    state.subscriptions.selectedSubscription;
const subscriptionsList = (state: ApplicationState) =>
    state.subscriptions.subscriptionList;
const subscriptions = (state: ApplicationState) =>
    state.subscriptions.subscriptions;
const currentSubscription = (state: ApplicationState) =>
    state.subscriptions.currentSubscription;
const eula = (state: ApplicationState) => state.subscriptions.eula;

export const getSubscriptions = createSelector(
    [subscriptions],
    (subscriptions) => subscriptions
);

export const getCurrentSubscription = createSelector(
    [currentSubscription],
    (currentSubscription) => currentSubscription
);

export const getSubscriptionId = createSelector(
    [currentSubscription],
    (currentSubscription) => currentSubscription.subscriptionId
);

export const getSubscriptionRoleAssignments = createSelector(
    [getSelectedSubscription],
    (subscription) => subscription.users
);

export const getSubscriptionInfo = createSelector(
    [getSelectedSubscription, subscriptionsList],
    (selectedSubscription, subscriptionsList) =>
        subscriptionsList[selectedSubscription.id?.toString() ?? '']
);

export const getEula = createSelector([eula], (eula) => eula);
