//Converted to javascript from http://gotgit1/green/vdt/-/blob/master/navlab/x4y4.py

// Calculation of x4y4 curves is done on a curve defined by:
// x^4 + (y-R)^4 = R^4

// the curve start at origo parallel with the x-axis.

// The curve is parameterized as:
// x(t) = R * sin(t)^(1/2)
// y(t) = R * (1- cos(t)^(1/2))

// Note that t has nothing to do with time, just an indepedent variable.

// dx/dt = 1/2*R*sin(t)^(-1/2)*cos(t)
// dy/dt = 1/2*R*cos(t)^(-1/2)*sin(t)
// dy/dx =  cos(t)^(-1/2)*sin(t) /(sin(t)^(-1/2)*cos(t))
// dy/dx =  tan(t)^3/2 = tan(th)
// t = atan(tan(th)^2/3)

const f_t = (th: number) => {
    /* Return the value of the independent variable t for a given
    angle of the tangent.*/
    return Math.atan(Math.tan(th) ** (2.0 / 3.0));
};

const unit_curvature = (th: number) => {
    /* Curvature for x4y4 with R == 1 */
    // Failed to derive this but it is used in ACC70, VMC500 and
    // WinLay.
    // Do calculation with z = y - R
    const t = f_t(th);
    const x2 = Math.cos(t);
    const z2 = Math.sin(t);
    return (3.0 * x2 * z2) / (x2 * x2 * x2 + z2 * z2 * z2) ** (3.0 / 2.0);
};

const solve_radius4 = (x_e: number, y_e: number, th4: number) => {
    /* Solve R in x^4 + (y-R)^4 = R */
    // The centre of rotation for the end point of the x4y4 part and
    // x2y2 part is projected down on the line between start and end
    // point of the complete curve. The length of the projection should
    // be half of the distance between start and end point.
    const t = f_t(th4);
    const r = 1.0 / unit_curvature(th4);
    const den =
        x_e * (Math.sqrt(Math.sin(t)) + r * -Math.sin(th4)) +
        y_e * (1 - Math.sqrt(Math.cos(t)) + r * Math.cos(th4));
    return (x_e * x_e + y_e * y_e) / 2 / den;
};

type Point = { x: number; y: number };
type PointWithTh = { x: number; y: number; th: number };

const _start_at_origo = (start: PointWithTh, end: PointWithTh) => {
    const s = Math.sin(start.th);
    const c = Math.cos(start.th);
    const rot = [
        { s: c, c: -s },
        { s: s, c: c },
    ];
    const x_e = end.x - start.x;
    const y_e = end.y - start.y;
    return { x_e: c * x_e + s * y_e, y_e: -s * x_e + c * y_e, rot: rot };
};

export default class Curve {
    x_s: number;
    y_s: number;
    ccw: boolean;
    th_m: number;
    dth424: number;
    radius4: number;
    radius2: number;
    x_c: number;
    y_c: number;
    u_dist: number;
    u_x: number;
    u_y: number;
    rot: any;

    constructor(
        start: PointWithTh,
        end: PointWithTh,
        bulge: number,
        dth424 = 0.0
    ) {
        const direction = Math.atan2(end.y - start.y, end.x - start.x);
        const a = 2.0 * Math.atan(bulge);
        start.th = direction - a;
        end.th = direction + a;
        // do computation with starting point at origo, transform back later
        let { x_e, y_e, rot } = _start_at_origo(start, end);

        this.rot = rot;
        this.x_s = start.x;
        this.y_s = start.y;
        // always do calculation as a ccw curve, flip back later if not ccw
        this.ccw = y_e >= 0.0;
        if (!this.ccw) y_e = -y_e;
        // half turning angle is the same as the direction to the end-point
        this.th_m = Math.atan2(y_e, x_e);
        // dth424 is not allowed to be bigger than half turning angle
        // and it must be less than pi / 2 so that tan(dth424) >= 0.
        const almost_half_pi = Math.PI / 2.0 - 1e-12;
        const max_dth424 = Math.min(this.th_m, almost_half_pi);
        this.dth424 = Math.min(dth424, max_dth424);
        if (this.dth424 > 0.0) {
            this.radius4 = solve_radius4(x_e, y_e, this.dth424);
            // compute radius of x2y2 parh (= radius at dth424)
            this.radius2 = this.radius4 / unit_curvature(this.dth424);
        } else {
            // Only x2y2
            this.radius2 = (x_e * x_e + y_e * y_e) / (2 * y_e);
            // Hack, makes this.x4y4(0) return useable value
            this.radius4 = this.radius2;
        }
        // compute constant for x4y4 and coordinates for switch-point (x,y)
        const { x, y } = this.x4y4(this.dth424);
        // centre for x2y2
        this.x_c = x - Math.sin(this.dth424) * this.radius2;
        this.y_c = y + Math.cos(this.dth424) * this.radius2;
        // distance and direction to mirror line
        this.u_dist = Math.hypot(x_e, y_e) / 2.0;
        this.u_x = Math.cos(this.th_m);
        this.u_y = Math.sin(this.th_m);
    }

    private x4y4 = (th: number): Point => {
        const t = f_t(th);
        const x = this.radius4 * Math.sqrt(Math.sin(t));
        const y = this.radius4 * (1 - Math.sqrt(Math.cos(t)));
        return { x, y };
    };

    private x2y2 = (th: number): Point => {
        const x = this.x_c + Math.sin(th) * this.radius2;
        const y = this.y_c - Math.cos(th) * this.radius2;
        return { x, y };
    };

    private mirror = (point: Point): Point => {
        const { x, y } = point;
        const s = this.u_dist - (x * this.u_x + y * this.u_y);
        return { x: x + 2 * s * this.u_x, y: y + 2 * s * this.u_y };
    };
    private points = (size = 20) => {
        let step = this.dth424 / size;
        let p: Point[] = [];
        // first x4y4 part

        //Following for loop is auto converted to javascript from python code below
        //for th in [step * i for i in range(size + 1)]:
        //p.append(self.x4y4(th))
        for (
            var th,
                _pj_c = 0,
                _pj_a = function () {
                    var _pj_d = [],
                        _pj_e = [...Array(size + 1).keys()];

                    for (
                        var _pj_f = 0, _pj_g = _pj_e.length;
                        _pj_f < _pj_g;
                        _pj_f += 1
                    ) {
                        var i = _pj_e[_pj_f];

                        _pj_d.push(step * i);
                    }

                    return _pj_d;
                }.call(this),
                _pj_b = _pj_a.length;
            _pj_c < _pj_b;
            _pj_c += 1
        ) {
            th = _pj_a[_pj_c];
            p.push(this.x4y4(th));
        }
        // x2y2 part if it exist
        const dth2 = 2 * (this.th_m - this.dth424);
        const dth424 = this.dth424;
        if (dth2 > 1.0e-6) {
            step = dth2 / size;
            //Following for loop is auto converted to javascript from python code below
            // for th in [self.dth424 + step * i for i in range(size + 1)]:
            //     p.append(self.x2y2(th))
            for (
                var th,
                    _pj_c = 0,
                    _pj_a = function () {
                        var _pj_d = [],
                            _pj_e = [...Array(size + 1).keys()];

                        for (
                            var _pj_f = 0, _pj_g = _pj_e.length;
                            _pj_f < _pj_g;
                            _pj_f += 1
                        ) {
                            var i = _pj_e[_pj_f];

                            _pj_d.push(dth424 + step * i);
                        }

                        return _pj_d;
                    }.call(this),
                    _pj_b = _pj_a.length;
                _pj_c < _pj_b;
                _pj_c += 1
            ) {
                th = _pj_a[_pj_c];
                p.push(this.x2y2(th));
            }
        }
        // second x4y4 part
        step = this.dth424 / size;
        //Following for loop is auto converted to javascript from python code below
        // for th in [self.dth424 - step * i for i in range(size + 1)]:
        //     if th >= 0.0:
        //         p.append(self.mirror(self.x4y4(th)))
        for (
            var th,
                _pj_c = 0,
                _pj_a = function () {
                    var _pj_d = [],
                        _pj_e = [...Array(size + 1).keys()];

                    for (
                        var _pj_f = 0, _pj_g = _pj_e.length;
                        _pj_f < _pj_g;
                        _pj_f += 1
                    ) {
                        var i = _pj_e[_pj_f];

                        _pj_d.push(dth424 - step * i);
                    }

                    return _pj_d;
                }.call(this),
                _pj_b = _pj_a.length;
            _pj_c < _pj_b;
            _pj_c += 1
        ) {
            th = _pj_a[_pj_c];

            if (th >= 0.0) {
                p.push(this.mirror(this.x4y4(th)));
            }
        }
        if (!this.ccw)
            p = p.map((item) => {
                return { x: item.x, y: -item.y };
            });
        // transform back to original coordinate system
        const { s, c } = this.rot[1];
        return p.map((item) => {
            return {
                x: this.x_s + c * item.x - s * item.y,
                y: this.y_s + s * item.x + c * item.y,
            };
        });
    };

    getPoints = (size?: number) => this.points(size);
}
