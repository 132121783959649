import { IntlShape } from 'react-intl';

export const fileDateFormatter = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // In JavaScript, getMonth returns January as 0.
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const second = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}_${hour}-${minute}-${second}`;
};

const dateOrTimeFormatter = (intl: IntlShape, date: Date) => {
    if (date === undefined) return '';
    date = new Date(date);
    let now = new Date();
    if (
        // is today
        date.getDate() == now.getDate() &&
        date.getMonth() == now.getMonth() &&
        date.getFullYear() == now.getFullYear()
    ) {
        return intl.formatTime(date);
    }
    return intl.formatDate(date);
};

export default dateOrTimeFormatter;
