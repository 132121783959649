import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const getCirrusInstallInfo = (state: ApplicationState) =>
    state.cirrusInstallInfo;

export const getCreatedDate = createSelector(
    [getCirrusInstallInfo],
    (cirrusInstallInfo) => cirrusInstallInfo?.info?.createdDate
);
export const getInfo = createSelector(
    [getCirrusInstallInfo],
    (cirrusInstallInfo) => cirrusInstallInfo?.info
);
export const getIsLoaded = createSelector(
    [getCirrusInstallInfo],
    (cirrusInstallInfo) => cirrusInstallInfo?.info?.loaded
);
export const getVersion = createSelector(
    [getCirrusInstallInfo],
    (cirrusInstallInfo) => cirrusInstallInfo?.info?.version
);
