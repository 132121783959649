import { Reducer, Action } from 'redux';
import * as modalActions from './modalActions';
import * as modalConstants from './modalConstants';
import { ModalState } from './interfaces';

export const initialState: ModalState = {
    modals: [],
};
export const reducer: Reducer<ModalState> = (
    state: ModalState = initialState,
    incomingAction: Action
): ModalState => {
    const action = incomingAction as modalActions.KnownAction;
    switch (action.type) {
        case modalConstants.OPEN_MODAL:
            return { ...state, modals: state.modals.concat(action.modalObj) };
        case modalConstants.CLOSE_MODAL:
            return {
                ...state,
                modals: state.modals.filter(
                    (item) => item.id !== action.modalId
                ),
            };
        default:
            return state;
    }
};
