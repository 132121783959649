import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';
import { NOT_FOUND } from '../redux/Projects/interfaces';
import api from './api';

export default class CirrusInstallFileService {
    url: string;

    constructor(url: string) {
        this.url = url;
    }
    getCirrusInstallVersionInformation() {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<string>({
                url: `${backends.projectApi}/api${backends.projectApiVersion}/me/action/cirrusinstallgetlatestfileinfo`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                if (error.status == 404) {
                    return NOT_FOUND;
                }
                alert('Failed to get file info');
                throw error;
            });
        });
    }
    getFileDownloadLink() {
        const requestConfig = customApiScopes.projectApiRequestConfig;

        return getTokenPopup(requestConfig).then((accessToken) => {
            const options = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-version': '1.0',
                    Authorization: `Bearer ${accessToken}`,
                },
            };

            return api<string>({
                url: `${backends.projectApi}/api${backends.projectApiVersion}/me/action/cirrusinstallgetlatestfileuri`,
                method: 'GET',
                options,
            }).catch((error) => {
                if (error.status == 403) {
                    throw error;
                }
                if (error.status == 404) {
                    return NOT_FOUND;
                }
                alert('Failed to download file');
                throw error;
            });
        });
    }
}
