import { PixiApp } from '../PixiApp';
import { Layer } from '@pixi/layers';

export default class LayerComponent extends Layer {
    id: string;

    constructor(
        app: PixiApp,
        width = app.renderer.width,
        height = app.renderer.height,
        id: string,
        zIndex = -1
    ) {
        super();
        this.id = id;
        this.name = this.id;
        this.pivot.x = width / 2;
        this.pivot.y = height / 2;
        this.position.x = app.renderer.width / 2;
        this.position.y = app.renderer.height / 2;
        this.zIndex = zIndex;
    }
}
