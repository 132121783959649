const wrapEmail = (email: string) => {
    // if word wrap is necessary - wrap at '@'
    const [beforeAt, afterAt] = email.split('@');
    return (
        <span>
            {beforeAt}
            {afterAt ? (
                <>
                    <wbr />@{afterAt}
                </>
            ) : (
                ''
            )}
        </span>
    );
};

export default wrapEmail;
