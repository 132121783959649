import { apiConstants, apiActions } from '../redux/Api';
import { ApplicationState } from '../reduxSetup/interfaces';
import { Guid } from 'guid-typescript';
import { Middleware } from 'redux';
import {
    subscriptionActions,
    subscriptionConstants,
} from '../redux/Subscriptions';
import { SubscriptionService, AccessValidatorService } from 'src/services';
import { AccessValidatorQuery } from 'src/services/interfaces';
import { permissionActions } from 'src/redux/Permissions';
import { SubscriptionPermissions } from 'src/redux/Permissions/interfaces';
import { modalActions } from 'src/redux/Modals';
import { ModalType } from 'src/constants/enums';
import Snackbar from 'src/utils/snackbar';
import { SubscriptionsDTO } from 'src/services/Dtos/SubscriptionDTO';
import { formatRoleAssignments } from 'src/helpers/roles';
import {
    EulaState,
    SubscriptionInfo,
    SubscriptionList,
    Subscriptions,
} from 'src/redux/Subscriptions/interfaces';

export const createSubscriptionAccessValidatorQuery = (subscriptionId: any) => {
    return {
        readProjects: `GET:/api/v1.0/subscriptions/${subscriptionId}/projects`,
        createProjects: `POST:/api/v1.0/subscriptions/${subscriptionId}/projects`,
        readCompanies: `GET:/api/v1.0/subscriptions/${subscriptionId}/companies`,
        createCompanies: `POST:/api/v1.0/subscriptions/${subscriptionId}/companies`,
        readUsers: `GET:/api/subscriptions/${subscriptionId}/roleassignments`,
        createUsers: `POST:/api/subscriptions/${subscriptionId}/roleassignments`,
        deleteUsers: `DELETE:/api/subscriptions/${subscriptionId}/roleassignments`,
        createSubscriptions: 'POST:/api/subscriptions',
        readOperationSettings: `GET:/api/subscriptions/${subscriptionId}/operationsettingstemplates`,
        createOperationSettings: `POST:/api/subscriptions/${subscriptionId}/operationsettingstemplates`,
    };
};

const subscriptionMiddleware: Middleware<
    {}, // legacy,
    ApplicationState
> = (store) => (next) => async (action) => {
    next(action);

    const dispatch = store.dispatch;
    const knownAction = action as
        | apiActions.KnownAction
        | subscriptionActions.KnownAction;
    const subscriptionService = new SubscriptionService();
    const accessValidatorService = new AccessValidatorService();

    const normalizeSubscriptionList = (
        subscriptions: Subscriptions
    ): SubscriptionList<SubscriptionInfo> => {
        const result: SubscriptionList<SubscriptionInfo> = {};

        subscriptions.forEach((s) => {
            result[s.subscriptionId.toString()] = {
                subscriptionId: s.subscriptionId,
                displayName: s.displayName,
                description: s.description,
                companyNumber: s.companyNumber,
                owner: {
                    firstName: s.owner.firstName,
                    lastName: s.owner.lastName,
                    email: s.owner.email,
                    phoneNumber: s.owner.phoneNumber,
                    displayName: s.owner.displayName,
                },
            };
        });

        return result;
    };

    switch (knownAction.type) {
        case apiConstants.GET_SUBSCRIPTION:
            await subscriptionService
                .getSubscriptions()
                .then((result) => {
                    const resultAs = result as Subscriptions;
                    dispatch(
                        subscriptionActions.SetSubscriptionList(
                            normalizeSubscriptionList(resultAs)
                        )
                    );

                    resultAs?.length &&
                        dispatch(
                            subscriptionActions.SetSubscriptions(resultAs)
                        );
                })
                .catch((error) => {
                    console.log(error);
                });
            break;
        case subscriptionConstants.SET_CURRENT_SUBSCRIPTION:
            if (!knownAction?.currentSubscription?.subscriptionId) {
                return;
            }
            let accessValidatorQuery: AccessValidatorQuery = {};
            accessValidatorQuery[
                knownAction?.currentSubscription?.subscriptionId?.toString()
            ] = createSubscriptionAccessValidatorQuery(
                knownAction.currentSubscription.subscriptionId
            );
            accessValidatorService
                .validateAccess(accessValidatorQuery)
                .then((accessValidatorResult) => {
                    dispatch(
                        permissionActions.SetSubscriptionsPermissions(
                            accessValidatorResult as unknown as SubscriptionPermissions
                        )
                    );
                });
            break;

        case apiConstants.GET_SUBSCRIPTION_ROLE_ASSIGNMENTS:
            await subscriptionService
                .getSubscriptionRoleAssignments(knownAction.subscriptionId)
                .then((result) => {
                    if (Array.isArray(result)) {
                        dispatch(
                            subscriptionActions.SetSubscriptionRoleAssignments(
                                formatRoleAssignments(result)
                            )
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            break;
        case apiConstants.CREATE_SUBSCRIPTION:
            await subscriptionService
                .createSubscription(knownAction.createSubscriptionCommand)
                .then((result) => {
                    const subscriptionInfo = result as SubscriptionInfo;
                    knownAction.createdCallback(true, subscriptionInfo);
                    Snackbar.success('Snackbar.CreateSubscription.Success');
                })
                .catch((error) => {
                    knownAction.createdCallback(false);
                    const modalId = Guid.create();
                    dispatch(
                        modalActions.OpenModal({
                            id: modalId,
                            heading: 'Error.CreateSubscription.Header',
                            type: ModalType.Message,
                            content: 'Error.CreateSubscription.Message',
                            primaryButton: {
                                label: 'General.Close',
                                onClick: () => {
                                    dispatch(modalActions.CloseModal(modalId));
                                },
                            },
                        })
                    );
                });
            break;
        case apiConstants.GET_EULA:
            try {
                const response = (await subscriptionService.getEula(
                    knownAction.subscriptionId
                )) as { status: number; json: () => Promise<any> };

                if (response === undefined) {
                    console.log('No Content 204');
                    const dataR = {
                        hasAccepted: false,
                    };
                    dispatch(subscriptionActions.SetEulaStatus(dataR));
                } else {
                    dispatch(
                        subscriptionActions.SetEulaStatus(
                            response as unknown as EulaState
                        )
                    );
                }
            } catch (error) {
                console.error(error);
            }
            break;
        case subscriptionConstants.SET_EULA:
            await subscriptionService
                .setEula(knownAction.subscriptionId)
                .then((result) => {
                    const eulaStatus = result as EulaState;
                    knownAction.setEulaCallback(eulaStatus.hasAccepted);
                })
                .catch((error) => {
                    console.log(error);
                });
            break;
    }
};

export default subscriptionMiddleware;
