import { Reducer, Action } from 'redux';
import * as layoutActions from './layoutActions';
import * as layoutConstants from './layoutConstants';
import { LayoutState } from './interfaces';

export const initialState: LayoutState = {
    layoutVisualization: {
        data: null,
        isLoading: false,
        isLoaded: false,
        error: '',
    },
    layoutBackgroundDrawing: {
        data: null,
        isLoading: false,
        isLoaded: false,
        error: '',
    },
};

export const reducer: Reducer<LayoutState> = (
    state: LayoutState = initialState,
    incomingAction: Action
): LayoutState => {
    const action = incomingAction as layoutActions.KnownAction;

    switch (action.type) {
        case layoutConstants.SET_LAYOUT_VISUALIZATION_DATA:
            return {
                ...state,
                layoutVisualization: {
                    ...state.layoutVisualization,
                    data: action.layoutVisualizationData,
                },
            };
        case layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_IS_LOADING:
            return {
                ...state,
                layoutVisualization: {
                    ...state.layoutVisualization,
                    isLoading: action.value,
                },
            };
        case layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_IS_LOADED:
            return {
                ...state,
                layoutVisualization: {
                    ...state.layoutVisualization,
                    isLoaded: action.value,
                },
            };
        case layoutConstants.SET_LAYOUT_VISUALIZATION_DATA_ERROR:
            return {
                ...state,
                layoutVisualization: {
                    ...state.layoutVisualization,
                    error: action.error,
                },
            };
        case layoutConstants.REMOVE_LAYOUT_VISUALIZATION_DATA:
            return {
                ...state,
                layoutVisualization: initialState.layoutVisualization,
            };

        case layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA:
            return {
                ...state,
                layoutBackgroundDrawing: {
                    ...state.layoutBackgroundDrawing,
                    data: action.layoutBackgroundDrawingData,
                },
            };
        case layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADING:
            return {
                ...state,
                layoutBackgroundDrawing: {
                    ...state.layoutBackgroundDrawing,
                    isLoading: action.value,
                },
            };
        case layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_IS_LOADED:
            return {
                ...state,
                layoutBackgroundDrawing: {
                    ...state.layoutBackgroundDrawing,
                    isLoaded: action.value,
                },
            };
        case layoutConstants.SET_LAYOUT_BACKGROUNDDRAWING_DATA_ERROR:
            return {
                ...state,
                layoutBackgroundDrawing: {
                    ...state.layoutBackgroundDrawing,
                    error: action.error,
                },
            };
        case layoutConstants.REMOVE_LAYOUT_BACKGROUNDDRAWING_DATA:
            return {
                ...state,
                layoutBackgroundDrawing: initialState.layoutBackgroundDrawing,
            };
        default:
            return state;
    }
};
