import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import ErrorContainer from './ErrorContainer';
import useTranslation from 'src/hooks/useTranslation';
import { history } from 'src/routing';
import { Button } from 'src/components/Button';

const ButtonContainer = styled.div`
    margin-top: 25px;
`;

const NotAuthorizedResource = () => {
    const getTranslation = useTranslation();
    const { instance } = useMsal();
    return (
        <ErrorContainer>
            <Typography variant="h6" gutterBottom>
                {getTranslation('ErrorPages.NotAuthorizedResource.Title')}
            </Typography>
            <Typography variant="body1">
                {getTranslation('ErrorPages.NotAuthorizedResource.Description')}
            </Typography>
            <ButtonContainer>
                <Button
                    key={'sign-out-btn'}
                    variant="contained"
                    style={{ width: 'auto' }}
                    onClick={() => history.push('/')}
                >
                    {getTranslation('ErrorPages.NotAuthorizedResource.CTA')}
                </Button>
            </ButtonContainer>
        </ErrorContainer>
    );
};

export default NotAuthorizedResource;
