import { Guid } from 'guid-typescript';

export enum ProjectViewerTabValue {
    Files = 'FILES',
    Vehicles = 'VEHICLES',
    Users = 'USERS',
    Updates = 'UPDATES',
}

export enum ProjectStatus {
    Ongoing = 0,
    Finished = 1,
}

type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

export enum Role {
    Owner,
    Contributor,
    SubscriptionCreator,
    CirrusEditor,
    CirrusLayoutOwner,
    CirrusSiteInstaller,
}
export enum RoleId {
    'f02667f0-7695-4918-911f-9500488bfee5' = Role.Owner,
    '8487dc22-e96a-45ff-af33-bbd4fcc0b546' = Role.Contributor,
    '373c72fd-9db2-485a-8d29-c30ba861da03' = Role.SubscriptionCreator,
    '1453dd32-80f9-471f-ae37-bd9277102501' = Role.CirrusEditor,
    'a2fb15df-139a-4a4a-9c45-8be165ee311e' = Role.CirrusLayoutOwner,
    '0cc7d727-5c2d-48f5-b87f-0d08b4975c67' = Role.CirrusSiteInstaller,
}

export const RoleIds: EnumDictionary<Role, Guid> = {
    [Role.Owner]: Guid.parse('F02667F0-7695-4918-911F-9500488BFEE5'),
    [Role.Contributor]: Guid.parse('8487DC22-E96A-45FF-AF33-BBD4FCC0B546'),
    [Role.SubscriptionCreator]: Guid.parse(
        '373C72FD-9DB2-485A-8D29-C30BA861DA03'
    ),
    [Role.CirrusEditor]: Guid.parse('1453DD32-80F9-471F-AE37-BD9277102501'),
    [Role.CirrusLayoutOwner]: Guid.parse(
        'A2FB15DF-139A-4A4A-9C45-8BE165EE311E'
    ),
    [Role.CirrusSiteInstaller]: Guid.parse(
        '0CC7D727-5C2D-48F5-B87F-0D08B4975C67'
    ),
};

export const RoleNames: EnumDictionary<Role, string> = {
    [Role.Owner]: 'Admin',
    [Role.Contributor]: 'Contributor',
    [Role.SubscriptionCreator]: 'SubscriptionCreator',
    [Role.CirrusEditor]: 'Editor',
    [Role.CirrusLayoutOwner]: 'Layout owner',
    [Role.CirrusSiteInstaller]: 'Site installer',
};

export const RoleTranslationKey: EnumDictionary<Role, string> = {
    [Role.Owner]: 'Owner',
    [Role.Contributor]: 'Contributor',
    [Role.SubscriptionCreator]: 'SubscriptionCreator',
    [Role.CirrusEditor]: 'CirrusEditor',
    [Role.CirrusLayoutOwner]: 'CirrusLayoutOwner',
    [Role.CirrusSiteInstaller]: 'CirrusSiteInstaller',
};

export const RoleIdToTranslationKeyMap: EnumDictionary<string, string> = {
    [RoleId[Role.Owner].toUpperCase()]: RoleTranslationKey[Role.Owner],
    [RoleId[Role.Contributor].toUpperCase()]:
        RoleTranslationKey[Role.Contributor],
    [RoleId[Role.SubscriptionCreator].toUpperCase()]:
        RoleTranslationKey[Role.SubscriptionCreator],
    [RoleId[Role.CirrusEditor].toUpperCase()]:
        RoleTranslationKey[Role.CirrusEditor],
    [RoleId[Role.CirrusLayoutOwner].toUpperCase()]:
        RoleTranslationKey[Role.CirrusLayoutOwner],
    [RoleId[Role.CirrusSiteInstaller].toUpperCase()]:
        RoleTranslationKey[Role.CirrusSiteInstaller],
};

export const RoleIdToRoleMap: EnumDictionary<string, Role> = {
    [RoleId[Role.Owner].toUpperCase()]: Role.Owner,
    [RoleId[Role.Contributor].toUpperCase()]: Role.Contributor,
    [RoleId[Role.SubscriptionCreator].toUpperCase()]: Role.SubscriptionCreator,
    [RoleId[Role.CirrusEditor].toUpperCase()]: Role.CirrusEditor,
    [RoleId[Role.CirrusLayoutOwner].toUpperCase()]: Role.CirrusLayoutOwner,
    [RoleId[Role.CirrusSiteInstaller].toUpperCase()]: Role.CirrusSiteInstaller,
};

export const GlobalRoles = [Role.Owner, Role.CirrusEditor, Role.Contributor];
export const ProjectRoles = [Role.CirrusLayoutOwner, Role.CirrusSiteInstaller];

export enum B2cErrorCode {
    InviteExpired = 'AADB2C90208',
}

export enum OperationCodeFormat {
    Hexadecimal = 'Hexadecimal',
    Decimal = 'Decimal',
}

export enum Scope {
    Subscription = 'SUBSCRIPTION',
    Project = 'PROJECT',
    OperationSettings = 'OPERATIONSETTINGS',
}

export enum LayoutLayers {
    BackgroundDrawingLayer = 'BACKGROUND_DRAWIG_LAYER',
    SelectionLayer = 'SELECTION_LAYER',
    PointLayer = 'POINT_LAYER',
    SegmentLayer = 'SEGMENT_LAYER',
    UpdatedPointLayer = 'UPDATED_POINT_LAYER',
    UpdatedSegmentLayer = 'UPDATED_SEGMENT_LAYER',
}
export enum LayoutObjectType {
    POINT = 'POINT',
    SEGMENT = 'SEGMENT',
    UPDATEDPOINT = 'UPDATEDPOINT',
    UPDATEDSEGMENT = 'UPDATEDSEGMENT',
}

export enum ModalType {
    Message = 'MESSAGE',
    Custom = 'CUSTOM',
}

export enum PointSpriteName {
    ORIGINAL = 'originalPoint',
    UPDATED = 'updatedPoint',
}

export enum LocalStorageEnum {
    ACTIVESUBSCRIPTIONID = 'activeSubscriptionId',
}

export enum ArchiveType {
    Downloaded,
    Manual,
}
