import { createSelector } from 'reselect';
import { ApplicationState } from 'src/reduxSetup/interfaces';

const getLayoutVisualization = (state: ApplicationState) =>
    state.layout.layoutVisualization;
const getLayoutBackgroundDrawing = (state: ApplicationState) =>
    state.layout.layoutBackgroundDrawing;

export const getLayoutVisualizationData = createSelector(
    [getLayoutVisualization],
    (lv) => lv.data
);
export const getLayoutVisualizationDataIsLoading = createSelector(
    [getLayoutVisualization],
    (lv) => lv.isLoading
);
export const getLayoutVisualizationDataIsLoaded = createSelector(
    [getLayoutVisualization],
    (lv) => lv.isLoaded
);
export const getLayoutVisualizationDataError = createSelector(
    [getLayoutVisualization],
    (lv) => lv.error
);

export const getLayoutBackgroundDrawingData = createSelector(
    [getLayoutBackgroundDrawing],
    (lv) => lv.data
);
export const getLayoutBackgroundDrawingDataIsLoading = createSelector(
    [getLayoutBackgroundDrawing],
    (lv) => lv.isLoading
);
export const getLayoutBackgroundDrawingDataIsLoaded = createSelector(
    [getLayoutBackgroundDrawing],
    (lv) => lv.isLoaded
);
export const getLayoutBackgroundDrawingDataError = createSelector(
    [getLayoutBackgroundDrawing],
    (lv) => lv.error
);
