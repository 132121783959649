import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { customApiScopes } from '../auth/apiScopes';
import { getTokenPopup } from '../auth/authUtils';
import { backends } from '../common/backends';

export const projectApi = createApi({
    reducerPath: 'projectApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${backends.projectApi}/api/`,
        prepareHeaders: async (headers) => {
            const requestConfig = customApiScopes.projectApiRequestConfig;
            const token = await getTokenPopup(requestConfig);
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            headers.set('Accept', 'application/json');
            headers.set('Content-Type', 'application/json');

            return headers;
        },
    }),

    endpoints: () => ({}),
});
