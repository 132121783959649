import * as addressConstants from './addressConstants';
import { AddressInfo } from './interfaces';

export interface SetAddressesAction {
    type: typeof addressConstants.SET_ADDRESSES;
    addresses: AddressInfo[] | undefined;
}

export const SetAddresses = (addresses: AddressInfo[]) => ({
    type: addressConstants.SET_ADDRESSES,
    addresses,
});

export type KnownAction = SetAddressesAction;
