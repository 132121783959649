import { Action, Reducer } from 'redux';
import * as cirrusInstallFileInfoActions from './cirrusInstallInfoActions';
import * as cirrusInstallFileInfoConstants from './cirrusInstallInfoConstants';
import { CirrusInstallInfoState } from './interfaces';

export const initialState: CirrusInstallInfoState = {
    info: undefined,
};

export const reducer: Reducer<CirrusInstallInfoState> = (
    state: CirrusInstallInfoState = initialState,
    incomingAction: Action
): CirrusInstallInfoState => {
    const action = incomingAction as cirrusInstallFileInfoActions.KnownAction;

    switch (action.type) {
        case cirrusInstallFileInfoConstants.SET_CIRRUS_INSTALL_INFO:
            return { ...state, info: action.info };
        default:
            return state;
    }
};
