import { ApplicationState } from './interfaces';
import { initialState as account } from '../redux/Account/accountReducers';
import { initialState as addresses } from '../redux/Addresses/addressReducers';
import { initialState as cirrusInstallInfo } from '../redux/CirrusInstallInfo/cirrusInstallInfoReducers';
import { initialState as companies } from '../redux/Companies/companyReducers';
import { initialState as layout } from '../redux/Layout/layoutReducers';
import { initialState as modals } from '../redux/Modals/modalReducers';
import { initialState as permissions } from '../redux/Permissions/permissionReducers';
import { initialState as project } from '../redux/Projects/projectReducers';
import { initialState as subscriptions } from '../redux/Subscriptions/subscriptionReducers';
import { initialState as operationSettings } from '../redux/OperationSettings/operationSettingsReducers';

export const initialState: ApplicationState = {
    account,
    addresses,
    cirrusInstallInfo,
    companies,
    layout,
    modals,
    permissions,
    project,
    subscriptions,
    operationSettings,
};
