import React from 'react';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';

const StyledRadioButton = styled(Radio)`
    ${({ theme }) => `
    && {
        color: ${theme.palette.neutral.dark};
        &.Mui-checked {
            color: ${theme.palette.primary.dark};
        }
    }
    `}
`;

const RadioButton = (props: any) => {
    return <StyledRadioButton {...props} />;
};

RadioButton.propTypes = {
    props: PropTypes.any,
};

export default RadioButton;
