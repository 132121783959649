import { useIntl } from 'react-intl';
import { getEnTranslation } from '../translations';

export default () => {
    const intl = useIntl();

    const getTranslation = (
        key: string,
        values: Record<string, string | number | boolean | undefined> = {}
    ) => {
        if (!key) throw Error;
        return intl.formatMessage(
            {
                id: key,
                defaultMessage: getEnTranslation(key),
            },
            values
        );
    };

    return getTranslation;
};
